import NgoModel from "../../models/Ngo";
import firebase from "../../utils/firebase";

let ngoSocket: any = null;

export const getNgo = () => {
  return async (dispatch: any, getState: any) => {
    try {
      if (!ngoSocket) {
        let ngoList: NgoModel[] = [];

        ngoSocket = firebase
          .firestore()
          .collection("ngoList")
          .onSnapshot((querySnapshot) => {
            ngoList = [];
            querySnapshot.forEach((doc) => {
              ngoList.push(doc.data() as NgoModel);
            });

            dispatch({
              type: "UPDATE_NGO",
              payload: {
                ngo: ngoList,
              },
            });
          });
      }
    } catch (err) {}
  };
};

export const clearNgoSocket = () => {
  if (ngoSocket) {
    ngoSocket();
    ngoSocket = null;
  }
};
