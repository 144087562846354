import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

class PrivacyPolicy extends Component<Props> {
  handleNavigate = (url: string) => {
    window.open(url);
  };

  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Header history={this.props.history} />
        <div className="relative max-w-7xl text-justify pt-6 pb-16 px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl font-bold">{i18n.t("privacy.title1")}</h2>
          <p className="mt-2 text-sm">{i18n.t("privacy.paragraph1")}</p>
          <p className="mt-2 text-sm">{i18n.t("privacy.paragraph2")}</p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title2")}
          </h2>
          <p className="mt-2 text-sm">
            {i18n.t("privacy.paragraph3")}
            <Link to="/termscondition">
              <span className="text-custom-red-500 hover:text-custom-red-400">
                {i18n.t("privacy.reference1")}
              </span>
            </Link>
            {i18n.t("privacy.terms")}
          </p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title3")}
          </h2>
          <p className="mt-4 text-sm font-bold">
            {i18n.t("privacy.boldparagraph4")}
          </p>
          <p className="mt-2 text-sm">{i18n.t("privacy.paragraph5")}</p>
          <ul className="ml-4 mt-2 list-disc">
            <li>
              <span className="font-bold">{i18n.t("privacy.bold1")}</span>
              {i18n.t("privacy.point1")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold2")}</span>
              {i18n.t("privacy.point2")}
              {i18n.t("privacy.reference2")}
              {i18n.t("privacy.point2a")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold3")}</span>
              {i18n.t("privacy.point3")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold4")}</span>
              {i18n.t("privacy.point4")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold5")}</span>
              {i18n.t("privacy.point5")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold6")}</span>
              {i18n.t("privacy.point6")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold7")}</span>
              {i18n.t("privacy.point7")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.point8")}</span>
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold9")}</span>
              {i18n.t("privacy.point9")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold10")}</span>
              {i18n.t("privacy.point10")}
            </li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title5")}
          </h2>
          <p className="mt-4 text-sm">{i18n.t("privacy.paragraph7")}</p>
          <ul className="ml-4 mt-2 list-disc">
            <li>
              <span className="font-bold">{i18n.t("privacy.bold11")}</span>
              {i18n.t("privacy.point11")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold12")}</span>
              {i18n.t("privacy.point12")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold13")}</span>
              {i18n.t("privacy.point13")}
            </li>
            <li>
              <span className="font-bold">{i18n.t("privacy.bold14")}</span>
              {i18n.t("privacy.point14")}
              {i18n.t("privacy.reference3")}
              {i18n.t("privacy.point14a")}
            </li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title6")}
          </h2>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph8")}
            </span>
            {i18n.t("privacy.paragraph8")}
          </p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("privacy.point15")}</li>
            <li>{i18n.t("privacy.point16")}</li>
            <li>{i18n.t("privacy.point17")}</li>
            <li>{i18n.t("privacy.point18")}</li>
            <li>{i18n.t("privacy.point19")}</li>
            <li>{i18n.t("privacy.point20")}</li>
            <li>{i18n.t("privacy.point21")}</li>
          </ul>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph9")}
            </span>
            {i18n.t("privacy.paragraph9")}
          </p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("privacy.point22")}</li>
            <li>{i18n.t("privacy.point23")}</li>
            <li>
              {i18n.t("privacy.point24")}
              {i18n.t("privacy.reference4")}
            </li>
            <li>{i18n.t("privacy.point25")}</li>
            <li>{i18n.t("privacy.point26")}</li>
            <li>{i18n.t("privacy.point27")}</li>
            <li>{i18n.t("privacy.point28")}</li>
            <li>{i18n.t("privacy.point29")}</li>
            <li>{i18n.t("privacy.point30")}</li>
            <li>
              {i18n.t("privacy.point31")}
              <Link to="/termscondition">
                <span className="text-custom-red-500 hover:text-custom-red-400">
                  {i18n.t("privacy.reference5")}
                </span>
              </Link>
              {i18n.t("privacy.point31a")}
              {i18n.t("privacy.reference6")}
              {i18n.t("privacy.point31b")}
            </li>
            <li>{i18n.t("privacy.point32")}</li>
          </ul>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph10")}
            </span>
            {i18n.t("privacy.paragraph10")}
          </p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("privacy.point33")}</li>
            <li>{i18n.t("privacy.point34")}</li>
            <li>{i18n.t("privacy.point35")}</li>
            <li>
              {i18n.t("privacy.point36")}
              {i18n.t("privacy.reference7")}
              {i18n.t("privacy.point36a")}
            </li>
            <li>{i18n.t("privacy.point37")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title7")}
          </h2>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph11")}
            </span>
            <br />
            {i18n.t("privacy.paragraph12")}
            <br />
            {i18n.t("privacy.paragraph13")}
          </p>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph14")}
            </span>
            <br />
            {i18n.t("privacy.paragraph15")}
            <br />
            {i18n.t("privacy.point38")}
          </p>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph16")}
            </span>
            <br />
            {i18n.t("privacy.paragraph17")}
            {i18n.t("privacy.reference8")}
            {i18n.t("privacy.paragraph17a")}
            {i18n.t("privacy.reference9")}
            {i18n.t("privacy.paragraph17b")}
            <br />
            <br />
            {i18n.t("privacy.paragraph18")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph19")}
            </span>
            <br />
            {i18n.t("privacy.paragraph19")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph20")}
            </span>
            <br />
            {i18n.t("privacy.paragraph21")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">{i18n.t("privacy.bold39")}</span>
            <br />
            {i18n.t("privacy.point39")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">{i18n.t("privacy.bold40")}</span>
            <br />
            {i18n.t("privacy.point40")}
          </p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("privacy.title8")}
          </h2>
          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph21")}
            </span>
            <br />
            {i18n.t("privacy.paragraph22")}
            <Link to="/termscondition">
              <span className="text-custom-red-500 hover:text-custom-red-400">
                {i18n.t("privacy.reference10")}
              </span>
            </Link>
            {i18n.t("privacy.paragraph22a")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph23")}
            </span>
            <br />
            {i18n.t("privacy.paragraph23")}
          </p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("privacy.point41")}</li>
            <li>{i18n.t("privacy.point42")}</li>
            <li>{i18n.t("privacy.point43")}</li>
            <li>{i18n.t("privacy.point44")}</li>
            <li>{i18n.t("privacy.point45")}</li>
            <li>{i18n.t("privacy.point46")}</li>
          </ul>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph24")}
            </span>
            <br />
            {i18n.t("privacy.paragraph25")}
            <span
              className="cursor-pointer text-custom-red-500 hover:text-custom-red-400"
              onClick={this.handleNavigate.bind(
                this,
                "https://www.google.com/intl/en_us/help/terms_maps.html"
              )}
            >
              {i18n.t("privacy.reference11")}
            </span>
            {" , "}
            <span
              className="cursor-pointer text-custom-red-500 hover:text-custom-red-400"
              onClick={this.handleNavigate.bind(
                this,
                "https://www.google.com/privacy.html"
              )}
            >
              {i18n.t("privacy.reference12")}
            </span>
            {i18n.t("privacy.paragraph25a")}
            <span
              className="cursor-pointer text-custom-red-500 hover:text-custom-red-400"
              onClick={this.handleNavigate.bind(
                this,
                "https://policies.google.com/technologies/partner-sites"
              )}
            >
              {i18n.t("privacy.reference13")}
            </span>{" "}
            {i18n.t("privacy.paragraph25b")} {i18n.t("privacy.paragraph25c")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph25")}
            </span>
            <br />
            {i18n.t("privacy.paragraph26")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph27")}
            </span>
            <br />
            {i18n.t("privacy.paragraph27")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph28")}
            </span>
            <br />
            {i18n.t("privacy.paragraph28")}
            <br />
            {i18n.t("privacy.paragraph29")}
            <br />
            {i18n.t("privacy.paragraph30")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph31")}
            </span>
            <br />
            {i18n.t("privacy.paragraph32")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph33")}
            </span>
            <br />
            {i18n.t("privacy.paragraph34")}
          </p>

          <p className="mt-4 text-sm">
            <span className="font-bold">
              {i18n.t("privacy.boldparagraph35")}
            </span>
            <br />
            {i18n.t("privacy.paragraph36")}
          </p>

          <p className="mt-10 text-sm font-bold">
            {i18n.t("privacy.boldaddress1")}
          </p>
          <ul className="mt-2">
            <li>{i18n.t("privacy.address2")}</li>
            <li>{i18n.t("privacy.address3")}</li>
            <li>{i18n.t("privacy.address4")}</li>
            <li>{i18n.t("privacy.address5")}</li>
            <li>{i18n.t("privacy.address6")}</li>
          </ul>
        </div>

        <Footer history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(PrivacyPolicy);
