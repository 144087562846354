import { combineReducers } from "redux";
import authReducer from "./authReducer";
import bookReducer from "./bookReducer";
import centerReducer from "./centerReducer";
import ngoReducer from "./ngoReducer";
import resReducer from "./resReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  utilsStore: utilsReducer,
  centerStore: centerReducer,
  resStore: resReducer,
  ngoStore: ngoReducer,
  bookStore: bookReducer,
});

export default rootReducer;
