import { Radio } from "antd";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/base/Button";
import Footer from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { createRiskStatus } from "../store/actions/statusActions";
import { handleNotification } from "../store/actions/utilsActions";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  match: any;
}

interface Question {
  title: string;
  titleA?: string;
  titleB?: string;
  titleC?: string;
  titleD?: string;
  titleE?: string;
  titleF?: string;
  titleG?: string;
  titleH?: string;
  titleI?: string;
  titleJ?: string;
  titleA1?: string;
  titleB1?: string;
  titleC1?: string;
  titleD1?: string;
  a: string;
  b: string;
  answer: string;
}

interface State {
  questions: Question[];
  answers: { [key: string]: string };
  answerError: { [key: string]: boolean };
}

class RiskStatus extends Component<Props> {
  state: State = {
    questions: [
      {
        title: i18n.t("riskStatus.questionrisk1"),
        titleA: i18n.t("riskStatus.question1a"),
        titleB: i18n.t("riskStatus.question1b"),
        titleC: i18n.t("riskStatus.question1c"),
        titleD: i18n.t("riskStatus.question1d"),
        titleE: i18n.t("riskStatus.question1e"),
        titleF: i18n.t("riskStatus.question1f"),
        titleG: i18n.t("riskStatus.question1g"),
        titleH: i18n.t("riskStatus.question1h"),
        titleI: i18n.t("riskStatus.question1i"),
        titleJ: i18n.t("riskStatus.question1j"),
        a: i18n.t("riskStatus.answer1"),
        b: i18n.t("riskStatus.answer2"),
        answer: "b",
      },
      {
        title: i18n.t("riskStatus.question2"),
        titleA1: i18n.t("riskStatus.question2a"),
        titleB1: i18n.t("riskStatus.question2b"),
        titleC1: i18n.t("riskStatus.question2c"),
        titleD1: i18n.t("riskStatus.question2d"),
        a: i18n.t("riskStatus.answer1"),
        b: i18n.t("riskStatus.answer2"),
        answer: "b",
      },
      {
        title: i18n.t("riskStatus.question3"),
        a: i18n.t("riskStatus.answer1"),
        b: i18n.t("riskStatus.answer2"),
        answer: "b",
      },
      {
        title: i18n.t("riskStatus.question4"),
        a: i18n.t("riskStatus.answer1"),
        b: i18n.t("riskStatus.answer2"),
        answer: "b",
      },
      {
        title: i18n.t("riskStatus.question5"),
        a: i18n.t("riskStatus.answer1"),
        b: i18n.t("riskStatus.answer2"),
        answer: "b",
      },
    ],
    answers: {
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
    },
    answerError: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleChangeRadio = (indexString: string, choice: string, e: any) => {
    let tempAnswer = JSON.parse(JSON.stringify(this.state.answers));
    tempAnswer[indexString as keyof typeof tempAnswer] = choice;
    this.setState({
      answers: tempAnswer,
    });
  };

  handleCreateStatus = () => {
    const answerError: { [key: string]: boolean } = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    };
    let conditionPassed = 0;
    Object.keys(this.state.answers).map((eachAnswer, index) => {
      const selectedAnswer = this.state.answers[eachAnswer];
      if (!selectedAnswer) {
        answerError[eachAnswer] = true;
      } else {
        conditionPassed += 1;
        answerError[eachAnswer] = false;
      }
      return null;
    });
    this.setState(
      {
        answerError: answerError,
      },
      async () => {
        if (conditionPassed >= 5) {
          await createRiskStatus({
            userId: this.handleGetParams(),
            answer: this.state.answers,
          });
          handleNotification("success", "Risk Status Updated", "");
          handleNavigation(this.props.history, "/");
        }
      }
    );
  };

  renderQuestions = (index: string) => {
    const listClassName = "mt-1 text-base";
    const detail = this.state.questions[Number(index) - 1];
    const answerError = this.state.answerError[index];
    let indexString = index.toString();
    return (
      <div key={index} className="w-full mt-4 mb-8">
        <p className="font-bold text-base">{detail.title}</p>
        {detail.titleA && (
          <ul className="ml-10 mt-2 list-disc">
            <li className={listClassName}>{detail.titleA}</li>
            <li className={listClassName}>{detail.titleB}</li>
            <li className={listClassName}>{detail.titleC}</li>
            <li className={listClassName}>{detail.titleD}</li>
            <li className={listClassName}>{detail.titleE}</li>
            <li className={listClassName}>{detail.titleF}</li>
            <li className={listClassName}>{detail.titleG}</li>
            <li className={listClassName}>{detail.titleH}</li>
            <li className={listClassName}>{detail.titleI}</li>
            <li className={listClassName}>{detail.titleJ}</li>
          </ul>
        )}
        {detail.titleA1 && (
          <ul className="ml-10 mt-2 list-disc">
            <li className={listClassName}>{detail.titleA1}</li>
            <li className={listClassName}>{detail.titleB1}</li>
            <li className={listClassName}>{detail.titleC1}</li>
            <li className={listClassName}>{detail.titleD1}</li>
          </ul>
        )}

        <div className="mt-6 space-y-4">
          {this.renderRadioButton(indexString, detail.a, "yes")}
          {this.renderRadioButton(indexString, detail.b, "no")}
        </div>

        {answerError && (
          <p className="font-bold text-sm text-custom-red-600 mt-4">
            {i18n.t("riskStatus.error")}
          </p>
        )}
      </div>
    );
  };

  renderRadioButton = (indexString: string, title: string, choice: string) => {
    let checked = false;
    if (indexString in this.state.answers) {
      const selectedAnswer = this.state.answers[indexString];
      if (choice === selectedAnswer) {
        checked = true;
      }
    }

    return (
      <div className="flex items-center">
        <Radio
          className="h-5 w-4"
          checked={checked}
          onChange={this.handleChangeRadio.bind(this, indexString, choice)}
        ></Radio>
        {/* <input
          type="radio"
          className="h-4 w-4 text-custom-red-600 focus:ring-custom-red-500 border-gray-500 cursor-pointer"
          checked={checked}
          style={{ color: "#d6d6d6 !important" }}
          onChange={this.handleChangeRadio.bind(this, indexString, choice)}
        ></input> */}
        <label
          htmlFor="push_everything"
          className="ml-3 block text-base font-medium text-gray-700"
        >
          {title}
        </label>
      </div>
    );
  };

  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Header history={this.props.history} />
        <div className="max-w-7xl w-full flex flex-col item-start px-6">
          <h1 className="text-3xl tracking-tight font-extrabold mt-10 mb-2">
            {i18n.t("riskStatus.titlerisk")}
          </h1>
          {Object.keys(this.state.answers).map((eachKey: string) =>
            this.renderQuestions(eachKey)
          )}
          <div className="w-full mt-6 mb-20 flex justify-end">
            <Button
              text={i18n.t("riskStatus.titlebutton")}
              className="w-full sm:w-auto"
              type="normal"
              onClick={this.handleCreateStatus}
            />
          </div>
        </div>
        <Footer history={this.props.history} />
      </VerticalContainer>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(RiskStatus);
