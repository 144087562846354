import styled from "styled-components";
import { DeviceSize } from "../store/reducers/utilsReducer";

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FlexibleRowContainer = styled(RowContainer)`
  @media only screen and (max-width: ${DeviceSize.minTabletBreakpoints - 1}px) {
    flex-direction: column;
  }
`;

export const FlexibleVerticalContainer = styled(VerticalContainer)`
  @media only screen and (max-width: ${DeviceSize.minTabletBreakpoints - 1}px) {
    flex-direction: row;
  }
`;
