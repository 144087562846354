export enum ProfessionType {
  STUDENT = "Student",
  HEALTH = "Doctor/Nurse/Medical Workers",
  NGO = "Society/Body/Agency",
  OTHERS = "Others",
}

export enum GenderType {
  M = "Male",
  F = "Female",
}

export enum RaceType {
  MALAY = "Malay",
  CHINESE = "Chinese",
  INDIAN = "Indian",
  SABAHBUMI = "Bumiputera - Sabah",
  SARAWAKBUMI = "Bumiputera - Sarawak",
  SEMENANJUNGBUMI = "Bumiputera - Semenanjung",
  OTHERS = "Others",
}

export interface Address {
  street: string;
  district: string;
  poscode: string;
  state: string;
}

export enum BankType {
  AFFIN = "AFFIN BANK",
  ALLIANCE = "ALLIANCE BANK MALAYSIA BERHAD",
  AL_RAJHI = "AL_RAJHI BANK",
  AMBANK = "AMBANK BHD",
  BANKISLAM = "BANK ISLAM MALAYSIA BERHAD",
  BANKKERJASAMARAKYATMALAYSIA = "BANK KERJASAMA RAKYAT MALAYSIA",
  BANKMUAMALAT = "BANK MUAMALAT",
  BANKOFCHINA = "BANK OF CHINA (MALAYSIA) BERHAD",
  BANKPERTANIAN = "BANK PERTANIAN MALAYSIA BERHAD (AGROBANK)",
  BSN = "BANK SIMPANAN NASIONAL",
  CIMB = "CIMB BANK BHD",
  CITI = "CITIBANK BHD",
  DEUTSCHEBANK = "DEUTSCHE BANK",
  HLB = "HONG LEONG BANK BHD",
  HSBC = "HSBC BANK MALAYSIA BHD",
  ICBC = "INDUSTRIAL AND COMMERCIAL BANK OF CHINA",
  JPMORGANCHASE = "J.P. MORGAN CHASE BANK BERHAD",
  KFH = "KUWAIT FINANCE HOUSE (MALAYSIA) BHD",
  MAYBANK = "MALAYAN BANKING BHD (MAYBANK)",
  OCBC = "OCBC BANK MALAYSIA BHD",
  PBB = "PUBLIC BANK BHD",
  RHB = "RHB BANK BHD",
  STANDARDCHARTERED = "STANDARD CHARTERED BANK BHD",
  UOB = "UNITED OVERSEAS BANK MALAYSIA BHD",
}

export enum NGOType {
  MOH = "Ministry of Health",
  MOY = "Ministry of Youth & Sports",
  MOSTI = "Ministry of Science, Techonology & Innovation",
  MOHE = "Ministry of Higher Education",
  MRCS = "Malaysian Red Crescent Society (MRCS)",
  MERCY = "MERCY Malaysia",
  TZUCHI = "Tzu Chi Foundation Malaysia",
  IMARET = "IMARET",
  MRA = "Malaysian Relief Agency (MRA)",
  INTEL = "Intel Corporation",
  OTHERS = "Others",
}

export enum ProfessionHealthType {
  DOC = "Doctor",
  NUR = "Nurse",
  DEN = "Dentist",
  MA = "Medical Assistant",
  PHA = "Pharmacist",
  GRADDOC = "Graduate Doctor",
  GRADDEN = "Graduate Dentist",
  GRADPHA = "Graduate Pharmacist",
}

export interface User {
  id: string;
  createdAt: Date;
  ic: string;
  name: string;
  age: number;
  gender: keyof typeof GenderType;
  race: keyof typeof RaceType;
  raceOthers: string;
  address: Address;
  email: string;
  mobileNo: string;
  profession: keyof typeof ProfessionType;
  professionOthers: string;
  professionHealthId: string;
  professionHealthType?: keyof typeof ProfessionHealthType;
  verifiedHealthID?: boolean;
  organisationName: NGOType | string;
  organisationAddress: string;
  bankName: keyof typeof BankType;
  bankAccountName: string;
  bankAccountNo: string;
  imageToken: string;
  nameNextofKin?: string;
  relationship?: string;
  contactNoNextofKin?: string;
  secretAgreement?: {
    token: string;
    name: string;
    date: Date;
  };
  liabilityAgreement?: {
    token: string;
    name: string;
    date: Date;
  };
  healthDeclaration?: {
    token: string;
    name: string;
    date: Date;
  };
  offerLetter?: {
    token: string;
    name: string;
    date: Date;
  };
  medicalDegree?: {
    token: string;
    name: string;
    date: Date;
  };
  attendanceBriefing?: boolean;
  quizResult?: {
    passed: boolean;
    date: Date;
  };
  blacklisted?: boolean;
}
