import React, { Component } from "react";
import styled from "styled-components";
import { COLOR } from "../styles/Colors";
import { RowContainer } from "../styles/Layout";
import { BaseActionText, InvertedSmallActionText } from "../styles/Typography";

interface Props {
  width: number | string;
  text: string;
  small?: boolean;
  outline?: boolean;
  margin?: string | number;
  onClick?: () => void;
}

export default class Button extends Component<Props> {
  render() {
    return (
      <ButtonContainer
        style={{ width: this.props.width, margin: this.props.margin ?? 0 }}
        small={this.props.small ?? false}
        onClick={this.props.onClick}
        outline={this.props.outline ?? false}
      >
        {this.props.outline ? (
          <InvertedSmallActionText>{this.props.text}</InvertedSmallActionText>
        ) : (
          <BaseActionText>{this.props.text}</BaseActionText>
        )}
      </ButtonContainer>
    );
  }
}

interface ButtonProps {
  small: boolean;
  outline: boolean;
}

const ButtonContainer = styled(RowContainer)<ButtonProps>`
  background: ${(props) => (props.outline ? COLOR.WHITE : COLOR.RED)};
  height: ${(props) => (props.small ? 35 : 40)}px;
  border-radius: 5px;
  padding: 0px 10px;
  border: ${(props) => (props.outline ? `1.8px solid ${COLOR.RED}` : "none")};
  &:hover {
    background: ${(props) => (props.outline ? COLOR.WHITE : COLOR.OPAQUERED)};
  }
`;
