import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, UserCircleIcon } from "@heroicons/react/solid";
import i18n from "i18next";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/logo.png";
import { signOut } from "../../store/actions/authActions";
import { updateLanguage } from "../../store/actions/utilsActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import { utilsStateInterface } from "../../store/reducers/utilsReducer";
import { COLOR } from "../../styles/Colors";
import { VerticalContainer } from "../../styles/Layout";
import { handleNavigation } from "../../utils/navigator";
import Button from "./Button";

export enum AuthScreens {
  SIGNIN = "signIn",
  SIGNUP = "signUp",
  RESETPASS = "resetPassword",
}

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  updateLanguage: (language: string) => Promise<void>;
}

interface State {
  languageInput: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

class Header extends Component<Props> {
  state: State = {
    languageInput: true,
  };

  componentDidMount() {
    if (this.props.utilsStore.language === "en") {
      this.setState({
        languageInput: true,
      });
    } else {
      this.setState({
        languageInput: false,
      });
    }
  }

  handleNavigate = (type: string) => {
    if (type === "signUp") {
      handleNavigation(this.props.history, "/signUp");
    }
  };

  handleChangeLanguage = () => {
    if (this.state.languageInput) {
      this.props.updateLanguage("bm");
      this.setState({
        languageInput: false,
      });
    } else {
      this.props.updateLanguage("en");
      this.setState({
        languageInput: true,
      });
    }
  };

  renderLogo = () => {
    return (
      <Link to="/">
        <img
          className="block lg:hidden h-8 w-auto"
          src={Logo}
          alt="MyVac Logo"
        />
        <img
          className="hidden lg:block h-10 w-auto"
          src={Logo}
          alt="MyVac Logo"
        />
      </Link>
    );
  };

  renderToggleLanguage = () => {
    return (
      <Switch.Group
        as="div"
        className="px-4 py-2 flex items-center lg:px-0 lg:py-0"
      >
        <Switch.Label as="span" className="mr-3">
          <span className="text-base text-gray-600 font-medium">
            {this.state.languageInput ? "EN" : "BM"}
          </span>
        </Switch.Label>
        <Switch
          checked={this.state.languageInput}
          className={classNames(
            this.state.languageInput ? "bg-custom-red-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red-500"
          )}
          onChange={this.handleChangeLanguage}
        >
          <span
            aria-hidden="true"
            className={classNames(
              this.state.languageInput ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </Switch.Group>
    );
  };

  renderUserNavigation = () => {
    return [
      {
        name: `${this.props.authStore.user?.name}'s Profile`,
        href: "/profile",
      },
      { name: i18n.t("header.bookings"), href: "/bookings" },
      { name: i18n.t("header.signOut"), action: "logout", href: "#" },
    ];
  };

  renderPopOverMenu = () => {
    const userNavigation = this.renderUserNavigation();

    return (
      <Menu as="div" className="ml-3 relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red-500">
                <UserCircleIcon className="h-8 w-8 text-custom-red-600" />
                <ChevronDownIcon className="h-4 w-4 text-custom-red-600" />
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => {
                      if (item.action) {
                        return (
                          <span
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:text-custom-red-600"
                            )}
                            onClick={signOut}
                          >
                            {item.name}
                          </span>
                        );
                      } else {
                        return (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 hover:text-custom-red-600"
                            )}
                          >
                            {item.name}
                          </Link>
                        );
                      }
                    }}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  };

  render() {
    const pathName = this.props.history.location.pathname;
    const userNavigation = this.renderUserNavigation();
    const navigation = [{ name: i18n.t("header.browse"), href: "/faqs" }];
    if (this.props.authStore.userAuth) {
      navigation.push({ name: i18n.t("header.searchBar"), href: "/centers" });
    } else {
      navigation.push({ name: i18n.t("header.signIn"), href: "/signIn" });
    }

    return (
      <Disclosure as="nav" className="bg-white shadow-sm w-full z-10">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    {this.renderLogo()}
                  </div>
                  <div className="hidden sm:-my-px lg:ml-6 lg:flex lg:space-x-8"></div>
                </div>
                <div className="hidden sm:-my-px lg:space-x-8 lg:ml-6 lg:flex lg:items-center">
                  {this.renderToggleLanguage()}
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        pathName === item.href
                          ? "border-custom-red-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:border-gray-300",
                        "inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium hover:text-gray-700"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                  {this.props.authStore.userAuth ? (
                    this.renderPopOverMenu()
                  ) : (
                    <Button
                      type="normal"
                      size="small"
                      text={i18n.t("header.signUp")}
                      onClick={this.handleNavigate.bind(this, "signUp")}
                    />
                  )}
                </div>

                <div className="-mr-2 flex items-center lg:hidden">
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {this.renderToggleLanguage()}
                {navigation.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    className={classNames(
                      pathName === item.href
                        ? "bg-custom-red-50 border-custom-red-500 text-custom-red-600 hover:text-custom-red-600"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
                {!this.props.authStore.userAuth && (
                  <Link
                    to="/signUp"
                    className={classNames(
                      pathName === "/signUp"
                        ? "bg-custom-red-50 border-custom-red-500 text-custom-red-600 hover:text-custom-red-600"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                  >
                    {i18n.t("header.signUp")}
                  </Link>
                )}
              </div>

              {this.props.authStore.userAuth && (
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <UserCircleIcon className="h-10 w-10 text-custom-red-600" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {this.props.authStore.user?.name}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {this.props.authStore.user?.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => {
                      if (item.action) {
                        return (
                          <span
                            className="cursor-pointer block px-4 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100"
                            onClick={signOut}
                          >
                            {item.name}
                          </span>
                        );
                      } else {
                        return (
                          <Link
                            to={item.href}
                            key={item.name}
                            className="block px-4 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100"
                          >
                            {item.name}
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateLanguage: (language: string) => dispatch(updateLanguage(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

export const Overlay = styled(VerticalContainer)`
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background ${COLOR.WHITE};
  `;
