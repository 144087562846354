import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

class ContactUs extends Component<Props> {
  render() {
    return (
      <div className="min-h-screen bg-white">
        <WindowResizer />
        <main className="overflow-hidden">
          <div className="bg-warm-gray-50">
            <Header history={this.props.history} />
            <div className="py-12 lg:py-16">
              <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold tracking-tight text-warm-gray-900 sm:text-4xl">
                  {i18n.t("contact.title")}
                </h1>
                <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                  {i18n.t("contact.description")}
                  <Link
                    to="/faqs"
                    className="text-custom-red-600 hover:text-custom-red-400"
                  >
                    {i18n.t("contact.page")}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <section aria-labelledby="officesHeading">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <h2
                id="officesHeading"
                className="text-xl font-extrabold text-warm-gray-900"
              >
                {i18n.t("contact.details")}
              </h2>
              <div className="mt-2 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
                <div>
                  <h3 className="text-lg font-medium text-warm-gray-900">
                    MySukarela Hotline
                  </h3>
                  <p className="mt-2 text-lg text-warm-gray-500">
                    <span className="block">{i18n.t("contact.operation")}</span>
                    <span className="block">Email : info@mysukarela.com</span>
                    <span className="block">
                      Whatsapp (Message Only) : (+60) 17-303 9042
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <FooterBar history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(ContactUs);
