import {
  CheckCircleIcon as CheckCircleIconOutline,
  DocumentDuplicateIcon,
  DocumentSearchIcon,
  FolderOpenIcon,
  IdentificationIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  MailIcon,
  UserIcon,
  XCircleIcon as XCircleIconOutline,
} from "@heroicons/react/outline";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { Col, Divider, notification, Row, Spin, Tabs } from "antd";
import i18n from "i18next";
import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import favicon from "../assets/favicon.png";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Button from "../components/Button";
import ProfileEditor from "../components/ProfileEditor";
import WindowResizer from "../components/WindowResizer";
import { GenderType, ProfessionType } from "../models/User";
import NavigatorSecurity from "../navigator/NavigatorSecurity";
import {
  handleCheckBooking,
  resendVerificationEmail,
  uploadDocument,
  uploadPhoto,
} from "../store/actions/authActions";
import { handleNotification } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, InvertedActionText, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

const { TabPane } = Tabs;

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
}

const formIconStyle = (complete: boolean) => {
  return `w-10 h-10 ${complete ? "text-green-500" : "text-custom-red-400"}`;
};

const completedIcon = (
  <CheckCircleIconOutline className="w-5 h-5 ml-1 text-green-500" />
);
const incompleteIcon = (
  <XCircleIconOutline className="w-5 h-5 ml-1 text-custom-red-500" />
);

const generalSecretFormPdfLink =
  "https://firebasestorage.googleapis.com/v0/b/mysukarela.appspot.com/o/static%2Fforms%2Fgeneral%2FBORANG%20AKTA%20RAHSIA%20RASMI%201972%20-%20LAMPIRAN%20E%20%26%20F.pdf?alt=media&token=1da94a8d-37c5-47b1-b536-4aca03e71a3d";
const generalHealthDeclarationPdfLink =
  "https://firebasestorage.googleapis.com/v0/b/mysukarela.appspot.com/o/static%2Fforms%2Fgeneral%2FBORANG%20DEKLARASI%20KESIHATAN%20SUKARELAWAN%20MYVAC.pdf?alt=media&token=a4e3bbec-3818-441d-8af2-d5dd7b16a066";
const generalLiabilityPdfLink =
  "https://firebasestorage.googleapis.com/v0/b/mysukarela.appspot.com/o/static%2Fforms%2Fgeneral%2FBORANG%20PELEPASAN%20DAN%20PENEPIAN%20LIABILITI%20SUKARELAWAN%20BUKAN%20KESIHATAN%20MyVaC%20.pdf?alt=media&token=3003a45e-b5fb-42c1-8d52-9e847f11dd25";
const healthLiabilityPdfLink =
  "https://firebasestorage.googleapis.com/v0/b/mysukarela.appspot.com/o/static%2Fforms%2Fhealth%2FBORANG%20PELEPASAN%20DAN%20PENEPIAN%20LIABILITI%20SUKARELAWAN%20KESIHATAN%20MyVAC.pdf?alt=media&token=f64aed11-7c0c-4b3c-abc2-51ae72d58e58";
const healthOfferPdfLink =
  "https://firebasestorage.googleapis.com/v0/b/mysukarela.appspot.com/o/static%2Fforms%2Fhealth%2FSURAT%20LANTIKAN%20SUKARELAWAN%20KESHITAN%20MYVAC.pdf?alt=media&token=bdc88605-dca1-4669-81c7-592c86013d62";

class Profile extends Component<Props> {
  state = {
    loading: false,
    booked: false,
  };

  componentDidMount() {
    if (this.props.authStore.userAuth) {
      this.handleCheckBooking();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.authStore.userAuth) !==
        JSON.stringify(prevProps.authStore.userAuth) &&
      this.props.authStore.userAuth
    ) {
      this.handleCheckBooking();
    }
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "home") {
      handleNavigation(this.props.history, "/");
    } else if (type === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (type === "resources") {
      handleNavigation(this.props.history, "/resources");
    } else if (type === "quiz") {
      handleNavigation(this.props.history, "/quiz");
    }
  };

  handleDocumentInput = (position: number, type: string) => {
    let idType = "";
    if (type === "photo") {
      idType = "upload-photo";
    } else {
      idType = "upload-button" + position;
    }

    const element: HTMLElement = document.getElementById(idType) as HTMLElement;
    if (element) {
      element.click();
    }
  };

  handleDocumentUpload = async (position: number, type: string, e: any) => {
    const file = e.target.files[0];
    const fileType = file.type.substr(file.type.indexOf("/") + 1);
    const fileSize = file.size / 1024 / 1024;
    let fileName = file.name;

    if (!file) {
      handleNotification("warning", "Upload Failed", "Please select a file");
      return false;
    }
    if (!file.type.match("image.*|application/pdf")) {
      handleNotification(
        "warning",
        "Upload Failed",
        "Only jpeg, png or pdf files are accepted"
      );
      return false;
    }

    if (fileSize > 5) {
      handleNotification(
        "warning",
        "Upload Failed",
        "File size cannot exceed 5MB"
      );
      return false;
    }

    if (type === "photo") {
      fileName = "avatar.jpeg";
    } else {
      switch (position) {
        case 0:
          if (file.type.match("image.*")) {
            fileName = "Borang Akta Rahsia.jpeg";
          } else {
            fileName = "Borang Akta Rahsia." + fileType;
          }
          break;
        case 1:
          if (file.type.match("image.*")) {
            fileName = "Borang Penetapan Liabiliti.jpeg";
          } else {
            fileName = "Borang Penetapan Liabiliti." + fileType;
          }
          break;
        case 2:
          if (file.type.match("image.*")) {
            fileName = "Borang Kesihatan.jpeg";
          } else {
            fileName = "Borang Kesihatan." + fileType;
          }
          break;
        case 3:
          if (file.type.match("image.*")) {
            fileName = "Tawaran Pelatikan.jpeg";
          } else {
            fileName = "Tawaran Pelatikan." + fileType;
          }
          break;
        case 4:
          if (file.type.match("image.*")) {
            fileName = "Sijil APC.jpeg";
          } else {
            fileName = "Sijil APC." + fileType;
          }
          break;
      }
    }

    const data = {
      file: file,
      fileName: fileName,
      type: position,
    };

    try {
      let upload: string = "";
      if (type === "photo") {
        upload = await uploadPhoto(data);
      } else {
        upload = await uploadDocument(data);
      }

      if (upload) {
        handleNotification("warning", "Upload Failed", upload);
      } else {
        handleNotification("success", "Upload Successful", "");
      }
    } catch (err) {
      handleNotification("warning", "Upload Failed", err.message);
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification["success"]({ message: message, description: "" });
    } else if (type === "warning") {
      notification["warning"]({
        message: message,
        description: description,
      });
    }
  };

  handleVerificationEmail = async () => {
    try {
      await resendVerificationEmail();
      handleNotification(
        "success",
        "Verification Sent",
        "Email verification link has been sent to your email"
      );
    } catch (err) {
      handleNotification("warning", "Verification Failed", err.message);
    }
  };

  handleCheckBooking = async () => {
    const check = await handleCheckBooking();
    this.setState({
      booked: check,
    });
  };

  renderOccupation = () => {
    if (this.props.authStore.user) {
      const profession =
        Object(ProfessionType)[this.props.authStore.user.profession];
      if (profession === ProfessionType.OTHERS) {
        return this.props.authStore.user.professionOthers;
      } else if (profession === ProfessionType.HEALTH) {
        return i18n.t("profile.health");
      } else if (profession === ProfessionType.NGO) {
        return i18n.t("profile.ngo");
      } else {
        return i18n.t("profile.student");
      }
    }

    return "";
  };

  returnStepsInfo = (position: number) => {
    const completedIcon = (
      <CheckCircleIcon className="w-5 h-5 text-green-500" />
    );
    const incompleteIcon = (
      <XCircleIcon className="w-5 h-5 text-custom-red-500" />
    );
    const docuIconIncomplete = (
      <DocumentSearchIcon className="w-5 h-5 text-custom-red-500" />
    );
    const docuIconcomplete = (
      <DocumentSearchIcon className="w-5 h-5 text-green-500" />
    );

    const step1Icon = this.props.authStore.user?.quizResult?.passed
      ? completedIcon
      : incompleteIcon;
    const step2Icon =
      this.props.authStore.user?.bankAccountName &&
      this.props.authStore.user?.bankAccountNo &&
      this.props.authStore.user?.imageToken &&
      this.props.authStore.user?.nameNextofKin &&
      this.props.authStore.user?.contactNoNextofKin
        ? completedIcon
        : incompleteIcon;
    let step3Icon: any = null;
    if (
      this.props.authStore.user &&
      this.props.authStore.user.profession === "HEALTH"
    ) {
      step3Icon =
        this.props.authStore.user?.healthDeclaration?.name &&
        this.props.authStore.user?.liabilityAgreement?.name &&
        this.props.authStore.user?.secretAgreement?.name &&
        this.props.authStore.user?.medicalDegree?.name
          ? completedIcon
          : incompleteIcon;
    } else {
      step3Icon =
        this.props.authStore.user?.healthDeclaration?.name &&
        this.props.authStore.user?.liabilityAgreement?.name &&
        this.props.authStore.user?.secretAgreement?.name
          ? completedIcon
          : incompleteIcon;
    }
    const step3adoc = this.props.authStore.user?.healthDeclaration?.name
      ? docuIconcomplete
      : docuIconIncomplete;
    const step3bdoc = this.props.authStore.user?.liabilityAgreement?.name
      ? docuIconcomplete
      : docuIconIncomplete;
    const step3cdoc = this.props.authStore.user?.secretAgreement?.name
      ? docuIconcomplete
      : docuIconIncomplete;
    const step3ddoc = this.props.authStore.user?.medicalDegree?.name
      ? docuIconcomplete
      : docuIconIncomplete;
    const step4Icon = this.props.authStore.user?.offerLetter?.name
      ? completedIcon
      : incompleteIcon;
    const step5Icon = this.state.booked ? completedIcon : incompleteIcon;
    const step6Icon = this.state.booked ? completedIcon : incompleteIcon;
    const stepHealthVerifiedID = this.props.authStore.user?.verifiedHealthID
      ? completedIcon
      : incompleteIcon;
    const stepHealthAttendance = this.props.authStore.user?.attendanceBriefing
      ? completedIcon
      : incompleteIcon;

    const linktoMap = (
      <a
        href="/officeList"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.maplink")}
      </a>
    );
    const linktoZoom = (
      <a
        href="https://us02web.zoom.us/j/81691400184?pwd=eFlZbWtodC9zcnM0Z3RqZytBdk5yZz09"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.zoomLink")}
      </a>
    );

    let stepsInfo: any = [
      {
        icon: step1Icon,
        title: i18n.t("profile.step1"),
      },
      {
        icon: step2Icon,
        title: i18n.t("profile.step2"),
      },
      {
        icon: step3Icon,
        title: i18n.t("profile.step3"),
      },
      {
        icon: step3adoc,
        title: i18n.t("profile.step3a"),
      },
      {
        icon: step3bdoc,
        title: i18n.t("profile.step3b"),
      },
      {
        icon: step3cdoc,
        title: i18n.t("profile.step3c"),
      },
      {
        icon: step4Icon,
        title: i18n.t("profile.step4"),
        link: linktoMap,
      },
      {
        icon: step5Icon,
        title: i18n.t("profile.step5"),
      },
      {
        icon: step6Icon,
        title: i18n.t("profile.step6"),
      },
      {
        icon: step6Icon,
        title: i18n.t("profile.step7"),
      },
      {
        icon: stepHealthVerifiedID,
        title: i18n.t("profile.stephealth1"),
      },
      {
        icon: stepHealthAttendance,
        title: i18n.t("profile.stephealth2"),
        link: linktoZoom,
      },
      {
        icon: step3ddoc,
        title: i18n.t("profile.step3d"),
      },
    ];
    return stepsInfo[position];
  };

  renderStepsCard = (detail: any) => {
    return (
      <div className="flex items-start justify-start w-full mb-2">
        <div className="w-5 mr-2">{detail.icon}</div>
        <p className="text-justify text-xs text-gray-600">
          {detail.title}
          {detail.link}
        </p>
      </div>
    );
  };

  returnUploadFormInfo = (position: number) => {
    let status1Icon: ReactElement;
    let status2Icon: ReactElement;
    let status3Icon: ReactElement;
    let status4Icon: ReactElement;
    let status5Icon: ReactElement;
    let status1Body: string;
    let status2Body: string;
    let status3Body: string;
    let status4Body: string;
    let status5Body: string;

    let status1Success: boolean = false;
    let status2Success: boolean = false;
    let status3Success: boolean = false;
    let status4Success: boolean = false;
    let status5Success: boolean = false;
    const status1Link = (
      <a
        href={generalSecretFormPdfLink}
        target="_blank"
        rel="noreferrer"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.here")}
      </a>
    );
    const status2Link = (
      <a
        href={
          this.props.authStore.user?.profession === "HEALTH"
            ? healthLiabilityPdfLink
            : generalLiabilityPdfLink
        }
        target="_blank"
        rel="noreferrer"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.here")}
      </a>
    );
    const status3Link = (
      <a
        href={generalHealthDeclarationPdfLink}
        target="_blank"
        rel="noreferrer"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.here")}
      </a>
    );
    const status4Link = (
      <a
        href={healthOfferPdfLink}
        target="_blank"
        rel="noreferrer"
        className="text-custom-red-600 hover:text-custom-red-400"
      >
        {i18n.t("profile.here")}
      </a>
    );

    if (this.props.authStore.user?.secretAgreement?.name) {
      status1Body = i18n.t("profile.completedStatus");
      status1Icon = completedIcon;
      status1Success = true;
    } else {
      status1Body = i18n.t("profile.notCompleteStatus");
      status1Icon = incompleteIcon;
    }
    if (this.props.authStore.user?.liabilityAgreement?.name) {
      status2Body = i18n.t("profile.completedStatus");
      status2Icon = completedIcon;
      status2Success = true;
    } else {
      status2Body = i18n.t("profile.notCompleteStatus");
      status2Icon = incompleteIcon;
    }
    if (this.props.authStore.user?.healthDeclaration?.name) {
      status3Body = i18n.t("profile.completedStatus");
      status3Icon = completedIcon;
      status3Success = true;
    } else {
      status3Body = i18n.t("profile.notCompleteStatus");
      status3Icon = incompleteIcon;
    }

    if (this.props.authStore.user?.offerLetter?.name) {
      status4Body = i18n.t("profile.completedStatus");
      status4Icon = completedIcon;
      status4Success = true;
    } else {
      status4Body = i18n.t("profile.notCompleteStatus");
      status4Icon = incompleteIcon;
    }

    if (this.props.authStore.user?.medicalDegree?.name) {
      status5Body = i18n.t("profile.completedStatus");
      status5Icon = completedIcon;
      status5Success = true;
    } else {
      status5Body = i18n.t("profile.notCompleteStatus");
      status5Icon = incompleteIcon;
    }

    let uploadFormInfo: any = [
      {
        icon: (
          <DocumentDuplicateIcon className={formIconStyle(status1Success)} />
        ),
        title: i18n.t("profile.aktaRahsiaForm"),
        body: status1Body,
        statusIcon: status1Icon,
        p1: i18n.t("profile.downloadAktaRahsiaForm"),
        link: status1Link,
        p2:
          i18n.t("profile.formUploadInstruction") +
          i18n.t("profile.formCivilVerification"),
        position: position,
        status: status1Success,
      },
      {
        icon: (
          <DocumentDuplicateIcon className={formIconStyle(status2Success)} />
        ),
        title: i18n.t("profile.penetapanLiabilitiForm"),
        body: status2Body,
        statusIcon: status2Icon,
        p1: i18n.t("profile.downloadPenetapanLiabilitiForm"),
        link: status2Link,
        p2:
          i18n.t("profile.formUploadInstruction") +
          i18n.t("profile.formAgeVerification"),
        position: position,
        status: status2Success,
      },
      {
        icon: (
          <DocumentDuplicateIcon className={formIconStyle(status3Success)} />
        ),
        title: i18n.t("profile.healthForm"),
        body: status3Body,
        statusIcon: status3Icon,
        p1: i18n.t("profile.downloadHealthForm"),
        link: status3Link,
        p2:
          i18n.t("profile.formUploadInstruction") +
          i18n.t("profile.formSelfVerification"),
        position: position,
        status: status3Success,
      },
      {
        icon: (
          <DocumentDuplicateIcon className={formIconStyle(status4Success)} />
        ),
        title: i18n.t("profile.confirmationForm"),
        body: status4Body,
        p1:
          this.props.authStore.user?.profession === "HEALTH"
            ? i18n.t("profile.downloadHealthOfferForm")
            : i18n.t("profile.confirmationFormInfo"),
        statusIcon: status4Icon,
        link:
          this.props.authStore.user?.profession === "HEALTH" ? status4Link : "",
        p2:
          i18n.t("profile.formUploadInstruction") +
          i18n.t("profile.formHealthVerification"),
        position: position,
        status: status4Success,
      },
      {
        icon: (
          <DocumentDuplicateIcon className={formIconStyle(status5Success)} />
        ),
        title: i18n.t("profile.medicalCert"),
        body: status5Body,
        p1: i18n.t("profile.medicalInfo"),
        statusIcon: status5Icon,
        p2: i18n.t("profile.formUploadInstruction"),
        position: position,
        status: status5Success,
      },
    ];
    return uploadFormInfo[position];
  };

  renderUploadFormCard = (detail: any) => {
    return (
      <Row style={{ width: "100%", alignItems: "center" }}>
        <Col span={24} lg={16}>
          <RowContainer style={{ width: "100%" }}>
            {detail.icon}
            <VerticalContainer
              style={{
                alignItems: "flex-start",
                marginLeft: 10,
                width: "100%",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>{detail.title}</Title3>
              <RowContainer>
                <Body
                  style={{
                    color: detail.status ? COLOR.GREEN : COLOR.RED,
                    marginBottom: 0,
                  }}
                >
                  {detail.body}
                </Body>
                {detail.statusIcon}
              </RowContainer>
              <Body
                style={{
                  marginBottom: 0,
                  textAlign: "justify",
                }}
              >
                {detail.p1} {detail.link}
                {detail.p2}
              </Body>
            </VerticalContainer>
          </RowContainer>
        </Col>
        <Col span={24} lg={8}>
          <VerticalContainer
            style={{
              marginTop:
                this.props.utilsStore.deviceType < DeviceType.Laptop ? 30 : 0,
              alignItems: "flex-end",
            }}
          >
            <Button
              text={i18n.t("profile.upload")}
              onClick={this.handleDocumentInput.bind(
                this,
                detail.position,
                "document"
              )}
              width={120}
              margin={"0px 5px"}
              small={true}
            />
            <input
              type="file"
              id={"upload-button" + detail.position}
              accept=".jpg, .jpeg, .png, .pdf"
              style={{ display: "none" }}
              onChange={this.handleDocumentUpload.bind(
                this,
                detail.position,
                "document"
              )}
            />
          </VerticalContainer>
        </Col>
      </Row>
    );
  };

  renderGender = () => {
    if (this.props.authStore.user?.gender) {
      if (this.props.authStore.user?.gender === Object.keys(GenderType)[0]) {
        return i18n.t("auth.male");
      } else {
        return i18n.t("auth.female");
      }
    }
    return "";
  };

  renderImage = (): string => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const storageBucketId = isProd
      ? process.env.REACT_APP_PROD_STORAGE_BUCKET
      : process.env.REACT_APP_DEV_STORAGE_BUCKET;

    let link: string = "";
    if (this.props.authStore.user?.imageToken) {
      link = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/users%2F${this.props.authStore.user?.id}%2Favatar.jpeg?alt=media&token=${this.props.authStore.user?.imageToken}`;
    } else {
      link = favicon;
    }

    return link;
  };

  render() {
    if (this.props.authStore.userLoading) return null;

    let healthCareWorker = false;
    if (
      this.props.authStore.user &&
      this.props.authStore.user.profession === "HEALTH"
    ) {
      healthCareWorker = true;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <VerticalContainer style={{ width: "100%" }}>
          <WindowResizer />
          <NavigatorSecurity />
          <Header history={this.props.history} />
          <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
            <h1 className="text-2xl font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
              {i18n.t("profile.account")}
            </h1>
            <VerticalContainer style={{ width: "100%" }}>
              <BaseStepContainer>
                <VerticalContainer
                  style={{
                    padding: 20,
                    alignItems: "flex-start",
                  }}
                >
                  <p className="text-justify font-bold mb-2">
                    {i18n.t("profile.stepTitle")}
                  </p>
                  {this.renderStepsCard(this.returnStepsInfo(0))}
                  {this.renderStepsCard(this.returnStepsInfo(1))}
                  {this.renderStepsCard(this.returnStepsInfo(2))}
                  <ul className="ml-6">
                    <li>{this.renderStepsCard(this.returnStepsInfo(3))}</li>
                    <li>{this.renderStepsCard(this.returnStepsInfo(4))}</li>
                    <li>{this.renderStepsCard(this.returnStepsInfo(5))}</li>
                    {healthCareWorker && (
                      <li>{this.renderStepsCard(this.returnStepsInfo(12))}</li>
                    )}
                  </ul>
                  {healthCareWorker
                    ? this.renderStepsCard(this.returnStepsInfo(11))
                    : this.renderStepsCard(this.returnStepsInfo(6))}
                  {healthCareWorker &&
                    this.renderStepsCard(this.returnStepsInfo(10))}
                  {this.renderStepsCard(this.returnStepsInfo(7))}
                  {this.renderStepsCard(this.returnStepsInfo(8))}
                </VerticalContainer>
              </BaseStepContainer>
            </VerticalContainer>

            <Row
              style={{
                width: "100%",
                margin: "30px 0px",
              }}
            >
              <Col span={24} lg={8}>
                <VerticalContainer className="rounded-md p-10 border border-gray-300">
                  <VerticalContainer>
                    <img
                      src={this.renderImage()}
                      style={{
                        height:
                          this.props.utilsStore.deviceType >= DeviceType.Laptop
                            ? 130
                            : 100,
                        width:
                          this.props.utilsStore.deviceType >= DeviceType.Laptop
                            ? 130
                            : 100,
                        objectFit: "cover",
                        borderRadius: 200,
                      }}
                      alt={"Default Avatar"}
                    />
                  </VerticalContainer>
                  <VerticalContainer
                    style={{
                      alignItems: "flex-start",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <h2 className="text-xl font-bold">
                      {this.props.authStore.user?.name}
                    </h2>

                    <RowContainer style={{ margin: "10px 0px" }}>
                      <MailIcon className="w-5 h-5 mr-2 text-custom-red-500" />
                      <p className="text-gray-600">
                        {this.props.authStore.userAuth?.emailVerified
                          ? i18n.t("profile.emailVerification")
                          : i18n.t("profile.emailNotVerification")}
                      </p>
                    </RowContainer>

                    <RowContainer style={{ margin: "10px 0px" }}>
                      <IdentificationIcon className="w-5 h-5 mr-2 text-custom-red-500" />
                      <p className="text-gray-600">
                        {this.props.authStore.user?.ic}
                      </p>
                    </RowContainer>

                    <RowContainer style={{ margin: "10px 0px" }}>
                      <InformationCircleIcon className="w-5 h-5 mr-2 text-custom-red-500" />
                      <p className="text-gray-600">
                        {this.props.authStore.user?.age}, {this.renderGender()}
                      </p>
                    </RowContainer>

                    <RowContainer style={{ margin: "10px 0px" }}>
                      <UserIcon className="w-5 h-5 mr-2 text-custom-red-500" />
                      <p className="text-gray-600">{this.renderOccupation()}</p>
                    </RowContainer>

                    <RowContainer style={{ margin: "10px 0px" }}>
                      <LocationMarkerIcon className="w-5 h-5 mr-2 text-custom-red-500" />
                      <p style={{ marginBottom: 0 }}>
                        {this.props.authStore.user?.address.district},{" "}
                        {this.props.authStore.user?.address.state}
                      </p>
                    </RowContainer>
                  </VerticalContainer>
                  <Button
                    text={i18n.t("profile.uploadPhoto")}
                    width="auto"
                    margin={"30px 0px 0px 0px"}
                    small={true}
                    onClick={this.handleDocumentInput.bind(this, 0, "photo")}
                  />
                  <input
                    type="file"
                    id={"upload-photo"}
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={this.handleDocumentUpload.bind(this, 0, "photo")}
                  />
                  {!this.props.authStore.userAuth?.emailVerified && (
                    <InvertedActionText
                      onClick={this.handleVerificationEmail}
                      style={{ marginTop: 20 }}
                    >
                      {i18n.t("profile.emailVerificationAction")}
                    </InvertedActionText>
                  )}
                </VerticalContainer>
              </Col>
              <Col span={1} />
              <Col span={24} lg={15}>
                <VerticalContainer
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop:
                      this.props.utilsStore.deviceType < DeviceType.Laptop
                        ? 30
                        : 0,
                  }}
                >
                  <Tabs
                    style={{
                      width: "100%",
                    }}
                    defaultActiveKey="1"
                  >
                    <TabPane tab={i18n.t("profile.information")} key="1">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          alignItems: "flex-start",
                          padding: 5,
                        }}
                      >
                        <ProfileEditor handleLoading={this.handleLoading} />
                      </VerticalContainer>
                    </TabPane>
                    <TabPane tab={i18n.t("profile.resources")} key="2">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          padding: 5,
                        }}
                      >
                        <Row style={{ width: "100%", alignItems: "center" }}>
                          <Col span={24} lg={16}>
                            <RowContainer
                              style={{
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <QuestionMarkCircleIcon className="w-8 h-8 text-custom-red-500" />
                              <VerticalContainer
                                style={{
                                  alignItems: "flex-start",
                                  marginLeft: 10,
                                }}
                              >
                                <Title3 style={{ marginBottom: 0 }}>
                                  {i18n.t("profile.quiz")}
                                </Title3>
                                <RowContainer>
                                  <Body
                                    style={{
                                      color: this.props.authStore.user
                                        ?.quizResult?.passed
                                        ? COLOR.GREEN
                                        : COLOR.RED,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {this.props.authStore.user?.quizResult
                                      ?.passed
                                      ? i18n.t("profile.completedStatus")
                                      : i18n.t("profile.notCompleteStatus")}
                                  </Body>

                                  {this.props.authStore.user?.quizResult?.passed
                                    ? completedIcon
                                    : incompleteIcon}
                                </RowContainer>
                              </VerticalContainer>
                            </RowContainer>
                          </Col>
                          <Col span={24} lg={8}>
                            <VerticalContainer
                              style={{
                                marginTop:
                                  this.props.utilsStore.deviceType <
                                  DeviceType.Laptop
                                    ? 30
                                    : 0,
                                alignItems: "flex-end",
                              }}
                            >
                              {!this.props.authStore.user?.quizResult
                                ?.passed && (
                                <Button
                                  text={i18n.t("profile.takeQuiz")}
                                  width={120}
                                  margin={
                                    this.props.utilsStore.deviceType >=
                                    DeviceType.Laptop
                                      ? "0px 5px"
                                      : "20px 0px 0px 0px"
                                  }
                                  small={true}
                                  onClick={this.handleNavigate.bind(
                                    this,
                                    "quiz"
                                  )}
                                />
                              )}
                            </VerticalContainer>
                          </Col>
                        </Row>

                        <Divider />

                        <Row style={{ width: "100%", alignItems: "center" }}>
                          <Col span={24} lg={18}>
                            <RowContainer
                              style={{
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <FolderOpenIcon className="w-8 h-8 text-custom-red-500 mr-2" />
                              <Title3 style={{ marginBottom: 0 }}>
                                {i18n.t("profile.learningResource")}
                              </Title3>
                            </RowContainer>
                          </Col>
                          <Col span={24} lg={6}>
                            <VerticalContainer
                              style={{
                                marginTop:
                                  this.props.utilsStore.deviceType <
                                  DeviceType.Laptop
                                    ? 30
                                    : 0,
                                alignItems: "flex-end",
                              }}
                            >
                              <Button
                                text={i18n.t("profile.learn")}
                                width={120}
                                margin={
                                  this.props.utilsStore.deviceType >=
                                  DeviceType.Laptop
                                    ? "0px 5px"
                                    : "20px 0px 0px 0px"
                                }
                                small={true}
                                onClick={this.handleNavigate.bind(
                                  this,
                                  "resources"
                                )}
                              />
                            </VerticalContainer>
                          </Col>
                        </Row>
                        <Divider />
                      </VerticalContainer>
                    </TabPane>
                    <TabPane tab={i18n.t("profile.declarationForm")} key="3">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          padding: 5,
                        }}
                      >
                        {this.renderUploadFormCard(
                          this.returnUploadFormInfo(0)
                        )}
                        <Divider />
                        {this.renderUploadFormCard(
                          this.returnUploadFormInfo(1)
                        )}
                        <Divider />
                        {this.renderUploadFormCard(
                          this.returnUploadFormInfo(2)
                        )}
                        <Divider />
                        {this.renderUploadFormCard(
                          this.returnUploadFormInfo(healthCareWorker ? 4 : 3)
                        )}
                        <Divider />
                      </VerticalContainer>
                    </TabPane>
                  </Tabs>
                </VerticalContainer>
              </Col>
            </Row>
          </div>
          <FooterBar history={this.props.history} />
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Profile);

const BaseStepContainer = styled(VerticalContainer)`
  width: 100%;
  border-radius: 10px;
  background: ${COLOR.LIGHTGREY};
  align-items: flex-start;
  margin: 10px 0px;
`;
