import { BookingModel } from "../../models/Booking";

export interface bookStateInterface {
  bookings: BookingModel[];
  loading: boolean;
  selectedLoading: boolean;
  selectedBookings: BookingModel[];
}

const initState: bookStateInterface = {
  bookings: [],
  loading: true,
  selectedLoading: false,
  selectedBookings: [],
};

const bookReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_BOOKING":
      return {
        ...state,
        bookings: action.payload.bookings,
        loading: false,
      };
    case "UPDATE_SELECTED_BOOKING":
      return {
        ...state,
        selectedBookings: action.payload.selectedBookings,
      };
    case "UPDATE_SELECTED_BOOKING_LOADING":
      return {
        ...state,
        selectedLoading: action.payload.selectedLoading,
      };
    default:
      return state;
  }
};

export default bookReducer;
