import StatusModel from "../../models/RiskStatus";
import firebase from "../../utils/firebase";

export const createRiskStatus = async (items: {
  userId: string;
  answer: { [key: string]: string };
}) => {
  try {
    const riskstatusRef = firebase.firestore().collection("riskStatus").doc();
    if (riskstatusRef.id) {
      const riskstatusModel: StatusModel = {
        id: riskstatusRef.id,
        createdAt: new Date(),
        userId: items.userId,
        answer: items.answer,
      };
      await firebase
        .firestore()
        .collection("riskStatus")
        .doc(riskstatusRef.id)
        .set(riskstatusModel);
      return "";
    } else return "Status is not created";
  } catch (err) {
    return err.message;
  }
};
