const resources = {
  en: {
    translation: {
      header: {
        browse: "Frequently Asked Questions",
        signIn: "Sign In",
        signUp: "Sign Up",
        signOut: "Sign Out",
        centers: "FAQs",
        account: "Account",
        searchBar: "Browse Centers",
        language: "Language",
        legal: "Legal",
        confirm: `Confirm`,
        next: `Next`,
        resetPassword: `Reset Password`,
        step: "Step",
        bookings: "My Volunteering Bookings",
        banner:
          "The MyVac program had ended. If you are interested to volunteer, please visit MySukarela website (https://mysukarela.com). Thank you.",
      },
      footer: {
        knowUsTitle: "About Us",
        aboutTitle: "About Us",
        contactTitle: "Contact Us",
        kbsOffice: "KBS Offices",
        legalTitle: "Legal",
        tncTitle: "Terms & Conditions",
        privacyTitle: "Privacy Policy",
        supportTitle: "Collaborated By",
        supportTitleDescription: "With",
        poweredTitle: "Powered By",
        copyrightTitle:
          "Copyright © 2021 Malaysian Red Crescent Society. All rights reserved.",
        kbsofficeTitle: "List of KBS office",
      },
      disease: {
        hypertension: `Uncontrolled Hypertension`,
        diabetes: `Diabetes Mellitus`,
        asthma: `Asthma/Chronic Obstructive Pulmonary Disease (COPD)`,
        cancer: `Cancer on active treatment`,
        autoimmune: `Autoimmune disease on treatment`,
        cardio: `Heart Disease`,
        hepatitis: `Hepatitis`,
        hiv: `HIV/AIDS`,
        obesity: `Obesity (BMI > 35kgm2)`,
        tb: `Tuberculosis in active phase`,
        kidney: `Kidney disease`,
        blood: `Blood disorder (Major Thalassemia, Sickle Cell Anaemia)`,
        genetic: `Genetic disorder (DOWN Syndrome etc)`,
        epilepsy: `Epilepsy`,
        mental: `Mental Illness`,
      },
      illness: {
        fever: `Fever`,
        cough: `Cough`,
        nose: `Running nose or nasal congestion`,
        chills: `Chills`,
        myalgia: `Myalgia`,
        headache: `Headache`,
        sorethroat: `Sorethroat`,
        nausea: `Nausea and vomiting`,
        diarrhea: `Diarrhea`,
        fatigue: `Fatigue`,
        breathing: `Difficulty in breathing`,
        smell: `Lost of sense of smell`,
        taste: `Lost of sense of taste`,
      },
      auth: {
        signUp: `Sign Up`,
        resetSuccessful: "Successfully Reset?",
        resetPassword: "Reset Password",
        resetPasswordSuccess:
          "You have successfully reset your password. Please sign in with your new password.",
        newPassword: "New Password",
        confirm: `Confirm`,
        emailError: `Please enter the correct email format`,
        emailReset: `Password reset link has been sent to your email`,
        emailVerify: "Email Verification",
        emailVerifySuccess: "Your email have been verified!",
        passwordError: `Password length must be more than 6 characters`,
        codeError: "Invalid Code",
        actionError:
          "The desired action is invalid, it may be due to this link has expired",
        icError: `Please enter the correct IC format`,
        nameError: `Please enter your name`,
        addressError: `Please enter your address`,
        poscodeError: `Please enter your postcode`,
        mobilenoError: `Please enter a Malaysia Telephone Number`,
        medicalidError: `Please enter your medical ID`,
        professionError: `Please enter your profession`,
        organisationNameError: `Please enter your company/organisation name`,
        organisationAddressError: `Please enter your company/organisation address`,
        ageError: `Please enter a valid age`,
        ageunderError: `You are under age`,
        emppermError: `Please get permission from your employer and re-apply`,
        kkmempError: `You are not eligible to apply to volunteer if you are an employee of Ministry of Health`,
        unfitError: `You are currently unfit for volunteering`,
        noteligError: `You are not eligible for volunteering`,
        bankAccountNoError: "Please enter bank account no",
        bankAccountNameError: "Please enter bank account name",
        contactNoNextofKinError: "Please enter next of kin contact number",
        relationshipError: "Please enter next of kin relationship",
        nameNextofKinError: "Please enter next of kin name",
        email: `Email`,
        password: `Password`,
        forgotpassword: `Forgot Password`,
        name: `Name`,
        fullname: `Full Name`,
        icno: `IC Number`,
        age: `Age`,
        gender: `Gender`,
        male: `Male`,
        female: `Female`,
        race: `Race`,
        address: `Home Address`,
        fulladdress: `Full Address`,
        poscode: `Postcode`,
        district: `District`,
        state: `State`,
        mobileno: `Telephone Number`,
        profession: `Profession`,
        professionothers: `Profession (Others)`,
        professioname: `Profession Name`,
        healthprofession: "Health Profession Type",
        professionhealthid: `Medical ID`,
        universityTitle: `University Name`,
        universityAddress: `University Address`,
        ngoTitle: `Organisation Name`,
        ngoAddress: `Organisation Address`,
        organisationname: `Company/Organisation/University Name`,
        organisationnameothers: `Organisation Name (Others)`,
        organisationaddress: `Company/Organisation/University Address`,
        organisationaddressfull: `Full Company/Organisation/University Address`,
        yes: `Yes`,
        no: `No`,
        empperm: `Have you gotten permission from your employer to participate in this programme?`,
        kkmemp: `Are you a employee under the Ministry of Health Malaysia?`,
        pregnant: `Are you pregnant? Or recently conceived a baby in the past 3 months?`,
        illness: `Do you have any illnesses as stated below?`,
        exposure: `In relation to exposure : `,
        redzone: `Have you attended an event/activity OR gone to a location which is related to COVID-19 Cluster?`,
        overseas: `Have been outside the country in the past 14 days?`,
        closecontact: `Have you been in close contact with a known COVID-19 positive patient in the past 14 days?`,
        symptoms1: `In relation to symptoms :`,
        symptoms2: `Do you show signs of any of the symptoms stated below?`,
        tncheader: `Note : By confirming, you agree that all the information have been provided is accurate and you accept our`,
        tnc: `terms of service`,
        privacy: `privacy policy`,
        stepper1: `Personal Details`,
        stepper2: `Occupation Details`,
        stepper3: `Health Declaration`,
        stepper4: `COVID-19 Exposure`,
        backToHome: "Back To Home",
      },
      home: {
        title1: "COVID-19",
        title2: "Volunteer Coordination Centre",
        bannerFooter: "A platform by Malaysians for Malaysians",
        aboutTitle: "About MyVac",
        aboutparagraph1: `MyVAC (Malaysia Vaccine Support Volunteers) is a dedicated platform established in collaboration with the Ministry of Health (MOH), Ministry of Science, Technology & Innovation (MOSTI), Ministry of Youth & Sports (KBS), Ministry of Higher Education (KPT) and Malaysian Red Crescent Society (MRCS) to assist in the roll out of the government’s vaccination programme, by deploying willing individuals to be part of the vaccination team. This volunteer mobilisation program will be a crucial instrument to drive Malaysia’ vaccination campaign and achieve “Herd Immunity”. `,
        aboutparagraph2: `MySukarela was thus established as a one-stop-platform to support the effective roll out of MyVAC. The platform will supplement the humanitarian needs of Malaysia by deploying  willing and passionate volunteers  from all walks of life in this time of crisis. `,
        aboutparagraph3: `The Covid-19 Vaccination Program will be carried out in 605 Vaccine Administration Centres (VACs) around Malaysia. By signing up to this platform, you may choose to serve your locality according to your own time of preference. We are calling out all willing and able Malaysians to be part of the team to manage and carry out the Covid-19 Vaccination Program 2021 in the service of our nation. `,
        aboutparagraph4: `Everyone is welcome to be part of MyVAC community. Sign up as a volunteer today. `,
        vacTitle: `Vaccination Administration Centres`,
        vacparagraph1: `The government of Malaysia is setting up more than 605 Vaccination Administration Centres (VACs) around Malaysia to ensure complete coverage of the entire nation. Vaccination centres will be managed by personnel from various ministries, agencies and organisation. 4 groups of personnel will be tasked to ensure a safe and organised execution of the program. Types of vaccination administration centres are as the following :`,
        vacpoint1: `Medical Personals`,
        vacpoint2: `Security Forces`,
        vacpoint3: `Healthcare Volunteers`,
        vacpoint4: `Non-Healthcare Volunteers`,
        vactypeTitle: `Type of Vaccination Administration Centres`,
        typeTitle1: `Community Centres, Government Agencies’ Offices`,
        vacType1: `VAC Type 1 - 200 Covid-19 Vaccine doses/ day`,
        vacType2: `VAC Type 2 - 400 Covid-19 Vaccine doses/ day`,
        vacType3: `VAC Type 3 - 600 Covid-19 Vaccine doses/ day`,
        typeTitle2: `Community Halls, Sports Centres`,
        vacType4: `VAC Type 4 - 800 Covid-19 Vaccine doses/ day`,
        vacType5: `VAC Type 5 - 1000 Covid-19 Vaccine doses/ day`,
        vacType6: `VAC Type 6 - 1200 Covid-19 Vaccine doses/ day`,
        typeTitle3: `Stadiums, Assembly Halls`,
        vacType7: `VAC Type 7 - 1400 Covid-19 Vaccine doses/ day`,
        vacType8: `VAC Type 8 - 1600 Covid-19 Vaccine doses/ day`,
        stepNonHealthcareTitle: `Steps to become a Non-Healthcare Volunteer`,
        step1NonHealthcare: `Step 1`,
        step1NonHealthcarepoint1: `Register yourself in the MyVAC platform @ www.myvac.com.my`,
        step1NonHealthcarepoint2: `Verify your email`,
        step1NonHealthcarepoint3: `Complete your profile`,
        step1NonHealthcarepoint4: `Undergo all training modules and complete the quiz`,
        step1NonHealthcarepoint5: `Download, print, verify and upload all relevant documents`,
        step2NonHealthcare: `Step 2 - Visit your nearest Youth and Sports State office/district office`,
        step2NonHealthcarepoint1: `Submit all forms`,
        step2NonHealthcarepoint2: `Sign the Volunteer’s Offer Letter`,
        step2NonHealthcarepoint3: `Book your slot`,
        step2NonHealthcarepoint4: `Collect your Volunteer Kit`,
        step3NonHealthcare: `Step 3`,
        step3NonHealthcarepoint1: `Declaration of Covid-19 exposure before reporting date`,
        step3NonHealthcarepoint2: `Report for duty to desired Vaccination Administration Centre on desired date`,
        stepHealthcareTitle: `Steps to become a Healthcare Volunteer`,
        step1Healthcare: `Step 1`,
        step1Healthcarepoint1: `Register yourself in the MyVAC platform @ www.myvac.com.my`,
        step1Healthcarepoint2: `Verify your email`,
        step1Healthcarepoint3: `APC Verification by MOH (4-5 working days)`,
        step2Healthcare: `Step 2`,
        step2Healthcarepoint1: `Complete your profile `,
        step2Healthcarepoint2: `Undergo all training modules and complete the quiz`,
        step2Healthcarepoint3: `Download, print, verify and upload all relevant documents`,
        step3Healthcare: `Step 3`,
        step3Healthcarepoint1: `Attend an online briefing for Healthcare Volunteers (Scheduled every Friday)`,
        step3Healthcarepoint2: `Book your slot`,
        step4Healthcare: `Step 4`,
        step4Healthcarepoint1: `Declaration of Covid-19 exposure before reporting date`,
        step4Healthcarepoint2: `Report for duty to desired Vaccination Administration Centre on desired date and submit all forms to your VAC’s Supervisor`,
        jobscopeTitle: `Job Scope`,
        genvolunteerTitle: `Non-Healthcare Volunteers`,
        genvolunteerpoint1: `Conducting symptomatic and temperature screening`,
        genvolunteerpoint2: `Registration of recipients using the MySejahtera and MyVAS system`,
        genvolunteerpoint3: `Distributing Vaccination Consent Form`,
        genvolunteerpoint4: `Assisting medical personnels`,
        genvolunteerpoint5: `Managing and controlling crowd`,
        genvolunteerpoint6: `Ensuring proper SOP practises`,
        medvolunteerTitle: `Healthcare Volunteers`,
        medvolunteerpoint1: `Conducting symptomatic and temperature screening`,
        medvolunteerpoint2: `Managing recipients who failed symptoms and temperature checks at the entrance `,
        medvolunteerpoint3: `Providing education and explanation about the Covid-19 vaccine`,
        medvolunteerpoint4: `Managing the sick bay and observation bay`,
        medvolunteerpoint5: `Administering vaccine to recipients`,
        volunteerreqTitle: `Volunteer Requirement`,
        volunteerreqpoint1: `Above 18 year old`,
        volunteerreqpoint2: `Valid APC/ARC (for Healthcare Volunteers ONLY)`,
        volunteerreqpoint3: `Healthy without any comorbidities or disabilities`,
        volunteerreqpoint4: `No risk of Covid-19 exposure in the past 14 days before reporting date`,
        learnmorebtn: `Learn More`,
        bookBtn:
          "To find a centre near you, please register yourself to be a MyVac Volunteer",
      },
      contact: {
        title: "📱 Contact Us",
        description:
          "Hi there 👋, we would like to thank you for your interest in contacting us. Before you proceed, please ensure that your questions are not duplicated in the ",
        page: "Frequently Asked Page",
        details: "Contact Details",
        operation: "Operating Hours : 9am - 6pm",
      },
      profile: {
        account: `Your Account`,
        volunteerRegistrationWarning: `You have not completed your Volunteer registration process yet. Please complete all the steps below to start booking.`,
        stepTitle: `Steps to complete your Volunteer Registration Process`,
        stephealth1: ` Verification by MOH (APC validity). This process will take 4-5 working days`,
        stephealth2: `Step 4 : Attend a special online briefing for Healthcare Volunteers (3 hours) on every Friday 9am. Upload the offer letter to the in MyVac system`,
        step1: `Step 1 : Complete the module and training`,
        step2: `Step 2 : Upload profile picture, update your bank and next-of-kin details`,
        step3: `Step 3 :  Download, print, sign and upload your`,
        step3a: ` Health declaration form`,
        step3b: ` Liability Waiver form`,
        step3c: ` Official Secrecy Act form`,
        step3d: ` Medical Degree/APC Certificate`,
        step4:
          "Step 4 : Visit the nearest Youth and Sports State office/district office (refer map location) and submit all the physical forms. Sign offer letter and collect your volunteer kit provided by Youth and Sports office. Upload the offer letter to the in MyVac system",
        step5: `Step 5 : Book your volunteer slot - confirmation email`,
        step6: `Step 6 : A reminder email will be sent to you 3 days before volunteer date the to redeclare your health status`,
        emailVerification: `Email Verified`,
        emailNotVerification: `Email Not Verified`,
        emailVerificationAction: "Resend Verification Email?",
        uploadPhoto: `Upload Photo`,
        logOut: `Log Out`,
        information: "Information",
        resources: `Resources`,
        declarationForm: `Declaration Form`,
        quiz: `Quiz`,
        learn: "Learn",
        takeQuiz: `Take Quizzes`,
        learningResource: `Learning Resource`,
        aktaRahsiaForm: `Akta Rahsia Form`,
        penetapanLiabilitiForm: `Liability Waiver Form`,
        healthForm: `Health Form`,
        downloadAktaRahsiaForm: `Download the Akta Rahsia Form`,
        downloadPenetapanLiabilitiForm: `Download the Liability Waiver Form`,
        downloadHealthForm: `Download the Health Form`,
        downloadHealthOfferForm: "Download the Offer Letter",
        here: `here`,
        maplink: `. Map Location`,
        zoomLink: ". Zoom Link",
        confirmationForm: `Offer Letter`,
        medicalCert: `Medical Degree/APC Certificate`,
        medicalInfo: `Please upload your Medical Degree if you are a graduate doctor/dentist/pharmacist. On the other hand, please upload your APC certificate if you are currently working as a doctor/nurse/dentist/medical assistant/pharmacist`,
        confirmationFormInfo: `You can get the offer letter from Youth and Sports Department / Youth and Sport District / Region / Area Office`,
        formUploadInstruction: `. Please make sure that all information on the uploaded form is completed and can be clearly read`,
        formSelfVerification: " Self verified by the volunteer",
        formAgeVerification:
          " Can be verified / witness by anyone above the age of 18 years old",
        formCivilVerification:
          " Combine your files and upload all pages. Can be verified / witness by any civil servant (Grade 41 and above) from the Ministry of Health or Ministry of Youth and Sports (If needed you may visit the nearest District Youth and Sports Office / District Health Office)",
        formHealthVerification: " Combine your files and upload all pages.",
        upload: `Upload`,
        completedStatus: `Status : Completed`,
        notCompleteStatus: `Status : Not completed`,
        comingSoon: `Coming Soon`,
        health: `Doctor/Nurse/Medical Workers`,
        ngo: `Society/Body/Agency`,
        student: `Student`,
      },
      profileEditor: {
        mobilenoError: `Please enter a Malaysia Telephone Number`,
        addressError: `Please enter your address`,
        poscodeError: `Please enter your poscode`,
        profileEditSuccess: `Profile Edited Successfully`,
        imageNullError: `Please select an image.`,
        imageValidError: `Please select a valid image.`,
        imageEditSuccess: `Photo Successfully Updated`,
        professionhealthid: `Medical ID`,
        professioname: `Profession Name`,
        editProfile: `Edit Profile`,
        icon: `Identity Card No.`,
        name: `Name`,
        fullname: `Full Name`,
        address: `Home Address`,
        fulladdress: `Full Address`,
        poscode: `Poscode`,
        district: `District`,
        state: `State`,
        mobileno: `Telephone Number`,
        organisationname: `Company/Organisation/University Name`,
        organisationaddress: `Company/Organisation/University Address`,
        bankName: "Bank Name",
        bankAccountNo: "Bank Account No",
        bankAccountName: "Bank Account Name",
        updateBtn: `Update`,
        nameNextofKin: `Next of Kin`,
        relationship: `Relationship with Kin`,
        contactNoNextofKin: `Kin Contact Number`,
      },
      centers: {
        search: "Search Centers",
        areaAddress: "Area Name/Address...",
        state: "State",
        searchbtn: "Search",
        center: "Centers Recommended To You",
        operating_address: "Operating Hours :",
      },
      eachCenter: {
        personnelNeeded: `Personnel Needed`,
        doses: `Doses per Day`,
        healthWorker: `Healthworker`,
        general: `General`,
        address: `Address : `,
        contactNumber: "Contact Number : ",
        contactEmail: "Contact Email : ",
        operatingHours: `Operating Hours : `,
        direction: `Description : `,
        volunteerDates: `Select Volunteer Dates`,
        selectVolunteerDate: "Select Volunteer Dates",
        shift: "Shift",
        shiftDetails: "Shift Details",
        morningShift: "Morning Shift",
        eveningShift: "Evening Shift",
        wholeDayShift: "Whole Day Shift",
        generalMorning: "General Morning",
        generalEvening: "General Evening",
        healthMorning: "Health Morning",
        healthEvening: "Health Evening",
        selectShiftInstruction:
          " Please select your volunteer date (Monday only) :",
        selectedShiftRange: "You have selected week shift starts from",
        to: "to",
        confirmParticipation: `Confirm Participation`,
        popup: {
          title: `Confirm Participation`,
        },
        toBeFixCondition: "Currently open for general volunteers only",
        condition1: "Please select Monday only",
        condition2: "Please ensure you are authenticated",
        condition2a:
          "You have been blacklisted from booking. Please contact info@mysukarela.com if you think that there is any mistakes on the judgement",
        condition3: "Please verify your email address",
        condition4: "Your profile is not yet completed",
        condition5:
          "ID perubatan anda mungkin belum disahkan atau anda belum menghadiri taklimat Zoom. Sila hubungi info@mysukarela.com jika masalah ini berlanjutan",
        condition6: "You can only book future dates",
        condition7: "You already have bookings during these dates",
        condition8: "Booking slots are no longer available",
        roles: "Roles",
        volunteeringRoles: "Volunteering roles",
        stepBookType: "Select your volunteering type",
        stepBookDate: "Select your volunteering date",
      },
      questions: {
        topic: "🙋 Frequently Asked Questions",
        description:
          "If your question is un-answered from the answers below, feel free to contact us in ",
        page: "Contact Us Page",
        q1: "What is the Volunteer initiative for the National COVID-19 Immunization Programme - Malaysia Vaccine Support Volunteers (MyVac)?",
        q2: "What are the qualifications to be a volunteer?",
        q3: "What is required of me to be a volunteer?",
        q4: "What is the scope of my duties as a volunteer?",
        q5: "How can I register to become a volunteer?",
        q6: "What is the process for me to become a volunteer?",
        q7: "As a volunteer, do I need to undergo any health checks?",
        q8: "Can I apply for a support  letter for temporary release from my work/study place to become a volunteer?",
        q9: "How many hours must I contribute as a volunteer?",
        q10: "Are there rewards/incentives for volunteering?",
        q11: "Can I choose a date, time and location to volunteer?",
        q12: "Is there a specific code of conduct that I need to adhere to while serving as a volunteer?",
        q13: "Do I need to attend any training?",
        q14: "As a volunteer, can I apply to get the vaccine in advance?",
        q15: "When is the closing date for application as a volunteer?",
        q16: "Who can be a witness for all the required forms?",
      },
      answer: {
        aGen: "For Non-Healthcare Volunteer :",
        aMed: "For Healthcare Volunteer :",
        a1: "MyVac is an initiative to mobilize volunteers in assisting frontliners to  administer vaccines to the population. Volunteers will be the facilitators at the Vaccine Administration Centers (VAC)",
        a2: "This programme is open to all Malaysian citizens who are 18 years old and above and in good health. If you are either a doctor, a dentist, a pharmacist, a nurse or a medical assistant and wish to participate as a Healthcare Volunteer, you must have a valid Annual Practising Certificate (APC)",
        a3: "Able to give full commitment, responsible, honest and able to comply with instructions in carrying out tasks entrusted to fight against the COVID-19 pandemic through the national immunization programme",
        a4: "Volunteers can choose to become healthcare or non-healthcare volunteers based on their qualifications. Among the scope of duties identified :",
        a4a: "Healthcare Volunteers: Vaccinators, management of sick / emergency bay and Covid-19 Vaccine Counselling",
        a4b: "Non-Healthcare Volunteers: Temperature and symptom screening, assigning appointments, registration, managing the waiting room.",
        a4c: "You can make this choice during registration on the website. However, for non-healthcare volunteers, specific areas of responsibility will be determined by their respective supervisors at the VAC later.",
        a5: "Registration can be done through the volunteer website at www.myvac.com.my",
        a6: "Below is the process of becoming a MyVac volunteer:",
        a6a: `Register your interest at www.myvac.com.my `,
        a6b: `Verify your email. Verification of APC by MOH is needed for healthcare volunteers (Estimated 5 working days).`,
        a6c: `Next, you will have to undergo a virtual training and pass a participation quiz. ONLY for Healthcare Volunteers, an additional online training is required (dates of such training will be made known to you on your profile page).`,
        a6d: `Complete your profile by keying in your bank details, next-of-kin details and uploading your profile picture.`,
        a6e: `Download, print, verify and upload all required documents (documents can be downloaded on your profile page).`,
        a6f: `Those who have participation quizzes and uploaded all documents may then report themselves to the nearest Youth and Sports Office to sign their Volunteer’s Offer Letter and collect their volunteer kit. `,
        a6g: `You may then begin choosing date, time and location to volunteer. The availability of the date, time and location will be known in real time. `,
        a6h: `Three (3) days prior to your supposed reporting date, you are to declare your Covid-19 exposure risk in the profile page.`,
        a6i: `Report to the VAC an hour earlier on the designated date (or as agreed by the VAC supervisor).`,
        a7: "No, you are only required to perform a self health declaration for symptoms and Covid-19 exposure risk on the website.",
        a8: "Yes. A support letter for release can be issued by the local District Youth and Sports Office. You may contact your nearest District Youth and Sports Office once you have successfully become a MyVac volunteer. However, release from work/study place lies on the decision of the respective employers/institutions.",
        a9: "The duty period of each volunteer is divided into two (2) sessions:",
        a9a: "One Session: Six (6) hours per session. MyVac volunteers must work for at least six (6) hours to receive the minimum token payment.",
        a9b: "Two (2) sessions: Maximum 12 hours or depending on VAC operating hours. If a VAC operates for less than 12 hours, then the volunteer will be considered to be on duty for one day.",
        a10: "Taking into account that the MyVac is a volunteering programme based on the spirit of wanting to get involved in the efforts to ensure that the National COVID-19 Immunization Programme can be implemented as best as possible for the welfare of the country, volunteers will be only given consolation payments in accordance to the rates as below:",
        aGen10i: "Operating hours of VAC (8.00 am to 5.00pm)",
        aGen10ia:
          "RM50.00 for each volunteer that works exceeding eight (8) hours",
        aGen10ii: "Operating hours of VAC (8.00 am to 10.00pm)",
        aGen10iia:
          "RM50.00 for each volunteer that works exceeding seven (7) hours according the the shift system below :",
        aGen10iib: "Shift A	: 8.00 am - 3.00 pm",
        aGen10iic: "Shift B	: 2.00 pm -10.00 pm",
        aGen10iid:
          "*Volunteers that work two (2) shifts consecutively within the same day are eligible to claim a total of RM100/day.",
        aMed10a:
          "RM50.00 for each volunteer who works for two (2) sessions or throughout VAC's entire operating hours.",
        aMed10b:
          "RM25.00 for each volunteer who works for one (1) session for six (6) hours.",
        a10c: "Individuals who are a civil servant or under the payroll of any government establishment are not eligible to receive any form of monetary allowances / token in accordance to  Pekeliling Perbendaharaan Malaysia WP10.9 (Rate and Procurement of Expertise Services from Non-Civil Officers in Official Government Programs); Ministry of Finance Malaysia, 15. January 2021.",
        a10d: "We will provide identification cards and vests and to each volunteer on duty. At the end of the programme, you will be presented with a certificate of appreciation.",
        a11: "Each volunteer can choose dates to be on duty. Each shift consists of a duration of six (6) hours. Selection can be done on the website after a successful registration. Each volunteer can choose a duty area, but it depends on the vacancy at that location.",
        aGen11:
          "There are 3 working slots to choose from. Single day bookings will not be entertained. Each non-healthcare volunteer must give a minimum commitment according to the following;",
        aGen11a: "Monday - Wednesday (three (3) consecutive days)",
        aGen11b: "Thursday - Friday (two (2) consecutive days)",
        aGen11c: "Saturday - Sunday (two (2) consecutive days)",
        aMed11:
          "Healthcare volunteers must give a commitment of seven (7) consecutive days of the selected week. However some centers may not be operational for the entirety of the week, in such cases, volunteers will just be deployed on ALL working days of week for the center.",
        a12: "Maintaining the good name of MyVac Volunteers,and complying with all instructions and Standard Operating Procedures (SOPs) set. Volunteers are not allowed to speak to the media without permission from their respective supervisors at the VAC.",
        a13: "Training will be provided virtually. All volunteers must complete all modules provided and also pass the participation quizzes.",
        a14: "No. Vaccination will be given according to the prescribed phase.",
        a15: "Currently, there is no set closing date for registration as a MyVac Volunteer. You can register through the link www.myvac.com.my. However, vacancy is dependent on the needs of a VAC. If the number of volunteers in each VAC is sufficient, you will be placed on a reserved list and will be notified immediately if there is an opening in the VAC.",
        a16a: "Health Declaration Form	- Self verified by the volunteer",
        a16b: "Waiver of Liability Form - Verified / witness by anyone above the age of 18 years old",
        a16c: "Borang Akta Rahsia - Verified / witness by any civil servant (Grade 41 and above) from the Ministry of Health or Ministry of Youth and Sports (If needed you may visit the nearest District Youth and Sports Office / District Health Office)",
      },
      termsandcondition: {
        title1: "MySukarela - Terms of Service",
        paragraph1: `This document sets out the Terms and Conditions (“Terms'') on which Malaysian Red Crescent Society (MRCS) having its registered office at Lot PT54, Lengkok Belfield, Off Jalan Wisma Putra, 50460 Kuala Lumpur (“We”, “Us” Or “Our”) provides you (“You” Or “Your” Or “User”) with access to the ‘MySukarela ’ (the “platform”). Please read these terms very carefully. `,
        paragraph2: `By creating an account through the website or otherwise using the Platform, you indicate your acceptance of these terms of service. If you do not accept these terms of service, then do not use this platform or any of its content or services.`,
        paragraph3: `These terms of service may be amended or updated by MySukarela from time to time without notice and the terms of service may have changed since your last visit to this website. It is your responsibility to review these terms of service for any changes. Your use after any amendments or updates of these terms of service shall signify your assent to and acceptance of such revised terms. Any new features that may be added to this website from time to time will be subject to these terms of service, unless stated otherwise. You should visit this page periodically to review these terms of service.`,
        paragraph4: `In order to use the services, you must register with us and provide certain information. For information on how we use your personal information, please see the MySukarela privacy policy, the terms of which are incorporated by reference into this agreement. If you do not agree with our privacy policy, do not use our Platform or services.`,
        definition: `Definitions:`,
        paragraph5: `“Platform” refers to the object code form of the MySukarela Platform (s), available for on web browser, download through the Apple, Windows, Google Play and Amazon app stores. The MySukarela Platform offers an online space that enables users`,
        users: `(“Users”)`,
        paragraph6: `to create, publish, host, offer, search for, and book volunteering events. Users who create, publish, host, and offer events are`,
        admins: `“Admins”`,
        paragraph7: `and users who search for, or book are`,
        volunteers: `“Volunteers.”`,
        paragraph8: `Admins creates activities, excursions and events`,
        events: `(“Events”)`,
        paragraph9: `. You must register an account to access and use many features of the Platform, and must keep your account information accurate.`,
        title2: `​Information about the MySukarela Platform`,
        point1: `The MySukarela platform  is owned and managed by Malaysian Red Crescent Society (“MRCS''). All information supplied through the Service is managed by MRCS.`,
        point2: `MRCS can be contacted by writing to Malaysian Red Crescent Society (MRCS) having its registered office at Lot PT54, Lengkok Belfield, Off Jalan Wisma Putra, 50460 Kuala Lumpur.`,
        point3: `The MySukarela Platform is hosted and managed on Google Firebase and adheres to all terms and conditions as well as privacy policy set by Google.`,
        title3: `Use of the Service`,
        paragraph10: `You must be at least 18 years old to register to the platform as a User. In order to register you must provide your full name, email address, and additional information required by the event that you are affiliated with. Any personal information provided by You to us will be stored in accordance with the MySukarela Privacy Policy, which You should read carefully. We ask that to provide your real name when creating an account. You will be solely responsible and liable for any and all loss, damage, and additional costs that we or any other person may incur as a result of your submission of any false, incorrect or incomplete information or your failure to update your registration information as needed. Here are the commitments Users make to us relating to registering and maintaining the security of their account:`,
        userresp: `User’s responsibilities;`,
        point4: `Provide accurate information when registering and keep such information up to date;`,
        point5: `Keep your user login details and access to the Platform secured. We will not be liable for any damages caused by virtue of the compromise of your account or password, including any unauthorized access to your account or use. Please contact us immediately if you suspect or become aware of any unauthorized use of your email login or password or any other breach of security; and`,
        point6: `Treat the other users of the Platform with whom you interact with respect and not engage in any conduct which is threatening, harassing or otherwise harmful to other users or third parties, or do anything that breaches the general User conduct guidelines in Section 6 below. We reserve the right to terminate your access to the Platform and take appropriate legal action if you are in breach of these requirements or the restrictions in the section below;`,
        point7: `Agree not to upload any content or create any User Groups, events that are harmful, abusive, constitute hate speech, or are harassing, discriminatory or otherwise objectionable and harmful to other Users and/or the public;`,
        point8: `If we disable a User’s account, they will not create another one without our permission.`,
        point9: `Users under 18 are only permitted with a parent or legal guardian's supervision (including that parent or guardian agreeing to these Terms of Service and our Privacy Policy on behalf of the User).`,
        point10: `If you are accepting these Terms and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so.`,
        point11: `Except as expressly permitted in these Terms, you shall not, and shall not permit others to ;`,
        point12: `modify, translate, create derivative copies of or copy the Platform, in whole or in part;`,
        point13: `reverse engineer, decompile, disassemble or otherwise reduce the object code of the Platform to source code form;  `,
        point14: `distribute, sub-licence, assign, share, timeshare, sell, rent, lease, grant a security interest in, use for service bureau purposes, or otherwise transfer the Platform or your right to use the Platform;`,
        point15: `remove or modify any copyright, trademark, or other proprietary notices belonging to us or our licensors contained within the Platform; or`,
        point16: `use the Platform in any manner not expressly authorised by these Terms`,
        mrcsresp: `MRCS’s responsibilities`,
        point17: `We endeavour to provide the best service we can, but you understand and agree that the features, functionality, information and materials provided on or through this website are provided "as is." specifically, but without limitation, MySukarela does not represent, warrant or offer any conditions that:`,
        point18: `The information or materials on this website are correct, accurate, reliable or complete;`,
        point19: `The functions contained on this website will be uninterrupted or error-free;`,
        point20: `Defects will be corrected, or`,
        point21: `This website or the technology (s) that makes it available are free of viruses or other harmful components. MySukarela specifically disclaims all representations, warranties and conditions, either express, implied, statutory, by usage of trade, course of dealing or otherwise including but not limited to any implied warranties of merchantability, non-infringement, title, satisfactory quality or fitness for a particular purpose. Any information or material downloaded or otherwise obtained through the use of the website is at your own discretion and risk and you will be solely responsible for any damage to your computer system, loss of data, or any other loss that results from downloading or using any such material. MySukarela does not warrant, endorse, guarantee, provide any conditions or representations, or assume any responsibility for any product or service advertised or offered by any third party through the website or in respect to any website that can be reached from a link on the website or featured in any banner or other advertising on the website, and timecounts shall not be a party to any transaction that you may enter into with any such third party`,
        point22: `Under no circumstances shall MySukarela shall be held liable for any indirect, incidental, special, consequential, exemplary or other damages whatsoever, including, without limitation, any indirect, incidental, special, consequential, exemplary or other damages that result from`,
        point23: `Your use of or your inability to use, this website or any of the information or materials contained on this Platform, `,
        point24: `The cost of procurement of substitute goods, data, information or services`,
        point25: `Errors, mistakes, or inaccuracies in the materials on the website,`,
        point26: `Personal injury, death or property damage of any kind whatsoever arising from or relating to your use of the Platform,`,
        point27: `Any bugs, viruses, trojan horses, or any other files or data that may be harmful to computer or communication equipment or data that may have been transmitted to or through the website, or`,
        point28: `Any errors or omissions in any material on the website or any other loss or damage of any kind arising from or relating to your use of the Platform. These limitations shall apply even if MySukarela has been advised of the possibility of such damages. The foregoing limitations shall apply to the fullest extent permitted by law. `,
        point29: `We reserve the right to make changes to the Platform or part thereof from time to time including without limitation, the removal, modification and/or variation of any elements, features and functionalities of the Platform.`,
        point30: `Without prejudice to our other rights and remedies, we reserve the right to temporarily or permanently suspend or disable your access to the Platform at any time without notice to you in the event you breach any of the provisions herein.`,
        point31: `In the event that we, in our sole discretion, consider that you are making any illegal and/or unauthorised use of the Platform, and/or your use of the Platform is in breach of these Terms, we reserve the right to take any action that we deem necessary, including terminating without notice your use of the Platform and, in the case of illegal use, instigating legal proceedings.`,
        title4: `Membership`,
        point32: `Your Platform membership is free and will continue from a month-to-month cycle until termination. `,
        point33: `We may offer an offered by third parties in conjunction with the provision of their own products and services. We are not responsible for the products and services number of membership plans, including special promotional plans or memberships provided by such third parties.​`,
        title5: `Use of the Platform`,
        point34: `Where you access the Platform, you acknowledge and agree that:`,
        point35: `MRCS is in no way linked, connected or affiliated with Apple, Amazon, Google Play, Windows or Android;`,
        point36: `Access to and use of the Platform may be limited by your network carrier and will be limited if you are attempting to access the Platform from outside the area of your network carrier;`,
        point37: `You acknowledge you will not be able to access and use certain functionalities of the Platform unless you have internet access through a GPRS, 3G or Wi-Fi connected mobile device. All traffic charges or access charges incurred due to the use of the Platform are subject to your agreed terms with your mobile network provider.`,
        point38: `Neither your airtime provider nor any app store or kiosk or kiosk operator (including but not limited to GooglePlay App Store, Apple AppStore, Amazon and Windows Phone Marketplace) shall bear any responsibility or liability whatsoever in relation to sale, distribution, functionality, accessibility, support or performance or non-performance of the Platform;`,
        point39: `Your airtime provider and any App kiosk or kiosk operator (including but not limited to GooglePlay App Store, Apple AppStore, Amazon and Windows Phone Marketplace) are third party beneficiaries in respect of this clause`,
        point40: `MRCS is solely responsible for providing any support and maintenance in respect of the Platform; and you will comply with any applicable third party terms and conditions in your use of the Platform.`,
        title6: `Term and Termination`,
        point41: `These Terms will remain in full force and effect while you use the Platform. We may, at any time and for any reason, terminate these Terms with you and deny you access to the Service. In the event of termination of these Terms for any reason, you must not attempt to use the Platform. `,
        title7: `Intellectual Property`,
        point42: `Other than in relation to any links to third party websites, MRCS own or have a licence to use all right, title and interest in and to the Platform, including without limitation all copyright and any other intellectual property rights therein. These Terms shall not be construed to convey title to or ownership of the Platform or the content contained therein to you. All rights in and to the Platform or content not expressly granted to you are reserved by us. You are expressly prohibited from removing, modifying, altering or using any registered or unregistered marks/logos owned by us, and doing anything which may be seen to take unfair advantage of our reputation and goodwill or could be considered an infringement of any of the intellectual property rights owned and/or licensed to us, without first obtaining our written permission.`,
        title8: `Warranties`,
        point43: `Any content, information or material provided to you as part of the Service is provided on an ‘as is’ basis for your convenience only. Such content, information and material does not constitute advice or a recommendation and therefore it should not be solely relied on to assist in making or refraining from making a decision, or to assist in deciding on a course of action. To the maximum extent permitted by law, we expressly exclude all representations, warranties, obligations and liabilities in connection with the Service, and any content, information or materials provided therein.`,
        title9: `Liability`,
        point44: `Use of the Platform is provided free of charge.`,
        point45: `We shall not be responsible for any:`,
        point46: `losses, damages, costs and expenses which were not reasonably foreseeable to be incurred by you and could not have been reasonably foreseeable by you or us on entering these Terms;`,
        point47: `or loss of profits, contracts or business, loss of an anticipated outcome or anticipated savings.`,
        point48: `We accept and do not limit our liability to you for the following categories:`,
        point49: `death or personal injury;`,
        point50: `negligent misrepresentation, fraud or fraudulent misrepresentation;`,
        point51: `any breach of the obligations implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982.`,
        title10: `Data Protection and Privacy`,
        point52: `We are committed to protecting (i) your privacy; and (ii) the confidentiality of the information provided by you using the Platform. For the purposes of the Data Protection Act 1988, the data controller is Malaysian Red Crescent Society having its registered office at Lot PT54, Lengkok Belfield, Off Jalan Wisma Putra, 50460 Kuala Lumpur `,
        point53: `Where you provide any personal information through the Platform, for example where you register and set up an account, we shall use that information for the following purposes:`,
        point54: `to assist in providing you with the features and services in the Platform;`,
        point55: `to inform you about changes to the Platform;`,
        point56: `and to inform you about additional content and features of the Platform.`,
        point57: `We may disclose your personal information to third parties afliated the the event (ie providing information to third parties for verification of user) : `,
        point58: `All information you provide to us is stored on our secure servers. These servers may be located outside the European Economic Area, and accordingly your data may be transferred and stored outside the EEA. By submitting your personal data, you agree to this transfer and/or storing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with data protection principles. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Platform; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.`,
        title11: `General`,
        point59: `You may print and keep a copy of these Terms, which form the entire agreement between you and us and supersede any other communications or advertising with respect to the Platform.`,
        point60: `These Terms may only be modified with our prior written consent. We may alter or amend these Terms at any time, with immediate effect and without notice. By continuing to use the Platform after such alteration, you will be deemed to have accepted any amendment to these Terms.`,
        point61: `These Terms and their performance shall be governed by and construed in accordance with the laws of Malaysia and the parties hereby submit to the non-exclusive jurisdiction of the courts of Malaysia.`,
        point62: `You shall comply with all foreign and local laws and regulations which apply to your use of the Platform in whatever country you are physically located, including without limitation, consumer law, export control laws and regulations.`,
        point63: `You agree that because of the unique nature of the Platform, and our proprietary rights therein, a demonstrated breach of these Terms by you would irreparably harm us and monetary damages would be inadequate compensation. Therefore, you agree that we shall be entitled to preliminary and permanent injunctive relief, as determined by any court of competent jurisdiction to enforce the provisions of these Terms.`,
        point64: `If any provision of these Terms is declared void, illegal, or unenforceable, the remainder of these Terms will be valid and enforceable to the extent permitted by applicable law. In such event, the parties agree to use their best efforts to replace the invalid or unenforceable provision by a provision that, to the extent permitted by the applicable law, achieves the purposes intended under the invalid or unenforceable provision.`,
        point65: `Any failure by any party to these Terms to enforce at any time any term or condition under these Terms will not be considered a waiver of that party's right thereafter to enforce each and every term and condition of these Terms.`,
        point66: `Neither party will be responsible for delays resulting from circumstances beyond the reasonable control of such party, provided that the non-performing party uses reasonable efforts to avoid or remove such causes of non-performance and continues performance hereunder with reasonable dispatch whenever such causes are removed.`,
        point67: `Nothing in these Terms shall give, directly or indirectly, any third party any enforceable benefit or any right of action against us and such third parties shall not be entitled to enforce any term of these Terms against us.`,
        point68: `If you feel that any materials appearing on the Platform are offensive, objectionable or potentially defamatory please contact us providing full details of the nature of your complaint and the materials to which the complaint relates.`,
      },
      privacy: {
        title1: `MySukarela – Privacy Policy `,
        paragraph1: `The Malaysian Red Crescent Society, warrants you that we will take precautionary measurements to ensure that your privacy and personal data is protected. `,
        paragraph2: `This policy explains how we manage your personal information, and how to contact us if you have any query.`,
        title2: `Definitions`,
        paragraph3: `Undefined terms in this privacy policy have the same definition as in our `,
        reference1: ` terms of service `,
        terms: `(“terms”)`,
        title3: `Personal information we collect`,
        boldparagraph4: `Information needed to use the MySukarela Platform.`,
        paragraph5: `We collect personal information about you when you use the Platform. Without it, we may not be able to provide you with all services requested. This information includes:`,
        bold1: `Contact information, account, personal information.`,
        point1: `Such as your first name, last name, phone number, postal address, email address, date of birth, profession and profile photo, some of which will depend on the features you use.`,
        bold2: `Identity verification and payment information.`,
        point2: ` Such as images of your government issued id (as permitted by applicable laws), your id number or other `,
        reference2: `verification`,
        point2a: ` information, bank account or payment account information. `,
        bold3: `Additional personal information.`,
        point3: ` Such as gender, preferred language(s), city, and personal description. Some of this information as indicated in your account settings is part of your public profile page and will be publicly visible.`,
        bold4: `Other information.`,
        point4: ` Such as when you fill in a form, add information to your account, respond to surveys, respond to community forums, participate in promotions, communicate with our customer care team and other members, or share your experience with us. This may include health information and travel information.`,
        title4: `Information mandatorily collected when booking`,
        paragraph6: `When you decide to book for an event set by the admin, we automatically collect personal information. This information may include: `,
        bold5: `Information mandatorily collected when booking an event. `,
        point5: ` We automatically collect personal information when you book an event set by the admin. This information may include:`,
        bold6: `Usage information.`,
        point6: `Such as the pages or content you view, searches for listings, bookings you have made, and other actions on the MySukarela platform.`,
        bold7: `Log data and device information.`,
        point7: `Such as details about how you’ve used the MySukarela platform (including if you clicked on links to third party applications), ip address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the MySukarela platform. We may collect this information even if you haven’t created an MySukarela account or logged in.`,
        point8: `Cookies and similar technologies.`,
        bold9: `Payment transaction information.`,
        point9: ` Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, paypal email address, iban information, your address and other related transaction details. `,
        bold10: `Personal declaration.`,
        point10: ` Such as health declaration and financial declaration forms.`,
        title5: `Personal information we collect from third parties.`,
        paragraph7: `We collect personal information from other sources, such as:.`,
        bold11: `Third-party services.`,
        point11: ` If you link, connect, or login to the MySukarela platform with a third party service (e.g. Google, facebook, wechat), you direct the service to send us information such as your registration, friends list, and profile information as controlled by that service or as authorized by you via your privacy settings at that service.`,
        bold12: `Background information.`,
        point12: ` To the extent permitted by applicable laws and with your consent where required, we may obtain the local version of police, background or registered sex offender checks. We may use your information, including your full name and date of birth, to obtain such reports.`,
        bold13: `Other participating organizations, bodies and agencies.`,
        point13: ` If you are invited tthe MySukarela Platform by other organizations, organization, body or agency who invited you can submit personal information about you such as your email address or other contact information.`,
        bold14: `Other sources.`,
        point14: `To the extent permitted by applicable law, we may receive additional information about you, such as `,
        reference3: `references`,
        point14a: `, demographic data or information to help detect fraud and safety issues from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results or fraud warnings from identity verification service providers for use in our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the MySukarela platform, or about your experiences and interactions from our partners. We may receive health information, including but not limited to health information related to contagious diseases.`,
        title6: `How we use information we collect`,
        boldparagraph8: `Provide, improve, and develop services on the MySukarela platform.`,
        paragraph8: ` We use personal information to:`,
        point15: `Enable you to access the platform;`,
        point16: `Enable you to communicate with other user, and admins;`,
        point17: `Perform analytics, debug and conduct research;`,
        point18: `Provide customer service;`,
        point19: `Provide coordination and organisation of events near you;`,
        point20: `Send you messages, updates, security alerts, and account notifications,`,
        point21: `Personalize and customize your experience based on your interactions with the Platform, your search and booking history, your profile information and preferences, and other content you submit, and`,
        boldparagraph9: `Create and maintain a trusted and safer environment.`,
        paragraph9: `We use personal information to:`,
        point22: `Detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,`,
        point23: `Study and combat discrimination consistent with our `,
        reference4: `nondiscrimination policy`,
        point24: `Conduct security investigations and risk assessments,`,
        point25: `Verify or authenticate information provided by you,`,
        point26: `Conduct checks against databases and other information sources, including background or police checks,`,
        point27: `Comply with our legal obligations, protect the health and well-being of our guests, hosts, hosts’ employees and members of the public,`,
        point28: `Resolve disputes with our members,`,
        point29: `Enforce our agreements with third parties,`,
        point30: `Comply with law, respond to legal requests, prevent harm and protect our rights `,
        point31: `Enforce our `,
        reference5: `terms`,
        point31a: ` and other policies (e.g. `,
        reference6: `Nondiscrimination policy`,
        point31b: `), and `,
        point32: `In connection with the activities above, we may conduct profiling based on your interactions with the MySukarela platform, your profile information and other content you submit to MySukarela, and information obtained from third parties. In limited cases, automated processes could restrict or suspend access to the MySukarela platform if such processes detect activity that we think poses a safety or other risk to MySukarela, our community, or third parties. If you would like to challenge the decisioning based on the automated process, please contact us via the contact information section below.`,
        boldparagraph10: `Provide payment services.`,
        paragraph10: ` Personal information is used to enable or authorize third parties to use payment services: `,
        point33: `Detect and prevent money laundering, fraud, abuse, security incidents.`,
        point34: `Conduct security investigations and risk assessments.`,
        point35: `Comply with legal obligations (such as anti-money laundering regulations).`,
        point36: `Enforce the `,
        reference7: `payment terms`,
        point36a: ` and other payment policies. `,
        point37: `Provide and improve the payment services.`,
        title7: `Sharing & Disclosure`,
        boldparagraph11: `Sharing with your consent or at your direction.`,
        paragraph12: `Where you provide consent, we share your information as described at the time of consent, such as when authorizing a third-party application or website to access your account .`,
        paragraph13: `Where permissible with applicable law, we may use certain information about you, such as your email address, de-identify it, and share it with social media platforms, to generate leads, drive traffic to the Platform or otherwise promote our products and services.`,
        boldparagraph14: `Sharing between members.`,
        paragraph15: `To help facilitate bookings or other interactions between members, we may need to share certain information such as:`,
        point38: `When a booking request is made or dispute is submitted, certain information may be shared between volunteers(s) and admins(s), including profile, name, names of any additional guests, cancellation history, review information, age of guest (unless prohibited by applicable law), dispute outcome (when applicable) and other information you choose to share and submit. When a booking is confirmed, additional information is shared to assist with coordinating, like profile photo and phone number. `,
        boldparagraph16: `Complying with law, responding to legal requests, preventing harm and protecting our rights.`,
        paragraph17: `We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with a valid legal request or to respond to claims asserted against MySukarela, (iii) to respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability (more information on MySukarela law enforcement guidelines `,
        reference8: `here`,
        paragraph17a: `, (iv) to enforce and administer our `,
        reference9: `agreements`,
        paragraph17b: ` with members, or (v) to protect the rights, property or personal safety of MySukarela, its employees, its members, or members of the public. For example, if permitted due to the forgoing circumstances, host tax information may be shared with tax authorities or other governmental agencies.`,
        paragraph18: `Where appropriate, we may notify members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon or harm to MySukarela, our members, or expose MySukarela to a claim of obstruction of justice.`,
        boldparagraph19: `Service providers.`,
        paragraph19: `We share personal information with affiliated and unaffiliated service providers to help us run our Platform, including service providers that help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the MySukarela services through third-party platforms and software tools (e.g. Through the integration with our apis), (vi) provide customer service, advertising, or payments services, (vii) process, handle or assess insurance claims or similar claims, or (viii) facilitate non-profit and charitable activities consistent with MySukarela mission. These providers are contractually bound to protect your personal information and have access to your personal information to perform these tasks.`,
        boldparagraph20: `Corporate affiliates.`,
        paragraph21: `To support us in providing, integrating, promoting and improving the Platform, payment services, and our affiliates’ services, we may share personal information within our corporate family of companies that are related by common ownership or control. Some examples are:`,
        bold39: `Sharing with payment solutions providers.`,
        point39: ` In order to facilitate payments on or through the Platform, certain information will be shared with the relevant payments entity.`,
        bold40: `Sharing with govermental agencies and bodies.`,
        point40: ` In the event that aid is needed for the authorities (local and international), certain information will be shared to relevant parties to facilitate the execution and planning of such events. In some conditions when said event will be funded by these bodies, bank details and personal details will be shared.`,
        title8: `Other important information`,
        boldparagraph21: `Analyzing your communications and experiences `,
        paragraph22: `We may review, scan, or analyze your communications and experiences on the Platform for reasons outlined in the “how we use information we collect” section of this policy, including fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, enforcing our `,
        reference10: `terms of service`,
        paragraph22a: `, and customer support purposes. For example, as part of our fraud prevention efforts, we scan and analyze messages to mask contact information and references to other sites. In some cases, we may also scan, review, or analyze messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. Occasionally we may need to manually review communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyze your messaging communications to send third-party marketing messages to you and we will not sell reviews or analyses of these communications.`,
        boldparagraph23: `Linking third-party accounts.`,
        paragraph23: `You can link your account with certain third-party services like social networks. Your contacts on these third-party services are referred to as “friends.” When you direct the data sharing by creating this link:`,
        point41: `Some of the information provided to us from linking accounts may be published on your public profile,`,
        point42: `Your activities on the Platform may be displayed to your friends on the Platform and/or that third-party service,`,
        point43: `A link to your public profile on that third-party service may be included in your public profile,`,
        point44: `Other users may be able to see any friends that you may have in common with them, or that you are a friend of their friend if applicable,`,
        point45: `Information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes, and`,
        point46: `Publication and display of information that you provide to the platform through this linkage is subject to your settings and authorizations on the MySukarela platform and the third-party service.`,
        boldparagraph24: `Third-party partners & integrations.`,
        paragraph25: `Parts of Platform may link to third-party services, not owned or controlled by MySukarela, such as google maps/earth. Use of these services is subject to the privacy policies of those providers, such as`,
        reference11: `google maps/earth additional terms of use`,
        reference12: `google privacy policy`,
        paragraph25a: `(see `,
        reference13: `here`,
        paragraph25b: ` for more information on how google uses information)`,
        paragraph25c: `. The Platform does not own or control these third parties and when you interact with them you are providing your information to them.`,
        boldparagraph25: `Managing your information.`,
        paragraph26: `You can access and update some of your personal information through your account settings. `,
        boldparagraph27: `Data access and portability.`,
        paragraph27: `In some jurisdictions, applicable law may entitle you to request certain copies of your personal information or information about how we handle your personal information, request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format, and/or request that we transmit this information to another service provider (where technically feasible).`,
        boldparagraph28: `Email & Contact Number`,
        paragraph28: `We will normally use email or contact number to contact you, and this may include occasional notifications about reminders, upcoming events, and updates if you have chosen this option in your account preferences. `,
        paragraph29: `We will not pass your email details or other contact details onto third parties unless we believe it is appropriate as outlined in the previous section above.`,
        paragraph30: `We do use email as a means of receiving feedback from our members, and we encourage you to email us with your questions or comments.`,
        boldparagraph31: `Security`,
        paragraph32: `While no organization can guarantee perfect security, we are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration.`,
        boldparagraph33: `Changes to this privacy policy`,
        paragraph34: `We reserve the right to modify this privacy policy at any time in accordance with applicable law. If we do so, we will post the revised privacy policy and update the “last updated” date at the top. In case of material changes, we will also provide you with notice of the modification by email at least thirty (30) days before the effective date. If you disagree with the revised privacy policy, you can cancel your account. If you do not cancel your account before the date the revised privacy policy becomes effective, your continued access to or use of the MySukarela platform will be subject to the revised privacy policy.`,
        boldparagraph35: `Contacting us and/or challenging compliance`,
        paragraph36: `For anything to do with this policy, including questions or comments, or to challenge our compliance with this policy, please contact us as follows:`,
        boldaddress1: `Malaysian Red Crescent Society`,
        address2: `Lot PT54, Lengkok Belfield, `,
        address3: `Off Jalan Wisma Putra, `,
        address4: `50460 Kuala Lumpur `,
        address5: `+603-2143 8122 `,
        address6: `secgen@redcrescent.org.my`,
      },
      bookings: {
        bookingId: "Booking Id",
        bookingEmail: "User Email",
        bookingDetails: "Booking Details",
        bookingTitle: "Your Bookings",
        bookingMedAppointment: "Surat Bertugas",
        bookingMedAccepted:
          "Anda dibenarkan untuk menjadi sukarelawan di pusat ini",
        bookingMedDeclined:
          "Anda masih tidak diizinkan untuk menjadi sukarelawan di pusat ini, tunggu 1-3 hari bekerja untuk KKM menghantar surat bertugas ke e-mel anda",
        centerName: "Center Name",
        volDate: "Volunteering Date",
        volShift: "Volunteering Shift",
        cancellation: "Request for Cancellation",
        district: "District",
        warning:
          "Bookings can only be cancelled 3 days or earlier from the volunteering date",
        confirmation: "Are you sure to cancel these bookings?",
      },
      notfound: {
        error404: "404 Page Not Found",
        errorURL:
          "We looked everywhere for this page. Are you sure the website URL is correct?",
      },
      officelist: {
        listTitle: "🏢 List of Ministry of Youth & Sports (KBS) Office",
        description:
          "Operating hours of KBS offices may be changed depending on COVID-19 guidelines. You are advised to contact email or office number of respective office to ensure its exact operating hours.",
        stateTitle: "State",
        districtTitle: "District",
        officeTitle: "Office",
        addressTitle: "Office Address",
        officerTitle: "Officer Name",
        offcontactTitle: "Contact No",
        offemailTitle: "Email",
      },
      quiz: {
        testName: "MYVAC Volunteer Accrediation Test",
        testDescription:
          "All MyVac volunteers must undergo the training module before they are eligible to be a volunteer. If you have not gone through the module, please return to your profile page to undergo the training modules.",
        testInstructions:
          "Choose only one (1) correct answer for each question",
        testPassingScore:
          "To pass you are required to get 18 out of 25 questions right",
        question1:
          "Question 1 : One way to stay positive while volunteering is",
        question1A:
          "Working with others. Leveraging on each other’s strengths and weaknesses",
        question1B: "Have personal goals and strive to meet them",
        question1C: "Laugh at other workers",
        question1D: "Vent and complaint",
        question2:
          "Question 2 : Which of the following isn’t an attribute of a volunteers",
        question2A: "Professional",
        question2B: "Impartiality",
        question2C: "Non-discrimination ",
        question2D: "Favoritism",
        question3: "Question 3 : Volunteer’s etiquettes includes;",
        question3I: "Always smiling, cheerful and friendly ",
        question3II:
          "Ensuring privacy of recipients and the country’s information ",
        question3III: "Self-interest",
        question3IV: "Respectful toward workplace rules and regulations",
        question3A: "i, ii and iv only",
        question3B: "i, ii and iii only",
        question3C: "i, iii and iv only",
        question3D: "All the above",
        question4:
          "Question 4 : The following are social media etiquettes except; ",
        question4A:
          "Abstaining from taking photo of vaccine recipients without their consent",
        question4B:
          "Constantly posting on social media without regarding the outcome",
        question4C:
          "Posting positive messages on social media regarding the COVID-19 vaccine ",
        question4D:
          "Abstaining from posting photos and information that doesn't adhere to the SOP for COVID-19 prevention on social media",
        question5:
          "Question 5 : Who is the Person in Charge (PIC) at the vaccination centres?",
        question5A: "Vaccinators",
        question5B: "Head of Vaccination Administration Centre",
        question5C: "Volunteers’ supervisor",
        question5D: "Vaccine recipients",
        question6:
          "Question 6 : Individuals that are assigned with non medical related task are;",
        question6A: "Volunteer Supervisor",
        question6B: "Vaccinator",
        question6C: "Volunteers",
        question6D: "Head of VAC",
        question7:
          "Question 7 : Which of the following can be isn’t the responsibility of a general volunteer",
        question7A:
          "Conducting temperature and symptomatic screening at the entrance",
        question7B:
          "Controlling crowds within the centre to ensure a smooth and comfortable process",
        question7C: "Administering vaccines",
        question7D:
          "Encouraging and reassuring recipients of the benefits of the vaccine",
        question8:
          "Question 8 : What is the most optimal method to check body temperature using an infrared thermometer?",
        question8A: "5 cm distant - inner surface of the wrist",
        question8B: "10 - 15 cm distant - clean, unobstructed forehead",
        question8C: "10 - 15 cm distant - inner surface of the palm",
        question8D: "20 - 25 cm distant - clean, unobstructed forehead",
        question9:
          "Question 9 : What body temperature values aren’t allowed to enter the Vaccination Administration Centre?",
        question9A: "37.5 °C and above",
        question9B: "36.5 °C - 37.5 °C",
        question9C: "35 °C",
        question9D: "33 °C",
        question10:
          "Question 10 : Which of the following isn’t associated as symptoms for a COVID-19 infection",
        question10A: "Fever",
        question10B: "Lost of smell and taste",
        question10C: "Sore Throat",
        question10D: "Leg pain",
        question11:
          "Question 11 : Which of the following isn’t associated as a COVID-19 exposure risk",
        question11A:
          "Visiting an area deemed as a COVID-19 cluster zone in the past 14 days ",
        question11B:
          "Living in the same house with person that has been diagnosed with COVID-19",
        question11C:
          "Having a physical meeting with  person that has been diagnosed with COVID-19",
        question11D:
          "Living in the same apartment/flat with a person that has been diagnosed with COVID-19",
        question12: "Question 12 : Choose proper workplace practises",
        question12I: "Practising physical distancing of at least 1 meter ",
        question12II: "Practising the 3Ws (wash, wear, warn)",
        question12III:
          "Avoid the 3Cs (crowded place, confined space, closed conversation)",
        question12IV: "Eating together ",
        question12A: "i, ii and iv only",
        question12B: "i, ii and iii only",
        question12C: "i, iii and iv only",
        question12D: "All the above",
        question13:
          "Question 13 : What is to be done first when a recipient comes to the center? ",
        question13A: "Refer the recipient to a healthcare worker ",
        question13B:
          "Advice the recipient to sign the Vaccination Consent Form ",
        question13C: "Perform a health and temperature screening ",
        question13D: "Usher the recipient to the Vaccination Station ",
        question14:
          "Question 14 : What method can a volunteer use to survey symptoms before and after serving in a Vaccination Administration Centre? ",
        question14A: "MyVas application",
        question14B: "MyVac application",
        question14C: "MySymptom application",
        question14D: "COVID19 status in the MySejahtera application",
        question15:
          "Question 15 : What is the purpose of the COVID-19 vaccine?",
        question15A: "To make you ill",
        question15B:
          "To stimulate your immune system so it is ready to fight the disease in the future",
        question15C: "To give you a sore arm",
        question15D: "To make you smarter",
        question16: "Question 16 : How are vaccines made?",
        question16A: "By using a harmless portion of the infective agent",
        question16B: "By mixing chemical components and heating them up",
        question16C: "By mixing water and salt together",
        question16D:
          "By growing various plants and using extracts to create immunity",
        question17:
          "Question 17 : What part of your immune system defends you against bacteria and viruses?",
        question17A: "Your neurotransmitters",
        question17B: "Your antibodies",
        question17C: "Your hemoglobin (blood)",
        question17D: "Your saliva",
        question18:
          "Question 18 : Following are the contraindication of receiving the COVID-19 vaccine except ",
        question18A: "Ongoing infection by other viruses / bacterias",
        question18B: "On immunosuppressive medication such as steroids",
        question18C: "Hypertension and diabetes mellitus",
        question18D: "Known case of severe allergic reaction to vaccines",
        question19:
          "Question 19 : Best practices to prevent the COVID-19 infection",
        question19A: "Vaccination",
        question19B: "Hand hygiene and reducing physical contact",
        question19C: "Consuming eggs",
        question19D: "Wearing a Virus Removal Card",
        question20:
          "Question 20 : After serving in a Vaccination Centre, how long must a volunteer self monitor themself for any symptoms? ",
        question20A: "7 days",
        question20B: "10 days",
        question20C: "14 days",
        question20D: "21 days",
        question21:
          "Question 21 : What should be done if a volunteer presents with symptoms while working at a Vaccination Administration Centre? ",
        question21A: "Return home immediately",
        question21B:
          "Inform the Person in Charge and get treated from a health facility immediately",
        question21C: "Take medicine and continue working ",
        question21D: "Ignore the symptoms ",
        question22:
          "Question 22 : Which of the options below is the most common form of mental health issue in Malaysia?",
        question22A: "Mixed anxiety and depression",
        question22B: "Depression",
        question22C: "Bi-polar disorder",
        question22D: "Schizophrenia",
        question23:
          "Question 23 : Which of the following are risk factors which may affect a person’s mental health?",
        question23A: "Physical health",
        question23B: "Working conditions",
        question23C: "Poverty",
        question23D: "All of the above",
        question24:
          "Question 24 : Which of the following is often a sign that a person is stressed?",
        question24A: "Smoking",
        question24B: "Hearing voices",
        question24C: "Drop in performances",
        question24D: "Exercising",
        question25:
          "Question 25 : Which of the following are examples of helpful coping strategies for stress?",
        question25A: "Meditation, talking about it to friends & family",
        question25B: "Exercise, Smoking",
        question25C: "Alcohol, Meditation",
        question25D: "Social media, Music",
        correctAnswer: "Correct Answer",
        incorrectAnswer: "Incorrect Answer",
        submitAnswer: "Submit Answers",
        goToProfile: "Go to Profile",
        revisitAnswer: "Revisit Answer",
        passedTestTitle: "You have passed the test !",
        passedTestBody:
          "Congratulations on completing the modules to be a volunteer. You are one step closer to completing your registration. Please proceed back to your profile page to complete the remaining checklist.",
        failedTestTitle: "You failed the test !",
        failedTestBody:
          "Unfortunately you didn't meet the minimal requirement to pass the modules. Please retake the test. Do revisit your mistakes and if required return back to your profile to resit the training modules.",
      },
      riskStatus: {
        titlerisk: "Covid-19 Risk Status ",
        titlebutton: " Submit",
        questionrisk1:
          "1. Are you exhibiting 2 or more symptoms as listed below?",
        question1a: "Fever",
        question1b: "Chills",
        question1c: "Shivering",
        question1d: "Body ache",
        question1e: "Headache",
        question1f: "Sore throat",
        question1g: "Nausea or vomiting",
        question1h: "Diarrhea",
        question1i: "Fatigue",
        question1j: "Runny Nose or nasal congestion",
        question2:
          "2. Beside the above, are you exhibiting any of the symptoms below?",
        question2a: "Cough",
        question2b: "Difficulty in breathing",
        question2c: "Lost of smell",
        question2d: "Lost of taste",
        question3:
          "3. Have you attended any events / areas associated with a COVID-19 cluster in the past 14 days?",
        question4:
          "4. Have you traveled to any country outside of Malaysia in the past 14 days?",
        question5:
          "5. Have you had any close contact with a confirmed or suspected COVID-19 case in the past 14 days?",
        answer1: "Yes",
        answer2: "No",
        error: "Please select an answer",
      },
      attendance: {
        verifyAttendance: "Verify Your Attendance",
        email: "Email",
        verifyButton: "Verify Attendance",
        verifySuccess: "Attendance Successfully Verified",
        verifyError: "Unable to verify attendance",
        emailError: `Please enter the correct email format`,
      },
    },
  },
  bm: {
    translation: {
      header: {
        browse: "Soalan Lazim",
        signIn: "Log Masuk",
        signUp: "Daftar",
        signOut: "Log Keluar",
        centers: "Pusat",
        account: "Akaun",
        searchBar: "Cari Pusat",
        language: "Bahasa",
        legal: "Legal",
        confirm: `Konfirmasi`,
        next: `Teruskan`,
        resetPassword: `Menetap Semula Kata Laluan`,
        step: "Langkah",
        bookings: "Tempahan Sukarelawan Saya",
        banner:
          "Program MyVac telah berakhir. Jika anda berminat untuk sukarela, sila melayari laman web MySukarela (https://mysukarela.com). Sekian terima kasih",
      },
      footer: {
        knowUsTitle: "Kenali Kami",
        aboutTitle: "Tentang Kami",
        contactTitle: "Hubungi Kami",
        kbsOffice: "Pejabat KBS",
        legalTitle: "Perundangan",
        tncTitle: "Terma & Syarat",
        privacyTitle: "Polisi Privasi",
        supportTitle: "Kerjasama Daripada",
        supportTitleDescription: "Dengan",
        poweredTitle: "Ditaja Oleh",
        copyrightTitle:
          "Hak Cipta © 2021 Malaysian Red Crescent Society. Hak cipta terpelihara.",
        kbsofficeTitle: "Senarai Pejabat KBS",
      },
      disease: {
        hypertension: `Darah tinggi (tidak terkawal)`,
        diabetes: `Kencing manis`,
        asthma: `Asthma/Chronic Obstructive Pulmonary Disease (COPD)`,
        cancer: `Kanser (dalam rawatan aktif)`,
        autoimmune: `Penyakit Autoimun (dalam rawatan)`,
        cardio: `Penyakit jantung`,
        hepatitis: `Hepatitis`,
        hiv: `HIV/AIDS`,
        obesity: `Obesiti (BMI > 35kgm2)`,
        tb: `Tuberculosis dalam fasa aktif`,
        kidney: `Penyakit buah pinggang`,
        blood: `Penyakit darah (major thalasaemia, sickle cell anemia)`,
        genetic: `Penyakit Genetik (DOWN Syndrome dll)`,
        epilepsy: `Penyakit sawan`,
        mental: `Penyakit mental`,
      },
      illness: {
        fever: `Deman`,
        cough: `Batuk`,
        nose: `Selesema`,
        chills: `Menggigil`,
        myalgia: `Sakit sendi dan otot`,
        headache: `Sakit kepala`,
        sorethroat: `Sakit tekak`,
        nausea: `Loya dan muntah-muntah`,
        diarrhea: `Cirit-Birit`,
        fatigue: `Lesu`,
        breathing: `Sesak Nafas`,
        smell: `Kehilangan deria bau`,
        taste: `Kehilangan deria rasa`,
      },
      auth: {
        signUp: `Pendaftaran`,
        resetSuccessful: "Berjaya Tetapkan Semula?",
        resetPassword: "Tetap Semula Kata Laluan",
        resetPasswordSuccess:
          "Anda telah berjaya menetapkan semula kata laluan anda. Sila log masuk dengan kata laluan baharu anda.",
        newPassword: "Kata Laluan Baharu",
        emailError: `Sila masukkan e-mel dengan format yang betul`,
        emailReset: `Pautan untuk menetap semula kata laluan telah berjaya dihantar ke e-mel anda`,
        emailVerify: "Pengesahan E-mel",
        emailVerifySuccess: "E-mel anda telah disahkan!",
        passwordError: `Panjang kata laluan mesti lebih 6 aksara`,
        codeError: "Kod Tidak Sah",
        actionError:
          "Tindakan yang diinginkan tidak sah, mungkin kerana pautan ini telah tamat",
        icError: `Sila masukkan No IC dengan format yang betul`,
        nameError: `Sila masukkan nama anda`,
        addressError: `Sila masukkan alamat anda`,
        poscodeError: `Sila masukkan poskod kawasan anda`,
        mobilenoError: `Sila masukkan no telefon Malaysia anda`,
        medicalidError: `Sila masukkan no pendaftaran Kad Kesihatan anda`,
        professionError: `Sila pilih sebuah pekerjaan`,
        organisationNameError: `Sila masukkan nama syarikat/organisasi`,
        organisationAddressError: `Sila masukkan alamat syarikat/organisasi`,
        ageError: `Sila masukkan umur yang sah`,
        ageunderError: `Anda di bawah umur`,
        emppermError: `Sila ambil kebenaran dari majikan anda dan daftar semula`,
        kkmempError: `Anda tidak layak untuk berdaftar sebagai seorang sukarelawan jika anda seorang pegawai di Kementerian Kesihatan Malaysia`,
        unfitError: `Anda tidak sesuai untuk berdaftar sebagai sukarelawan`,
        noteligError: `Anda tidak layak untuk berdaftar sebagai sukarelawan`,
        bankAccountNoError: "Sila masukkan nombor akaun bank",
        bankAccountNameError: "Sila masukkan nama akaun bank",
        contactNoNextofKinError: "Sila masukkan nombor waris",
        relationshipError: "Sila masukkan hubungan waris",
        nameNextofKinError: "Sila masukkan nama waris",
        email: `E-Mel`,
        password: `Kata Laluan`,
        forgotpassword: `Lupa Kata Laluan`,
        name: `Nama`,
        fullname: `Name Penuh`,
        icno: `No Kad Pengenalan`,
        age: `Umur`,
        gender: `Jantina`,
        male: `Lelaki`,
        female: `Perempuan`,
        race: `Bangsa`,
        address: `Alamat Rumah`,
        fulladdress: `Alamat Penuh`,
        poscode: `Poskod`,
        district: `Daerah`,
        state: `Negeri`,
        mobileno: `No Telefon`,
        profession: `Pekerjaan`,
        professionothers: `Pekerjaan (Lain-lain)`,
        professioname: `Nama Pekerjaan`,
        healthprofession: "Jenis Pekerjaan Kesihatan",
        professionhealthid: `No Pendaftaran Kad Kesihatan`,
        universityTitle: `Nama Universiti`,
        universityAddress: `Alamat Penuh Universiti`,
        ngoTitle: `Nama Organisasi`,
        ngoAddress: `Alamat Penuh Organisasi`,
        organisationname: `Nama Syarikat/Organisasi/Universiti`,
        organisationnameothers: `Nama Organisasi (Lain-Lain)`,
        organisationaddress: `Alamat Syarikat/Organisasi/Universiti`,
        organisationaddressfull: `Alamat Penuh Syarikat/Organisasi/Universiti`,
        yes: `Ya`,
        no: `Tidak`,
        empperm: `Adakah anda telah mendapat pelepasan dari majikan anda terlebih dahulu?`,
        kkmemp: `Adakah anda anggota kementerian kesihatan Malaysia?`,
        pregnant: `Adakah anda mengandung? Atau baru bersalin dalam tempoh 3 bulan?`,
        illness: `Adakah anda mempunyai sebarang penyakit seperti yang dinyatakan?`,
        exposure: `Berkenaan pendedahan : `,
        redzone: `Adakah anda pernah menghadiri majlis/aktiviti ATAU pergi ke kawasan yang berkaitan dengan kluster COVID-19?`,
        overseas: `Adakah anda telah melawati/tinggal di luar negara dalam masa 14 hari?`,
        closecontact: `Adakah anda telah merupakan Kontak Rapat kepada individu yang disahkan positif COVID-19 dalam masa 14 hari?`,
        symptoms1: `Berkenaan gejala : `,
        symptoms2: `Adalah anda sedang mengalami gejala seperti berikut? `,
        tncheader: `Nota : Dengan pengesahan ini, anda telah bersetuju yang segala butiran yang anda telah berikan adalah tepat dan anda menerima `,
        tnc: `Terma Dan Syarat`,
        privacy: `Polisi Privasi kami`,
        stepper1: `Butiran Pribadi`,
        stepper2: `Butiran Pekerjaan`,
        stepper3: `Deklarasi Kesihatan`,
        stepper4: `Pendedahan COVID-19`,
        backToHome: "Kembali Ke Laman Utama",
      },
      home: {
        title1: "Pusat Koordinasi",
        title2: "Sukarelawan COVID-19 ",
        bannerFooter: "Platform oleh rakyat Malaysia untuk rakyat Malaysia",
        aboutTitle: "Mengenai MyVac",
        aboutparagraph1: `MyVAC (Malaysia Vaccine Support Volunteers) merupakan satu inisiatif yang ditubuhkan hasil kerjasama antara  Kementerian Kesihatan (KKM), Kementerian Sains, Teknologi & Inovasi (MOSTI), Kementerian Belia & Sukan (KBS), Kementerian Pengajian Tinggi (KPT) dan Persatuan Bulan Sabit Merah Malaysia (MRCS) untuk membantu dalam pelancaran program imunisasi kerajaan dengan menempatkan individu yang bersedia berkhidmat dalam pasukan vaksinasi. Program mobilisasi sukarelawan ini akan menjadi instrumen yang amat penting dalam kempen vaksinasi Malaysia bagi mencapai "Herd Immunity". `,
        aboutparagraph2: `Oleh itu, MySukarela ditubuhkan untuk menyokong pelaksanaan MyVAC dengan berkesan. Ia membantu mengisikan keperluan tenaga kerja bagi perlaksaanan Program Imunisasi Covid-19 Kebangsaan dengan menempatkan sukarelawan yang rela dan bersemangat dari semua lapisan masyarakat. `,
        aboutparagraph3: `Program Imunisasi Covid-19 Kebangsaan akan dilaksanakan di 605 Pusat Pemberian Vaksin (PPV) di seluruh Malaysia. Dengan mendaftarkan diri, anda boleh memilih untuk berkhidmat di kawasan anda mengikut masa pilihan anda sendiri. Kami menyeru semua rakyat Malaysia yang bersedia dan berkemampuan untuk menjadi sebahagian daripada pasukan bagi mengurus dan melaksanakan Program  Imunisasi Covid-19 Kebangsaan 2021.`,
        aboutparagraph4: `Semua orang dialu-alukan untuk menjadi sebahagian dalam komuniti MyVAC. Daftar sebagai sukarelawan hari ini. `,
        vacTitle: `Pusat Pemberian Vaksin (PPV)`,
        vacparagraph1: `Kerajaan Malaysia merancang / telah menubuhkan 605 Pusat Pemberian Vaksin (PPV) di seluruh Malaysia untuk memastikan liputan seluruh negara. Pusat Pemberian Vaksin akan dikendalikan oleh kakitangan dari pelbagai kementerian, agensi dan organisasi. 4 kumpulan kakitangan akan ditugaskan untuk memastikan pelaksanaan program dengan selamat dan teratur. Jenis pusat pemberian vaksinasi adalah seperti berikut :`,
        vacpoint1: `Pasukan Perubatan`,
        vacpoint2: `Pasukan Keselamatan`,
        vacpoint3: `Pasukan Sukarelawan Kesihatan`,
        vacpoint4: `Pasukan Sukarelawan Bukan Kesihatan`,
        vactypeTitle: `Jenis Pusat Pemberian Vaksin (PPV)`,
        typeTitle1: `Pejabat Komuniti, Pejabat Daerah Kerajaan`,
        vacType1: `PPV Jenis 1 - 200 dos/sehari`,
        vacType2: `PPV Jenis 2 - 400 dos/sehari`,
        vacType3: `PPV Jenis 3 - 600 dos/sehari`,
        typeTitle2: `Dewan Komuniti, Balai Raya Komuniti`,
        vacType4: `PPV Jenis 4 - 800 dos/sehari`,
        vacType5: `PPV Jenis 5 - 1000 dos/sehari`,
        vacType6: `PPV Jenis 6 - 1200 dos/sehari`,
        typeTitle3: `Stadium, Dewan Perhimpunan`,
        vacType7: `PPV Jenis 7 - 1400 dos/sehari`,
        vacType8: `PPV Jenis 8 - 1600 dos/sehari`,
        stepNonHealthcareTitle: `Langkah-Langkah untuk menjadi Sukarelawan Bukan Kesihatan`,
        step1NonHealthcare: `Langkah 1`,
        step1NonHealthcarepoint1: `Daftar diri anda di laman web MyVAC @ www.myvac.com.my`,
        step1NonHealthcarepoint2: `Mengesahkan akaun anda melalui emel anda`,
        step1NonHealthcarepoint3: `Lengkapkan profil anda`,
        step1NonHealthcarepoint4: `Jalankan semua modul latihan sukarelawan dan lulus kuiz`,
        step1NonHealthcarepoint5: `Muat turun, cetak dan mengesahkan semua borang yang berkaitan`,
        step2NonHealthcare: `Langkah 2 - Laporkan diri anda ke Pejabat Belia dan Sukan yang berdekatan`,
        step2NonHealthcarepoint1: `Serahkan semua borang`,
        step2NonHealthcarepoint2: `Menerima dan menandatangani Surat Tawaran Sukarelawan`,
        step2NonHealthcarepoint3: `Pilih lokasi dan tarikh yang anda hendak berkhidmat`,
        step2NonHealthcarepoint4: `Terima Kit Sukarelawan anda`,
        step3NonHealthcare: `Langkah 3`,
        step3NonHealthcarepoint1: `Mengesahkan Deklarasi Saringan Covid-19 sebelum tarikh lapor diri `,
        step3NonHealthcarepoint2: `Melaporkan diri di Pusat Pemberian Vaksin `,
        stepHealthcareTitle: `Langkah-Langkah untuk menjadi Sukarelawan Kesihatan`,
        step1Healthcare: `Langkah 1`,
        step1Healthcarepoint1: `Daftarkan diri anda di laman web MyVAC @ www.myvac.com.my`,
        step1Healthcarepoint2: `Mengesahkan akaun anda melalui emel anda.`,
        step1Healthcarepoint3: `Pengesahan APC oleh KKM (4-5 hari bekerja)`,
        step2Healthcare: `Langkah 2`,
        step2Healthcarepoint1: `Lengkapkan profil anda `,
        step2Healthcarepoint2: `Jalankan semua modul latihan sukarelawan dan lulus kuiz`,
        step2Healthcarepoint3: `Muat turun, cetak dan mengesahkan semua borang yang berkaitan `,
        step3Healthcare: `Langkah 3`,
        step3Healthcarepoint1: `Menghadiri taklimat Sukarelawan Kesihatan “atas talian” (Dijadualkan setiap hari Jumaat)`,
        step3Healthcarepoint2: `Pilih lokasi dan tarikh yang anda hendak berkhidmat`,
        step4Healthcare: `Langkah 4`,
        step4Healthcarepoint1: `Mengesahkan Deklarasi Saringan Covid-19 sebelum tarikh lapor diri `,
        step4Healthcarepoint2: `Melaporkan diri di Pusat Pemberian Vaksin dan serahkan semua borang kepada Penyelaras PPV anda`,
        jobscopeTitle: `Skop Kerja Sukarelawan`,
        genvolunteerTitle: `Sukarelawan Bukan Kesihatan`,
        genvolunteerpoint1: `Membuat saringan suhu dan gejala`,
        genvolunteerpoint2: `Membuat pendaftaran penerima vaksin menggunakan sistem MySejahtera dan MyVAS`,
        genvolunteerpoint3: `Membantu mengedarkan borang kebenaran menerima vaksin`,
        genvolunteerpoint4: `Mengawal dan mengurus orang ramai di PPV`,
        genvolunteerpoint5: `Memberi bantuan kepada Pasukan Kesihatan`,
        genvolunteerpoint6: `Memastikan SOP Covid-19 dipatuhi`,
        medvolunteerTitle: `Sukarelawan Kesihatan`,
        medvolunteerpoint1: `Membuat saringan penerima vaksin`,
        medvolunteerpoint2: `Mengendalikan penerima vaksin yang mempunyai gejala`,
        medvolunteerpoint3: `Memberi penerangan dan konsultasi berkaitan dengan pemberian vaksin`,
        medvolunteerpoint4: `Pengawasi semua penerima di Ruang Permerhatian`,
        medvolunteerpoint5: `Berkhidmat sebagai Vaksinator`,
        volunteerreqTitle: `Syarat-Syarat`,
        volunteerreqpoint1: `Berumur 18 tahun ke atas`,
        volunteerreqpoint2: `Mempunyai Nombor Perubatan APC/ARC yang sah (hanya untuk Sukarelawan Kesihatan SAHAJA)`,
        volunteerreqpoint3: `Sihat dan tidak menghidapi sebarang penyakit kronik atau ketidakupayaan`,
        volunteerreqpoint4: `Tidak mempunyai risiko pendedahan kepada jangkitan Covid-19 dalam tempoh 14 hari`,
        learnmorebtn: `Maklumat Lanjut`,
        bookBtn:
          "Untuk mengetahui PPV berdekatan anda, sila daftarkan diri anda untuk menjadi seorang sukarelawan MyVAc",
      },
      contact: {
        title: "📱 Hubungi Kami",
        description:
          "Salam sejahtera 👋, kami mengucapkan terima kasih atas minat anda untuk menghubungi kami. Sebelum meneruskan, pastikan soalan anda tidak diduplikasi di ",
        page: "Halaman Soalan Lazim",
        details: "Maklumat untuk Dihubungi",
        operation: "Waktu Operasi : 9am - 6pm",
      },
      profile: {
        account: `Akaun Anda`,
        volunteerRegistrationWarning: `Anda masih belum melengkapkan proses pendaftaran Sukarelawan anda. Sila lengkapkan semua langkah di bawah untuk melakukan tempahan.`,
        stepTitle: `Langkah-langkah untuk melengkapkan proses pendaftaran Sukarelawan anda`,
        stephealth1: `Pengesahan oleh KKM (APC yang sah). Proses ini akan mengambil masa dalam 4-5 hari bekerja`,
        stephealth2: `Langkah 4 : Menghadiri taklimat khas untuk Sukarelawan Kesihatan secara maya (3 jam) pada setiap Jumaat 9am.`,
        step1: `Langkah 1 : Lengkapkan kuiz pos modul`,
        step2: `Langkah 2 : Muat naik gambar profil anda. Kemas kini butiran bank anda dan butiran saudara terdekat`,
        step3: `Langkah 3: Cetak, tandatangan dan muat naik borang-borang berikut:`,
        step3a: ` Borang Pengisytiharan Kesihatan`,
        step3b: ` Borang Penetapan Liabiliti`,
        step3c: ` Borang Akta Rahsia`,
        step3d: ` Ijazah Perubatan / Sijil APC`,
        step4: `Langkah 4: Melaporkan diri anda ke Jabatan Belia dan Sukan Negeri/Pejabat Belia dan Sukan Daerah/Bahagian/Kawasan (rujuk peta lokasi) dan hantar semua borang berkenaan.  Menandatangani surat tawaran pelantikan dan mengambil kit sukarelawan yang disediakan. Muat naik surat tawaran ke dalam system MyVac `,
        step5: `Langkah 5: Membuat tempahan slot - Pengesahan Melalui Emel`,
        step6: `Langkah 6: Emel peringatan akan dihantar kepada anda 3 hari sebelum tarikh sukarela, untuk mengisytiharkan semula status kesihatan anda`,
        emailVerification: `E-Mel Disahkan`,
        emailNotVerification: `E-Mel Belum Disahkan`,
        emailVerificationAction: "Menghantar E-Mel Pengesahan",
        uploadPhoto: `Muatnaik Gambar`,
        logOut: `Log Keluar`,
        information: "Informasi",
        resources: `Sumber`,
        declarationForm: `Borang Deklarasi`,
        quiz: `Kuiz`,
        learn: "Belajar",
        takeQuiz: `Ambil Kuiz`,
        learningResource: `Sumber Pembelajaran`,
        aktaRahsiaForm: `Borang Akta Rahsia`,
        penetapanLiabilitiForm: `Borang Penetapan Liabiliti`,
        healthForm: `Borang Kesihatan`,
        downloadAktaRahsiaForm: `Muat turun borang Akta Rahsia`,
        downloadPenetapanLiabilitiForm: `Muat turun borang Penetapan Liabiliti`,
        downloadHealthForm: `Muat turun borang Kesihatan`,
        downloadHealthOfferForm: "Muat turun Surat Tawaran",
        confirmationForm: `Surat Tawaran`,
        medicalCert: `Ijazah Perubatan/ Sijil APC`,
        medicalInfo: `Sila muat naik Ijazah Perubatan jika anda adalah graduan doktor/doktor gigi/ahli farmasi. Sebaliknya, sila muat naik sijil APC anda jika anda sekarang bekerja sebagai doktor/jururawat/doktor gigi/pembantu perubatan/ahli farmasi`,
        confirmationFormInfo: `Borang Tawaran boleh didapati daripada Jabatan Belia dan Sukan Negeri/ Pejabat Belia dan Sukan Daerah/ Bahagian/ Kawasan`,
        here: `di sini`,
        maplink: `. Peta Lokasi`,
        zoomLink: ". Pautan Zoom",
        formUploadInstruction: `. Pastikan semua maklumat pada borang yang dimuat naik adalah lengkap dan dapat dibaca dengan jelas.`,
        formSelfVerification:
          " Borang Deklarasi Kesihatan hanya perlu disahkan oleh sukarelawan itu sendiri.",
        formAgeVerification:
          " Boleh disaksikan oleh sesiapa sahaja yang berumur 18 tahun keatas.",
        formCivilVerification:
          " Gabung semua borang dan muat naik semua muka surat yang ada. Boleh disaksikan oleh pegawai penjawat awam (Gred 41 dan ke atas) dari Kementerian Kesihatan Malaysia atau Kementerian Belia dan Sukan. Anda boleh mengunjungi mana-mana Pejabat Belia dan Sukan/ Pejabat Kesihatan Daerah berdekatan anda)",
        formHealthVerification:
          " Gabung semua borang dan muat naik semua muka surat yang ada.",
        upload: `Muatnaik`,
        completedStatus: `Status : Lengkap`,
        notCompleteStatus: `Status : Tidak lengkap`,
        comingSoon: `Akan Datang`,
        health: `Doktor/Jururawat/Pegawai Perubatan`,
        ngo: `Persatuan/Badan/Agensi`,
        student: `Pelajar`,
      },
      profileEditor: {
        mobilenoError: `Sila masukkan no telefon Malaysia anda`,
        addressError: `Sila masukkan alamat anda`,
        poscodeError: `Sila masukkan poskod kawasan anda`,
        profileEditSuccess: `Profil berjaya diubah`,
        imageNullError: `Sila pilih sebuah foto.`,
        imageValidError: `Sila pilih foto yang sah.`,
        imageEditSuccess: `Foto berjaya diubah`,
        professionhealthid: `No Pendaftaran Kad Kesihatan`,
        professioname: `Nama Pekerjaan`,
        editProfile: `Edit Profil`,
        icon: `No. Kad Pengenalan`,
        name: `Nama`,
        fullname: `Nama Penuh`,
        address: `Alamat Rumah`,
        fulladdress: `Alamat Penuh`,
        poscode: `Poskod`,
        district: `Daerah`,
        state: `Negeri`,
        mobileno: `No Telefon`,
        organisationname: `Nama Syarikat/Organisasi/Universiti`,
        organisationaddress: `Alamat Syarikat/Organisasi/Universiti`,
        bankName: "Nama Bank",
        bankAccountNo: "No Akaun Bank",
        bankAccountName: "Nama Akaun Bank",
        updateBtn: `Kemas Kini`,
        nameNextofKin: `Waris Terdekat`,
        relationship: `Hubungan Dengan Waris`,
        contactNoNextofKin: `No Telefon Waris`,
      },
      centers: {
        search: "Cari Pusat Pemberian Vaksin",
        areaAddress: "Nama Kawasan/Alamat...",
        state: "Negeri",
        searchbtn: "Cari",
        center: "Pusat Pemberian Vaksin Yang Disarankan Untuk Anda",
        operating_address: "Waktu Operasi :",
      },
      eachCenter: {
        personnelNeeded: `Kakitangan Diperlukan`,
        doses: `Dos dalam Sehari`,
        healthWorker: `Kesihatan`,
        general: `Bukan Kesihatan/Awam`,
        address: `Alamat : `,
        contactNumber: "Nombor Telefon : ",
        contactEmail: "E-mel : ",
        operatingHours: `Waktu Operasi : `,
        direction: `Deskripsi : `,
        volunteerDates: `Pilih Tarikh Untuk Bersukarela`,
        selectVolunteerDate: "Pilih Tarikh Sukarela",
        shift: "Syif",
        shiftDetails: "Maklumat Syif",
        morningShift: "Pagi",
        eveningShift: "Petang",
        wholeDayShift: "Sepanjang Hari",
        generalMorning: "Umum Pagi",
        generalEvening: "Umum Petang",
        healthMorning: "Kesihatan Pagi",
        healthEvening: "Kesihatan Petang",
        selectShiftInstruction:
          " Sila pilih tarikh Sukarela anda (Isnin sahaja) :",
        selectedShiftRange: "Anda telah memilih minggu syif bermula dari",
        to: "sehingga",
        confirmParticipation: `Pengesahan Penyertaan`,
        popup: {
          title: `Pengesahan Penyertaan`,
        },
        toBeFixCondition: "Hanya dibuka untuk sukarelawan umum sahaja",
        condition1: "Hanya Isnin boleh dipilih",
        condition2: "Sila pastikan anda dah mendaftar masuk",
        condition2a:
          "Anda telah disenarai hitam dari membuat tempahan. Sila hubungi info@mysukarela.com jika anda berpendapat bahawa terdapat kesilapan dalam penghakiman tersebut",
        condition3: "E-mel anda belum disahkan",
        condition4: "Profile anda belum diselesai",
        condition5:
          "Your medical ID may have not been verified yet or you have not attended a Zoom briefing yet.Please contact info@mysukarela.com if this problem continues",
        condition6:
          "Anda hanya boleh membuat tempahan untuk tarikh yang akan datang",
        condition7: "Anda sudah mempunyai tempahan pada tarikh tersebut",
        condition8: "Slot tempahan tidak lagi disedia",
        roles: "Peranan",
        volunteeringRoles: "Peranan sukarelawan",
        stepBookType: "Pilih jenis Sukarela anda",
        stepBookDate: "Pilih tarikh Sukarela anda",
      },
      questions: {
        topic: "🙋 Soalan Lazim",
        description:
          "Sekiranya soalan anda tidak dijawab dari jawapan di bawah, jangan ragu untuk menghubungi kami di ",
        page: "Halaman Hubungi Kami",
        q1: `Apakah itu inisiatif Sukarelawan bagi Program Imunisasi COVID-19 Kebangsaan – Malaysia Vaccine Support Volunteers (MyVac)`,
        q2: `Apakah kelayakan untuk menjadi seorang sukarelawan?`,
        q3: `Apakah yang diperlukan daripada saya sebagai seorang sukarelawan?`,
        q4: `Apakah skop tugas saya sebagai sukarelawan?`,
        q5: `Bagaimana saya boleh mendaftar sebagai sukarelawan?`,
        q6: `Apakah dia proses untuk saya menjadi seorang sukarelawan?`,
        q7: `Sebagai sukarelawan yang akan berkhidmat di Pusat Pemberian Vaksin, adakah saya perlu melakukan sebarang pemeriksaan kesihatan?`,
        q8: `Bolehkan saya memohon surat sokongan untuk pelepasan sementara daripada tempat saya bekerja/belajar untuk menjadi sukarelawan?`,
        q9: `Berapakah bilangan jam yang perlu disumbang sebagai sukarelawan?`,
        q10: `Adakah ganjaran yang akan saya peroleh sewaktu menjadi sukarelawan?`,
        q11: `Bolehkah saya memilih tarikh, jam dan lokasi untuk berkhidmat sebagai sukarelawan?`,
        q12: `Adakah terdapat kod etika tertentu yang perlu dipatuhi sepanjang berkhidmat sebagai sukarelawan?`,
        q13: `Adakah saya perlu menghadiri latihan sukarelawan?`,
        q14: `Sebagai sukarelawan yang akan berkhidmat di Pusat Pemberian Vaksin, adakah saya boleh memohon untuk mendapatkan vaksin lebih awal?`,
        q15: `Bilakah tarikh tutup untuk menghantar permohonan sebagai sukarelawan?`,
        q16: "Siapa yang boleh menjadi saksi untuk semua borang pengesahan?",
      },
      answer: {
        aGen: "Bagi Sukarelawan Bukan Kesihatan :",
        aMed: "Bagi Sukarelawan Kesihatan :",
        a1: `Inisiatif Sukarelawan bagi Program Imunisasi COVID-19 Kebangsaan (PICK) adalah satu inisiatif mobilisasi sukarelawan untuk membantu frontliners dalam urusan penyampaian vaksin kepada penerima. Sukarelawan bagi PICK akan menjadi pemudahcara urusan penyampaian vaksin di Pusat Pemberian Vaksin (PPV).`,
        a2: `Program ini terbuka kepada warganegara Malaysia yang berumur 18 tahun ke atas dan sihat tubuh badan. Jika anda merupakan seorang pegawai perubatan, jururawat, penolong pegawai perubatan, pegawai pergigian, atau pegawai farmasi, anda layak untuk menjadi seorang Sukarelawan Kesihatan (tertakluk kepada Sijil Perakuan Pendaftaran Tahunan (APC) yang sah).`,
        a3: `Dapat memberikan sepenuhnya komitmen, bertanggungjawab, berintegriti serta patuh dengan arahan dari semasa ke semasa dalam melaksanakan tugas yang diamanahkan demi bersama negara di dalam memerangi pandemik COVID-19 melalui program imunisasi kebangsaan.`,
        a4: `Sukarelawan boleh memilih sama ada menjadi sukarelawan kesihatan ataupun bukan kesihatan, berdasarkan kelayakan dalam melancarkan pergerakan penerima vaksin di Pusat Pemberian Vaksin (PPV) seluruh negeri dan daerah. Antara skop tugasan yang dikenalpasti :`,
        a4a: `Sukarelawan Kesihatan: Vaksinator, mengawal sick bay/kecemasan dan konsultasi Vaksin Covid-19`,
        a4b: `Sukarelawan Bukan Kesihatan: Membuat saringan gejala suhu, janji temu, pendaftaran, mengawal ruang menunggu.`,
        a4c: `Anda boleh membuat pilihan ini sewaktu pendaftaran di laman web. Bagaimanapun, untuk sukarelawan bukan kesihatan, bidang tugasan spesifik akan ditentukan oleh penyelia masing-masing di PPV nanti.`,
        a5: `Pendaftaran boleh dilakukan melalui laman web sukarelawan di www.myvac.com.my.`,
        a6: `Di bawah adalah proses menjadi sukarelawan MyVac:`,
        a6a: `Mendaftar minat anda untuk di www.myvac.com.my`,
        a6b: `Mengesahkan pendaftaran anda melalui emel. Pengesahan APC oleh KKM akan dilakukan untuk Sukarelawan Kesihatan (jangkaan 5 hari bekerja).`,
        a6c: `Seterusnya, anda perlu menghadiri satu latihan secara maya dan lulus. Hanya untuk sukarelawan kesihatan, satu taklimat khas akan diberikan secara maya (tarikh taklimat akan diketahui dalam laman profil anda). `,
        a6d: `Lengkapkan profil anda dengan mengisikan butiran bank, butiran waris terdekat dan memuat naikan gambar profil anda. `,
        a6e: `Muat turun, cetak dan mengesahkan semua borang yang berkaitan (borang-borang ini boleh didapati di dalam laman profil anda.`,
        a6f: `Kemudian anda dikehendaki untuk melaporkan diri anda ke Pejabat Belia dan Sukan berdekatan untuk menandatangi Surat Tawaran Sukarelawan dan mengambil kit sukarelawan.`,
        a6g: `Membuat pilihan tarikh, masa dan lokasi untuk bertugas. Kesesuaian dan kekosongan tarikh, masa dan lokasi akan diketahui serta-merta.`,
        a6h: `Tiga (3) hari sebelum tarikh lapor diri, anda dikehendaki untuk mengesahkan Deklarasi Saringan Covid-19 di dalam laman profil anda`,
        a6i: `Hadir sejam lebih awal di PPV pada waktu tarikh tugas bermula (atau seperti dipersetujui oleh penyelia PPV).`,
        a7: `Tidak, Sukarelawan hanya perlu melakukan pengesahan deklarasi kesihatan di dalam laman web.`,
        a8: `Ya. Surat sokongan pelepasan boleh dikeluarkan oleh Pejabat Belia dan Sukan Daerah. Anda boleh menghubungi Pejabat Belia dan Sukan Daerah yang terdekat dengan anda setelah anda disahkan berjaya menjadi seorang sukarelawan MyVac. Namun begitu, pelepasan daripada tempat kerja/belajar adalah dimuktamadkan oleh majikan/institusi pengajian masing-masing. `,
        a9: `Jangka masa bertugas setiap sukarelawan adalah terbahagi kepada dua (2) sesi iaitu:`,
        a9a: `Satu Sesi: Enam (6) jam satu sesi. Sukarelawan MyVac perlu bertugas sekurang-kurangnya enam (6) jam bagi mendapat bayaran token minimum;`,
        a9b: `Dua (2) sesi: Maksimum 12 jam atau bergantung kepada waktu operasi PPV. Sekiranya terdapat PPV yang beroperasi kurang dari 12 jam, maka sukarelawan tersebut akan dianggap bertugas untuk satu hari.`,
        a10: `Mengambil prinsip bahawa MyVac ini adalah satu program sukarelawan yang berlandaskan kepada semangat ingin melibatkan diri di dalam usaha memastikan Program Imunisasi COVID-19 Kebangsaan dapat dilaksanakan sebaik yang mungkin demi kesejahteraan negara, hanya bayaran saguhati sukarelawan akan diberikan mengikut kadar seperti di bawah:`,
        aGen10i: "Tempoh Operasi PPV (8.00 pagi hingga 5.00 petang)",
        aGen10ia:
          "RM50.00 untuk setiap sukarelawan yang bertugas melebihi lapan (8) jam waktu operasi PPV",
        aGen10ii: "Tempoh Operasi PPV (8.00 pagi hingga 10.00 malam)",
        aGen10iia:
          "RM50.00 untuk setiap sukarelawan yang bertugas melebihi tujuh (7) jam waktu operasi PPV seperti berikut:",
        aGen10iib: "Syif A	: 8.00 pagi shingga 3.00 peatang",
        aGen10iic: "Syif B	: 2.00 petang hingga 10.00 malam",
        aGen10iid:
          "*Petugas yang bertugas dua (2) sesi berturut-turut pada hari yang sama layak menuntut elaun RM100.00/ hari.",
        aMed10a: `RM50.00 untuk setiap sukarelawan yang bertugas selama dua sesi atau sepanjang tempoh waktu operasi PPV.`,
        aMed10b: `RM25.00 untuk setiap sukarelawan yang bertugas selama satu (1) sesi selama enam (6) jam.`,
        a10c: `Sukarelawan daripada kalangan Penjawat Awam adalah tidak layak untuk menerima bayaran saguhati mengikut Pekeliling Perbendaharaan Malaysia WP10.9 (Penetapan Kadar dan Perolehan Perkhidmatan Kepakaran Daripada Kalangan Bukan Pegawai Awam Dalam Program Rasmi Kerajaan) yang telah dikeluarkan oleh Kementerian Kewangan Malaysia berkuat kuasa pada 15 Januari 2021.`,
        a10d: `Kami akan menyediakan kad pengenalan diri dan vest dan kepada setiap sukarelawan yang bertugas. Di akhir program PICK, kami akan mengeluarkan sijil untuk setiap sukarelawan.`,
        a11: `Setiap sukarelawan boleh memilih tarikh untuk bertugas. Setiap syif bertugas adalah untuk tempoh enam (6) jam. Pemilihan boleh dilakukan di dalam laman web setelah berjaya dipilih menjadi sukarelawan. Setiap sukarelawan boleh memilih kawasan bertugas, tetapi ia bergantung kepada kekosongan di lokasi tersebut.`,
        aGen11:
          "Terdapat 3 slot tempahan yang disediakan. Tempahan slot harian tidak akan dilayan. Sukarelawan bukan kesihatan perlu memberi komitmen berdasarkan slot berikut;",
        aGen11a: "Isnin - Rabu (tiga (3) hari berturut)",
        aGen11b: "Khamis - Jumaat (dua (2) hari berturut)",
        aGen11c: "Sabtu - Ahad (dua (2) hari berturut)",
        aMed11:
          "Sukarelawan kesihatan  perlu bertugas sekurang kurangnya tujuh (7) hari berturut-turut dalam minggu yang dipilih. Walaubagaimanapun, bukan semua PPV buka sepanjang minggu. Dalam situasi ini, sukarelawan hanya perlu bertugas untuk SEMUA hari beroperasi PPV tersebut.",
        a12: `Menjaga nama baik Sukarelawan MyVac, mematuhi segala arahan dan Operasi Standard Prosedur (SOP) yang ditetapkan. Para sukarelawan juga tidak dibenarkan untuk bercakap dengan pihak media tanpa kebenaran daripada penyelia masing-masing di PPV.`,
        a13: `Latihan sukarelawan akan diberikan secara maya. Kesemua sukarelawan haruslah menghabiskan kesemua modul yang diberikan dan juga lulus kuiz yang termaklum dalam semua modul.`,
        a14: `Tidak. Suntikan vaksin akan diberikan mengikut fasa yang telah ditetapkan.`,
        a15: `Buat waktu ini, tiada tarikh tutup yang ditetapkan untuk mendaftar sebagai Sukarelawan MyVac. Anda boleh mendaftar melalui link www.myvac.com.my. Bagaimanapun, kekosongan untuk menjadi sukarelawan adalah bergantung kepada keperluan di PPV. Sekiranya bilangan sukarelawan di setiap PPV adalah mencukupi, anda akan diletakkan di dalam senarai simpanan dan akan dimaklumkan kelak sekiranya ada kekosongan di PPV.`,
        a16a: "Deklarasi Kesihatan	- Disahkan oleh sukarelawan itu sendiri",
        a16b: " Borang Penepian Liabiliti	- Disaksikan oleh sesiapa sahaja yang berumur 18 tahun ke atas",
        a16c: "Borang Akta Rahsia	- Disaksikan oleh pegawai penjawat awam (Gred 41 dan ke atas) dari Kementerian Kesihatan Malaysia atau Kementerian Belia dan Sukan. Anda boleh mengunjungi mana-mana Pejabat Belia dan Sukan/ Pejabat Kesihatan Daerah berdekatan anda)",
      },
      termsandcondition: {
        title1: "MySukarela - Terma dan Syarat",
        paragraph1: `Dokumen ini menetapkan Terma dan Syarat ("Syarat") di mana Persatuan Bulan Sabit Merah Malaysia (MRCS) mempunyai pejabat berdaftar di Lot PT54, Lengkok Belfield, Jalan Off Wisma Putra, 50460 Kuala Lumpur ("Kami", "Kami" Atau "Kami") menyediakan Anda ("Anda" Atau "Anda" Atau "Pengguna") dengan akses ke 'MySukarela' ("platform"). Sila baca syarat-syarat ini dengan teliti.`,
        paragraph2: `Dengan membuat akaun melalui laman web atau menggunakan Platform, anda menunjukkan penerimaan anda terhadap syarat perkhidmatan ini. Sekiranya anda tidak menerima syarat perkhidmatan ini, maka jangan gunakan platform ini atau kandungan atau perkhidmatannya.`,
        paragraph3: `Syarat perkhidmatan ini boleh dipinda atau diperbaharui oleh MySukarela dari semasa ke semasa tanpa pemberitahuan dan syarat perkhidmatan mungkin telah berubah sejak lawatan terakhir anda ke laman web ini. Tanggungjawab anda untuk mengkaji syarat perkhidmatan ini untuk sebarang perubahan. Penggunaan anda setelah sebarang pindaan atau kemas kini syarat perkhidmatan ini akan menandakan persetujuan anda dan penerimaan syarat-syarat yang disemak semula. Sebarang ciri baru yang mungkin ditambahkan ke laman web ini dari semasa ke semasa akan dikenakan syarat perkhidmatan ini, kecuali dinyatakan sebaliknya. Anda mesti berkunjung ke halaman ini secara berkala untuk mengkaji syarat perkhidmatan ini.`,
        paragraph4: `Untuk menggunakan perkhidmatan, anda mesti mendaftar dengan kami dan memberikan maklumat tertentu. Untuk maklumat mengenai bagaimana kami menggunakan maklumat peribadi anda, sila lihat dasar privasi MySukarela, syarat-syaratnya digabungkan dengan merujuk kepada perjanjian ini. Sekiranya anda tidak bersetuju dengan dasar privasi kami, jangan gunakan Platform atau perkhidmatan kami.`,
        definition: `Definisi:`,
        paragraph5: `"Platform" merujuk kepada bentuk kod objek dari Platform MySukarela, tersedia untuk penyemak imbas web, muat turun melalui kedai aplikasi Apple, Windows, Google Play dan Amazon. Platform MySukarela menawarkan ruang dalam talian yang membolehkan pengguna`,
        users: `("Pengguna")`,
        paragraph6: `membuat, menerbitkan, menghoskan, menawarkan, mencari, dan menempah acara sukarela. Pengguna yang membuat, menerbitkan, menjadi tuan rumah, dan menawarkan acara adalah`,
        admins: `“Admin”`,
        paragraph7: ` dan pengguna yang mencari, atau memesan adalah`,
        volunteers: `"Sukarelawan".`,
        paragraph8: `Pentadbir membuat aktiviti, lawatan dan acara`,
        events: `("Acara").`,
        paragraph9: `Anda mesti mendaftarkan akaun untuk mengakses dan menggunakan banyak ciri Platform, dan mesti memastikan maklumat akaun anda tepat.`,
        title2: `Maklumat mengenai Platform MySukarela`,
        point1: `Platform MySukarela dimiliki dan diuruskan oleh Persatuan Bulan Sabit Merah Malaysia (“MRCS”). Semua maklumat yang diberikan melalui Perkhidmatan diuruskan oleh MRCS.`,
        point2: `MRCS boleh dihubungi dengan menulis surat kepada Persatuan Bulan Sabit Merah Malaysia (MRCS) yang mempunyai pejabat berdaftar di Lot PT54, Lengkok Belfield, Jalan Off Wisma Putra, 50460 Kuala Lumpur.`,
        point3: `Platform MySukarela dihoskan dan dikendalikan di Google Firebase dan mematuhi semua terma dan syarat serta dasar privasi yang ditetapkan oleh Google.`,
        title3: `Penggunaan Perkhidmatan`,
        paragraph10: `Anda mesti berumur sekurang-kurangnya 18 tahun untuk mendaftar ke platform sebagai Pengguna. Untuk mendaftar anda mesti memberikan nama penuh, alamat e-mel, dan maklumat tambahan yang diperlukan oleh acara yang anda berafiliasi. Segala maklumat peribadi yang diberikan oleh Anda kepada kami akan disimpan sesuai dengan Dasar Privasi MySukarela, yang harus Anda baca dengan teliti. Kami meminta untuk memberikan nama sebenar anda semasa membuat akaun. Anda akan bertanggungjawab dan bertanggungjawab sepenuhnya atas segala kerugian, kerosakan, dan kos tambahan yang mungkin ditanggung oleh kami atau orang lain akibat penyerahan maklumat palsu, tidak betul atau tidak lengkap atau kegagalan anda untuk mengemas kini maklumat pendaftaran anda sekiranya diperlukan . Berikut adalah komitmen yang dibuat oleh Pengguna kepada kami berkaitan dengan mendaftar dan menjaga keselamatan akaun mereka:`,
        userresp: `Tanggungjawab pengguna;`,
        point4: `Memberi maklumat yang tepat semasa mendaftar dan memastikan maklumat tersebut terkini;`,
        point5: `Pastikan maklumat masuk pengguna dan akses ke Platform dijamin. Kami tidak akan bertanggungjawab atas kerosakan yang disebabkan oleh kompromi akaun atau kata laluan anda, termasuk akses ke akaun atau penggunaan anda yang tidak dibenarkan. Sila hubungi kami dengan segera sekiranya anda mengesyaki atau menyedari adanya penggunaan login e-mel atau kata laluan e-mel anda atau pelanggaran keselamatan lain yang tidak dibenarkan; dan`,
        point6: `Perlakukan pengguna Platform yang lain dengan siapa anda berinteraksi dengan hormat dan jangan terlibat dalam tindakan yang mengancam, mengganggu atau memudaratkan pengguna lain atau pihak ketiga, atau melakukan apa-apa yang melanggar garis panduan tingkah laku Pengguna umum dalam Bahagian 6 di bawah. Kami berhak untuk menghentikan akses anda ke Platform dan mengambil tindakan undang-undang yang sewajarnya jika anda melanggar syarat-syarat ini atau sekatan di bahagian di bawah;`,
        point7: `Bersetuju untuk tidak memuat naik kandungan apa pun atau membuat Kumpulan Pengguna, peristiwa yang berbahaya, kasar, menimbulkan ucapan benci, atau mengganggu, diskriminasi atau tidak menyenangkan dan memudaratkan Pengguna dan / atau orang lain;`,
        point8: `Sekiranya kita melumpuhkan akaun Pengguna, mereka tidak akan membuat akaun lain tanpa kebenaran kita.`,
        point9: `Pengguna di bawah 18 tahun hanya dibenarkan dengan pengawasan ibu bapa atau penjaga sah (termasuk ibu bapa atau penjaga yang bersetuju dengan Syarat Perkhidmatan ini dan Dasar Privasi kami bagi pihak Pengguna).`,
        point10: `Sekiranya anda menerima Syarat ini dan menggunakan Perkhidmatan atas nama syarikat, organisasi, kerajaan, atau badan hukum lain, anda menyatakan dan menjamin bahawa anda diberi kuasa untuk melakukannya.`,
        point11: `Kecuali sebagaimana yang dinyatakan secara jelas dalam Syarat ini, anda tidak boleh, dan tidak akan membenarkan orang lain untuk;`,
        point12: `mengubah suai, menterjemahkan, membuat salinan terbitan atau menyalin Platform, secara keseluruhan atau sebahagian;`,
        point13: `jurutera terbalik, menyusun semula, membongkar atau mengurangkan kod objek Platform ke bentuk kod sumber;`,
        point14: `mengedar, sub-lesen, menetapkan, berkongsi, timeshare, menjual, menyewa, memajak, memberikan kepentingan keselamatan dalam, menggunakan untuk tujuan biro perkhidmatan, atau memindahkan Platform atau hak anda untuk menggunakan Platform;`,
        point15: `membuang atau mengubah apa-apa hak cipta, cap dagang, atau notis hak milik lain milik kami atau pemberi lesen kami yang terdapat dalam Platform; atau`,
        point16: `gunakan Platform dengan cara apa pun yang tidak dibenarkan oleh Syarat ini`,
        mrcsresp: `Tanggungjawab MRCS`,
        point17: `Kami berusaha untuk memberikan perkhidmatan terbaik yang kami dapat, tetapi anda memahami dan bersetuju bahawa ciri, fungsi, maklumat dan bahan yang disediakan di atau melalui laman web ini disediakan "sebagaimana adanya." secara khusus, tetapi tanpa had, MySukarela tidak mewakili, menjamin atau menawarkan sebarang syarat yang:`,
        point18: `Maklumat atau bahan di laman web ini betul, tepat, boleh dipercayai atau lengkap;`,
        point19: `Fungsi yang terdapat di laman web ini tidak akan terganggu atau bebas ralat;`,
        point20: `Kecacatan akan diperbaiki, atau`,
        point21: `Laman web ini atau teknologi yang membuatnya bebas daripada virus atau komponen berbahaya yang lain. MySukarela secara khusus menolak semua pernyataan, jaminan dan syarat, sama ada tersurat, tersirat, berkanun, dengan penggunaan perdagangan, cara berurusan atau sebaliknya termasuk tetapi tidak terhad kepada sebarang jaminan tersirat mengenai kebolehdagangan, bukan pelanggaran, tajuk, kualiti atau kesesuaian yang memuaskan untuk tujuan tertentu. Segala maklumat atau bahan yang dimuat turun atau diperolehi melalui penggunaan laman web adalah mengikut budi bicara dan risiko anda sendiri dan anda akan bertanggungjawab sepenuhnya atas sebarang kerosakan pada sistem komputer anda, kehilangan data, atau kerugian lain yang disebabkan oleh memuat turun atau menggunakan bahan sedemikian. MySukarela tidak menjamin, menyokong, menjamin, memberikan sebarang syarat atau perwakilan, atau memikul tanggungjawab untuk produk atau perkhidmatan yang diiklankan atau ditawarkan oleh pihak ketiga melalui laman web atau berkenaan dengan laman web yang bolehboleh dihubungi dari pautan di laman web atau dipaparkan di mana-mana sepanduk atau iklan lain di laman web, dan timecount tidak boleh menjadi pihak bagi sebarang transaksi yang anda boleh lakukan dengan pihak ketiga tersebut.`,
        point22: `Dalam keadaan apa pun, MySukarela tidak akan bertanggungjawab atas sebarang kerosakan tidak langsung, sampingan, khas, akibat, teladan atau lain-lain, termasuk, tanpa batasan, sebarang kerosakan tidak langsung, sampingan, khas, akibat, teladan atau lain-lain yang disebabkan oleh`,
        point23: `Penggunaan atau ketidakmampuan anda untuk menggunakan, laman web ini atau maklumat atau bahan yang terdapat di Platform ini,`,
        point24: `Kos perolehan barang pengganti, data, maklumat atau perkhidmatan`,
        point25: `Kesalahan, kesilapan, atau ketidaktepatan dalam bahan di laman web,`,
        point26: `Kecederaan peribadi, kematian atau kerosakan harta benda apa pun yang timbul dari atau berkaitan dengan penggunaan Platform oleh anda,`,
        point27: `Apa-apa bug, virus, trojan horse, atau fail atau data lain yang mungkin berbahaya bagi komputer atau peralatan komunikasi atau data yang mungkin telah dihantar ke atau melalui laman web, atau`,
        point28: `Sebarang kesilapan atau ketinggalan dalam apa-apa bahan di laman web atau kehilangan atau kerosakan lain yang timbul atau berkaitan dengan penggunaan Platform oleh anda. Batasan ini akan berlaku walaupun MySukarela telah diberitahu mengenai kemungkinan kerosakan tersebut. Batasan di atas akan berlaku seluas-luasnya yang dibenarkan oleh undang-undang.`,
        point29: `Kami berhak untuk membuat perubahan pada Platform atau sebahagiannya dari semasa ke semasa termasuk tanpa batasan, penghapusan, pengubahsuaian dan / atau variasi elemen, ciri dan fungsi Platform.`,
        point30: `Tanpa menjejaskan hak dan penyelesaian kami yang lain, kami berhak untuk menangguhkan atau mematikan akses anda ke Platform buat sementara waktu atau selama-lamanya tanpa pemberitahuan kepada anda sekiranya anda melanggar mana-mana ketentuan di sini.`,
        point31: `Sekiranya kami, mengikut budi bicara kami sendiri, menganggap bahawa anda membuat penggunaan Platform secara haram dan / atau tidak sah, dan / atau penggunaan Platform oleh anda melanggar Syarat-syarat ini, kami berhak untuk mengambil tindakan apa pun yang kami anggap perlu, termasuk menamatkan tanpa pemberitahuan penggunaan Platform oleh anda dan, dalam hal penggunaan haram, menghasut proses undang-undang.`,
        title4: `Keahlian`,
        point32: `Keahlian Platform anda percuma dan akan berterusan dari kitaran bulan ke bulan sehingga penamatan.`,
        point33: `Kami mungkin menawarkan tawaran oleh pihak ketiga bersama dengan penyediaan produk dan perkhidmatan mereka sendiri. Kami tidak bertanggungjawab untuk produk dan perkhidmatan bilangan rancangan keahlian, termasuk rancangan promosi khas atau keahlian yang disediakan oleh pihak ketiga tersebut.`,
        title5: `Penggunaan Platform`,
        point34: `Di mana anda mengakses Platform, anda mengakui dan bersetuju bahawa:`,
        point35: `MRCS sama sekali tidak dihubungkan, dihubungkan atau berafiliasi dengan Apple, Amazon, Google Play, Windows atau Android;`,
        point36: `Akses ke dan penggunaan Platform mungkin dibatasi oleh pembawa rangkaian anda dan akan dibatasi jika anda cuba mengakses Platform dari luar kawasan pembawa rangkaian anda;`,
        point37: `Anda mengakui bahawa anda tidak akan dapat mengakses dan menggunakan fungsi tertentu dari Platform melainkan anda mempunyai akses internet melalui peranti mudah alih yang disambungkan dengan GPRS, 3G atau Wi-Fi. Semua caj lalu lintas atau caj akses yang timbul kerana penggunaan Platform tertakluk kepada syarat yang anda setujui dengan penyedia rangkaian mudah alih anda.`,
        point38: `Baik penyedia masa udara anda atau mana-mana kedai aplikasi atau kios atau pengendali kios (termasuk tetapi tidak terhad kepada GooglePlay App Store, Apple AppStore, Amazon dan Windows Phone Marketplace) akan menanggung sebarangtanggungjawab atau liabiliti apa pun berkaitan dengan penjualan, pengedaran, fungsi, kebolehaksesan, sokongan atau prestasi atau bukan prestasi Platform;`,
        point39: `Penyedia masa udara anda dan mana-mana pengendali kios Aplikasi atau kios (termasuk tetapi tidak terhad kepada GooglePlay App Store, Apple AppStore, Amazon dan Windows Phone Marketplace) adalah penerima pihak ketiga berkenaan dengan klausa ini`,
        point40: `MRCS bertanggungjawab sepenuhnya untuk memberikan sokongan dan penyelenggaraan sehubungan dengan Platform; dan anda akan mematuhi mana-mana terma dan syarat pihak ketiga yang berlaku dalam penggunaan Platform oleh anda.`,
        title6: `Tempoh dan Penamatan`,
        point41: `Syarat ini akan terus berkuatkuasa dan berkuat kuasa semasa anda menggunakan Platform. Kami boleh, pada bila-bila masa dan dengan alasan apa pun, menamatkan Syarat ini dengan anda dan menolak anda mengakses Perkhidmatan. Sekiranya penamatan Syarat ini atas sebab apa pun, anda tidak boleh menggunakan Platform ini.`,
        title7: `Harta Intelek`,
        point42: `Selain berkaitan dengan pautan ke laman web pihak ketiga, MRCS memiliki atau mempunyai lesen untuk menggunakan semua hak, hak dan kepentingan dalam dan ke Platform, termasuk tanpa batasan semua hak cipta dan hak harta intelek lain di dalamnya. Syarat-syarat ini tidak boleh ditafsirkan untuk menyampaikan hak atau kepemilikan Platform atau kandungan yang terdapat di dalamnya kepada anda. Semua hak di dan ke Platform atau kandungan yang tidak diberikan secara jelas kepada anda adalah milik kami. Anda secara tegas dilarang membuang, mengubah, mengubah atau menggunakan tanda / logo berdaftar atau tidak berdaftar yang dimiliki oleh kami, dan melakukan apa sahaja yang dilihat dapat memanfaatkan reputasi dan muhibah kami yang tidak adil atau boleh dianggap sebagai pelanggaran intelektual hak harta yang dimiliki dan / atau dilesenkan kepada kami, tanpa terlebih dahulu mendapat kebenaran bertulis dari kami.`,
        title8: `Jaminan`,
        point43: `Sebarang kandungan, maklumat atau bahan yang diberikan kepada anda sebagai sebahagian daripada Perkhidmatan disediakan berdasarkan 'apa adanya' untuk kemudahan anda sahaja. Kandungan, maklumat dan bahan semacam itu bukan merupakan nasihat atau saranan dan oleh itu tidak boleh diandalkan semata-mata untuk membantu dalam membuat atau menahan diri dari membuat keputusan, atau untuk membantu dalam memutuskan tindakan. Sejauh maksimum yang diizinkan oleh undang-undang, kami dengan tegas mengecualikan semua pernyataan, jaminan, kewajiban dan liabiliti yang berkaitan dengan Perkhidmatan, dan setiap kandungan, maklumat atau bahan yang disediakan di dalamnya.`,
        title9: `Tanggungjawab`,
        point44: `Penggunaan Platform disediakan secara percuma.`,
        point45: `Kami tidak akan bertanggungjawab untuk:`,
        point46: `kerugian, kerosakan, kos dan perbelanjaan yang tidak dapat diramalkan oleh anda dan tidak dapat diramalkan secara munasabah oleh anda atau kami semasa memasukkan Syarat ini;`,
        point47: `atau kehilangan keuntungan, kontrak atau perniagaan, kehilangan hasil yang dijangkakan atau jangkaan simpanan.`,
        point48: `Kami menerima dan tidak menghadkan tanggungjawab kami kepada anda untuk kategori berikut:`,
        point49: `kematian atau kecederaan diri;`,
        point50: `salah penyataan yang cuai, penipuan atau penafian yang salah;`,
        point51: `sebarang pelanggaran kewajipan yang disiratkan oleh seksyen 12 Akta Jual Barang 1979 atau seksyen 2 Akta Pembekalan Barang dan Perkhidmatan 1982.`,
        title10: `Perlindungan dan Data Peribadi`,
        point52: `Kami komited untuk melindungi (i) privasi anda; dan (ii) kerahsiaan maklumat yang diberikan oleh anda menggunakan Platform. Untuk tujuan Akta Perlindungan Data 1988, pengawal data adalah Persatuan Bulan Sabit Merah Malaysia yang mempunyai pejabat berdaftar di Lot PT54, Lengkok Belfield, Jalan Off Wisma Putra, 50460 Kuala Lumpur`,
        point53: `Sekiranya anda memberikan maklumat peribadi melalui Platform, misalnya di mana anda mendaftar dan membuat akaun, kami akan menggunakan maklumat tersebut untuk tujuan berikut:`,
        point54: `untuk membantu memberikan anda ciri dan perkhidmatan dalam Platform;`,
        point55: `untuk memberitahu anda mengenai perubahan pada Platform;`,
        point56: `dan untuk memberitahu anda mengenai kandungan dan ciri tambahan Platform.`,
        point57: `Kami mungkin mendedahkan maklumat peribadi anda kepada pihak ketiga yang terlibat dalam acara tersebut (iaitu memberikan maklumat kepada pihak ketiga untuk pengesahan pengguna):`,
        point58: `Semua maklumat yang anda berikan kepada kami disimpan di pelayan selamat kami. Pelayan-pelayan ini mungkin berada di luar Kawasan Ekonomi Eropah, dan oleh itu data anda mungkin dipindahkan dan disimpan di luar EEA. Dengan menyerahkan data peribadi anda, anda bersetuju dengan pemindahan dan / atau penyimpanan ini. Kami akan mengambil semua langkah yang diperlukan untuk memastikan data anda dilayan dengan selamat dan sesuai dengan prinsip perlindungan data. Malangnya, penghantaran maklumat melalui internet tidak sepenuhnya selamat. Walaupun kami akan melakukan yang terbaik untuk melindungi data peribadi anda, kami tidak dapat menjamin keselamatan data anda yang dihantar ke Platform kami; sebarang penghantaran adalah atas risiko anda sendiri. Sebaik sahaja kami menerima maklumat anda, kami akan menggunakan prosedur dan ciri keselamatan yang ketat untuk mengelakkan akses yang tidak dibenarkan.`,
        title11: `Maklumat Am`,
        point59: `Anda boleh mencetak dan menyimpan salinan Syarat ini, yang membentuk keseluruhan perjanjian antara anda dan kami dan menggantikan komunikasi atau iklan lain yang berkaitan dengan Platform.`,
        point60: `Syarat-syarat ini hanya boleh diubah dengan persetujuan bertulis kami terlebih dahulu. Kami boleh mengubah atau meminda Syarat-syarat ini pada bila-bila masa, dengan serta-merta dan tanpa pemberitahuan. Dengan terus menggunakan Platform setelah perubahan tersebut, anda akan dianggap telah menerima pindaan terhadap Syarat ini.`,
        point61: `Syarat-syarat ini dan pelaksanaannya akan diatur oleh dan ditafsirkan sesuai dengan undang-undang Malaysia dan pihak-pihak dengan ini tunduk kepada bidang kuasa bukan eksklusif pengadilan Malaysia.`,
        point62: `Anda harus mematuhi semua undang-undang dan peraturan asing dan lokal yang berlaku untuk penggunaan Platform Anda di negara manapun Anda berada secara fizikal, termasuk tanpa batasan, undang-undang pengguna, undang-undang dan peraturan kawalan eksport.`,
        point63: `Anda bersetuju bahawa kerana sifat Platform yang unik, dan hak milik kami di dalamnya, pelanggaran Terma yang ditunjukkan oleh anda akan merosakkan kami dan kerosakan wang akan menjadi pampasan yang tidak mencukupi. Oleh itu, anda bersetuju bahawa kami berhak mendapat bantuan awal dan tetap, sebagaimana ditentukan oleh mahkamah mana-mana bidang kuasa yang berwenang untuk menguatkuasakan ketentuan Syarat ini.`,
        point64: `Sekiranya ada ketentuan dari Syarat-syarat ini dinyatakan batal, tidak sah, atau tidak dapat dilaksanakan, selebihnya dari Syarat ini akan berlaku dan dapat diberlakukan sejauh yang diizinkan oleh hukum yang berlaku. Dalam keadaan demikian, para pihak setuju untuk menggunakan upaya terbaik untuk mengganti ketentuan yang tidak sah atau tidak dapat dilaksanakan dengan ketentuan yang, sejauh yang diizinkan oleh undang-undang yang berlaku, mencapai tujuan yang dimaksudkan di bawah ketentuan yang tidak sah atau tidak dapat dilaksanakan.`,
        point65: `Sebarang kegagalan oleh mana-mana pihak kepada Syarat-syarat ini untuk menguatkuasakan pada bila-bila masa apa-apa terma atau syarat di bawah Syarat ini tidak akan dianggap sebagai pengabaian hak pihak tersebut selepas itu untuk menegakkan setiap terma dan syarat Syarat ini.`,
        point66: `Kedua-dua pihak tidak akan bertanggungjawab atas kelewatan yang disebabkan oleh keadaan di luar kawalan pihak yang munasabah, dengan syarat pihak yang tidak melaksanakannya menggunakan usaha yang munasabah untuk mengelakkan atau menghapus sebab-sebab yang tidak menunjukkan prestasi dan meneruskan prestasi di bawah ini dengan pengiriman yang munasabah setiap kali sebab-sebab tersebut dihapus .`,
        point67: `Tiada apa-apa dalam Syarat ini yang akan memberi, secara langsung atau tidak langsung, mana-mana pihak ketiga apa-apa faedah yang boleh dilaksanakan atau hak tindakan terhadap kami dan pihak ketiga tersebut tidak berhak untuk menguatkuasakan mana-mana terma Syarat ini terhadap kami.`,
        point68: `Sekiranya anda merasakan bahawa sebarang bahan yang muncul di Platform menyinggung perasaan, tidak menyenangkan atau berpotensi memfitnah, sila hubungi kami dengan memberikan maklumat lengkap mengenai sifat aduan anda dan bahan yang berkaitan dengan aduan tersebut.`,
      },
      privacy: {
        title1: `MySukarela - Dasar Privasi`,
        paragraph1: `Persatuan Bulan Sabit Merah Malaysia, menjamin anda bahawa kami akan mengambil langkah berjaga-jaga untuk memastikan bahawa privasi dan data peribadi anda dilindungi.`,
        paragraph2: `Dasar ini menerangkan bagaimana kami menguruskan maklumat peribadi anda, dan bagaimana menghubungi kami sekiranya anda mempunyai pertanyaan.`,
        title2: `Definisi`,
        paragraph3: `Syarat yang tidak ditentukan dalam dasar privasi ini mempunyai definisi yang sama seperti dalam `,
        reference1: `syarat perkhidmatan kami`,
        terms: ` ("syarat")`,
        title3: `Maklumat peribadi yang kami dikumpul`,
        boldparagraph4: `Maklumat diperlukan untuk menggunakan Platform MySukarela.`,
        paragraph5: `Kami mengumpul maklumat peribadi mengenai anda semasa anda menggunakan Platform. Tanpa itu, kami mungkin tidak dapat memberikan anda semua perkhidmatan yang diminta. Maklumat ini merangkumi:`,
        bold1: `Maklumat hubungan, akaun, maklumat peribadi. `,
        point1: ` Seperti nama depan, nama belakang, nombor telefon, alamat pos, alamat e-mel, tarikh lahir, profesi dan foto profil, beberapa di antaranya bergantung pada ciri yang anda gunakan.`,
        bold2: `Maklumat pengesahan dan pembayaran.`,
        point2: `Seperti gambar id yang dikeluarkan oleh kerajaan anda (seperti yang diizinkan oleh undang-undang yang berlaku), nombor id anda atau maklumat `,
        reference2: `pengesahan`,
        point2a: ` lain, akaun bank atau maklumat akaun pembayaran.`,
        bold3: `Maklumat peribadi tambahan.`,
        point3: ` Seperti jantina, bahasa pilihan, bandar, dan perihalan peribadi. Sebilangan maklumat ini seperti yang ditunjukkan dalam akaun anda adalah dari halaman profil awam anda dan akan dapat dilihat oleh umum.`,
        bold4: `Maklumat lain.`,
        point4: ` Seperti ketika anda mengisi borang, menambah maklumat ke akaun anda, menjawab tinjauan, menjawab forum komuniti, mengambil bahagian dalam promosi, berkomunikasi dengan pasukan penjagaan pelanggan kami dan ahli lain, atau berkongsi pengalaman anda dengan kami. Ini mungkin merangkumi maklumat kesihatan dan maklumat perjalanan.`,
        title4: `Maklumat wajib dikumpulkan semasa membuat tempahan`,
        paragraph6: `Apabila anda memutuskan untuk menempah acara yang ditetapkan oleh pentadbir, kami secara automatik mengumpul maklumat peribadi. Maklumat ini mungkin merangkumi:`,
        bold5: `Maklumat yang wajib dikumpulkan apabila menempah acara. `,
        point5: `Kami mengumpulkan maklumat peribadi secara automatik apabila anda menempah acara yang ditetapkan oleh admin.  Maklumat ini mungkin merangkumi:`,
        bold6: `Maklumat penggunaan.`,
        point6: ` Seperti halaman atau kandungan yang anda lihat, mencari senarai, tempahan yang telah anda buat, dan tindakan lain di platform MySukarela.`,
        bold7: `Data log dan maklumat peranti.`,
        point7: ` Seperti perincian mengenai cara anda menggunakan platform MySukarela (termasuk jika anda mengklik pautan ke aplikasi pihak ketiga), alamat ip, tarikh dan masa akses, maklumat perkakasan dan perisian, maklumat peranti, maklumat peristiwa peranti, pengecam unik, data kerosakan , data kuki, dan halaman yang telah anda lihat atau terlibat sebelum atau selepas menggunakan platform MySukarela. Kami mungkin mengumpul maklumat ini walaupun anda belum membuat akaun MySukarela atau log masuk.`,
        point8: `Kuki dan teknologi serupa.`,
        bold9: `Maklumat transaksi pembayaran.`,
        point9: ` Seperti instrumen pembayaran yang digunakan, tarikh dan waktu, jumlah pembayaran, tarikh luput instrumen pembayaran dan kod pos penagihan, alamat e-mel paypal, maklumat iban, alamat anda dan perincian transaksi lain yang berkaitan.`,
        bold10: `Pengesahan peribadi.`,
        point10: ` Seperti borang perisytiharan kesihatan dan perisytiharan kewangan.`,
        title5: `Maklumat peribadi yang kami dari pihak ketiga.`,
        paragraph7: `Kami mengumpul maklumat peribadi dari sumber lain, seperti:.`,
        bold11: `Perkhidmatan pihak ketiga.`,
        point11: ` Sekiranya anda memautkan, menyambung, atau log masuk ke platform MySukarela dengan perkhidmatan pihak ketiga (contohnya Google, facebook, wechat), anda mengarahkan perkhidmatan tersebut untuk menghantar maklumat seperti pendaftaran, senarai rakan, dan maklumat profil kepada kami seperti yang dikendalikan oleh perkhidmatan tersebut atau seperti yang diizinkan oleh anda melalui tetapan privasi anda di perkhidmatan tersebut.`,
        bold12: `Maklumat latar belakang.`,
        point12: ` Sejauh yang diizinkan oleh undang-undang yang berlaku dan dengan persetujuan anda jika diperlukan, kami dapat memperoleh pemeriksaan polis tempatan, latar belakang atau pemeriksaan pelaku seks yang didaftarkan. Kami mungkin menggunakan maklumat anda, termasuk nama penuh dan tarikh lahir anda, untuk mendapatkan laporan tersebut.`,
        bold13: `Organisasi, badan dan agensi lain yang mengambil bahagian.`,
        point13: ` Sekiranya anda dijemput ke Platform MySukarela oleh organisasi, organisasi, badan atau agensi lain yang mengundang anda boleh menghantar maklumat peribadi mengenai anda seperti alamat e-mel anda atau maklumat hubungan lain.`,
        bold14: `Sumber lain.`,
        point14: ` Sejauh yang diizinkan oleh undang-undang yang berlaku, kami mungkin akan menerima maklumat tambahan mengenai anda, seperti `,
        reference3: `rujukan`,
        point14a: `, data demografi atau maklumat untuk membantu mengesan masalah penipuan dan keselamatan dari penyedia perkhidmatan dan / atau rakan pihak ketiga, dan menggabungkannya dengan maklumat yang kami ada mengenai anda . Sebagai contoh, kami mungkin menerima hasil pemeriksaan latar belakang atau amaran penipuan dari penyedia perkhidmatan pengesahan identiti untuk digunakan dalam usaha pencegahan penipuan dan penilaian risiko kami. Kami mungkin menerima maklumat mengenai anda dan aktiviti anda di dalam dan di luar platform MySukarela, atau mengenai pengalaman dan interaksi anda dari rakan kongsi kami. Kami mungkin menerima maklumat kesihatan, termasuk tetapi tidak terhad kepada maklumat kesihatan yang berkaitan dengan penyakit berjangkit.`,
        title6: `Cara kami menggunakan maklumat yang kami kumpulkan`,
        boldparagraph8: `Menyediakan, memperbaiki, dan mengembangkan perkhidmatan di platform MySukarela.`,
        paragraph8: ` Kami menggunakan maklumat peribadi untuk: `,
        point15: `Membolehkan anda mengakses platform;`,
        point16: `Membolehkan anda berkomunikasi dengan pengguna dan pentadbir lain;`,
        point17: `Lakukan analitik, debug dan jalankan penyelidikan;`,
        point18: `Memberi perkhidmatan pelanggan;`,
        point19: `Menyediakan penyelarasan dan penganjuran acara di dekat anda;`,
        point20: `Kirimi anda pesan, kemas kini, peringatan keselamatan, dan pemberitahuan akaun,`,
        point21: `Memperibadikan dan menyesuaikan pengalaman anda berdasarkan interaksi anda dengan Platform, sejarah carian dan tempahan anda, maklumat dan pilihan profil anda, dan kandungan lain yang anda kirimkan, dan`,
        boldparagraph9: `Perkembangan keselataman.`,
        paragraph9: ` Kami menggunakan maklumat peribadi untuk: `,
        point22: `Mengesan dan mencegah penipuan, spam, penyalahgunaan, keselamatan dan insiden keselamatan, dan aktiviti berbahaya yang lain,`,
        point23: `Mengkaji dan memerangi diskriminasi yang selaras dengan `,
        reference4: `dasar tanpa diskriminasi kami`,
        point24: `Lakukan siasatan keselamatan dan penilaian risiko,`,
        point25: `Sahkan atau sahkan maklumat yang diberikan oleh anda,`,
        point26: `Menjalankan pemeriksaan terhadap pangkalan data dan sumber maklumat lain, termasuk pemeriksaan latar belakang atau polis,`,
        point27: `Mematuhi tanggungjawab undang-undang kami, melindungi kesihatan dan kesejahteraan tetamu, tuan rumah, pekerja tuan rumah dan orang ramai,`,
        point28: `Selesaikan pertikaian dengan anggota kami,`,
        point29: `Menguatkuasakan perjanjian kami dengan pihak ketiga,`,
        point30: `Mematuhi undang-undang, bertindak balas terhadap permintaan undang-undang, mencegah bahaya dan melindungi hak kita`,
        point31: `Menguatkuasakan `,
        reference5: `syarat`,
        point31a: ` dan dasar kami yang lain (mis. `,
        reference6: `Polisi tanpa diskriminasi`,
        point31b: `), dan `,
        point32: `Sehubungan dengan aktiviti di atas, kami mungkin melakukan profil berdasarkan interaksi anda dengan platform MySukarela, maklumat profil anda dan kandungan lain yang anda kirimkan ke MySukarela, dan maklumat yang diperoleh dari pihak ketiga. Dalam kes yang terhad, proses automatik dapat menyekat atau menangguhkan akses ke platform MySukarela jika proses tersebut mengesan aktiviti yang menurut kami menimbulkan keselamatan atau risiko lain kepada MySukarela, masyarakat kita, atau pihak ketiga. Sekiranya anda ingin mencabar keputusan berdasarkan proses automatik, sila hubungi kami melalui bahagian maklumat hubungan di bawah.`,
        boldparagraph10: `Memberi perkhidmatan pembayaran.`,
        paragraph10: ` Maklumat peribadi digunakan untuk membolehkan atau membenarkan pihak ketiga menggunakan perkhidmatan pembayaran: `,
        point33: `Mengesan dan mencegah pengubahan wang haram, penipuan, penyalahgunaan, insiden keselamatan.`,
        point34: `Lakukan siasatan keselamatan dan penilaian risiko.`,
        point35: `Mematuhi tanggungjawab undang-undang (seperti peraturan anti pencucian wang).`,
        point36: `Kuatkan `,
        reference7: `syarat pembayaran`,
        point36a: ` dan dasar pembayaran lain.`,
        point37: `Menyediakan dan meningkatkan perkhidmatan pembayaran.`,
        title7: `Perkongsian & Pendedahan`,
        boldparagraph11: `Berkongsi dengan persetujuan anda atau atas arahan anda.`,
        paragraph12: `Di mana anda memberikan persetujuan, kami akan berkongsi maklumat anda seperti yang dijelaskan pada masa persetujuan, seperti ketika membenarkan aplikasi atau laman web pihak ketiga untuk mengakses akaun anda.`,
        paragraph13: `Sekiranya dibenarkan dengan undang-undang yang berlaku, kami mungkin menggunakan maklumat tertentu tentang anda, seperti alamat e-mel anda, nyah mengenal pasti, dan membaginya dengan platform media sosial, untuk menjana petunjuk, mendorong lalu lintas ke Platform atau mempromosikan produk dan perkhidmatan kami.`,
        boldparagraph14: `Perkongsian antara ahli.`,
        paragraph15: `Untuk membantu memudahkan tempahan atau interaksi lain antara ahli, kami mungkin perlu berkongsi maklumat tertentu seperti:`,
        point38: `Apabila permintaan tempahan dibuat atau perselisihan dikemukakan, maklumat tertentu dapat dikongsi antara sukarelawan dan pentadbir, termasuk profil, nama, nama tetamu tambahan, sejarah pembatalan, maklumat ulasan, usia tetamu (kecuali dilarang oleh undang-undang yang berlaku), hasil sengketa (bila ada) dan maklumat lain yang anda pilih untuk dikongsi dan dihantar. Apabila tempahan disahkan, maklumat tambahan akan dikongsi untuk membantu penyelarasan, seperti foto profil dan nombor telefon.`,
        boldparagraph16: `Mematuhi undang-undang, menanggapi permintaan undang-undang, mencegah bahaya dan melindungi hak-hak kita.`,
        paragraph17: `Kami mungkin mendedahkan maklumat anda kepada pengadilan, penguatkuasa undang-undang, pihak berkuasa pemerintah atau awam, pihak berkuasa cukai, atau pihak ketiga yang diberi kuasa, jika dan sejauh mana kami diharuskan atau diizinkan untuk melakukannya oleh undang-undang atau di mana pendedahan itu wajar: (i) untuk mematuhi kewajiban undang-undang kami, (ii) untuk mematuhi permintaan undang-undang yang sah atau untuk menanggapi tuntutan yang ditegaskan terhadap MySukarela, (iii) untuk menjawab permintaan undang-undang yang sah yang berkaitan dengan penyiasatan jenayah untuk menangani dugaan atau disyaki aktiviti haram, atau untuk bertindak balas atau menangani aktiviti lain yang boleh mendedahkan kami, anda, atau pengguna kami yang lain terhadap tanggungjawab undang-undang atau peraturan (maklumat lebih lanjut mengenai garis panduan penguatkuasaan undang-undang MySukarela di `,
        reference8: `sini`,
        paragraph17a: `), (iv) untuk menguatkuasakan dan menguruskan `,
        reference9: `perjanjian kami`,
        paragraph17b: ` dengan anggota, atau ( v) untuk melindungi hak, harta benda atau keselamatan peribadi MySukarela, pegawainya, anggotanya, atau orang awam. Sebagai contoh, jika dibenarkan kerana keadaan yang berlanjutan, maklumat cukai tuan rumah dapat dikongsi dengan pihak berkuasa cukai atau agensi kerajaan lain.`,
        paragraph18: `Sekiranya sesuai, kami dapat memberitahu anggota tentang permintaan hukum kecuali: (i) pemberitahuan dilarang oleh proses hukum itu sendiri, dengan perintah pengadilan yang kami terima, atau oleh undang-undang yang berlaku, atau (ii) kami percaya bahawa pemberitahuan akan sia-sia, tidak berkesan , menimbulkan risiko kecederaan atau kecederaan badan kepada individu atau kumpulan, atau mewujudkan atau meningkatkan risiko penipuan atau kerosakan kepada MySukarela, anggota kami, atau mendedahkan MySukarela kepada tuntutan penghalang keadilan.`,
        boldparagraph19: `Penyedia perkhidmatan.`,
        paragraph19: `Kami berkongsi maklumat peribadi dengan penyedia perkhidmatan gabungan dan tidak berkaitan untuk membantu kami menjalankan Platform kami, termasuk penyedia perkhidmatan yang membantu kami: (i) mengesahkan identiti anda atau mengesahkan dokumen pengenalan diri anda, (ii) memeriksa maklumat terhadap pangkalan data awam, (iii) melakukan latar belakang atau pemeriksaan polis, pencegahan penipuan, dan penilaian risiko, (iv) melakukan pengembangan produk, penyelenggaraan dan penyahpepijatan, (v) membenarkan penyediaan perkhidmatan MySukarela melalui platform pihak ketiga dan alat perisian (mis. Melalui penyatuan dengan apis kami), (vi) menyediakan perkhidmatan pelanggan, iklan, atau perkhidmatan pembayaran, (vii) memproses, menangani atau menilai tuntutan insurans atau tuntutan serupa, atau (viii) memfasilitasi aktiviti bukan untung dan amal selaras dengan misi MySukarela. Penyedia ini terikat secara kontrak untuk melindungi maklumat peribadi anda dan mempunyai akses ke maklumat peribadi anda untuk melaksanakan tugas-tugas ini.`,
        boldparagraph20: `Gabungan korporat.`,
        paragraph21: `Untuk menyokong kami dalam menyediakan, mengintegrasikan, mempromosikan dan meningkatkan Platform, perkhidmatan pembayaran, dan perkhidmatan sekutu kami, kami dapat berkongsi maklumat peribadi dalam keluarga syarikat korporat kami yang berkaitan dengan pemilikan atau kawalan bersama. Beberapa contoh adalah:`,
        bold39: `Berkongsi dengan penyedia penyelesaian pembayaran.`,
        point39: ` Untuk memudahkan pembayaran di atau melalui Platform, maklumat tertentu akan dikongsi dengan entiti pembayaran yang berkaitan.`,
        bold40: `Berkongsi dengan agensi dan badan pemerintah.`,
        point40: ` Sekiranya bantuan diperlukan untuk pihak berkuasa (tempatan dan antarabangsa), maklumat tertentu akan dibagikan kepada pihak-pihak yang berkaitan untuk memudahkan pelaksanaan dan perancangan acara tersebut. Dalam beberapa keadaan apabila acara tersebut akan dibiayai oleh badan-badan ini, maklumat bank dan maklumat peribadi akan dikongsi.`,
        title8: `Maklumat penting lain`,
        boldparagraph21: `Menganalisis komunikasi dan pengalaman anda`,
        paragraph22: `Kami mungkin meninjau, mengimbas, atau menganalisis komunikasi dan pengalaman anda di Platform dengan alasan yang digariskan dalam bahagian "bagaimana kami menggunakan maklumat yang kami kumpulkan" dalam dasar ini, termasuk pencegahan penipuan, penilaian risiko, pematuhan peraturan, penyelidikan, pengembangan produk, penyelidikan, analisis, menegakkan `,
        reference10: `syarat perkhidmatan kami`,
        paragraph22a: `, dan tujuan sokongan pelanggan. Sebagai contoh, sebagai sebahagian daripada usaha pencegahan penipuan, kami mengimbas dan menganalisis mesej untuk menutup maklumat hubungan dan rujukan ke laman web lain. Dalam beberapa kes, kami juga dapat mengimbas, meninjau, atau menganalisis pesan untuk men-debug, memperbaiki, dan memperluas penawaran produk. Kami menggunakan kaedah automatik di mana mungkin. Kadang-kadang kita mungkin perlu meninjau komunikasi secara manual, seperti untuk penyelidikan penipuan dan sokongan pelanggan, atau untuk menilai dan meningkatkan fungsi alat automatik ini. Kami tidak akan menyemak, mengimbas, atau menganalisis komunikasi pesanan anda untuk menghantar pesanan pemasaran pihak ketiga kepada anda dan kami tidak akan menjual ulasan atau analisis komunikasi ini.`,
        boldparagraph23: `Talian dengan akaun pihak ketiga.`,
        paragraph23: `Anda boleh menghubungkan akaun anda dengan perkhidmatan pihak ketiga tertentu seperti rangkaian sosial. Kenalan anda pada perkhidmatan pihak ketiga ini disebut sebagai "kawan". Apabila anda mengarahkan perkongsian data dengan membuat pautan ini:`,
        point41: `Sebilangan maklumat yang diberikan kepada kami dari memautkan akaun mungkin disiarkan di profil awam anda,`,
        point42: `Aktiviti anda di Platform boleh ditunjukkan kepada rakan anda di Platform dan / atau perkhidmatan pihak ketiga tersebut,`,
        point43: `Pautan ke profil awam anda pada perkhidmatan pihak ketiga tersebut mungkin disertakan dalam profil awam anda,`,
        point44: `Pengguna lain mungkin dapat melihat rakan-rakan yang mungkin anda miliki dengan mereka, atau bahawa anda adalah rakan rakan mereka jika ada,`,
        point45: `Maklumat yang anda berikan kepada kami dari memautkan akaun anda mungkin disimpan, diproses dan dihantar untuk tujuan pencegahan penipuan dan penilaian risiko, dan`,
        point46: `Penerbitan dan paparan maklumat yang anda berikan ke platform melalui pautan ini bergantung pada tetapan dan kebenaran anda di platform MySukarela dan perkhidmatan pihak ketiga.`,
        boldparagraph24: `Rakan kongsi pihak ketiga & integrasi.`,
        paragraph25: `Bahagian Platform boleh dihubungkan ke perkhidmatan pihak ketiga, tidak dimiliki atau dikendalikan oleh MySukarela, seperti google maps / earth. Penggunaan perkhidmatan ini tertakluk pada dasar privasi penyedia tersebut, seperti `,
        reference11: `syarat penggunaan tambahan peta Google / bumi`,
        reference12: `dasar privasi google`,
        paragraph25a: ` (lihat di `,
        reference13: `sini`,
        paragraph25b: ` untuk maklumat lebih lanjut mengenai bagaimana google menggunakan maklumat)`,
        paragraph25c: `. Platform tidak memiliki atau mengawal pihak ketiga ini dan apabila anda berinteraksi dengan mereka, anda memberikan maklumat anda kepada mereka.`,
        boldparagraph25: `Menguruskan maklumat anda.`,
        paragraph26: `Anda boleh mengakses dan mengemas kini beberapa maklumat peribadi anda melalui tetapan akaun anda.`,
        boldparagraph27: `Akses dan mudah alih data.`,
        paragraph27: `Di beberapa bidang kuasa, undang-undang yang berlaku dapat memberi anda hak untuk meminta salinan tertentu dari maklumat peribadi anda atau maklumat mengenai bagaimana kami menangani maklumat peribadi anda, meminta salinan maklumat peribadi yang anda berikan kepada kami dalam format yang tersusun, biasa digunakan, dan dapat dibaca mesin , dan / atau meminta agar kami menyampaikan maklumat ini kepada penyedia perkhidmatan lain (jika layak secara teknikal).`,
        boldparagraph28: `E-mel & Nombor Telefon`,
        paragraph28: `Kami biasanya akan menggunakan e-mel atau nombor kenalan untuk menghubungi anda, dan ini mungkin termasuk pemberitahuan sesekali mengenai peringatan, acara yang akan datang, dan kemas kini jika anda memilih pilihan ini dalam pilihan akaun anda.`,
        paragraph29: `Kami tidak akan menyampaikan butiran e-mel anda atau maklumat hubungan lain kepada pihak ketiga melainkan kami percaya ia sesuai seperti yang dinyatakan dalam bahagian sebelumnya di atas.`,
        paragraph30: `Kami menggunakan e-mel sebagai kaedah untuk menerima maklum balas daripada ahli kami, dan kami mendorong anda untuk menghantar e-mel kepada kami dengan soalan atau komen anda.`,
        boldparagraph31: `Keselamatan`,
        paragraph32: `Walaupun tidak ada organisasi yang dapat menjamin keselamatan yang sempurna, kami terus menerapkan dan mengemas kini langkah-langkah keselamatan pentadbiran, teknikal, dan fizikal untuk membantu melindungi maklumat anda daripada akses, kehilangan, pemusnahan, atau perubahan yang tidak sah.`,
        boldparagraph33: `Perubahan pada dasar privasi ini`,
        paragraph34: `Kami berhak untuk mengubah kebijakan privasi ini setiap saat sesuai dengan undang-undang yang berlaku. Sekiranya kami melakukannya, kami akan memposting polisi privasi yang disemak semula dan mengemas kini tarikh "terakhir dikemas kini" di bahagian atas. Sekiranya berlaku perubahan material, kami juga akan memberi anda pemberitahuan mengenai pengubahsuaian melalui e-mel sekurang-kurangnya tiga puluh (30) hari sebelum tarikh kuat kuasa. Sekiranya anda tidak bersetuju dengan dasar privasi yang disemak semula, anda boleh membatalkan akaun anda. Sekiranya anda tidak membatalkan akaun anda sebelum tarikh dasar privasi yang disemak semula berkuatkuasa, akses terus ke atau penggunaan platform MySukarela akan tertakluk kepada dasar privasi yang disemak semula.`,
        boldparagraph35: `Menghubungi kami `,
        paragraph36: `Untuk apa-apa pertanyaan mengenai dasar ini, termasuk soalan atau komen, atau untuk cabaran terhadap dasar ini, sila hubungi kami:`,
        boldaddress1: `Persatuan Bulan Sabit Merah Malaysia`,
        address2: `Lot PT54, Lengkok Belfield, `,
        address3: `Di Jalan Wisma Putra, `,
        address4: `50460 Kuala Lumpur `,
        address5: `+603-2143 8122 `,
        address6: `secgen@redcrescent.org.my`,
      },
      bookings: {
        bookingId: "Id Tempahan",
        bookingEmail: "E-mel Pengguna",
        bookingDetails: "Butiran Slot Sukarela",
        bookingTitle: "Slot Sukarela Anda",
        bookingMedAppointment: "Letter of Appointment",
        bookingMedAccepted: "You are permitted to volunteer at this center",
        bookingMedDeclined:
          "You are still not permitted to volunteer at this center, please allow 1-3 working days for MOH to send letter of appointment to your email",
        centerName: "Name Pusat",
        volDate: "Tarikh Sukarela",
        volShift: "Syift Sukarela",
        cancellation: "Meminta Pembatalan",
        district: "Daerah",
        warning:
          "Slot sukarela hanya boleh dibatalkan 3 hari atau lebih awal dari tarikh sukarela.",
        confirmation: "Adakah pasti membatalkannya slot sukarela anda?",
      },
      notfound: {
        error404: "404 Halaman Tidak Dijumpai",
        errorURL:
          "Kami telah mencari di mana-mana halaman ini. Adakah anda pasti URL laman web anda betul?",
      },
      officelist: {
        listTitle: "🏢 Senarai Pejabat Kementerian Belia dan Sukan (KBS)",
        description:
          "Waktu operasi pejabat KBS boleh diubah bergantung pada panduan COVID-19. Anda dinasihatkan untuk menghubungi e-mel atau nombor pejabat pejabat masing-masing untuk memastikan waktu operasi yang tepat.",
        stateTitle: "Negeri",
        districtTitle: "Daerah",
        officeTitle: "Pejabat Belia & Sukan",
        addressTitle: "Alamat Pejabat",
        officerTitle: "Nama Pegawai",
        offcontactTitle: "No Telefon",
        offemailTitle: "Email",
      },
      quiz: {
        testName: "Ujian Akreditasi Sukarelawan MYVAC",
        testDescription:
          "Semua sukarelawan MyVac mesti menjalani modul latihan sebelum mereka layak menjadi seorang sukarelawan. Sekiranya anda belum melalui modul, sila kembali ke halaman profil anda untuk menjalani modul latihan.",
        testInstructions: "Pilih satu (1) jawapan yang betul sahaja",
        testPassingScore:
          "Untuk lulus, anda mestilah menjawab 18 daripada 25 soalan dengan betul",
        question1:
          "Soalan 1 : Salah satu cara untuk kekal positif semasa bertugas sebagai sukarelawan adalah",
        question1A:
          "Kerja berpasukan. Memanfaatkan kekuatan dan kelemahan masing-masing",
        question1B:
          "Menetapkan matlamat peribadi dan berusaha untuk mencapainya",
        question1C: "Ketawakan rakan sekerja",
        question1D: "Merungut",
        question2:
          "Soalan 2 : Berikut adalah sikap seseorang sukarelawan kecuali",
        question2A: "Profesionalisme",
        question2B: "Kesopanan",
        question2C: "Tiada diskriminasi",
        question2D: "Pilih kasih",
        question3: "Soalan 3 : Etika bagi sukarelawan adalah seperti berikut",
        question3I: "Sentiasa senyum, ceria dan mesra",
        question3II: "Menjaga kerahsiaan peribadi klien dan negara",
        question3III: "Berkepentingan peribadi",
        question3IV: "Menghormati peraturan tempat kerja",
        question3A: "i, ii dan iv sahaja",
        question3B: "i, ii dan iii sahaja",
        question3C: "i, iii dan iv sahaja",
        question3D: "Semua di atas",
        question4:
          "Soalan 4 : Etika media sosial sebagai sukarelawan adalah seperti berikut kecuali",
        question4A: "Tidak mengambil gambar klien yang sedang menerima vaksin",
        question4B:
          " Sentiasa kemaskini media sosial tanpa mengambil berat kepada penerima vaksin",
        question4C:
          "Membantu kemaskini status yang positif berkaitan vaksin di media sosial",
        question4D:
          "Tidak memuat naik (upload) sebarang tulisan atau gambar perlakuan yang tidak mematuhi SOP pencegahan COVID-19",
        question5:
          "Soalan 5 : Siapakah Orang yang Bertanggungjawab (Person-in-Charge) di Pusat Pemberian Vaksin?",
        question5A: "Vaksinator",
        question5B: "Ketua Pusat Pemberian Vaksin",
        question5C: "Penyelia Sukarelawan",
        question5D: "Penerima Vaksin",
        question6:
          "Soalan 6 : Individu yang dilantik bagi membantu urusan-urusan bukan kesihatan di Pusat Pemberian Vaksin dipanggil",
        question6A: "Penyelia Sukarelawan",
        question6B: "Vaksinator",
        question6C: "Sukarelawan",
        question6D: "Ketua PPV",
        question7:
          "Soalan 7 : Berikut adalah tugas seorang sukarelawan kecuali",
        question7A: "Membuat saringan suhu dan gejala",
        question7B: "Memastikan pematuhan prosedur operasi standard",
        question7C: "Menyelaras semua urusan Pusat Pemberian Vaksin",
        question7D: "Membantu memberi penerangan pemberian vaksin",
        question8:
          "Soalan 8 : Apakah kaedah yang paling sesuai untuk mengambil suhu dengan menggunakan termometer inframerah?",
        question8A: "5 cm jauh - bahagian dalam pergelangan tangan",
        question8B:
          "10 - 15 cm jauh - dahi yang bersih, tanpa sebarang halangan",
        question8C: "10 - 15 cm jauh - bahagian dalam tapak tangan",
        question8D:
          "20 - 25 cm jauh - dahi yang bersih, tanpa sebarang halangan",
        question9:
          "Soalan 9 : Berapakah suhu badan klien yang tidak dibenarkan memasuki Pusat Pemberian Vaksin?",
        question9A: "Melebihi 37.5 °C",
        question9B: "36.5 °C - 37.5 °C",
        question9C: "35 °C",
        question9D: "33 °C",
        question10:
          "Soalan 10 : Berikut adalah gejala jangkitan COVID-19 kecuali",
        question10A: "Demam",
        question10B: "Kehilangan deria bau dan rasa",
        question10C: "Sakit tekak",
        question10D: "Sakit kaki",
        question11:
          "Soalan 11 : Berikut diklasifikasikan sebagai risiko pendedahan kontak rapat COVID-19 kecuali",
        question11A:
          "Menghadiri majlis/aktiviti atau pergi ke kawasan yang berkaitan dengan kluster COVID-19 dalam masa 14 hari",
        question11B:
          "Tinggal serumah dengan individu yang disahkan positif COVID-19",
        question11C:
          "Bekerja bersama dalam jarak dekat atau berkongsi persekitaran, bilik atau ruang yang sama dengan individu yang disahkan positif COVID-19",
        question11D:
          "Tinggal dalam bangunan yang sama dengan individu yang disahkan positif COVID-19",
        question12: "Soalan 12 : Pilih amalan semasa bertugas yang betul",
        question12I:
          "Mempraktikkan penjarakkan fizikal sekurang-kurangnya 1 meter",
        question12II:
          "Mengamalkan  3Ws - wash (cuci), wear (pakai), warn (amaran)",
        question12III:
          "Mengelakkan 3Cs - crowded place (kawasan yang sesak), confined space (kawasan yang sempit), closed conversation (bertutur dengan jarak yang dekat)",
        question12IV: "Makan bersama-sama",
        question12A: "i, ii dan iv sahaja",
        question12B: "i, ii dan iii sahaja",
        question12C: "i, iii dan iv sahaja",
        question12D: "Semua di atas",
        question13:
          "Soalan 13 : Semasa anda bertugas,seorang klien datang untuk mengambil vaksin, apakah langkah pertama anda?",
        question13A: "Rujuk klien kepada pegawai KKM",
        question13B:
          "Menasihatkan klien supaya menandatangi borang kebenaran pemberian vaksin",
        question13C: " Membuat saringan suhu dan gejala",
        question13D: "Memandu arah klien ke stesen penerimaan suntikan",
        question14:
          "Soalan 14 : Bagaimanakah cara sukarelawan untuk memantau simptom kesihatan semasa dan selepas bertugas?",
        question14A: "Aplikasi MyVas",
        question14B: "Aplikasi MyVac",
        question14C: "Aplikasi MySymptom",
        question14D: "Status COVID19 dalam aplikasi MySejahtera",
        question15: "Soalan 15 : Apakah tujuan vaksin COVID-19?",
        question15A: "Membuatkan anda jatuh sakit",
        question15B:
          "Merangsang sistem imuniti anda supaya lebih bersedia untuk jangkitan masa hadapan",
        question15C: "Membuat lengan anda sakit",
        question15D: "Menjadikan anda lebih pandai",
        question16: "Soalan 16 : Bagaimanakah vaksin dihasilkan?",
        question16A: "Dengan menggunakan bahagian virus yang tidak berbahaya",
        question16B: "Campuran bahan kimia",
        question16C: "Campuran air dan garam",
        question16D: "Menggunakan ekstrak pelbagai tumbuhan",
        question17:
          "Soalan 17 : Komponen manakah dalam sistem imuniti anda bertindak sebagai pertahanan terhadap bakteria dan virus?",
        question17A: "Neurotransmitter anda",
        question17B: "Antibodi anda",
        question17C: "Sel darah merah anda",
        question17D: "Air liur anda",
        question18:
          "Soalan 18 : Penyakit berikut tidak dibenarkan untuk mendapatkan suntikan vaksin COVID-19 kecuali",
        question18A: "Sedang mengalami jangkitan virus/bakteria yang lain",
        question18B:
          "Sedang mengambil ubat yang merendahkan ketahanan diri seperti steroid",
        question18C: "Darah tinggi dan kencing manis",
        question18D: "Sejarah reaksi alahan yang teruk terhadap vaksin",
        question19:
          "Soalan 19 : Amalan terbaik untuk mengurangkan wabak COVID-19 adalah",
        question19A: "Vaksinasi",
        question19B: "Kebersihan tangan dan penjarakan fizikal",
        question19C: "Makan telur",
        question19D: "Memakai Kad Kesihatan Anti Virus",
        question20:
          "Soalan 20 : Setelah tamat bertugas, berapa lamakah tempoh seorang sukarelawan mesti memantau diri mereka bagi sebarang simptom?",
        question20A: "7 hari",
        question20B: "10 hari",
        question20C: "14 hari",
        question20D: "21 hari",
        question21:
          "Soalan 21 : Apakah tindakan yang paling wajar dilakukan sekiranya seorang sukarelawan mengalami gejala semasa bertugas?",
        question21A:
          "Pulang kerumah tanpa memaklumkan kepada Orang yang Bertanggungjawab (Person-in-Charge)",
        question21B:
          "Mendapatkan pemeriksaan difasiliti dan maklumkan kepada Orang yang Bertanggungjawab (Person-in-Charge)",
        question21C: "Makan ubat dan teruskan bertugas",
        question21D: "Mengabaikan simptom",
        question22:
          "Soalan 22 : Apakah jenis masalah kesihatan mental yang paling kerap di Malaysia?",
        question22A: "Kebimbangan dan kemurungan",
        question22B: "Kemurungan",
        question22C: "Bipolar",
        question22D: "Skizofrenia",
        question23:
          "Soalan 23 : Apakah risiko yang boleh mempengaruhi kesihatan mental seseorang?",
        question23A: "Kesihatan badan",
        question23B: "Tekanan di tempat kerja",
        question23C: "Kemiskinan",
        question23D: "Semua di atas",
        question24:
          "Soalan 24 : Manakah antara berikut yang sering menjadi tanda bahawa seseorang mengalami tekanan?",
        question24A: "Merokok",
        question24B: "Mendengar suara",
        question24C: "Penurunan prestasi kerja",
        question24D: "Bersenam",
        question25:
          "Soalan 25 : Apakah amalan yang boleh membantu seseorang individu untuk menangani tekanan dan kegelisahan",
        question25A: "Meditasi, perbincangan dengan rakan dan keluarga",
        question25B: "Bersenam, Merokok",
        question25C: "Minuman keras, Meditasi",
        question25D: "Media sosial, Muzik",
        correctAnswer: "Jawapan adalah betul",
        incorrectAnswer: "Jawapan adalah salah",
        submitAnswer: "Hantar Kesemua Jawapan",
        goToProfile: "Kembali ke Profil",
        revisitAnswer: "Semak semula jawapan",
        passedTestTitle: "Anda telah lulus ujian ini !",
        passedTestBody:
          "Tahniah kerana anda LULUS dalam ujian latihan sukarelawan MyVac. Untuk melengkapkan proses pendaftaran anda, sila kembali ke halaman Profil dan rujuk senarai semak yang lain.",
        failedTestTitle: "Anda gagal dalam ujian ini !",
        failedTestBody:
          "Dukacitanya anda GAGAL dalam ujian latihan sukarelawan MyVac. Sila periksa kesilapan anda dan mengambil semula ujian ini. Jika perlu, sila pulang ke halaman profil anda untuk menonton semula modul latihan yang disediakan.",
      },
      riskStatus: {
        titlerisk: "Status Risiko Covid-19 ",
        titlebutton: " Hantar",
        questionrisk1: "1. Adakah anda mengalami 2 atau lebih gejala berikut?",
        question1a: "Demam",
        question1b: "Kesejukan",
        question1c: "Menggigil",
        question1d: "Sakit badan",
        question1e: "Sakit kepala",
        question1f: "Sakit tekak",
        question1g: "Loya atau muntah",
        question1h: "Cirit birit",
        question1i: "Keletihan",
        question1j: "Selesema atau hidung sumbat",
        question2:
          "2. Selain daripada gejala diatas, adakah anda mengalami gejala seperti berikut?",
        question2a: "Batuk",
        question2b: "Sesak Nafas",
        question2c: "Hilang deria bau",
        question2d: "Hilang deria rasa ",
        question3:
          "3. Adalah anda telah mengunjungi lokasi yang berkaitan dengan kluster COVID-19 dalam tempoh 14 hari yang lepas?",
        question4:
          "4. Adakah anda telah berkunjung ke luar negara dalam tempoh 14 hari yang lepas?",
        question5:
          "5. Adakah anda telah berhubung rapat dengan seseorang yang positif atau disyaki dalam tempoh 14 hari yang lepas?",
        answer1: "Ya",
        answer2: "Tidak",
        error: "Sila pilih satu jawapan",
      },
      attendance: {
        verifyAttendance: "Sahkan Kehadiran Anda",
        email: "E-mel",
        verifyButton: "Sah Kehadiran",
        verifySuccess: "Kehadiran berjaya disahkan",
        verifyError: "Kehadiran tidak dapat disahkan",
        emailError: `Sila masukkan e-mel dengan format yang betul`,
      },
    },
  },
};

export default resources;
