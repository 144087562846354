export const officeList = [
  {
    officeState: "Perlis",
    officeName: "Pejabat Belia Dan Sukan Negeri Perlis",
    officeAddress: "Jalan Hospital, Pusat Bandar Kangar,01000 Kangar, Perlis",
    officerName: "Kamarunzaman Abd Hamid, Nurul Ain Tralah",
    officeDistrict: " ",
    officerContact: "018-9443934,017-2408594",
    officerEmail: "kamarunzamanabdhamid@gmail.com, tralahain@gmail.com",
  },
  {
    officeState: "Kedah",
    officeName: "Jabatan Belia dan Sukan Negeri Kedah",
    officeAddress:
      "Aras 2, Zon A, Wisma Persekutuan, Kompleks Pentadbiran Kerajaan Persekutuan, Bandar Muadzam Shah, Mukim Anak Bukit, 06550 Alor Setar, Kedah",
    officerContact: "04-7324500",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Muda",
    officeAddress: "No 7 & 8, Tingkat 2, Jalan Bank 08000 Sungai Petani, Kedah",
    officerName: "Mohd Abdul Aliff bin Hj Jenal",
    officeDistrict: " PBSD Kuala Muda",
    officerContact: "04-4211051,019-8002923",
    officerEmail: "mohdabdulalaliff@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Langkawi",
    officeAddress:
      "Tingkat 1 Bangunan Kompleks Sukan Perahu Layar Jalan Pokok Asam 07000 Langkawi, Kedah",
    officerName: "Mohd Ferdaus B. Osman",
    officeDistrict: " PBSD Langkawi",
    officerContact: "04-9666323,019-5694685",
    officerEmail: "ferdaus@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Bandar Baharu",
    officeAddress: "Kompleks Sukan Komuniti Bandar Baharu 09800 Serdang, Kedah",
    officerName: "Shamsul Qamar bin Shamsuddin",
    officeDistrict: "PBSD Bandar Baharu",
    officerContact: "04-4076224,012-5760341",
    officerEmail: "shamsul_qamar@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Sik",
    officeAddress:
      "No 4 & 5, Tingkat 2, Bangunan Tabung Haji 08200 Pekan Baru Sik, Kedah",
    officerName: "Siti Salmiah binti Md Ani",
    officeDistrict: "PBSD Sik",
    officerContact: "04-4695284,019-4511403",
    officerEmail: "ctsal@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Yan",
    officeAddress: "3A, Arked Mdy, Jalan Yan 08800 Guar Chempedak, Yan",
    officerName: "Asnatul Adha binti Ahmad",
    officeDistrict: "PBSD Yan",
    officerContact: "04-4685222,012-4917179",
    officerEmail: "asna@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Kulim",
    officeAddress:
      "No 29, Tingkat Bawah Dan 1, Taman Pelangi 09000 Kulim, Kedah",
    officerName: "Mohd Zaidi bin Zakariya",
    officeDistrict: "PBSD Kulim",
    officerContact: "04-4907663,012-4495558",
    officerEmail: "zaidi.zakariya@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Kubang Pasu",
    officeAddress:
      "171-B, Tingkat 1 & 2, Jalan PJ 7, Pekan Jitra 2, 06000 Jitra, Kedah",
    officerName: "Megat Shahrom Basir bin Makhdzir",
    officeDistrict: "PBSD Kubang Pasu",
    officerContact: "04-9176940,013-2599666",
    officerEmail: "megatshahrom@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Baling",
    officeAddress:
      "Kompleks Rakan Muda Baling Batu 42, Pulai, 09100 Baling, Kedah",
    officerName: "Maizatul Akmam bt Adam",
    officeDistrict: "PBSD / KRM Baling",
    officerContact: "04-4701279,019-5635092",
    officerEmail: "maizatul@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Padang Terap",
    officeAddress: "Aeked MDPT 1, Jalan Bendahara, 06300 Kuala Nerang, Kedah",
    officerName: "Mohd Farizan bin Mat Arshad",
    officeDistrict: "PBSD Padang Terap",
    officerContact: "04-7866381,019-4804068",
    officerEmail: "farizan.arshad@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Kota Setar",
    officeAddress: "Kompleks Belia Dan Sukan Negeri Kedah 06550 Alor Setar",
    officerName: "Nazifah binti Muhamad Zubir",
    officeDistrict: "PBSD Kota Setar",
    officerContact: "04-7324500,019-9596571",
    officerEmail: "nazifahzubir@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Pokok Sena",
    officeAddress: "No 49, Fasa 2, Taman Jabi, 06400 Pokok Sena, Kedah",
    officerName: "Muhammad bin Alli",
    officeDistrict: "PBSD Pokok Sena",
    officerContact: "04-7821803,013-2165622",
    officerEmail: "muhammad.alli@kbs.gov.my",
  },
  {
    officeState: "Kedah",
    officeName: "Pejabat Belia Dan Sukan Daerah Pendang",
    officeAddress:
      "N0 10A & 10B, Kawasan Perniagaan Kiara Mas, 06700 Pendang, Kedah",
    officerName: "Ahmad Kamaruizaman bin Jamaludiin",
    officeDistrict: "PBSD Pendang",
    officerContact: "04-7593171,013-6980594",
    officerEmail: "kamalz@kbs.gov.my",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Jabatan Belia dan Sukan Negeri Pulau Pinang",
    officeAddress: "Jalan Sultan Azlan Shah, 11700 Gelugor, Pulau Pinang",
    officerContact: "04-6554444",
    officerEmail: "siti.rohani5002@gmail.com",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Pejabat Belia Dan Sukan Daerah Seberang Perai Utara",
    officeAddress: "Jalan Bertam 2, 13200 Kepala Batas, Pulau Pinang",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-5751066",
    officerEmail: "maat7781@gmail.com",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Pejabat Belia Dan Sukan Daerah Seberang Perai Tengah",
    officeAddress:
      "No. 1A Lorong Perda Selatan 2, Bandar Perda,14000 Bukit Mertajam, Pulau Pinang",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-5303030",
    officerEmail: " azmanhebat@gmail.com",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Pejabat Belia Dan Sukan Daerah Seberang Perai Selatan",
    officeAddress:
      "D/A Kompleks Rakan Muda Seberang Perai Selatan, Jalan Serindit, 14200 Sungai Jawi, Pulau Pinang",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-5824380",
    officerEmail: "halimahtun.aziz@gmail.com",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Pejabat Belia Dan Sukan Daerah Barat Daya",
    officeAddress:
      "D/A Pertubuhan Peladang Kawasan (PPK) Simpang Empat, 11000 Balik Pulau, Pulau Pinang",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-8668244",
    officerEmail: "syazwanuar@yahoo.com",
  },
  {
    officeState: "Pulau Pinang",
    officeName: "Pejabat Belia Dan Sukan Daerah Timur Laut ",
    officeAddress:
      "D/A Kompleks Belia Dan Sukan Negeri Pulau Pinang, Jalan Sultan Azlan Shah 11700 Gelugor, Pulau Pinang",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-6598515",
    officerEmail: "shiranailofar@gmail.com",
  },
  {
    officeState: "Perak",
    officeName: "Jabatan Belia dan Sukan Negeri Perak",
    officeAddress:
      "JKompleks Belia Dan Sukan Negeri Perak , Jalan Ghazali Jawi,31400 Ipoh Perak.",
    officerContact: "05-5489500,05-5489501",
    officerEmail: "jbsnperak@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Kinta",
    officeAddress:
      "Tingkat 2, Kompleks Belia Dan Sukan Negeri Perak, Jalan Ghazali Jawi, 31400 Ipoh, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-2420735,05-5477869",
    officerEmail: "payrol_mu89@yahoo.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Hilir Perak",
    officeAddress:
      "Tingkat 1, Blok A Bangunan Pejabat Persekutuan, Jalan Changkat Jong, 36000 Teluk Intan, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-6221831,05-6234134",
    officerEmail: "saifollah@kbs.gov.my, pbsdh.perak@yahoo.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Larut Matang",
    officeAddress:
      "Pejabat Belia Dan Sukan Daerah Larut Matang, Kompleks Rakan Muda Larut Matang Dan Selama, 34600 Kamunting, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-8836003,05-8837031",
    officerEmail: "kamaruddinhamid04@gmail.com, pbsdlarutmatang@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Manjung",
    officeAddress:
      "Pejabat Belia Dan Sukan Daerah Manjung, Tingkat 3, Bangunan Persekutuan Seri Manjung, 32040 Seri Manjung, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-6881699,05-6885476",
    officerEmail: "pbsdmanjung@kbs.gov.my, pbsdmjg@gmail.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Bagan Datuk",
    officeAddress:
      "Pejabat Belia Dan Sukan Daerah Bagan Datuk Tingkat 1, Blok A Bangunan Persekutuan Teluk Intan",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-6221831",
    officerEmail: "pbsdbagandatuk@gmail.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Batang Padang",
    officeAddress:
      "D/A Kompleks Sukan Tapah Persiaran Wallagonia, 35400 Tapah Road, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-4011421,05-4015439",
    officerEmail: "rizuan2210@gmail.com, pbsdtapah@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Kampar",
    officeAddress:
      "Kompleks Rakan Muda Kampar, Jalan Batu Putih 31900 Kampar, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-4664634,05-4668970",
    officerEmail: "seinmosen@gmail.com, pbsdkampar@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Sungai Siput",
    officeAddress:
      "Kompleks Sukan Komuniti Sg Siput (U) No. 1, Taman Happy, 33100 Sungai Siput (U), Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-5982923,05-5973958",
    officerEmail: "luqsal_nick@yahoo.com, pbsdssu@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Pengkalan Hulu",
    officeAddress:
      "Kompleks Pentadbiran Daerah Kecil Pengkalan Hulu 33100 Hulu Perak, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "04-4778145,05-7679209",
    officerEmail: "zainolwahida4@gmail.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Kerian",
    officeAddress:
      "No. 15, Tingkat Bawah Lintang Bestari Pusat Bandar Fasa 2, 34200 Parit Buntar, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-7161357,05-7167872",
    officerEmail: "pbsdkerian@ymail.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Lenggong",
    officeAddress:
      "Aras 2, Bangunan Pusat Pengurusan TNB Lenggong 33400 Lenggong, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-7677320,05-7679206",
    officerEmail: "pbsdlenggong@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Kangsar",
    officeAddress:
      "No. 40, Tingkat 1, Taman Raja Idris 33000 Kuala Kangsar, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-7761861,05-7768864",
    officerEmail: "wmfaizal79@gmail.com, pbsdkualakangsar@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Hulu Perak",
    officeAddress: "Tingkat 2, Bangunan Persekutuan 33300 Gerik, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-7912331",
    officerEmail: "pbsdhuluperak@yahoo.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Kg.Gajah",
    officeAddress:
      "Tingkat 1, Kompleks Pentadbiran Daerah Kampung Gajah Jalan Tanjung Tualang, 36800 Kampung Gajah, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-6313141,05-6312260",
    officerEmail: "kamariahtufandi@kbs.gov.my, pbsdkggajah@kbs.gov.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Perak Tengah",
    officeAddress:
      "Aras 3 Kompleks Pentadbiran Daerah Perak Tengah 32610 Bandar Baru Seri Iskandar, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-3713828,05-3711007",
    officerEmail: "pbsdpt@kbs.gov.my, pbsdpt@yahoo.com.my",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Selama",
    officeAddress:
      "No. 2A, Tingkat 1, Bangunan MDS Jalan Menteri 34100, Selama, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-8394220,05-8394379",
    officerEmail: "aini5796@gmail.com",
  },
  {
    officeState: "Perak",
    officeName: "Pejabat Belia Dan Sukan Daerah Muallim",
    officeAddress:
      "No. 6, Wisma Majlis Daerah Tanjong Malim (MDTM) Jalan Ahmad Boestaman, 35800 Slim River, Perak",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-4528360,05-4527405",
    officerEmail: "zakiri0707@gmail.com, pbsdsrtm@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Jabatan Belia dan Sukan Negeri Selangor",
    officeAddress:
      "Tingkat 5, Bangunan Plaza Perangsang,Persiaran Perbandaran,40000 Shah Alam Selangor",
    officerContact: "03-5519 2326",
    officerEmail: "jbsselangor@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Sepang",
    officeAddress:
      "No. C-32-1 & C-32-2, Jalan 6, Kosmoplek Bandar Baru Salak Tinggi, 43900 Sepang",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-87061380",
    officerEmail: "jeffmiera67@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Petaling",
    officeAddress:
      "D/A Tingkat 5, Bangunan Plaza Perangsang, Persiaran Perbandaran, 40000 Shah Alam Selangor",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-55192326",
    officerEmail: "husmi1104@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Klang",
    officeAddress:
      "D/A Kompleks Rakan Muda Daerah Klang Lot 43204, Jalan Klang Perdana 42100 Klang",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-32904171",
    officerEmail: "malina@kbs.gov.my",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Gombak",
    officeAddress:
      "16-21, Selayang 18 Persiaran Bandar Baru Selayang 68100 Batu Caves, Selangor",
    officerName: "",
    officeDistrict: "",
    officerContact: "",
    officerEmail: "attobiary09@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Hulu Langat",
    officeAddress:
      "Tingkat 3, Kompleks Pentadbiran, Daerah Hulu Langat, Bangunan Sultan Hishamuddin Alam Shah, Persiaran Pusat Bandar 1, Seksyen 8, 43650 Bandar Baru Bangi",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-89251997",
    officerEmail: "hartynysaha@hotmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah HULU Selangor",
    officeAddress: "Tingkat 1, Lot 747, Jalan Mat Kilau 44000 Kuala Kubu Baru",
    officerName: "",
    officeDistrict: "",
    officerContact: " 03-60641098 ",
    officerEmail: "umiamira@kbs.gov.my",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Selangor",
    officeAddress: "No. G-01, Tingkat Bawah Kompleks PKNS 45000 Kuala Selangor",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-32891580",
    officerEmail: "jbsselangor@gmail.com, azlansap@kbs.gov.my",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia Dan Sukan Daerah Sabak Bernam",
    officeAddress:
      " Tingkat 1, Lot 1 & 2, Bangunan Lembaga Tabung Haji Sabak Bernam, Lot 5713, Jalan Sungai Limau, 45300 Sungai Besar",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-32242504",
    officerEmail: "rozanahadnan@gmail.com",
  },
  {
    officeState: "Selangor",
    officeName: "Pejabat Belia dan Sukan Daerah Kuala Langat",
    officeAddress: "JKR 1668, Persiaran Majlis, Off Jalan Morib",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-31871173",
    officerEmail: "",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Jabatan Belia dan Sukan WP Kuala Lumpur",
    officeAddress:
      "Jalan Bukit Kiara 1 60000, Wilayah Persekutuan Kuala Lumpur",
    officerContact: "03-20815800",
    officerEmail: "rakanmudakualalumpur@gmail.com",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Batu/ WANGSA Maju",
    officeAddress: "Jalan Bukit Kiara 1 60000 Wilayah Persekutuan Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-20815800,03-20815842",
    officerEmail: "safinah@kbs.gov.my, inano3113@gmail.com",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Kepong/ Segambut",
    officeAddress: "Jalan Bukit Kiara 1 60000 Wilayah Persekutuan Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-20950057",
    officerEmail: "pauzi.paiman@kbs.gov.my",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Seputeh",
    officeAddress: "Jalan Bukit Kiara 1 60000 Wilayah Persekutuan Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-20815800,03-20815847",
    officerEmail: "m.anazem@kbs.gov.my",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Lembah Pantai",
    officeAddress: "Jalan Bukit Kiara 1 60000 Wilayah Persekutuan Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-20815800,03-20815851",
    officerEmail: "rery@kbs.gov.my, messyrery@gmail.com",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Cheras/ Bandar Tun Razak",
    officeAddress:
      "D/A Aras 2 Kompleks Belia Dan Sukan Kuala Lumpur 4029 Jalan Kampung PanDan, 55100 Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-92006749",
    officerEmail: "javiquesbaDana@kbs.gov.my",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Titiwangsa/ Setiawangsa",
    officeAddress:
      "D/A Aras 2 Kompleks Belia Dan Sukan Kuala Lumpur 4029 Jalan Kampung Pandan, 55100 Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-92007205",
    officerEmail: "nazeri.gaib@kbs.gov.my, nallynaz@gmail.com",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Bukit Bintang",
    officeAddress:
      "D/A Aras 2 Kompleks Belia Dan Sukan Kuala Lumpur 4029 Jalan Kampung Pandan, 55100 Kuala Lumpur",
    officerName: "",
    officeDistrict: "",
    officerContact: "012-5776337",
    officerEmail: "lalkhasman@kbs.gov.my, lalkhasman79@gmail.com",
  },
  {
    officeState: "Kuala Lumpur & Putrajaya",
    officeName: "Pejabat Belia Dan Sukan Kawasan Putrajaya",
    officeAddress:
      "Aras 4G4 Podium A, Bangunan CAAM No 27, Persiaran Perdana Presint 4, Pusat Pentadbiran Kerajaan Persekutuan 62570 Putrajaya",
    officerName: "",
    officeDistrict: "",
    officerContact: "03-88713703",
    officerEmail: "rahayu@kbs.gov.my, srahayu81@gmail.com",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Jabatan Belia dan Sukan Negeri Sembilan",
    officeAddress:
      "D/A Kompleks Belia Dan Sukan Paroi 70400 Seremban Negeri Sembilan",
    officerContact: "06-7613163",
    officerEmail: "jbsn9@kbs.gov.my",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Seremban",
    officeAddress:
      "D/A Kompleks Belia Dan Sukan Paroi 70400, Seremban, Negeri Sembilan",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-761806",
    officerEmail: "azianahizazi@kbs.gov.my",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Rembau",
    officeAddress:
      "PT 568 , Bangunan Waris Fund, Jalan Seremban - Tampin, 713000, Rembau Negeri Sembilan",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-6851211",
    officerEmail: "izzatemir78@gmail.com",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Tampin",
    officeAddress:
      "Tingkat 1, Bangunan Majlis Belia Derah Tampin, 73000, Tampin, Negeri Sembilan",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-4411378,017-3815169",
    officerEmail: "kjranau81@kbs.gov",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Jempol",
    officeAddress:
      "Kompleks Rakan Muda Jempol Jalan Kasih, 72120 Bandar Sri Jempol,Negeri Sembilan ",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-4581399",
    officerEmail: "mahadirkbs.gov.my",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Jelebu",
    officeAddress: "Tingkat 1, Kompleks Pejabat Kerajaan,71600, Kuala Klawang,",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-6136204",
    officerEmail: "nurulhaizan@kbs.gov.my",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Pilah",
    officeAddress:
      " Kompleks Rakan Muda Kuala Pilah,Jalan Melang, 72000 Kuala Pilah,Negeri Sembilan",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-4811328",
    officerEmail: "rashidi@kbs.gov.my",
  },
  {
    officeState: "Negeri Sembilan",
    officeName: "Pejabat Belia Dan Sukan Daerah Port Dickson",
    officeAddress:
      "No 1 Tingkat Atas, Bangunan Koperasi Kenderaan Port Dickson Berhad, Batu 1, 71000, Port Dickson,Negeri Sembilan",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-6471430",
    officerEmail: "noorulhuda@kbs.gov.my",
  },
  {
    officeState: "Melaka",
    officeName: "Jabatan Belia dan Sukan Negeri Melaka",
    officeAddress:
      "Jalan Tasek, 75450 Hang Tuah Jaya , 75450 Ayer Keroh, Melaka",
    officerContact: "06-2345900,06-2345901,06-2345902,06-2345903",
    officerEmail: "jbsnmlk@kbs.gov.my",
  },
  {
    officeState: "Melaka",
    officeName: "Pejabat Belia Dan Sukan Daerah Alor Gajah",
    officeAddress:
      "D/A Kompleks Sukan Masjid Tanah (KSMT), 78300 Masjid Tanah, Melaka",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-3853800",
    officerEmail: "",
  },
  {
    officeState: "Melaka",
    officeName: "Pejabat Belia Dan Sukan Daerah Jasin",
    officeAddress: "JKR 1773, Jalan Muhibah, 77000 Melaka",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-5291841",
    officerEmail: "",
  },
  {
    officeState: "Melaka",
    officeName: "Pejabat Belia Dan Sukan Daerah Melaka Tengah",
    officeAddress:
      "Aras 2, Jabatan Belia Dan Sukan Negeri Melaka, Jalan Tasek Ayer Keroh, 75450 Hang Tuah Jaya, Melaka",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-2345900",
    officerEmail: "",
  },
  {
    officeState: "Johor",
    officeName: "Jabatan Belia dan Sukan Negeri Johor",
    officeAddress:
      "Tingkat 3, Blok B, Wisma Persekutuan , Jalan Air Molek, 80000 Johor Bahru, Johor",
    officerContact: "07-2232614,07-2224485,07-2234487",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Johor Bahru",
    officeAddress:
      "D/A Kompleks Belia Dan Sukan Daerah Johor Bahru Jalan Sentosa, Larkin, 80350 Johor Bahru, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-2213962",
    officerEmail: "afzareza94@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Muar",
    officeAddress:
      "Tingkat 2, Bangunan Kementerian Sumber Manusia Jalan Othman, 84000 Muar, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-9522287",
    officerEmail: "khasyimi@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Batu Pahat ",
    officeAddress:
      "Aras 3, Bangunan Persekutuan, Jalan Bakau Condong 83000 Batu Pahat, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-4340120",
    officerEmail: "mdisa.halim@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Segamat",
    officeAddress:
      "Bangunan Pejabat-Pejabat Kerajaan Jalan Omar, 85000 Segamat, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-9312857",
    officerEmail: "hyad.eddie279@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Kluang",
    officeAddress:
      "Aras 2, Bangunan Persekutuan Kluang Jalan Batu Pahat, 86000 Kluang, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: " 07-7722399",
    officerEmail: "mysezahr@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Kota Tinggi",
    officeAddress:
      "Tingkat 3, Bangunan Sultan Iskandar 81900 Kota Tinggi, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: " 07-8831266",
    officerEmail: "ruhisyam@yahoo.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Pontian",
    officeAddress: "Lot 2559, No.118, Jalan Alsagoff 82000 Pontian, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-6871535",
    officerEmail: "haszuan@kbs.gov.my",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Mersing",
    officeAddress: "Mini Kompleks, 898, Jalan Abu Bakar 86800 Mersing, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-7994013",
    officerEmail: "muhammadsaiful208@gmail.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Kulai",
    officeAddress: "Aras 3, Bangunan Pejabat Kerajaan 81000 Kulai, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "07-6619754",
    officerEmail: "shafiq.biog@yahoo.com",
  },
  {
    officeState: "Johor",
    officeName: "Pejabat Belia Dan Sukan Daerah Tangkak",
    officeAddress:
      "D/A Tangkak Racing Track (TRT), Jalan Industri B3, Kampung Gerisek, 84500 Gerisek, Tangkak, Johor",
    officerName: "",
    officeDistrict: "",
    officerContact: "06-9727145",
    officerEmail: "dotluvabun@yahoo.co.uk",
  },
  {
    officeState: "Pahang",
    officeName: "Jabatan Belia dan Sukan Negeri Pahang",
    officeAddress:
      "Tingkat 5, Wisma Persekutuan, Jalan Gambut 25570 Kuantan, Pahang Darul Makmur",
    officerContact: "09-5164354",
    officerEmail: "esmeralda@kbs.gov.my, myzumy59@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Pekan",
    officeAddress:
      "Kompleks Sukan Komuniti Daerah Pekan, Taman Tasik Sultan Abu Bakar, 26600 Pekan, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: " 09-4101813",
    officerEmail: "salmiyah579@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Rompin",
    officeAddress:
      "Tingkat 2 Kompleks Pejabat-Pejabat Kerajaan Daerah Rompin, Blok B, 26800 Kuala Rompin, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-4145223",
    officerEmail: "yue_ain2501@yahoo.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Temerloh",
    officeAddress: "Aras 2, Wisma Persekutuan Temerloh, 28000 Temerloh, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-2961826",
    officerEmail: "siti920225@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Cameron Highlands",
    officeAddress: "Jalan Masjid, Tanah Rata, 39000 Cameron Highlands, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "05-4911601",
    officerEmail: "pbsdch2018@gmail.com, kayefemar@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Raub",
    officeAddress:
      "Kompleks Rakan Muda Daerah Raub, Jalan Taman Dong Jaya, 27400 Raub, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-3658571",
    officerEmail: "pbsdraub@gmail.com, beruangdong@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Bera",
    officeAddress: "Kompleks Rakan Muda Daerah Bera, 28200 Bandar Bera, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-2507158",
    officerEmail: "emyrol096@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Maran",
    officeAddress:
      "Kompleks Rakan Muda Maran, Jalan Maran-Temerloh, Bandar Maran, 26500 Maran, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-4771382",
    officerEmail: "pbsdmaranofficial@gmail.com farida@kbs.gov.my",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Lipis",
    officeAddress:
      "No. 23A Tingkat 1, Jalan 2/3, PPBR Bukit Residen, 27200 Kuala Lipis, Pahang ",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-3121322",
    officerEmail: "pbsdlipis@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Bentong",
    officeAddress: "No. 60 Jalan Sri Jaafar, 28700 Bentong, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-2221631",
    officerEmail: "pbsdbentong@gmail.com",
  },
  {
    officeState: "Pahang",
    officeName: "Pejabat Belia Dan Sukan Daerah Jerantut",
    officeAddress:
      "Kompleks Pembangunan Belia Dan Sukan Daerah Jerantut, Jalan Pejabat Kerajaan, 27000 Jerantut, Pahang",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-2662285",
    officerEmail: "pbsdjerantut@gmail.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Jabatan Belia dan Sukan Negeri Terengganu",
    officeAddress: "Jalan Pasir Panjang, 21100 Kuala Terengganu, Terengganu",
    officerContact: "09-6222344,09-6223693,010-2004599",
    officerName: "Nor Hisham bin Mohamed",
    officerEmail: "norhisham1979@gmail.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Besut",
    officeAddress:
      " D/A:Kompleks Rakan Muda Besut Jalan Mini Stadium Kg Raja 22200 Kg Raja, Besut, Terengganu",
    officerName: "Sharifah Nadiah binti Ab.Rashid",
    officeDistrict: "",
    officerContact: "09-6956362,013-9337237",
    officerEmail: "rumaisha_05@yahoo.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Kemaman",
    officeAddress: "Jalan Mak Lagam, 24000 Chukai Kemaman, Terengganu ",
    officerName: "Nurhayati binti Ahmad",
    officeDistrict: "",
    officerContact: "09-868303,014-5427205,010-9096238",
    officerEmail: "pbsdkrmkmn@gmail.com, naroyati96@gmail.com ",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Dungun",
    officeAddress:
      "Tingkat 3, Lot 8891 Bangunan Femous Maju Enterprise Jalan Yahya Ahmad, 23000 Dungun, Terengganu ",
    officerName: "Noraini binti Mustapa",
    officeDistrict: "",
    officerContact: "09-8423121,019-9837124",
    officerEmail: "queenrain76@yahoo.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Hulu Terengganu",
    officeAddress:
      "Lot 9557 Jalan Simpang Sekayu 21700 Kuala Berang, Hulu Terengganu, Terengganu",
    officerName: "Ahmad Nur Al-Hadi bin Zolkefli",
    officeDistrict: "",
    officerContact: "09-6811237,016-9892402",
    officerEmail: "ahmadnuralhadi@yahoo.com, belia.sukan.htrg@gmail.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Marang",
    officeAddress: "No 13 Bangunan Mara, 21600 Marang, Terengganu",
    officerName: "Mohd Asrul Animi bin Haris",
    officeDistrict: "",
    officerContact: "09-6182827,010-3999586",
    officerEmail: "asrulni@yahoo.com.my, pbsdaerahmarang@gmail.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Setiu",
    officeAddress:
      "Bangunan PERMINT Bandar Permaisuri, 22100 Setiu, Terengganu",
    officerName: "Siti Nasuha binti Ibrahim@Yusof",
    officeDistrict: "",
    officerContact: "09-6099334,013-3137687",
    officerEmail: "setiupbsd@gmail.com",
  },
  {
    officeState: "Terengganu",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Terengganu",
    officeAddress:
      "Kuala Terengganu/Kuala NerusJalan Pasir Panjang, 21100 Kuala Terengganu, Terengganu",
    officerName: "Nurul Afifa Sofina Binti Azmi",
    officeDistrict: "",
    officerContact: "09-6222344/45,09-6223693,012-9567172",
    officerEmail: "afifasofina@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Jabatan Belia dan Sukan Negeri Kelantan",
    officeAddress: "Kompleks Belia dan Sukan Panji ,15200 Kota Bharu, Kelantan",
    officerName: "En Amreezal bin Abdul Kadir",
    officerContact: "09-7661200,012-9046819",
    officerEmail: "jbsnkelantan@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Pasir Puteh",
    officeAddress:
      "D/A Pejabat Belia Dan Sukan Daerah Bachok 16090 Jelawat, Bachok Kelantan",
    officerName: "En Syed Abdul Aziz bin Engku Chik",
    officeDistrict: "",
    officerContact: "09-7787811, 010-3186007 ",
    officerEmail: "pbsdpasirputeh@kbs.gov.my, syedabdulaziz@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Kuala Krai",
    officeAddress: "18000 Krai Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-9666269,019-9478111 ",
    officerEmail: "erffan@kbs.gov.my, pbsdkualakrai@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Tumpat",
    officeAddress: "16250 Wakaf Bharu, Tumpat Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-7190291,013-9286160 ",
    officerEmail: "azmie@kbs.gov.my, pbsdtumpat@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Gua Musang",
    officeAddress: "18300 Gua Musang Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-9121015,019-9362322",
    officerEmail: "bharubakar@kbs.gov.my, pbsdguamusang@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Pasir Mas",
    officeAddress: "17000 Pasir Mas Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-7922110,010-4166166",
    officerEmail: "wanman@kbs.gov.my, pbsdpasirmas@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Bachok",
    officeAddress: "16090 Jelawat, Bachok Kelantan",
    officerName: "Wan Roslinda bt Wan Ibrahim",
    officeDistrict: "",
    officerContact: "09-7787811,013-9204917",
    officerEmail: "w.roslinda@kbs.gov.my, pbsdbachok@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Machang",
    officeAddress:
      "Pejabat Belia Dan Sukan Daerah Machang 18500 Machang Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-9750231,016-3695009",
    officerEmail: "azma.yahya@kbs.gov.my, pbsdmachang@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Jeli",
    officeAddress:
      "Pejabat Belia Dan Sukan Daerah Jeli 17600 Bandar Baru Jeli Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-9441449,014-5021169",
    officerEmail: "sitiazura@kbs.gov.my, pbsdjeli@kbs.gov.my",
  },
  {
    officeState: "Kelantan",
    officeName: "Pejabat Belia Dan Sukan Daerah Tanah Merah",
    officeAddress: "17500 Tanah Merah Kelantan",
    officerName: "",
    officeDistrict: "",
    officerContact: "09-9502390,019-6452545",
    officerEmail: "arisawang@kbs.gov.my, pbsdtanahmerah@kbs.gov.my",
  },
  {
    officeState: "Sarawak",
    officeName: "Jabatan Belia dan Sukan Negeri Sarawak",
    officeAddress:
      "Aras 3, Kompleks Belia Dan Sukan Negeri Sarawak, Jalan Tun Ahmad Zaidi Adruce, 93150 Kuching Sarawak.",
    officerContact: "082-240631,082-248321",
    officerEmail: "rakanmuda.jbsswk@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Samarahan",
    officeAddress:
      "Tingkat 3, WIsma Persekutuan, Jln Datuk Mohammad Musa, 94300 Kota Samarahan, Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "082-671186",
    officerEmail: "azma.yahya@kbs.gov.my, pbsdmachang@kbs.gov.my",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Sri Aman",
    officeAddress:
      "Wisma Persekutuan, Tingkat 3, Fasa 2, Jalan Kejatau, Sarawak, 95007 Sri Aman",
    officerName: "",
    officeDistrict: "",
    officerContact: "083-322339",
    officerEmail: "pbssriaman@yahoo.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Betong",
    officeAddress:
      "Aras 2, Wisma Persekutuan, Jalan Tanjong Asam, 95700, Betong, Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "083-472950",
    officerEmail: "arisawang@kbs.gov.my, pbsdtanahmerah@kbs.gov.my",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Sarikei",
    officeAddress:
      "Tingkat 2, Wisma Persekutuan, Batu Satu, Jalan Repok, Sarawak, 96108 Sarikei",
    officerName: "",
    officeDistrict: "",
    officerContact: "084-651116",
    officerEmail: "pbssarikei@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Sibu",
    officeAddress:
      "Tingkat 4, Wisma Persekutuan, Blok 3, Persiaran Brooke, Peti Surat 1607, Sarawak, 96008 Sibu",
    officerName: "",
    officeDistrict: "",
    officerContact: "084-333400",
    officerEmail: "pbssibu@yahoo.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Mukah",
    officeAddress:
      "Sublot 101, Tingkat 2, Mukah New Township, Sarawak, 96400 Mukah",
    officerName: "",
    officeDistrict: "",
    officerContact: "084-872722",
    officerEmail: "pbsmukah@yahoo.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Kapit",
    officeAddress:
      "Wisma Persekutuan, Tingkat 2, Peti Surat 152, Sarawak, 96807 Kapit",
    officerName: "",
    officeDistrict: "",
    officerContact: "084-796262",
    officerEmail: "pbsbkapit@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Bintulu",
    officeAddress:
      "Tingkat 3, Wisma Persekutuan Fasa 2, Jalan Tun Razak, 97000, Bintulu, Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "086-333786",
    officerEmail: "pbsbbtu@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Miri",
    officeAddress:
      "Tingkat 1, Blok 1, Wisma Persekutuan,Jalan Kipas, 98000 Miri, Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "085-417487",
    officerEmail: "kbsteammiri@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Limbang",
    officeAddress:
      "Block A, Tingkat 3, Wisma Persekutuan 2, Peti Surat 256, 98700, Limbang, Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "085-211352",
    officerEmail: "pbsblimbang@gmail.com",
  },
  {
    officeState: "Sarawak",
    officeName: "Pejabat Belia Dan Sukan Bahagian Kuching",
    officeAddress:
      "Aras 3, Kompleks Belia Dan Sukan Negeri Sarawak Jalan Tun Ahmad Zaidi Adruce 93150 Kuching Sarawak",
    officerName: "",
    officeDistrict: "",
    officerContact: "013-8681612",
    officerEmail: "yaman.zy@gmail.com",
  },
  {
    officeState: "Sabah",
    officeName: "Jabatan Belia dan Sukan Negeri Sabah",
    officeAddress:
      "Kompleks Belia Dan Sukan (Persekutuan) Negeri Sabah ,Jalan Taman Pasir Putih, Putatan ,Mel Beg 81, 88998 Kota Kinabalu Sabah",
    officerEmail: "sabahrakanmuda@gmail.com",
    officerContact: "088-765777,088-761777",
  },
  {
    officeState: "Sabah",
    officeName: "Pejabat Belia Dan Sukan Bahagian Kudat",
    officeAddress:
      "Shop Lot 6, Tingkat 1, Kedai Taman Seri Kapor Peti Surat 373, 89058 Kudat Sabah",
    officerName: "",
    officeDistrict: "",
    officerContact: "088-611449",
    officerEmail: "jafrin@kbs.gov.my",
  },
  {
    officeState: "Sabah",
    officeName: "PEGAWAI Belia Dan Sukan Bahagian Pantai Barat",
    officeAddress:
      "Wisma Pandu Puteri, Jalan Mat Salleh, Sembulan Peti Surat 14486, 88851 Kota Kinabalu Sabah",
    officerName: "",
    officeDistrict: "",
    officerContact: "088-230942",
    officerEmail: "stephen@kbs.gov.my",
  },
  {
    officeState: "Sabah",
    officeName: "Pejabat Belia Dan Sukan Bahagian Beaufort",
    officeAddress:
      "Tingkat 1, Wisma Persekutuan, Peti Surat 329 89808 Beaufort Sabah",
    officerName: "",
    officeDistrict: "",
    officerContact: "087-211625",
    officerEmail: "nyukyintseu@kbs.gov.my",
  },
  {
    officeState: "Sabah",
    officeName: "Pejabat Belia Dan Sukan Bahagian Keningau",
    officeAddress: "Tingkat 1 Rumah Persekutuan, Peti Surat 1837",
    officerName: "",
    officeDistrict: "",
    officerContact: "087-339718",
    officerEmail: "jovanna@kbs.gov.my",
  },
  {
    officeState: "Sabah",
    officeName: "Pejabat Belia Dan Sukan Bahagian Sandakan",
    officeAddress:
      "Tingkat 7 Wisma Persekutuan, W.D.T 24, 90500 Sandakan, Sabah",
    officerName: "",
    officeDistrict: "",
    officerContact: "089-666119",
    officerEmail: "syahrin@kbs.gov.my",
  },
  {
    officeState: "Sabah",
    officeName: "Pejabat Belia Dan Sukan Bahagian Tawau",
    officeAddress:
      "Tingkat 4 Bangunan Guna Sama, Wisma Persekutuan Tawau 91000 Bandar Sabindo, Tawau Sabah",
    officerName: "",
    officeDistrict: "",
    officerContact: "089-776680",
    officerEmail: "syivero@kbs.gov.my",
  },
  {
    officeState: "Labuan",
    officeName: "Jabatan Belia dan Sukan WP Labuan",
    officeAddress:
      "Kompleks Sukan Wilayah Persekutuan Labuan ,Jalan Mohd Salleh, Peti Surat 80906 ,87019 Wilayah Persekutuan Labuan",
    officerName: "Abang Syamsulzainuri Bin Abang Sapini",
    officerEmail: "abang@kbs.gov.my",
    officerContact: "087-415344,013-856 0505",
  },
];

export const getOfficeList = (state: string) => {
  const stateOffices: any = [];
  officeList.map((eachOffice) => {
    if (eachOffice.officeState === state) {
      stateOffices.push(eachOffice);
    }
    return null;
  });

  return stateOffices;
};
