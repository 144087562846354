import { notification } from "antd";
import i18n from "i18next";
import { DeviceType } from "../reducers/utilsReducer";

export const updateDeviceType = (deviceType: DeviceType) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_TYPE",
        payload: {
          deviceType: deviceType,
        },
      });
    } catch (err) {}
  };
};

export const updateDeviceSize = (deviceSize: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_SIZE",
        payload: {
          deviceSize: deviceSize,
        },
      });
    } catch (err) {}
  };
};

export const updateLanguage = (language: string) => {
  return async (dispatch: any) => {
    try {
      i18n.changeLanguage(language);
      dispatch({
        type: "UPDATE_LANGUAGE",
        payload: {
          language: language,
        },
      });
    } catch (err) {}
  };
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const handleNotification = (
  type: string,
  message: string,
  description: string
) => {
  if (type === "success") {
    notification["success"]({ message: message, description: "" });
  } else if (type === "warning") {
    notification["warning"]({
      message: message,
      description: description,
    });
  }
};

export const getMonday = (d: Date) => {
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  const thisMonday = new Date(d.setDate(diff));
  thisMonday.setHours(0, 0, 0, 0);
  return thisMonday;
};
