import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface GeneralAnswerSpecial {
  title: string;
  points: string[];
}

interface FAQ {
  question: string;
  answer?: string;
  points?: string[];
  footer?: string;
  genAnswer?: string;
  genPoints?: string[];
  genPointsSpecial?: GeneralAnswerSpecial[];
  medAnswer?: string;
  medPoints?: string[];
}

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  updateLanguage: (language: string) => Promise<void>;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

class Faq extends Component<Props> {
  renderQuestions = () => {
    const faqs: FAQ[] = [
      {
        question: i18n.t("questions.q1"),
        answer: i18n.t("answer.a1"),
      },
      {
        question: i18n.t("questions.q2"),
        answer: i18n.t("answer.a2"),
      },
      {
        question: i18n.t("questions.q3"),
        answer: i18n.t("answer.a3"),
      },
      {
        question: i18n.t("questions.q4"),
        answer: i18n.t("answer.a4"),
        points: [i18n.t("answer.a4a"), i18n.t("answer.a4b")],
        footer: i18n.t("answer.a4c"),
      },
      {
        question: i18n.t("questions.q5"),
        answer: i18n.t("answer.a5"),
      },
      {
        question: i18n.t("questions.q6"),
        answer: i18n.t("answer.a6"),
        points: [
          i18n.t("answer.a6a"),
          i18n.t("answer.a6b"),
          i18n.t("answer.a6c"),
          i18n.t("answer.a6d"),
          i18n.t("answer.a6e"),
          i18n.t("answer.a6f"),
          i18n.t("answer.a6g"),
          i18n.t("answer.a6h"),
          i18n.t("answer.a6i"),
        ],
      },
      {
        question: i18n.t("questions.q7"),
        answer: i18n.t("answer.a7"),
      },
      {
        question: i18n.t("questions.q8"),
        answer: i18n.t("answer.a8"),
      },
      {
        question: i18n.t("questions.q9"),
        answer: i18n.t("answer.a9"),
        points: [i18n.t("answer.a9a"), i18n.t("answer.a9b")],
      },
      {
        question: i18n.t("questions.q10"),
        answer: i18n.t("answer.a10"),
        genPointsSpecial: [
          {
            title: i18n.t("answer.aGen10i"),
            points: [i18n.t("answer.aGen10ia")],
          },
          {
            title: i18n.t("answer.aGen10ii"),
            points: [
              i18n.t("answer.aGen10iia"),
              i18n.t("answer.aGen10iib"),
              i18n.t("answer.aGen10iic"),
              i18n.t("answer.aGen10iid"),
            ],
          },
        ],
        medPoints: [i18n.t("answer.aMed10a"), i18n.t("answer.aMed10b")],
        medAnswer: i18n.t("answer.a10c"),
        footer: i18n.t("answer.a10d"),
      },
      {
        question: i18n.t("questions.q11"),
        answer: i18n.t("answer.a11"),
        genAnswer: i18n.t("answer.aGen11"),
        genPoints: [
          i18n.t("answer.aGen11a"),
          i18n.t("answer.aGen11b"),
          i18n.t("answer.aGen11c"),
        ],
        medAnswer: i18n.t("answer.aMed11"),
      },
      {
        question: i18n.t("questions.q12"),
        answer: i18n.t("answer.a12"),
      },
      {
        question: i18n.t("questions.q13"),
        answer: i18n.t("answer.a13"),
      },
      {
        question: i18n.t("questions.q14"),
        answer: i18n.t("answer.a14"),
      },
      {
        question: i18n.t("questions.q15"),
        answer: i18n.t("answer.a15"),
      },
      {
        question: i18n.t("questions.q16"),
        points: [
          i18n.t("answer.a16a"),
          i18n.t("answer.a16b"),
          i18n.t("answer.a16c"),
        ],
      },
    ];

    return (
      <div className="w-full">
        <div className="max-w-7xl pb-12 pt-8 mx-auto px-4 sm:pb-16 sm:pt-4 sm:px-6 lg:px-8">
          <div className="mx-auto divide-y-2 divide-gray-200">
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-warm-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        {faq.answer && (
                          <p className="text-base text-warm-gray-500">
                            {faq.answer}
                          </p>
                        )}
                        {(faq.genAnswer || faq.genPointsSpecial) && (
                          <>
                            <p className="text-base text-warm-gray-500 font-bold mt-4">
                              {i18n.t("answer.aGen")}
                            </p>
                            <p className="text-base text-warm-gray-500 mt-2">
                              {faq.genAnswer}
                            </p>
                            {faq.genPoints && faq.genPoints.length > 0 && (
                              <ul className="list-disc text-base text-warm-gray-500 ml-4 mt-2">
                                {faq.genPoints.map((eachPoint, index) => (
                                  <li key={index}>{eachPoint}</li>
                                ))}
                              </ul>
                            )}
                            {faq.genPointsSpecial &&
                              faq.genPointsSpecial.length > 0 && (
                                <ul className="list-disc text-base text-warm-gray-500 ml-4 mt-2">
                                  {faq.genPointsSpecial.map(
                                    (eachPoint, index) => (
                                      <li key={index}>
                                        {eachPoint.title}
                                        {eachPoint.points.map(
                                          (eachPoint, smallIndex: number) => (
                                            <p key={`${index}:${smallIndex}`}>
                                              {eachPoint}
                                            </p>
                                          )
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                          </>
                        )}
                        {faq.medAnswer && (
                          <>
                            <p className="text-base text-warm-gray-500 font-bold mt-4">
                              {i18n.t("answer.aMed")}
                            </p>
                            {faq.medPoints && faq.medPoints.length > 0 && (
                              <ul className="list-disc text-base text-warm-gray-500 ml-4 mt-2">
                                {faq.medPoints.map((eachPoint, index) => (
                                  <li key={index}>{eachPoint}</li>
                                ))}
                              </ul>
                            )}
                            <p className="text-base text-warm-gray-500 mt-2">
                              {faq.medAnswer}
                            </p>
                          </>
                        )}
                        {faq.points && faq.points.length > 0 && (
                          <ul className="list-disc text-base text-warm-gray-500 ml-4 mt-6">
                            {faq.points.map((eachPoint, index) => (
                              <li key={index}>{eachPoint}</li>
                            ))}
                          </ul>
                        )}
                        {faq.footer && (
                          <p className="text-base text-warm-gray-500 mt-6">
                            {faq.footer}
                          </p>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <main className="overflow-hidden w-full">
          <div className="bg-warm-gray-50">
            <Header history={this.props.history} />
            <div className="py-12 lg:py-16">
              <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-extrabold tracking-tight text-warm-gray-900 sm:text-4xl">
                  {i18n.t("questions.topic")}
                </h1>
                <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                  {i18n.t("questions.description")}
                  <Link
                    to="/contactUs"
                    className="text-custom-red-600 hover:text-custom-red-400"
                  >
                    {i18n.t("questions.page")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </main>
        {this.renderQuestions()}
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Faq);
