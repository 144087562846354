import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Attendance from "../screens/Attendance";
import Auth from "../screens/Auth";
import BookingDetails from "../screens/BookingDetails";
import Bookings from "../screens/Bookings";
import Centers from "../screens/Centers";
import ContactUs from "../screens/ContactUs";
import EachCenter from "../screens/EachCenter";
import Faq from "../screens/FAQ";
import Home from "../screens/Home";
import NotFound from "../screens/NotFound";
import OfficeList from "../screens/OfficeList";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import Profile from "../screens/Profile";
import Quiz from "../screens/Quiz";
import Resources from "../screens/Resources";
import RiskStatus from "../screens/RiskStatus";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp";
import TermsCondition from "../screens/TermsCondition";
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions";
import { clearCentersSocket } from "../store/actions/centerActions";
import { clearNgoSocket } from "../store/actions/ngoActions";
import { clearResourcesSocket } from "../store/actions/resActions";

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

class Navigator extends Component<Props> {
  componentDidMount() {
    this.props.getAuthListener();
    if (
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_FIREBASE_ENV === "production" &&
      process.env.REACT_APP_GOOGLE_ANALYTICS
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
    clearCentersSocket();
    clearResourcesSocket();
    clearNgoSocket();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/termscondition" component={TermsCondition} />
          <Route exact path="/faqs" component={Faq} />
          <Route exact path="/officeList" component={OfficeList} />
          <Route exact path="/contactUs" component={ContactUs} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/centers" component={Centers} />
          <Route exact path="/center/:id" component={EachCenter} />
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signUp" component={SignUp} />
          <Route exact path="/bookings" component={Bookings} />
          <Route exact path="/bookingDetails/:id" component={BookingDetails} />
          <Route exact path="/quiz" component={Quiz} />
          <Route exact path="/riskStatus/:id" component={RiskStatus} />
          <Route exact path="/attendance/:id" component={Attendance} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
