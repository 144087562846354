import { Checkbox, Radio } from "antd";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import packageJson from "../../package.json";
import Button from "../components/base/Button";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import Select, { Option } from "../components/base/Select";
import WindowResizer from "../components/WindowResizer";
import {
  Address,
  GenderType,
  ProfessionHealthType,
  ProfessionType,
  RaceType,
} from "../models/User";
import {
  createAccount,
  handleAuthCondition,
} from "../store/actions/authActions";
import { getNgo } from "../store/actions/ngoActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { ngoStateInterface } from "../store/reducers/ngoReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";
import { getDistrictList, getStateList } from "../utils/districtList";

const genderTypeOption: Option[] = Object.keys(GenderType).map((eachGender) => {
  return {
    key: eachGender,
    title: GenderType[eachGender as keyof typeof GenderType],
  };
});

const raceTypeOption: Option[] = Object.keys(RaceType).map((eachRace) => {
  return {
    key: eachRace,
    title: RaceType[eachRace as keyof typeof RaceType],
  };
});

const medicalTypeOption: Option[] = Object.keys(ProfessionHealthType).map(
  (eachHealth) => {
    return {
      key: eachHealth,
      title:
        ProfessionHealthType[eachHealth as keyof typeof ProfessionHealthType],
    };
  }
);

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  ngoStore: ngoStateInterface;
  getNgo: () => void;
}

export interface UserAttributeState {
  ic: string;
  name: string;
  age: number;
  gender: keyof typeof GenderType;
  race: keyof typeof RaceType;
  raceOthers: string;
  address: Address;
  email: string;
  mobileNo: string;
  profession: keyof typeof ProfessionType;
  professionHealth: keyof typeof ProfessionHealthType;
  professionOthers: string;
  professionHealthId: string;
  organisationName: string;
  organisationNameOthers: string;
  organisationAddress: string;
  password: string;
  employeePermission: boolean;
  kkmEmployee: boolean;
  pregnancy: boolean;
  diseases: string[];
  covidRedZoneEvent: boolean;
  covidOutOfCountry: boolean;
  covidCloseContact: boolean;
  symptoms: string[];
}

export interface UserAttributeStateError {
  icError: string;
  nameError: string;
  ageError: string;
  raceOthersError: string;
  addressError: {
    streetError: string;
    poscodeError: string;
  };
  emailError: string;
  mobileNoError: string;
  professionOthersError: string;
  professionHealthIdError: string;
  organisationNameError: string;
  organisationNameOthersError: string;
  organisationAddressError: string;
  passwordError: string;
  employeePermissionError: string;
  kkmEmployeeError: string;
  pregnancyError: string;
  diseasesError: string;
  covidRedZoneEventError: string;
  covidOutOfCountryError: string;
  covidCloseContactError: string;
  symptomsError: string;
}

interface State {
  stepIndex: number;
  loading: boolean;
  user: UserAttributeState;
  userError: UserAttributeStateError;
}

const userAttributeError: UserAttributeStateError = {
  icError: "",
  nameError: "",
  ageError: "",
  raceOthersError: "",
  addressError: {
    streetError: "",
    poscodeError: "",
  },
  emailError: "",
  mobileNoError: "",
  professionOthersError: "",
  professionHealthIdError: "",
  organisationNameError: "",
  organisationNameOthersError: "",
  organisationAddressError: "",
  passwordError: "",
  employeePermissionError: "",
  kkmEmployeeError: "",
  pregnancyError: "",
  diseasesError: "",
  covidRedZoneEventError: "",
  covidOutOfCountryError: "",
  covidCloseContactError: "",
  symptomsError: "",
};

const userAttribute: UserAttributeState = {
  ic: "",
  name: "",
  age: 0,
  gender: Object.keys(GenderType)[0] as keyof typeof GenderType,
  race: Object.keys(RaceType)[0] as keyof typeof RaceType,
  raceOthers: "",
  address: {
    street: "",
    district: getDistrictList("")[0],
    poscode: "",
    state: getStateList()[0],
  },
  email: "",
  mobileNo: "",
  profession: Object.keys(ProfessionType)[0] as keyof typeof ProfessionType,
  professionHealth: Object.keys(
    ProfessionHealthType
  )[0] as keyof typeof ProfessionHealthType,
  professionOthers: "",
  professionHealthId: "",
  organisationName: "",
  organisationNameOthers: "",
  organisationAddress: "",
  password: "",
  employeePermission: false,
  kkmEmployee: false,
  pregnancy: false,
  diseases: [],
  covidRedZoneEvent: false,
  covidOutOfCountry: false,
  covidCloseContact: false,
  symptoms: [],
};

class SignUp extends Component<Props> {
  state: State = {
    stepIndex: 0,
    loading: false,
    user: JSON.parse(JSON.stringify(userAttribute)),
    userError: JSON.parse(JSON.stringify(userAttributeError)),
  };

  componentDidMount() {
    window.location.replace("https://mysukarela.com");
    // this.props.getNgo();
  }

  handleSelectChange = (id: string, key: string | boolean) => {
    const clonedUserAttribute = JSON.parse(JSON.stringify(this.state.user));
    clonedUserAttribute[id] = key;
    this.setState({
      user: clonedUserAttribute,
    });
  };

  handleSelectChangeAddress = (id: string, key: string) => {
    const clonedUserAttribute = JSON.parse(JSON.stringify(this.state.user));
    clonedUserAttribute["address"][id] = key;
    this.setState({
      user: clonedUserAttribute,
    });
  };

  handleChangeCheckbox = (key: string, checkedValues: any) => {
    const clonedUser = JSON.parse(JSON.stringify(this.state.user));
    clonedUser[key] = checkedValues;

    this.setState({
      user: clonedUser,
    });
  };

  handleChange = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.user));
    clonedUserState[e.target.id] = e.target.value;
    this.setState({
      user: clonedUserState,
    });
  };

  handleChangeAddress = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.user));
    clonedUserState["address"][e.target.id] = e.target.value;
    this.setState({
      user: clonedUserState,
    });
  };

  handleCheckCondition = (typeList: string[]) => {
    const clonedUserAttributeError = JSON.parse(
      JSON.stringify(this.state.userError)
    );

    handleAuthCondition(this.state.user, clonedUserAttributeError, typeList);

    this.setState({
      userError: clonedUserAttributeError,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        loading: true,
        userError: JSON.parse(JSON.stringify(userAttributeError)),
      },
      async () => {
        if (this.state.stepIndex === 0) {
          this.handleCheckCondition([
            "name",
            "ic",
            "password",
            "age",
            "street",
            "poscode",
            "email",
            "mobileNo",
          ]);
        } else if (this.state.stepIndex === 1) {
          const conditionList = [
            "organisationName",
            "organisationAddress",
            "employeePermission",
            "kkmEmployee",
          ];

          if (this.state.user.organisationName === "OTHERS") {
            conditionList.push("organisationNameOthers");
          }
          if (this.state.user.profession === "OTHERS") {
            conditionList.push("professionOthers");
          } else if (this.state.user.profession === "HEALTH") {
            conditionList.push("professionHealthId");
          }
          this.handleCheckCondition(conditionList);
        } else if (this.state.stepIndex === 2) {
          this.handleCheckCondition(["pregnancy", "diseases"]);
        } else if (this.state.stepIndex === 3) {
          this.handleCheckCondition([
            "covidRedZoneEvent",
            "covidOutOfCountry",
            "covidCloseContact",
            "symptoms",
          ]);
        }

        await sleep(250);

        let conditionPassed: boolean = false;

        if (this.state.stepIndex === 0) {
          conditionPassed =
            !this.state.userError.nameError &&
            !this.state.userError.icError &&
            !this.state.userError.passwordError &&
            !this.state.userError.ageError &&
            !this.state.userError.addressError.streetError &&
            !this.state.userError.addressError.poscodeError &&
            !this.state.userError.emailError &&
            !this.state.userError.mobileNoError
              ? true
              : false;
        } else if (this.state.stepIndex === 1) {
          conditionPassed =
            !this.state.userError.organisationNameError &&
            !this.state.userError.organisationAddressError &&
            !this.state.userError.employeePermissionError &&
            !this.state.userError.kkmEmployeeError
              ? true
              : false;

          if (this.state.user.organisationName === "OTHERS") {
            conditionPassed =
              conditionPassed &&
              !this.state.userError.organisationNameOthersError
                ? true
                : false;
          }
          if (this.state.user.profession === "OTHERS") {
            conditionPassed =
              conditionPassed && !this.state.userError.professionOthersError
                ? true
                : false;
          } else if (this.state.user.profession === "HEALTH") {
            conditionPassed =
              conditionPassed && !this.state.userError.professionHealthIdError
                ? true
                : false;
          }
        } else if (this.state.stepIndex === 2) {
          conditionPassed =
            !this.state.userError.pregnancyError &&
            !this.state.userError.diseasesError
              ? true
              : false;
        } else if (this.state.stepIndex === 3) {
          conditionPassed =
            !this.state.userError.covidRedZoneEventError &&
            !this.state.userError.covidOutOfCountryError &&
            !this.state.userError.covidCloseContactError &&
            !this.state.userError.symptomsError
              ? true
              : false;
        }

        try {
          if (conditionPassed) {
            if (this.state.stepIndex <= 2) {
              this.setState({ stepIndex: this.state.stepIndex + 1 });
              window.scrollTo(0, 0);
            } else if (this.state.stepIndex === 3) {
              let organisationName = "";
              if (this.state.user.organisationName === "OTHERS") {
                organisationName = this.state.user.organisationNameOthers;
              } else {
                organisationName = this.state.user.organisationName;
              }

              const userCreation = await createAccount({
                name: this.state.user.name,
                ic: this.state.user.ic,
                email: this.state.user.email.replace(/\s/g, ""),
                password: this.state.user.password.replace(/\s/g, ""),
                age: this.state.user.age,
                gender: this.state.user.gender,
                race: this.state.user.race,
                raceOthers: this.state.user.raceOthers,
                street: this.state.user.address.street,
                poscode: this.state.user.address.poscode,
                district: this.state.user.address.district,
                state: this.state.user.address.state,
                mobileNo: this.state.user.mobileNo,
                profession: this.state.user.profession,
                professionOthers: this.state.user.professionOthers,
                professionHealth: this.state.user.professionHealth,
                professionHealthId: this.state.user.professionHealthId,
                organisationName: organisationName,
                organisationAddress: this.state.user.organisationAddress,
              });
              if (userCreation) {
                const clonedUserError = JSON.parse(
                  JSON.stringify(this.state.userError)
                );
                clonedUserError["emailError"] = userCreation;
                this.setState({
                  userError: clonedUserError,
                  stepIndex: 0,
                });
              }
            }
          }
        } catch (err) {}
        this.setState({ loading: false });
      }
    );
  };

  renderNgoList = () => {
    const optionList: Option[] = [];
    if (this.props.ngoStore.ngo.length > 0) {
      this.props.ngoStore.ngo.map((eachNgo) => {
        optionList.push({
          key: eachNgo.shorthand,
          title: eachNgo.name,
        });
        return null;
      });
    }

    optionList.sort((a, b) => (a.title > b.title ? 1 : -1));
    optionList.push({
      key: "OTHERS",
      title: "Others",
    });

    return optionList;
  };

  renderAddressOptions = (type: string) => {
    const optionList: Option[] = [];
    if (type === "district") {
      getDistrictList(this.state.user.address.state).map(
        (eachDistrict: string) => {
          optionList.push({
            key: eachDistrict,
            title: eachDistrict,
          });
          return null;
        }
      );
    } else {
      getStateList().map((eachState: string) => {
        optionList.push({
          key: eachState,
          title: eachState,
        });
        return null;
      });
    }

    return optionList;
  };

  renderIndividualStep = (name: string, description: string, key: number) => {
    let completed: boolean = false;
    if (this.state.stepIndex >= key) {
      completed = true;
    }

    return (
      <li key={key} className="md:flex-1">
        <div
          className={`group pl-4 py-2 flex flex-col border-l-4 ${
            completed ? "border-custom-red-600" : "border-gray-200"
          } md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`}
        >
          <span
            className={`text-xs ${
              completed ? "text-custom-red-600" : "text-gray-500"
            }  font-semibold tracking-wide uppercase`}
          >
            {name}
          </span>
          <span className="text-sm font-medium">{description}</span>
        </div>
      </li>
    );
  };

  renderSteps = () => {
    const steps = [
      { id: i18n.t("header.step") + " 1", name: i18n.t("auth.stepper1") },
      { id: i18n.t("header.step") + " 2", name: i18n.t("auth.stepper2") },
      { id: i18n.t("header.step") + " 3", name: i18n.t("auth.stepper3") },
      { id: i18n.t("header.step") + " 4", name: i18n.t("auth.stepper4") },
    ];

    return (
      <div className="w-full">
        <nav aria-label="Progress" className="max-w-5xl mx-auto">
          <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
            {steps.map((step, index: number) =>
              this.renderIndividualStep(step.name, step.id, index)
            )}
          </ol>
        </nav>
      </div>
    );
  };

  renderTrueFalseRadio = (radioState: boolean, stateTitle: string) => {
    return (
      <div className="my-2 flex flex-col space-y-2">
        {[true, false].map((bool, index) => (
          <Radio
            key={index}
            className="h-5 w-4"
            checked={radioState === bool}
            onClick={this.handleSelectChange.bind(this, stateTitle, bool)}
          >
            <span className="text-sm">
              {bool ? i18n.t("auth.yes") : i18n.t("auth.no")}
            </span>
          </Radio>
        ))}
      </div>
    );
  };

  renderLabel = (label: string) => {
    return (
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    );
  };

  renderDiseaseList = () => {
    return [
      { label: i18n.t("disease.hypertension"), value: "Hypertension" },
      { label: i18n.t("disease.diabetes"), value: "Diabetes Mellitus" },
      {
        label: i18n.t("disease.asthma"),
        value: "Asma",
      },
      { label: i18n.t("disease.cancer"), value: "Cancer" },
      { label: i18n.t("disease.autoimmune"), value: "Autoimmune" },
      { label: i18n.t("disease.cardio"), value: "Cardiovascular" },
      { label: i18n.t("disease.hepatitis"), value: "Hepatitis" },
      { label: i18n.t("disease.hiv"), value: "HIV/AIDS" },
      { label: i18n.t("disease.obesity"), value: "Obesity" },
      { label: i18n.t("disease.tb"), value: "Tuberculosis active" },
      {
        label: i18n.t("disease.kidney"),
        value: "Kidney disease",
      },
      {
        label: i18n.t("disease.epilepsy"),
        value: "Epilepsy",
      },
      {
        label: i18n.t("disease.mental"),
        value: "Mental Illness",
      },
    ];
  };

  renderSymptoms = () => {
    return [
      { label: i18n.t("illness.fever"), value: "Fever" },
      { label: i18n.t("illness.cough"), value: "Cough" },
      { label: i18n.t("illness.nose"), value: "Running nose" },
      { label: i18n.t("illness.chills"), value: "Chills" },
      { label: i18n.t("illness.myalgia"), value: "Myalgia" },
      { label: i18n.t("illness.headache"), value: "Headache" },
      { label: i18n.t("illness.sorethroat"), value: "Sore throat" },
      { label: i18n.t("illness.nausea"), value: "Nausea and vomiting" },
      { label: i18n.t("illness.diarrhea"), value: "Diarrhea" },
      { label: i18n.t("illness.fatigue"), value: "Fatigue" },
      { label: i18n.t("illness.breathing"), value: "Difficulty breathing" },
      { label: i18n.t("illness.smell"), value: "Lost smell" },
      { label: i18n.t("illness.taste"), value: "Lost taste" },
    ];
  };

  renderContent = () => {
    if (this.state.stepIndex === 0) {
      return (
        <div className="space-y-4">
          <div>
            {this.renderLabel(i18n.t("auth.name"))}
            <Input
              id="name"
              className="mt-1"
              placeholder={i18n.t("auth.fullname")}
              value={this.state.user.name}
              onChange={this.handleChange}
              error={this.state.userError.nameError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.icno"))}
            <Input
              id="ic"
              className="mt-1"
              placeholder="XXXXXX-XX-XXXX"
              value={this.state.user.ic}
              onChange={this.handleChange}
              error={this.state.userError.icError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.mobileno"))}
            <Input
              id="mobileNo"
              className="mt-1"
              placeholder="+60123456789"
              value={this.state.user.mobileNo}
              onChange={this.handleChange}
              error={this.state.userError.mobileNoError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.email"))}
            <Input
              id="email"
              className="mt-1"
              placeholder={i18n.t("auth.email")}
              value={this.state.user.email}
              onChange={this.handleChange}
              error={this.state.userError.emailError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.password"))}
            <Input
              id="password"
              type="password"
              className="mt-1"
              placeholder={i18n.t("auth.password")}
              value={this.state.user.password}
              onChange={this.handleChange}
              error={this.state.userError.passwordError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.age"))}
            <Input
              id="age"
              type="number"
              className="mt-1"
              placeholder={i18n.t("auth.age")}
              value={this.state.user.age}
              onChange={this.handleChange}
              error={this.state.userError.ageError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.gender"))}
            <Select
              id="gender"
              placeholder=""
              value={this.state.user.gender}
              options={genderTypeOption}
              onChange={this.handleSelectChange}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.race"))}
            <Select
              id="race"
              placeholder=""
              value={this.state.user.race}
              options={raceTypeOption}
              onChange={this.handleSelectChange}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.address"))}
            <Input
              id="street"
              className="mt-1"
              placeholder={i18n.t("auth.fulladdress")}
              value={this.state.user.address.street}
              onChange={this.handleChangeAddress}
              error={this.state.userError.addressError.streetError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.poscode"))}
            <Input
              id="poscode"
              className="mt-1"
              placeholder={i18n.t("auth.poscode")}
              value={this.state.user.address.poscode}
              onChange={this.handleChangeAddress}
              error={this.state.userError.addressError.poscodeError}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.state"))}
            <Select
              id="state"
              placeholder=""
              value={this.state.user.address.state}
              options={this.renderAddressOptions("state")}
              onChange={this.handleSelectChangeAddress}
            />
          </div>
          <div>
            {this.renderLabel(i18n.t("auth.district"))}
            <Select
              id="district"
              placeholder={i18n.t("auth.district")}
              value={this.state.user.address.district}
              options={this.renderAddressOptions("district")}
              onChange={this.handleSelectChangeAddress}
            />
          </div>
        </div>
      );
    } else if (this.state.stepIndex === 1) {
      return (
        <div className="space-y-4">
          <div className="pb-2">
            {this.renderLabel(i18n.t("auth.profession"))}
            <div className="mt-2 flex flex-col space-y-2">
              {Object.keys(ProfessionType).map((key) => (
                <Radio
                  key={key}
                  className="h-5 w-4"
                  checked={this.state.user.profession === key}
                  onClick={this.handleSelectChange.bind(
                    this,
                    "profession",
                    key
                  )}
                >
                  <span className="text-sm">
                    {ProfessionType[key as keyof typeof ProfessionType]}
                  </span>
                </Radio>
              ))}
            </div>
          </div>
          {this.state.user.profession === "OTHERS" && (
            <div>
              {this.renderLabel(i18n.t("auth.professionothers"))}
              <Input
                id="professionOthers"
                className="mt-1"
                placeholder={i18n.t("auth.professioname")}
                value={this.state.user.professionOthers}
                onChange={this.handleChange}
                error={this.state.userError.professionOthersError}
              />
            </div>
          )}
          {this.state.user.profession === "HEALTH" && (
            <div className="space-y-4">
              <div>
                {this.renderLabel(i18n.t("auth.healthprofession"))}
                <Select
                  id="professionHealth"
                  placeholder=""
                  value={this.state.user.professionHealth}
                  options={medicalTypeOption}
                  onChange={this.handleSelectChange}
                />
              </div>
              <div>
                {this.renderLabel(i18n.t("auth.professionhealthid"))}
                <Input
                  id="professionHealthId"
                  className="mt-1"
                  placeholder={i18n.t("auth.professionhealthid")}
                  value={this.state.user.professionHealthId}
                  onChange={this.handleChange}
                  error={this.state.userError.professionHealthIdError}
                />
              </div>
            </div>
          )}
          {this.state.user.profession !== "NGO" ? (
            <div>
              {this.renderLabel(
                this.state.user.profession === "STUDENT"
                  ? i18n.t("auth.universityTitle")
                  : i18n.t("auth.organisationname")
              )}
              <Input
                id="organisationName"
                className="mt-1"
                placeholder={
                  this.state.user.profession === "STUDENT"
                    ? i18n.t("auth.universityTitle")
                    : i18n.t("auth.organisationname")
                }
                value={this.state.user.organisationName}
                onChange={this.handleChange}
                error={this.state.userError.organisationNameError}
              />
            </div>
          ) : (
            <div>
              {this.renderLabel(i18n.t("auth.ngoTitle"))}
              <Select
                id="organisationName"
                placeholder={i18n.t("auth.ngoTitle")}
                value={this.state.user.organisationName}
                options={this.renderNgoList()}
                onChange={this.handleSelectChange}
                error={this.state.userError.organisationNameError}
              />
            </div>
          )}
          {this.state.user.organisationName === "OTHERS" && (
            <div>
              {this.renderLabel(i18n.t("auth.organisationnameothers"))}
              <Input
                id="organisationNameOthers"
                className="mt-1"
                placeholder={i18n.t("auth.ngoTitle")}
                value={this.state.user.organisationNameOthers}
                onChange={this.handleChange}
                error={this.state.userError.organisationNameOthersError}
              />
            </div>
          )}
          <div>
            {this.renderLabel(
              this.state.user.profession === "STUDENT"
                ? i18n.t("auth.universityAddress")
                : this.state.user.profession === "NGO"
                ? i18n.t("auth.ngoAddress")
                : i18n.t("auth.organisationaddress")
            )}
            <Input
              id="organisationAddress"
              className="mt-1"
              placeholder={i18n.t("auth.organisationaddressfull")}
              value={this.state.user.organisationAddress}
              onChange={this.handleChange}
              error={this.state.userError.organisationAddressError}
            />
          </div>
          <div className="pt-2">
            {this.renderLabel(i18n.t("auth.empperm"))}
            {this.renderTrueFalseRadio(
              this.state.user.employeePermission,
              "employeePermission"
            )}
            <span className="text-red-500 text-sm">
              {this.state.userError.employeePermissionError}
            </span>
          </div>
          <div className="py-2">
            {this.renderLabel(i18n.t("auth.kkmemp"))}
            {this.renderTrueFalseRadio(
              this.state.user.kkmEmployee,
              "kkmEmployee"
            )}
            <span className="text-red-500 text-sm">
              {this.state.userError.kkmEmployeeError}
            </span>
          </div>
        </div>
      );
    } else if (this.state.stepIndex === 2) {
      return (
        <div>
          {this.renderLabel(i18n.t("auth.illness"))}
          <Checkbox.Group
            value={this.state.user.diseases}
            className="my-2"
            options={this.renderDiseaseList()}
            onChange={this.handleChangeCheckbox.bind(this, "diseases")}
          />
          <div className="text-red-500 text-sm">
            {this.state.userError.diseasesError}
          </div>
        </div>
      );
    } else if (this.state.stepIndex === 3) {
      return (
        <div className="space-y-4">
          {this.renderLabel(i18n.t("auth.exposure"))}
          <div className="pb-1">
            {this.renderLabel(i18n.t("auth.redzone"))}
            {this.renderTrueFalseRadio(
              this.state.user.covidRedZoneEvent,
              "covidRedZoneEvent"
            )}
            <span className="text-red-500 text-sm">
              {this.state.userError.covidRedZoneEventError}
            </span>
          </div>
          <div className="pb-1">
            {this.renderLabel(i18n.t("auth.overseas"))}
            {this.renderTrueFalseRadio(
              this.state.user.covidOutOfCountry,
              "covidOutOfCountry"
            )}
            <span className="text-red-500 text-sm">
              {this.state.userError.covidOutOfCountryError}
            </span>
          </div>
          <div className="pb-1">
            {this.renderLabel(i18n.t("auth.closecontact"))}
            {this.renderTrueFalseRadio(
              this.state.user.covidCloseContact,
              "covidCloseContact"
            )}
            <span className="text-red-500 text-sm">
              {this.state.userError.covidCloseContactError}
            </span>
          </div>
          {this.renderLabel(i18n.t("auth.symptoms1"))}
          <div>
            {this.renderLabel(i18n.t("auth.symptoms2"))}
            <Checkbox.Group
              value={this.state.user.symptoms}
              className="my-2"
              options={this.renderSymptoms()}
              onChange={this.handleChangeCheckbox.bind(this, "symptoms")}
            />
            <div className="text-red-500 text-sm mt-2">
              {this.state.userError.symptomsError}
            </div>
          </div>
          <p className="pt-4">
            {i18n.t("auth.tncheader")}{" "}
            <a
              href={"/termscondition"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-red-600 hover:text-custom-red-400"
            >
              {i18n.t("auth.tnc")}
            </a>{" "}
            {" & "}
            <a
              href={"/privacypolicy"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-red-600 hover:text-custom-red-400"
            >
              {i18n.t("auth.privacy")}
            </a>
          </p>
        </div>
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (this.props.authStore.userAuth) {
      return <Redirect to="/profile" />;
    }

    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Header history={this.props.history} />
        <Overlay loading={this.state.loading} text="Verifying Your Account" />
        <div className="min-h-full w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          {this.renderSteps()}
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900">
              {i18n.t("header.signUp")}
            </h2>
          </div>
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              {this.renderContent()}
              <Button
                className="w-full mt-6"
                text={i18n.t("header.next")}
                type="normal"
                onClick={this.handleSubmit}
              />
              <span className="flex mt-4 justify-end">
                Version {packageJson.version}
              </span>
            </div>
          </div>
        </div>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    ngoStore: state.ngoStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getNgo: () => dispatch(getNgo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
