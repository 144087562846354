import i18n from "i18next";
import React, { Component } from "react";
import LogoCITF from "../../assets/logo_citf.png";
import LogoIFRC from "../../assets/logo_ifrc.png";
import LogoKPMG from "../../assets/logo_kpmg.png";
import LogoMatch from "../../assets/logo_match.png";
import LogoMB from "../../assets/logo_mb.png";
import LogoMRCS from "../../assets/logo_mrcs.png";
import { handleNavigation } from "../../utils/navigator";

interface Props {
  history: any;
}

export default class Footer extends Component<Props> {
  handleNavigate = (type: string) => {
    if (type === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (type === "termscondition") {
      handleNavigation(this.props.history, "/termscondition");
    } else if (type === "privacypolicy") {
      handleNavigation(this.props.history, "/privacypolicy");
    } else if (type === "home") {
      handleNavigation(this.props.history, "/");
    } else if (type === "contactUs") {
      handleNavigation(this.props.history, "/contactUs");
    } else if (type === "kbsOffice") {
      handleNavigation(this.props.history, "/officeList");
    }
  };

  renderLink = (action: string, text: any) => {
    return (
      <li>
        <p
          className="cursor-pointer text-base underline text-gray-500 hover:text-gray-900"
          onClick={this.handleNavigate.bind(this, action)}
        >
          {text}
        </p>
      </li>
    );
  };

  render() {
    return (
      <footer
        className="bg-white w-full border-t-2"
        aria-labelledby="footerHeading"
      >
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-6 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider uppercase">
                    {i18n.t("footer.knowUsTitle")}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {this.renderLink("home", i18n.t("footer.aboutTitle"))}
                    {this.renderLink(
                      "contactUs",
                      i18n.t("footer.contactTitle")
                    )}
                    {this.renderLink("kbsOffice", i18n.t("footer.kbsOffice"))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider uppercase">
                    {i18n.t("footer.legalTitle")}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {this.renderLink(
                      "termscondition",
                      i18n.t("footer.tncTitle")
                    )}
                    {this.renderLink(
                      "privacypolicy",
                      i18n.t("footer.privacyTitle")
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-12 space-y-8 xl:col-span-1 xl:mt-0">
              <p className="text-sm mb-2 font-semibold uppercase">
                {i18n.t("footer.supportTitle")}
              </p>
              <div className="flex flex-row items-center">
                <img className="h-14 sm:h-20" src={LogoCITF} alt="" />
                <p className="text-sm mx-2 sm:mx-4">
                  {i18n.t("footer.supportTitleDescription")}
                </p>
                <img className="h-14 sm:h-20" src={LogoMRCS} alt="" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm my-2 font-semibold uppercase">
                  {i18n.t("footer.poweredTitle")}
                </p>
                <div className="flex flex-wrap items-center">
                  <img className="h-12 my-2" src={LogoIFRC} alt="" />
                  <img className="h-12 my-2 mx-2" src={LogoMB} alt="" />
                  <img className="h-12 my-2 mx-2" src={LogoKPMG} alt="" />
                  <img className="h-10 my-2" src={LogoMatch} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t-2 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              {i18n.t("footer.copyrightTitle")}
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
