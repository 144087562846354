import { Component } from "react";
import { connect } from "react-redux";
import {
  updateDeviceSize,
  updateDeviceType,
} from "../store/actions/utilsActions";
import {
  DeviceSize,
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";

interface Props {
  utilsStore: utilsStateInterface;
  updateDeviceType: (deviceType: DeviceType) => Promise<void>;
  updateDeviceSize: (deviceSize: number) => Promise<void>;
}

class WindowResizer extends Component<Props> {
  componentDidMount() {
    this.handleCalculateWidth();
    window.addEventListener("resize", this.handleCalculateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalculateWidth);
  }

  handleCalculateWidth = () => {
    let deviceType: DeviceType = DeviceType.Laptop;
    if (window.innerWidth < DeviceSize.minTabletBreakpoints)
      deviceType = DeviceType.Mobile;
    else if (window.innerWidth < DeviceSize.minLaptopBreakpoints)
      deviceType = DeviceType.Tablet;
    else deviceType = DeviceType.Laptop;

    if (deviceType !== this.props.utilsStore.deviceType)
      this.props.updateDeviceType(deviceType);
    if (window.innerWidth !== this.props.utilsStore.deviceSize)
      this.props.updateDeviceSize(window.innerWidth);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDeviceType: (deviceType: DeviceType) =>
      dispatch(updateDeviceType(deviceType)),
    updateDeviceSize: (deviceSize: number) =>
      dispatch(updateDeviceSize(deviceSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindowResizer);
