import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";
import { getOfficeList } from "../utils/kbsOfficeList";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
}

class OfficeList extends Component<Props> {
  renderState = (state: string, borderTop: boolean) => {
    const selectedStateOffice = getOfficeList(state);
    return (
      <div className="w-full max-w-md mx-auto py-10 px-4 sm:max-w-3xl  sm:px-6 lg:max-w-7xl lg:px-8">
        <div
          className={`divide-y divide-warm-gray-200 ${
            borderTop && "border-t-2"
          }`}
        >
          <section
            className="lg:grid lg:grid-cols-3 lg:gap-8 mt-8"
            aria-labelledby="contactHeading"
          >
            <h2
              id="contactHeading"
              className="text-xl font-extrabold text-warm-gray-900 sm:text-3xl"
            >
              {state}
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              {selectedStateOffice.map((eachOffice: any, index: number) => (
                <div key={eachOffice.state + index}>
                  <h3 className="text-lg font-medium text-warm-gray-900">
                    {eachOffice.officeName}
                  </h3>
                  <dl className="mt-2 text-base text-warm-gray-500">
                    <div>
                      <dt className="sr-only">Address</dt>
                      <dd>{eachOffice.officeAddress}</dd>
                    </div>
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>{eachOffice.officerName}</dd>
                    </div>
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>{eachOffice.officerEmail}</dd>
                    </div>
                    <div className="mt-1">
                      <dt className="sr-only">Phone number</dt>
                      <dd>{eachOffice.officerContact}</dd>
                    </div>
                  </dl>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <div className="bg-warm-gray-50 w-full">
          <Header history={this.props.history} />
          <div className="py-12 lg:py-16">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-extrabold tracking-tight text-warm-gray-900 sm:text-4xl">
                {i18n.t("officelist.listTitle")}
              </h1>
              <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                {i18n.t("officelist.description")}
              </p>
            </div>
          </div>
        </div>

        <VerticalContainer className="w-full">
          {this.renderState("Johor", false)}
          {this.renderState("Kedah", true)}
          {this.renderState("Kelantan", true)}
          {this.renderState("Kuala Lumpur & Putrajaya", true)}
          {this.renderState("Labuan", true)}
          {this.renderState("Melaka", true)}
          {this.renderState("Negeri Sembilan", true)}
          {this.renderState("Pahang", true)}
          {this.renderState("Perak", true)}
          {this.renderState("Perlis", true)}
          {this.renderState("Pulau Pinang", true)}
          {this.renderState("Sabah", true)}
          {this.renderState("Sarawak", true)}
          {this.renderState("Selangor", true)}
          {this.renderState("Terengganu", true)}
        </VerticalContainer>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(OfficeList);
