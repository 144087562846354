import ResourceModel from "../../models/Resources";
import firebase from "../../utils/firebase";

let resourcesSocket: any = null;

export const getResources = () => {
  return async (dispatch: any, getState: any) => {
    try {
      if (!resourcesSocket) {
        let resourcesList: ResourceModel[] = [];

        resourcesSocket = firebase
          .firestore()
          .collection("resource")
          .onSnapshot((querySnapshot) => {
            resourcesList = [];
            querySnapshot.forEach((doc) => {
              resourcesList.push(doc.data() as ResourceModel);
            });

            dispatch({
              type: "UPDATE_RESOURCES",
              payload: {
                resources: resourcesList,
              },
            });
          });
      }
    } catch (err) {}
  };
};

export const clearResourcesSocket = () => {
  if (resourcesSocket) {
    resourcesSocket();
    resourcesSocket = null;
  }
};
