import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import HomeAbout from "../assets/home_about.webp";
import Button from "../components/base/Button";
import Footer from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import WindowResizer from "../components/WindowResizer";
import VerificationLinkModel from "../models/HealthVerificationLink";
import {
  getVerificationDetails,
  healthVerificationRes,
} from "../store/actions/attendanceActions";
import { handleNotification } from "../store/actions/utilsActions";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  match: any;
  utilsStore: utilsStateInterface;
  getVerificationDetails: (id: string) => void;
}

interface State {
  email: string;
  verificationLink: VerificationLinkModel;
  loading: false;
}

class Attendance extends Component<Props> {
  state: State = {
    email: "",
    verificationLink: {
      id: "",
      title: "",
      applyDate: new Date(),
    },
    loading: false,
  };

  componentDidMount = async () => {
    this.handleLoading(true);
    if (!this.handleGetParams()) {
      this.handleNavigate("home");
    } else {
      const verificationQuery: healthVerificationRes =
        await getVerificationDetails(this.handleGetParams());
      if (verificationQuery.err) {
        this.handleNavigate("home");
      } else if (verificationQuery.data) {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        const validDate = new Date(
          verificationQuery.data?.applyDate.seconds * 1000
        );
        validDate.setHours(0, 0, 0, 0);
        const today = moment(todayDate);
        const valid = moment(validDate);
        if (today.diff(valid, "day") === 0) {
          this.setState({
            verificationLink: verificationQuery.data,
          });
          this.handleLoading(false);
        } else {
          this.handleNavigate("home");
        }
      }
    }
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "home") {
      handleNavigation(this.props.history, "/");
    }
  };

  handleChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleEmailVerification = async () => {
    this.handleLoading(true);
    let emailCondition = false;
    const filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line
    if (filter.test(this.state.email.replace(/\s/g, ""))) {
      emailCondition = true;
    }

    if (emailCondition && this.state.verificationLink.id) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      let apiUrl: string;
      if (isProd) {
        apiUrl =
          "https://asia-southeast2-mysukarela.cloudfunctions.net/linkVerification";
      } else {
        apiUrl =
          "https://asia-southeast2-mysukarela-dev.cloudfunctions.net/linkVerification";
      }
      const headers = {
        "Content-Type": "application/json",
        "x-auth-api-key": process.env.REACT_APP_AUTH_TOKEN,
      };

      try {
        const attendanceVerification = await axios.post(
          apiUrl,
          {
            id: this.state.verificationLink.id,
            userEmail: this.state.email,
          },
          { headers: headers }
        );
        if (attendanceVerification.data.response) {
          handleNotification("success", i18n.t("attendance.verifySuccess"), "");
          this.handleNavigate("home");
        } else {
          handleNotification("warning", i18n.t("attendance.verifyError"), "");
        }
        this.handleLoading(false);
      } catch (err) {
        if (err.response) {
          handleNotification("warning", err.response.data.message, "");
        } else {
          handleNotification("warning", err.message, "");
        }
        this.handleLoading(false);
      }
    } else {
      handleNotification("warning", i18n.t("attendance.emailError"), "");
      this.handleLoading(false);
    }
  };

  renderSvg = () => {
    return (
      <div className="hidden sm:block">
        <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
        <svg
          className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
          width={404}
          height={392}
          fill="none"
          viewBox="0 0 404 392"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={392}
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </svg>
      </div>
    );
  };

  renderContent = () => {
    return (
      <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div className="mt-20">
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    {i18n.t("attendance.verifyAttendance")}
                  </h1>
                </div>
                <div className="mt-10 sm:max-w-lg sm:w-full sm:flex">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="hero_email" className="sr-only">
                      Email address
                    </label>
                    <Input
                      id="email"
                      placeholder={i18n.t("attendance.email")}
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <Button
                      text={i18n.t("attendance.verifyButton")}
                      type="normal"
                      onClick={this.handleEmailVerification}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              {this.renderSvg()}
              <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={HomeAbout}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Overlay
          text="Please wait while we are verifying your account"
          loading={this.state.loading}
        />
        <Header history={this.props.history} />
        {this.renderContent()}
        <Footer history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    attendanceStore: state.attendanceStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getVerificationDetails: (id: string) =>
      dispatch(getVerificationDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
