const districtList = [
  {
    id: 1,
    state: "Johor",
    value: "BPT",
    district: "Batu Pahat",
  },
  {
    id: 2,
    state: "Johor",
    value: "JBU",
    district: "Johor Bahru",
  },
  {
    id: 3,
    state: "Johor",
    value: "KUG",
    district: "Kluang",
  },
  {
    id: 4,
    state: "Johor",
    value: "KTI",
    district: "Kota Tinggi",
  },
  {
    id: 5,
    state: "Johor",
    value: "MRG",
    district: "Mersing",
  },
  {
    id: 6,
    state: "Johor",
    value: "MAR",
    district: "Muar",
  },
  {
    id: 7,
    state: "Johor",
    value: "PNN",
    district: "Pontian",
  },
  {
    id: 8,
    state: "Johor",
    value: "SGT",
    district: "Segamat",
  },
  {
    id: 9,
    state: "Johor",
    value: "KLI",
    district: "Kulai",
  },
  {
    id: 10,
    state: "Johor",
    value: "TNK",
    district: "Tangkak",
  },
  {
    id: 11,
    state: "Kedah",
    value: "KSR",
    district: "Kota Setar",
  },
  {
    id: 12,
    state: "Kedah",
    value: "KPU",
    district: "Kubang Pasu",
  },
  {
    id: 13,
    state: "Kedah",
    value: "PTP",
    district: "Padang Terap",
  },
  {
    id: 14,
    state: "Kedah",
    value: "LNI",
    district: "Langkawi",
  },
  {
    id: 15,
    state: "Kedah",
    value: "KMA",
    district: "Kuala Muda",
  },
  {
    id: 16,
    state: "Kedah",
    value: "YAN",
    district: "Yan",
  },
  {
    id: 17,
    state: "Kedah",
    value: "SIK",
    district: "Sik",
  },
  {
    id: 18,
    state: "Kedah",
    value: "BLG",
    district: "Baling",
  },
  {
    id: 19,
    state: "Kedah",
    value: "KLM",
    district: "Kulim",
  },
  {
    id: 20,
    state: "Kedah",
    value: "BBU",
    district: "Bandar Baharu",
  },
  {
    id: 21,
    state: "Kedah",
    value: "PNG",
    district: "Pendang",
  },
  {
    id: 22,
    state: "Kedah",
    value: "PSA",
    district: "Pokok Sena",
  },
  {
    id: 23,
    state: "Kelantan",
    value: "BCK",
    district: "Bachok",
  },
  {
    id: 24,
    state: "Kelantan",
    value: "KBU",
    district: "Kota Bharu",
  },
  {
    id: 25,
    state: "Kelantan",
    value: "MCG",
    district: "Machang",
  },
  {
    id: 26,
    state: "Kelantan",
    value: "PMS",
    district: "Pasir Mas",
  },
  {
    id: 27,
    state: "Kelantan",
    value: "PPH",
    district: "Pasir Puteh",
  },
  {
    id: 28,
    state: "Kelantan",
    value: "TMH",
    district: "Tanah Merah",
  },
  {
    id: 29,
    state: "Kelantan",
    value: "TMT",
    district: "Tumpat",
  },
  {
    id: 30,
    state: "Kelantan",
    value: "GM",
    district: "Gua Musang",
  },
  {
    id: 31,
    state: "Kelantan",
    value: "KKI",
    district: "Kuala Krai",
  },
  {
    id: 32,
    state: "Kelantan",
    value: "JLI",
    district: "Jeli",
  },
  {
    id: 33,
    state: "Melaka",
    value: "MTH",
    district: "Melaka Tengah",
  },
  {
    id: 34,
    state: "Melaka",
    value: "JSN",
    district: "Jasin",
  },
  {
    id: 35,
    state: "Melaka",
    value: "AGH",
    district: "Alor Gajah",
  },
  {
    id: 36,
    state: "Negeri Sembilan",
    value: "JLU",
    district: "Jelebu",
  },
  {
    id: 37,
    state: "Negeri Sembilan",
    value: "KPH",
    district: "Kuala Pilah",
  },
  {
    id: 38,
    state: "Negeri Sembilan",
    value: "PDN",
    district: "Port Dickson",
  },
  {
    id: 39,
    state: "Negeri Sembilan",
    value: "RMU",
    district: "Rembau",
  },
  {
    id: 40,
    state: "Negeri Sembilan",
    value: "SRN",
    district: "Seremban",
  },
  {
    id: 41,
    state: "Negeri Sembilan",
    value: "TMN",
    district: "Tampin",
  },
  {
    id: 42,
    state: "Negeri Sembilan",
    value: "JML",
    district: "Jempol",
  },

  {
    id: 43,
    state: "Pahang",
    value: "BNG",
    district: "Bentong",
  },
  {
    id: 44,
    state: "Pahang",
    value: "CHS",
    district: "Cameron Highlands",
  },
  {
    id: 45,
    state: "Pahang",
    value: "JRT",
    district: "Jerantut",
  },
  {
    id: 46,
    state: "Pahang",
    value: "KAN",
    district: "Kuantan",
  },
  {
    id: 47,
    state: "Pahang",
    value: "KPS",
    district: "Kuala Lipis",
  },
  {
    id: 48,
    state: "Pahang",
    value: "PKN",
    district: "Pekan",
  },
  {
    id: 49,
    state: "Pahang",
    value: "RAB",
    district: "Raub",
  },
  {
    id: 50,
    state: "Pahang",
    value: "TMH",
    district: "Temerloh",
  },
  {
    id: 51,
    state: "Pahang",
    value: "RMN",
    district: "Rompin",
  },
  {
    id: 52,
    state: "Pahang",
    value: "MRN",
    district: "Maran",
  },
  {
    id: 53,
    state: "Pahang",
    value: "BRA",
    district: "Bera",
  },
  {
    id: 54,
    state: "Pulau Pinang",
    value: "SPT",
    district: "Seberang Perai Tengah (Bukit Mertajam)",
  },
  {
    id: 55,
    state: "Pulau Pinang",
    value: "SPU",
    district: "Seberang Perai Utara (ButterWorth)",
  },
  {
    id: 56,
    state: "Pulau Pinang",
    value: "SPS",
    district: "Seberang Perai Selatan (Nibong Tebal)",
  },
  {
    id: 57,
    state: "Pulau Pinang",
    value: "TLT",
    district: "Daerah Timur Laut",
  },
  {
    id: 58,
    state: "Pulau Pinang",
    value: "BDA",
    district: "Daerah Barat Daya",
  },
  {
    id: 59,
    state: "Perak",
    value: "BDK",
    district: "Bagan Datuk",
  },
  {
    id: 60,
    state: "Perak",
    value: "BPG",
    district: "Batang Padang",
  },
  {
    id: 61,
    state: "Perak",
    value: "HPK",
    district: "Hulu Perak",
  },
  {
    id: 62,
    state: "Perak",
    value: "MNG",
    district: "Manjung",
  },
  {
    id: 63,
    state: "Perak",
    value: "KNA",
    district: "Kinta",
  },
  {
    id: 64,
    state: "Perak",
    value: "KRN",
    district: "Kerian",
  },
  {
    id: 65,
    state: "Perak",
    value: "KKR",
    district: "Kuala Kangsar",
  },
  {
    id: 66,
    state: "Perak",
    value: "KGH",
    district: "Kg Gajah",
  },

  {
    id: 67,
    state: "Perak",
    value: "HIK",
    district: "Hilir Perak",
  },
  {
    id: 68,
    state: "Perak",
    value: "LMT",
    district: "Larut Matang",
  },

  {
    id: 69,
    state: "Perak",
    value: "SLA",
    district: "Selama",
  },
  {
    id: 70,
    state: "Perak",
    value: "PTH",
    district: "Perak Tengah",
  },
  {
    id: 71,
    state: "Perak",
    value: "KMR",
    district: "Kampar",
  },
  {
    id: 72,
    state: "Perak",
    value: "LGG",
    district: "Lenggong",
  },
  {
    id: 73,
    state: "Perak",
    value: "MAM",
    district: "Muallim",
  },
  {
    id: 74,
    state: "Perak",
    value: "PHU",
    district: "Pengkalan Hulu",
  },
  {
    id: 75,
    state: "Perak",
    value: "SSU",
    district: "Sg.Siput(U)",
  },
  {
    id: 76,
    state: "Perlis",
    value: "PRS",
    district: "Perlis",
  },
  {
    id: 77,
    state: "Selangor",
    value: "KAG",
    district: "Klang",
  },
  {
    id: 78,
    state: "Selangor",
    value: "KLT",
    district: "Kuala Langat",
  },
  {
    id: 79,
    state: "Selangor",
    value: "KSR",
    district: "Kuala Selangor",
  },
  {
    id: 80,
    state: "Selangor",
    value: "SBM",
    district: "Sabak Bernam",
  },
  {
    id: 81,
    state: "Selangor",
    value: "HLT",
    district: "Hulu Langat",
  },
  {
    id: 82,
    state: "Selangor",
    value: "HSR",
    district: "Hulu Selangor",
  },
  {
    id: 83,
    state: "Selangor",
    value: "PTG",
    district: "Petaling",
  },
  {
    id: 84,
    state: "Selangor",
    value: "GMK",
    district: "Gombak",
  },
  {
    id: 85,
    state: "Selangor",
    value: "SPG",
    district: "Sepang",
  },

  {
    id: 86,
    state: "Terengganu",
    value: "BST",
    district: "Besut",
  },
  {
    id: 87,
    state: "Terengganu",
    value: "DNN",
    district: "Dungun",
  },
  {
    id: 88,
    state: "Terengganu",
    value: "KMN",
    district: "Kemaman",
  },
  {
    id: 89,
    state: "Terengganu",
    value: "KTU",
    district: "Kuala Terengganu",
  },
  {
    id: 90,
    state: "Terengganu",
    value: "HTU",
    district: "Hulu Terengganu",
  },
  {
    id: 91,
    state: "Terengganu",
    value: "MRG",
    district: "Marang",
  },
  {
    id: 92,
    state: "Terengganu",
    value: "STU",
    district: "Setiu",
  },
  {
    id: 93,
    state: "Terengganu",
    value: "KNS",
    district: "Kuala Nerus",
  },
  {
    id: 94,
    state: "Sabah",
    value: "KKU",
    district: "Kota Kinabalu",
  },
  {
    id: 95,
    state: "Sabah",
    value: "PPR",
    district: "Papar",
  },
  {
    id: 96,
    state: "Sabah",
    value: "KBD",
    district: "Kota Belud",
  },

  {
    id: 97,
    state: "Sabah",
    value: "TAN",
    district: "Tuaran",
  },
  {
    id: 98,
    state: "Sabah",
    value: "KDT",
    district: "Kudat",
  },
  {
    id: 99,
    state: "Sabah",
    value: "RNU",
    district: "Ranau",
  },
  {
    id: 100,
    state: "Sabah",
    value: "SDN",
    district: "Sandakan",
  },

  {
    id: 101,
    state: "Sabah",
    value: "TWU",
    district: "Tawau",
  },
  {
    id: 102,
    state: "Sabah",
    value: "LDU",
    district: "Lahad Datu",
  },
  {
    id: 103,
    state: "Sabah",
    value: "SMA",
    district: "Semporna",
  },
  {
    id: 104,
    state: "Sabah",
    value: "KNU",
    district: "Keningau",
  },
  {
    id: 105,
    state: "Sabah",
    value: "TMN",
    district: "Tambunan",
  },
  {
    id: 106,
    state: "Sabah",
    value: "PSN",
    district: "Pensiangan",
  },
  {
    id: 107,
    state: "Sabah",
    value: "TNM",
    district: "Tenom",
  },
  {
    id: 108,
    state: "Sabah",
    value: "NBN",
    district: "Nabawan",
  },
  {
    id: 109,
    state: "Sabah",
    value: "BUT",
    district: "Beaufort",
  },

  {
    id: 110,
    state: "Sabah",
    value: "KPY",
    district: "Kuala Penyu",
  },
  {
    id: 111,
    state: "Sabah",
    value: "STG",
    district: "Sipitang",
  },
  {
    id: 112,
    state: "Sabah",
    value: "PMG",
    district: "Penampang",
  },
  {
    id: 113,
    state: "Sabah",
    value: "KMU",
    district: "Kota Marudu",
  },
  {
    id: 114,
    state: "Sabah",
    value: "PTS",
    district: "Pitas",
  },
  {
    id: 115,
    state: "Sabah",
    value: "KNK",
    district: "Kunak",
  },
  {
    id: 116,
    state: "Sabah",
    value: "BLN",
    district: "Beluran",
  },

  {
    id: 117,
    state: "Sabah",
    value: "KBN",
    district: "Kinabatangan",
  },
  {
    id: 118,
    state: "Sabah",
    value: "KBK",
    district: "Kelabakan",
  },
  {
    id: 119,
    state: "Sabah",
    value: "PBT",
    district: "Pantai Barat",
  },
  {
    id: 120,
    state: "Sabah",
    value: "TND",
    district: "Tongod",
  },
  {
    id: 121,
    state: "Sabah",
    value: "PTN",
    district: "Putatan",
  },
  {
    id: 122,
    state: "Sarawak",
    value: "KCG",
    district: "Kuching",
  },
  {
    id: 123,
    state: "Sarawak",
    value: "BAU",
    district: "Bau",
  },
  {
    id: 124,
    state: "Sarawak",
    value: "LND",
    district: "Lundu",
  },
  {
    id: 125,
    state: "Sarawak",
    value: "SAN",
    district: "Sri Aman",
  },
  {
    id: 126,
    state: "Sarawak",
    value: "LAT",
    district: "Lubok Antu",
  },
  {
    id: 127,
    state: "Sarawak",
    value: "SBU",
    district: "Sibu",
  },
  {
    id: 128,
    state: "Sarawak",
    value: "KWT",
    district: "Kanowit",
  },
  {
    id: 129,
    state: "Sarawak",
    value: "SLG",
    district: "Selanggau",
  },
  {
    id: 130,
    state: "Sarawak",
    value: "BLR",
    district: "Beluru",
  },
  {
    id: 131,
    state: "Sarawak",
    value: "MRI",
    district: "Miri",
  },
  {
    id: 132,
    state: "Sarawak",
    value: "MRD",
    district: "Baram/Marudi",
  },
  {
    id: 133,
    state: "Sarawak",
    value: "LMG",
    district: "Limbang",
  },
  {
    id: 134,
    state: "Sarawak",
    value: "LWS",
    district: "Lawas",
  },
  {
    id: 135,
    state: "Sarawak",
    value: "SRI",
    district: "Sarikei",
  },
  {
    id: 136,
    state: "Sarawak",
    value: "MEG",
    district: "Meradong",
  },
  {
    id: 137,
    state: "Sarawak",
    value: "JLU",
    district: "Julau",
  },
  {
    id: 138,
    state: "Sarawak",
    value: "PAN",
    district: "Pakan",
  },
  {
    id: 139,
    state: "Sarawak",
    value: "KPT",
    district: "Kapit",
  },
  {
    id: 140,
    state: "Sarawak",
    value: "BEG",
    district: "Belaga",
  },
  {
    id: 141,
    state: "Sarawak",
    value: "SNG",
    district: "Song",
  },
  {
    id: 142,
    state: "Sarawak",
    value: "TUN",
    district: "Telang Manis",
  },
  {
    id: 143,
    state: "Sarawak",
    value: "ASA",
    district: "Asajaya",
  },
  {
    id: 144,
    state: "Sarawak",
    value: "SEN",
    district: "Serian",
  },
  {
    id: 145,
    state: "Sarawak",
    value: "SMJ",
    district: "Simunjan",
  },
  {
    id: 146,
    state: "Sarawak",
    value: "BNU",
    district: "Bintulu",
  },
  {
    id: 147,
    state: "Sarawak",
    value: "TTU",
    district: "Tatau",
  },
  {
    id: 148,
    state: "Sarawak",
    value: "MKH",
    district: "Mukah",
  },
  {
    id: 149,
    state: "Sarawak",
    value: "MTU",
    district: "Matu",
  },
  {
    id: 150,
    state: "Sarawak",
    value: "DLT",
    district: "Oya/Dalat",
  },

  {
    id: 151,
    state: "Sarawak",
    value: "DRO",
    district: "Daro",
  },
  {
    id: 152,
    state: "Sarawak",
    value: "TJM",
    district: "Tanjung Manis",
  },
  {
    id: 153,
    state: "Sarawak",
    value: "BTG",
    district: "Saribas/Betong",
  },
  {
    id: 154,
    state: "Sarawak",
    value: "SRK",
    district: "Saratok",
  },
  {
    id: 155,
    state: "Sarawak",
    value: "TLP",
    district: "Telupid",
  },
  {
    id: 156,
    state: "Sarawak",
    value: "BMB",
    district: "Bukit Mabong",
  },
  {
    id: 157,
    state: "Sarawak",
    value: "KBG",
    district: "Kabong",
  },
  {
    id: 158,
    state: "Sarawak",
    value: "PUS",
    district: "Pusa",
  },
  {
    id: 159,
    state: "Sarawak",
    value: "SBH",
    district: "Sebauh",
  },
  {
    id: 160,
    state: "Sarawak",
    value: "SUB",
    district: "Subis",
  },
  {
    id: 161,
    state: "Sarawak",
    value: "TBU",
    district: "Tebedu",
  },
  {
    id: 162,
    state: "WP Kuala Lumpur",
    value: "BTR",
    district: "Bandar Tun Razak",
  },
  {
    id: 163,
    state: "WP Kuala Lumpur",
    value: "BTU",
    district: "Batu",
  },
  {
    id: 164,
    state: "WP Kuala Lumpur",
    value: "BBT",
    district: "Bukit Bintang",
  },
  {
    id: 165,
    state: "WP Kuala Lumpur",
    value: "CHR",
    district: "Cheras",
  },
  {
    id: 166,
    state: "WP Kuala Lumpur",
    value: "KPG",
    district: "Kepong",
  },
  {
    id: 167,
    state: "WP Kuala Lumpur",
    value: "LPI",
    district: "Lembah Pantai",
  },
  {
    id: 168,
    state: "WP Kuala Lumpur",
    value: "SGB",
    district: "Segambut",
  },
  {
    id: 169,
    state: "WP Kuala Lumpur",
    value: "SPH",
    district: "Seputeh",
  },
  {
    id: 170,
    state: "WP Kuala Lumpur",
    value: "STW",
    district: "Setiawangsa",
  },
  {
    id: 171,
    state: "WP Kuala Lumpur",
    value: "TTW",
    district: "Titiwangsa",
  },
  {
    id: 172,
    state: "WP Kuala Lumpur",
    value: "WMJ",
    district: "Wangsa Maju",
  },
  {
    id: 173,
    state: "WP Labuan",
    value: "LBN",
    district: "WP Labuan",
  },
  {
    id: 174,
    state: "WP Putrajaya",
    value: "PTA",
    district: "Putrajaya",
  },
];

export const getDistrictList = (stateFilter: string) => {
  const districtTemp: any = [];

  if (stateFilter) {
    districtList.map((eachDistrict) => {
      if (eachDistrict.state === stateFilter) {
        districtTemp.push(eachDistrict.district);
      }
      return null;
    });
  } else {
    districtList.map((eachDistrict) => {
      districtTemp.push(eachDistrict.district);
      return null;
    });
  }
  districtTemp.sort((a: string, b: string) => a.localeCompare(b));

  return districtTemp;
};

export const getStateList = () => {
  const stateTemp: any = [];

  districtList.map((eachDistrict) => {
    if (!stateTemp.includes(eachDistrict.state)) {
      stateTemp.push(eachDistrict.state);
    }
    return null;
  });

  stateTemp.sort((a: string, b: string) => a.localeCompare(b));

  return stateTemp;
};
