import HealthVerificationLinkModel from "../../models/HealthVerificationLink";
import firebase from "../../utils/firebase";

export interface healthVerificationRes {
  err: string;
  data: HealthVerificationLinkModel | null;
}

export const getVerificationDetails = async (id: string) => {
  let verificationRes: healthVerificationRes = {
    err: "",
    data: null,
  };

  try {
    const verificationQuery = await firebase
      .firestore()
      .collection("healthVerificationLinks")
      .doc(id)
      .get();

    if (verificationQuery.exists) {
      const verificationData = verificationQuery.data() as HealthVerificationLinkModel;
      verificationRes.data = verificationData;
    } else {
      verificationRes.err = "Cannot be found";
    }
  } catch (err) {
    verificationRes.err = err.message;
  }

  return verificationRes;
};

