import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Col, Divider, List, Modal, Row } from "antd";
import i18n from "i18next";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Button from "../components/Button";
import WindowResizer from "../components/WindowResizer";
import {
  BookingModel,
  ValidationResult,
  VolunteerRoles,
  VolunteerShift,
} from "../models/Booking";
import CenterModel, { CenterType } from "../models/Center";
import NavigatorSecurity from "../navigator/NavigatorSecurity";
import {
  BookRes,
  EachBookRes,
  getBooking,
  handleBookingCancellation,
} from "../store/actions/bookActions";
import { handleNotification } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { bookStateInterface } from "../store/reducers/bookReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, Title2 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  match: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  bookStore: bookStateInterface;
  centerStore: centerStateInterface;
}

interface State {
  center: CenterModel;
  booking: BookingModel;
}

const { confirm } = Modal;

class BookingDetails extends Component<Props> {
  state: State = {
    center: {
      id: "",
      centerName: "",
      centerAddress: {
        street: "",
        district: "",
        poscode: "",
        state: "",
      },
      centerEmail: "",
      centerContactNo: "",
      imageToken: "",
      centerType: "Type 1" as keyof typeof CenterType,
      doses: 0,
      volHealth: 0,
      volGeneral: 0,
      description: "",
      personnelNeeded: [],
    },
    booking: {
      id: "",
      userId: "",
      centerId: "",
      bookDate: [],
      startDate: new Date(),
      endDate: new Date(),
      shift: [],
      roles: "" as keyof typeof VolunteerRoles,
      type: "WEEK",
      createdAt: new Date(),
      validation: [],
    },
  };

  componentDidMount() {
    if (this.props.authStore.userAuth) {
      this.handleGetData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.authStore.userAuth) !==
        JSON.stringify(prevProps.authStore.userAuth) &&
      this.props.authStore.userAuth &&
      this.handleGetParams()
    ) {
      this.handleGetData();
    }
  }

  handleNavigate = (type: string) => {
    if (type === "bookings") {
      handleNavigation(this.props.history, "/bookings");
    }
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleGetData = async () => {
    const eachBookRes: EachBookRes = await getBooking(this.handleGetParams());
    if (eachBookRes.err || !eachBookRes.data) {
      this.handleNavigate("bookings");
    } else {
      this.setState({
        center: eachBookRes.data.center,
        booking: eachBookRes.data.booking,
      });
    }
  };

  handleBookingCancellation = async (booking: string) => {
    try {
      this.setState({ loading: true });
      const bookRes: BookRes = await handleBookingCancellation(booking);
      if (bookRes.err) {
        handleNotification("warning", bookRes.err, "");
        this.setState({ loading: false });
      } else if (bookRes.success) {
        handleNotification("success", "Booking has been cancelled", "");
        this.handleNavigate("bookings");
      }
    } catch (err) {
      handleNotification("warning", err.message, "");
      this.setState({ loading: false });
    }
  };

  handleConfirmCancellation = () => {
    confirm({
      title: i18n.t("bookings.confirmation"),
      okText: "Confirm",
      onOk: this.handleBookingCancellation.bind(this, this.state.booking.id),
      onCancel: () => {},
    });
  };

  renderCancellationButton = () => {
    if (this.state.booking) {
      const today = moment(new Date());
      const bookingData = moment(
        new Date(this.state.booking.startDate.seconds * 1000)
      );
      if (bookingData.diff(today, "days") >= 3) {
        return (
          <Button
            text={i18n.t("bookings.cancellation")}
            width="auto"
            small={true}
            onClick={this.handleConfirmCancellation}
          />
        );
      } else {
        return null;
      }
    }
  };

  renderCenterPreviewImage = () => {
    let imageUrl = "";

    if (this.state.center.imageToken) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const storageBucketId = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      imageUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/centers%2F${this.state.center.id}%2Fimage.jpg?alt=media&token=${this.state.center.imageToken}`;
    }

    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="Place Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return null;
    }
  };

  renderVolunteeringRoles = () => {
    let selectedRole = i18n.t("eachCenter.roles");
    if (this.state.booking.id) {
      selectedRole += " : " + VolunteerRoles[this.state.booking.roles];
    }
    return selectedRole;
  };

  renderBookingValidation = (selectedDate: string) => {
    let selectedValidate = "";
    if (this.state.booking.validation) {
      this.state.booking.validation.map((eachValidation) => {
        const selectedValidationDate = moment(
          eachValidation.date.seconds * 1000
        ).format("DD/MM/YYYY");
        if (
          selectedValidationDate === selectedDate &&
          eachValidation.result === "V"
        ) {
          selectedValidate = " - " + ValidationResult[eachValidation.result];
        }
        return null;
      });
    }
    return selectedValidate;
  };

  renderVolunteeringShift = () => {
    let listView: any = [];
    if (this.state.booking.shift.length > 0) {
      this.state.booking.shift.forEach((shift, index) => {
        const dateString = moment(
          this.state.booking.bookDate[index].seconds * 1000
        ).format("DD/MM/YYYY");
        listView.push(
          <li
            style={{
              marginBottom: 15,
              color: COLOR.BLACK,
            }}
            key={index}
          >
            <Body>
              {dateString} - {VolunteerShift[shift]}
              {this.renderBookingValidation(dateString)}
            </Body>
          </li>
        );
        return null;
      });
    }

    return listView;
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <NavigatorSecurity />
        <Header history={this.props.history} />
        <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
          <RowContainer className="mb-2">
            <ArrowLeftIcon
              className="text-custom-red-600 cursor-pointer h-6 w-6 mr-2"
              onClick={this.handleNavigate.bind(this, "bookings")}
            />
            <h1 className="text-2xl font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
              {i18n.t("bookings.bookingDetails")}
            </h1>
          </RowContainer>
          <Row style={{ width: "100%" }} gutter={8}>
            <Col sm={24} md={12}>
              <VerticalContainer
                style={{
                  width: "100%",
                  background: COLOR.LIGHTGREY,
                  height: 300,
                  position: "relative",
                }}
              >
                {this.renderCenterPreviewImage()}
              </VerticalContainer>
              <VerticalContainer
                style={{
                  width: "100%",
                  alignItems: "flex-start",
                  marginTop: 20,
                }}
              >
                <Title2 style={{ marginBottom: 10 }}>
                  {this.state.center.centerName}
                </Title2>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.address")}
                  {this.state.center.centerAddress.street},
                  {this.state.center.centerAddress.district},
                  {this.state.center.centerAddress.poscode},
                  {this.state.center.centerAddress.state}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.contactNumber")}
                  {this.state.center.centerContactNo}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.contactEmail")}{" "}
                  {this.state.center.centerEmail}
                </Body>
                <Body>
                  {i18n.t("eachCenter.direction")}
                  {this.state.center.description}
                </Body>
              </VerticalContainer>
            </Col>
            <Col
              sm={24}
              md={12}
              style={{
                width: "100%",
              }}
            >
              <VerticalContainer
                style={{
                  width: "100%",
                  paddingLeft:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 0
                      : 20,
                }}
              >
                <BaseProfileContainer
                  style={{
                    marginTop:
                      this.props.utilsStore.deviceType === DeviceType.Mobile
                        ? 15
                        : 0,
                  }}
                >
                  <VerticalContainer
                    style={{
                      margin: 20,
                      marginBottom: 40,
                      width: "90%",
                      maxWidth: 500,
                      alignItems: "flex-start",
                    }}
                  >
                    <Body style={{ marginTop: 10, marginBottom: 5 }}>
                      {i18n.t("bookings.bookingId")} : {this.state.booking.id}
                    </Body>
                    <Body style={{ marginTop: 20, marginBottom: 5 }}>
                      {i18n.t("bookings.bookingEmail")} :{" "}
                      {this.props.authStore.user?.email}
                    </Body>
                    <Body style={{ marginTop: 20, marginBottom: 5 }}>
                      {i18n.t("bookings.volDate")} :{" "}
                      {moment(
                        this.state.booking.startDate.seconds * 1000
                      ).format("DD/MM/YYYY") +
                        " - " +
                        moment(
                          this.state.booking.endDate.seconds * 1000
                        ).format("DD/MM/YYYY")}
                    </Body>
                    <Body style={{ marginTop: 20, marginBottom: 5 }}>
                      {this.renderVolunteeringRoles()}
                    </Body>
                    {this.state.booking.roles !== "G" && (
                      <Body
                        style={{
                          marginTop: 20,
                          marginBottom: 5,
                          color: COLOR.RED,
                        }}
                      >
                        {i18n.t("bookings.bookingMedAppointment")} :{" "}
                        {this.state.booking.medicalVerified
                          ? i18n.t("bookings.bookingMedAccepted")
                          : i18n.t("bookings.bookingMedDeclined")}
                      </Body>
                    )}
                    <Divider style={{ marginTop: 20, marginBottom: 5 }} />
                    <Body style={{ marginTop: 20, marginBottom: 5 }}>
                      {i18n.t("bookings.volShift")}
                    </Body>
                    <List>
                      <ul
                        style={{
                          paddingLeft: 10,
                        }}
                      >
                        {this.renderVolunteeringShift()}
                      </ul>
                    </List>
                    <Body
                      style={{
                        color: COLOR.RED,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                    >
                      {i18n.t("bookings.warning")}
                    </Body>
                    {this.renderCancellationButton()}
                  </VerticalContainer>
                </BaseProfileContainer>
              </VerticalContainer>
            </Col>
          </Row>
        </div>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    bookStore: state.bookStore,
    centerStore: state.centerStore,
  };
};

export default connect(mapStateToProps)(BookingDetails);

const BaseProfileContainer = styled(VerticalContainer)`
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 100%;
  border-radius: 10px;
`;
