import Ngo from "../../models/Ngo";

export interface ngoStateInterface {
  ngo: Ngo[];
}

const initState: ngoStateInterface = {
  ngo: [],
};

const ngoReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_NGO":
      return {
        ...state,
        ngo: action.payload.ngo,
      };
    default:
      return state;
  }
};

export default ngoReducer;
