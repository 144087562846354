import axios from "axios";
import moment from "moment";
import {
  BookingModel,
  VolunteerRoles,
  VolunteerShift,
  VolunteerShiftType,
} from "../../models/Booking";
import CenterModel, { Personnel } from "../../models/Center";
import firebase from "../../utils/firebase";

export interface Choice {
  date: Date;
  answer: keyof typeof VolunteerShift;
  availableShift: keyof typeof VolunteerShift;
}

export interface BookRes {
  success: boolean;
  err: string;
}

export interface AvailableDate {
  [key: string]: {
    MOR: number;
    EVE: number;
  };
}

export interface EachBookRes {
  data: {
    center: CenterModel;
    booking: BookingModel;
  } | null;
  err: string;
}

const getDaysBetweenDates = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MM-DD-YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const getBookings = () => {
  return async (dispatch: any, getState: any) => {
    if (firebase.auth().currentUser) {
      let bookings: BookingModel[] = [];
      const bookingSnapshot = await firebase
        .firestore()
        .collection("bookings")
        .where("userId", "==", firebase.auth().currentUser?.uid ?? "")
        .get();

      if (bookingSnapshot) {
        bookingSnapshot.forEach(async (eachDoc) => {
          bookings.push(eachDoc.data() as BookingModel);
          return null;
        });
      }

      dispatch({
        type: "UPDATE_BOOKING",
        payload: {
          bookings: bookings,
        },
      });
    } else {
      dispatch({
        type: "UPDATE_BOOKING",
        payload: {
          bookings: [],
        },
      });
    }
  };
};

export const getBooking = async (id: string) => {
  const eachBookRes: EachBookRes = {
    data: null,
    err: "",
  };

  try {
    const bookingQuery = await firebase
      .firestore()
      .collection("bookings")
      .doc(id)
      .get();
    if (bookingQuery.exists) {
      const selectedBooking: BookingModel = bookingQuery.data() as BookingModel;

      if (
        firebase.auth().currentUser &&
        selectedBooking.userId === firebase.auth().currentUser?.uid
      ) {
        const centerQuery = await firebase
          .firestore()
          .collection("centers")
          .doc(selectedBooking.centerId)
          .get();
        if (centerQuery.exists) {
          eachBookRes["data"] = {
            booking: selectedBooking,
            center: centerQuery.data() as CenterModel,
          };
        } else {
          eachBookRes["err"] = "Center does not exist";
        }
      } else {
        eachBookRes["err"] = "Security error";
      }
    } else {
      eachBookRes["err"] = "Booking does not exist";
    }
  } catch (err) {
    eachBookRes["err"] = err.message;
  }
  return eachBookRes;
};

export const getSelectedBookings = (
  centerId: string,
  startDate: Date,
  endDate: Date
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "UPDATE_SELECTED_BOOKING_LOADING",
        payload: {
          selectedLoading: true,
        },
      });

      let selectedBookingsSnapshot = await firebase
        .firestore()
        .collection("bookings")
        .where("centerId", "==", centerId)
        .where("startDate", ">=", startDate)
        .where("startDate", "<=", endDate)
        .get();

      const selectedBookings: BookingModel[] = [];
      if (selectedBookingsSnapshot) {
        selectedBookingsSnapshot.forEach((eachBooking) => {
          const selectedBooking = eachBooking.data() as BookingModel;
          selectedBookings.push(selectedBooking);
        });
      }

      dispatch({
        type: "UPDATE_SELECTED_BOOKING",
        payload: {
          selectedBookings: selectedBookings,
        },
      });
    } catch (err) {
      dispatch({
        type: "UPDATE_SELECTED_BOOKING",
        payload: {
          selectedBookings: [],
        },
      });
    }
    dispatch({
      type: "UPDATE_SELECTED_BOOKING_LOADING",
      payload: {
        selectedLoading: false,
      },
    });
  };
};

export const returnAvailableDates = (
  personnelList: Personnel[],
  bookings: BookingModel[],
  roles: keyof typeof VolunteerRoles
) => {
  let availableDates: AvailableDate = {};
  if (personnelList.length > 0) {
    personnelList.map((eachPersonel: Personnel) => {
      const startDate = new Date(eachPersonel.startDate.seconds * 1000);
      const endDate = new Date(eachPersonel.endDate.seconds * 1000);

      const eachDateList = getDaysBetweenDates(
        moment(startDate),
        moment(endDate)
      );

      if (eachDateList.length > 0 && roles) {
        eachDateList.map((eachDate) => {
          let morningNumber: number = 0;
          let eveningNumber: number = 0;

          if (roles === "G") {
            morningNumber = eachPersonel.volGeneralNo;
            eveningNumber = eachPersonel.volGeneralNo;
          } else if (roles === "C") {
            morningNumber = eachPersonel.volHealthCounsNo;
            eveningNumber = eachPersonel.volHealthCounsNo;
          } else if (roles === "O") {
            morningNumber = eachPersonel.volHealthObsNo;
            eveningNumber = eachPersonel.volHealthObsNo;
          } else if (roles === "V") {
            morningNumber = eachPersonel.volHealthVacNo;
            eveningNumber = eachPersonel.volHealthVacNo;
          }

          if (bookings.length > 0) {
            bookings.map((eachBooking) => {
              if (eachBooking.roles === roles) {
                const eachBookingStartDate = new Date(
                  eachBooking.startDate.seconds * 1000
                );
                const eachBookingEndDate = new Date(
                  eachBooking.endDate.seconds * 1000
                );
                const eachBookingDateList = getDaysBetweenDates(
                  moment(eachBookingStartDate),
                  moment(eachBookingEndDate)
                );

                const availableIndex = eachBookingDateList.indexOf(eachDate);
                if (availableIndex >= 0) {
                  const selectedBookingShift =
                    eachBooking.shift[availableIndex];
                  if (selectedBookingShift === "MOR") {
                    morningNumber -= 1;
                  } else if (selectedBookingShift === "EVE") {
                    eveningNumber -= 1;
                  } else {
                    morningNumber -= 1;
                    eveningNumber -= 1;
                  }
                }
              }
              return null;
            });
          }

          availableDates[eachDate] = {
            MOR: morningNumber,
            EVE: eveningNumber,
          };
          return null;
        });
      }
      return null;
    });
  }

  return availableDates;
};

const sendEmail = async (bookingId: string, action: string) => {
  const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
  let bookingResponse: BookRes = {
    success: false,
    err: "",
  };

  let apiUrl: string;
  if (isProd) {
    apiUrl =
      "https://asia-southeast2-mysukarela.cloudfunctions.net/bookingAction";
  } else {
    apiUrl =
      "https://asia-southeast2-mysukarela-dev.cloudfunctions.net/bookingAction";
  }

  const headers = {
    "Content-Type": "application/json",
    "x-auth-api-key": process.env.REACT_APP_AUTH_TOKEN,
  };

  try {
    const bookingCancellation = await axios.post(
      apiUrl,
      {
        bookingId,
        userId: firebase.auth().currentUser?.uid,
        action,
      },
      { headers: headers }
    );

    if (!bookingCancellation.data.response) {
      const actionWord =
        action === "bookingConfirmation" ? "confirmation" : "cancellation";
      bookingResponse.err = `Booking ${actionWord} failed`;
    }
  } catch (err) {
    if (err.response) {
      bookingResponse.err = err.response.data.message;
    } else {
      bookingResponse.err = err.message;
    }
  }

  return bookingResponse;
};

export const handleBookDates = async (
  choices: Choice[],
  centerId: string,
  roles: keyof typeof VolunteerRoles
) => {
  let bookingResponse: BookRes = {
    success: false,
    err: "",
  };
  if (firebase.auth().currentUser) {
    const centersQuery = await firebase
      .firestore()
      .collection("centers")
      .doc(centerId)
      .get();
    if (centersQuery.exists) {
      const centerData = centersQuery.data() as CenterModel;
      const selectedMonday = moment(choices[0].date).startOf("week");
      const selectedMondayOffset = moment(choices[0].date)
        .startOf("week")
        .add(7, "d");

      let selectedBookingsSnapshot = await firebase
        .firestore()
        .collection("bookings")
        .where("centerId", "==", centerData.id)
        .where("startDate", ">=", selectedMonday.toDate())
        .where("startDate", "<=", selectedMondayOffset.toDate())
        .get();
      const selectedBookings: BookingModel[] = [];
      if (selectedBookingsSnapshot) {
        selectedBookingsSnapshot.forEach((eachBooking) => {
          const selectedBooking = eachBooking.data() as BookingModel;
          selectedBookings.push(selectedBooking);
        });
      }

      const availableDates: AvailableDate = returnAvailableDates(
        centerData.personnelNeeded,
        selectedBookings,
        roles
      );

      if (choices.length > 0) {
        let bookingAvailable: number = 0;
        choices.map((eachChoice) => {
          const eachDateString = moment(eachChoice.date).format("MM-DD-YYYY");
          if (Object.keys(availableDates).includes(eachDateString)) {
            if (
              eachChoice.answer === "MOR" &&
              availableDates[eachDateString].MOR > 0
            ) {
              bookingAvailable += 1;
            } else if (
              eachChoice.answer === "EVE" &&
              availableDates[eachDateString].EVE > 0
            ) {
              bookingAvailable += 1;
            } else if (
              eachChoice.answer === "WHOLE" &&
              availableDates[eachDateString].MOR > 0 &&
              availableDates[eachDateString].EVE > 0
            ) {
              bookingAvailable += 1;
            }
          }
          return null;
        });
        const startDate = choices[0].date;
        const endDate = choices[choices.length - 1].date;
        if (bookingAvailable >= choices.length) {
          const bookDate: Date[] = [];
          const volunteerShift: VolunteerShiftType[] = [];
          choices.map((eachChoice) => {
            bookDate.push(eachChoice.date);
            volunteerShift.push(eachChoice.answer);
            return null;
          });
          const bookingRef = firebase.firestore().collection("bookings").doc();
          const newBooking: BookingModel = {
            id: bookingRef.id,
            userId: firebase.auth().currentUser?.uid ?? "",
            centerId: centerId,
            bookDate: bookDate,
            startDate: startDate,
            endDate: endDate,
            shift: volunteerShift,
            roles: roles,
            createdAt: new Date(),
            type: "WEEK",
          };

          await firebase
            .firestore()
            .collection("bookings")
            .doc(newBooking.id)
            .set(newBooking);

          bookingResponse = await sendEmail(
            newBooking.id,
            "bookingConfirmation"
          );
          bookingResponse.success = true;
        } else {
          bookingResponse.err =
            "Date may have been booked, please refresh your browser";
        }
      } else {
        bookingResponse.err = "No date is selected";
      }
    } else {
      bookingResponse.err = "Center cannot be found";
    }
  } else {
    bookingResponse.err = "User is not authenticated";
  }

  return bookingResponse;
};

export const handleBookingCancellation = async (bookingId: string) => {
  let bookingResponse: BookRes = {
    success: false,
    err: "",
  };
  if (firebase.auth().currentUser) {
    const bookingQuery = await firebase
      .firestore()
      .collection("bookings")
      .doc(bookingId)
      .get();

    if (bookingQuery.exists) {
      const bookingData = bookingQuery.data() as BookingModel;

      const today = moment(new Date());
      const bookingDataDate = moment(
        new Date(bookingData.startDate.seconds * 1000)
      );

      if (bookingDataDate.diff(today, "days") >= 3) {
        bookingResponse = await sendEmail(bookingId, "bookingCancellation");

        await firebase
          .firestore()
          .collection("bookingArchives")
          .doc(bookingId)
          .set(bookingData);
        firebase.firestore().collection("bookings").doc(bookingId).delete();
        bookingResponse.success = true;
      } else {
        bookingResponse.err = "Booking date is within 3 days";
      }
    } else {
      bookingResponse.err = "Booking cannot not found";
    }
  } else {
    bookingResponse.err = "User is not authenticated";
  }

  return bookingResponse;
};
