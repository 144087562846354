import CenterModel from "../../models/Center";

export interface centerStateInterface {
  centers: CenterModel[];
  loading: boolean;
}

const initState: centerStateInterface = {
  centers: [],
  loading: true,
};

const centerReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_CENTERS":
      return {
        ...state,
        centers: action.payload.centers,
        loading: false,
      };
    default:
      return state;
  }
};

export default centerReducer;
