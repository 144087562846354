import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import packageJson from "../../package.json";
import Button from "../components/base/Button";
import FooterBar from "../components/base/FooterBar";
import Header, { AuthScreens } from "../components/base/Header";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import WindowResizer from "../components/WindowResizer";
import {
  handleAuthConditionSignIn,
  resetPassword,
  signIn,
} from "../store/actions/authActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
}

interface SignInAttribute {
  email: string;
  password: string;
}

interface SignInError {
  emailError: string;
  passwordError: string;
}

interface State {
  loading: boolean;
  modal: AuthScreens;
  user: SignInAttribute;
  userError: SignInError;
}

class SignIn extends Component<Props> {
  state: State = {
    loading: false,
    modal: AuthScreens.SIGNIN,
    user: {
      email: "",
      password: "",
    },
    userError: {
      emailError: "",
      passwordError: "",
    },
  };

  handleCheckCondition = (typeList: string[]) => {
    const clonedUserAttributeError = JSON.parse(
      JSON.stringify(this.state.userError)
    );

    handleAuthConditionSignIn(
      this.state.user,
      clonedUserAttributeError,
      typeList
    );

    this.setState({
      userError: clonedUserAttributeError,
    });
  };

  handleInputChange = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.user));
    clonedUserState[e.target.id] = e.target.value;
    this.setState({
      userState: clonedUserState,
    });
  };

  handleClearForm = () => {
    this.setState({
      user: {
        email: "",
        password: "",
      },
      userError: {
        emailError: "",
        passwordError: "",
      },
    });
  };

  handleScreen = () => {
    let modal = AuthScreens.SIGNIN;
    if (this.state.modal === AuthScreens.SIGNIN) {
      modal = AuthScreens.RESETPASS;
    }

    this.setState({
      modal,
    });
    this.handleClearForm();
  };

  handleChange = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.user));
    clonedUserState[e.target.id] = e.target.value;
    this.setState({
      user: clonedUserState,
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    await sleep(250);

    if (this.state.modal === AuthScreens.SIGNIN) {
      this.handleCheckCondition(["email", "password"]);
    } else if (this.state.modal === AuthScreens.RESETPASS) {
      this.handleCheckCondition(["email"]);
    }

    await sleep(250);

    //INFO : Check condition is passed
    let conditionPassed: boolean = false;
    if (this.state.modal === AuthScreens.SIGNIN) {
      conditionPassed =
        !this.state.userError.emailError && !this.state.userError.passwordError
          ? true
          : false;
    } else if (this.state.modal === AuthScreens.RESETPASS) {
      conditionPassed = !this.state.userError.emailError ? true : false;
    }

    try {
      if (conditionPassed) {
        if (this.state.modal === AuthScreens.SIGNIN) {
          await signIn({
            email: this.state.user.email.replace(/\s/g, ""),
            password: this.state.user.password.replace(/\s/g, ""),
          });
        } else if (this.state.modal === AuthScreens.RESETPASS) {
          await resetPassword(this.state.user.email.replace(/\s/g, ""));
          const clonedUserAttributeError = JSON.parse(
            JSON.stringify(this.state.userError)
          );
          clonedUserAttributeError["emailError"] =
            "Password reset link has been sent to your email";
          this.setState({
            userError: clonedUserAttributeError,
          });
        }
      }
      this.setState({ loading: false });
    } catch (err) {
      const clonedUserAttributeError = JSON.parse(
        JSON.stringify(this.state.userError)
      );
      if (this.state.modal === AuthScreens.SIGNIN) {
        clonedUserAttributeError["passwordError"] = err.message;
      } else if (this.state.modal === AuthScreens.RESETPASS) {
        clonedUserAttributeError["emailError"] = err.message;
      }

      this.setState({
        userError: clonedUserAttributeError,
        loading: false,
      });
    }
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (this.props.authStore.userAuth) {
      return <Redirect to="/profile" />;
    }

    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <Header history={this.props.history} />
        <Overlay loading={this.state.loading} text="Verifying Your Account" />
        <div className="min-h-full w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {this.state.modal === AuthScreens.SIGNIN
                ? i18n.t("header.signIn")
                : i18n.t("header.resetPassword")}
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {i18n.t("auth.email")}
                  </label>
                  <Input
                    id="email"
                    className="mt-1"
                    placeholder={i18n.t("auth.email")}
                    value={this.state.user.email}
                    onChange={this.handleChange}
                    error={this.state.userError.emailError}
                  />
                </div>

                {this.state.modal === AuthScreens.SIGNIN && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {i18n.t("auth.password")}
                    </label>
                    <Input
                      id="password"
                      className="mt-1"
                      placeholder={i18n.t("auth.password")}
                      type="password"
                      value={this.state.user.password}
                      onChange={this.handleChange}
                      error={this.state.userError.passwordError}
                    />
                  </div>
                )}

                <Button
                  className="w-full"
                  text={i18n.t("header.confirm")}
                  type="normal"
                  onClick={this.handleSubmit}
                />

                <div className="flex items-center justify-between">
                  <span
                    className="text-sm font-medium text-custom-red-600 cursor-pointer hover:text-custom-red-400"
                    onClick={this.handleScreen}
                  >
                    {this.state.modal === AuthScreens.SIGNIN
                      ? i18n.t("auth.forgotpassword")
                      : i18n.t("auth.resetSuccessful")}
                  </span>
                </div>
              </div>
              <span className="flex mt-4 justify-end">
                Version {packageJson.version}
              </span>
            </div>
          </div>
        </div>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(SignIn);
