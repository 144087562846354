import Resources from "../../models/Resources";

export interface resStateInterface {
  resources: Resources[];
}

const initState: resStateInterface = {
  resources: [],
};

const resReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_RESOURCES":
      return {
        ...state,
        resources: action.payload.resources,
      };
    default:
      return state;
  }
};

export default resReducer;
