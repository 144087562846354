import i18n from "i18next";
import {
  UserAttributeEditorState,
  UserAttributeEditorStateError,
} from "../../components/ProfileEditor";
import {
  BankType,
  GenderType,
  NGOType,
  ProfessionHealthType,
  ProfessionType,
  RaceType,
  User,
} from "../../models/User";
import {
  UserAttributeState,
  UserAttributeStateError,
} from "../../screens/SignUp";
import firebase from "../../utils/firebase";

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;
let unsubscribeUserListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener)
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged(function (user) {
          if (user) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: user,
              },
            });
            getUserInfoListener(dispatch);
          } else {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
            removeUserInfoListener();
          }
        });
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
    removeUserInfoListener();
  };
};

export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  await firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password);
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {}
};

export const createAccount = async (credentials: {
  name: string;
  ic: string;
  email: string;
  password: string;
  age: number;
  gender: keyof typeof GenderType;
  race: keyof typeof RaceType;
  raceOthers: string;
  street: string;
  poscode: string;
  district: string;
  state: string;
  mobileNo: string;
  profession: keyof typeof ProfessionType;
  professionOthers: string;
  professionHealth: keyof typeof ProfessionHealthType;
  professionHealthId: string;
  organisationName: NGOType | string;
  organisationAddress: string;
}) => {
  try {
    const createdCredentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password);
    if (createdCredentials.user) {
      //TOFIX : User Model
      const userUUID: string = createdCredentials.user?.uid;
      const userModel: User = {
        id: userUUID,
        createdAt: new Date(),
        ic: credentials.ic,
        name: credentials.name,
        age: credentials.age,
        gender: credentials.gender,
        race: credentials.race,
        raceOthers: credentials.raceOthers,
        address: {
          street: credentials.street,
          district: credentials.district,
          poscode: credentials.poscode,
          state: credentials.state,
        },
        email: createdCredentials.user?.email ?? "",
        mobileNo: credentials.mobileNo,
        profession: credentials.profession,
        professionOthers: credentials.professionOthers,
        professionHealthType: credentials.professionHealth,
        professionHealthId: credentials.professionHealthId,
        organisationName: credentials.organisationName,
        organisationAddress: credentials.organisationAddress,
        bankName: "" as keyof typeof BankType,
        bankAccountName: "",
        bankAccountNo: "",
        imageToken: "",
      };
      await firebase
        .firestore()
        .collection("users")
        .doc(userUUID)
        .set(userModel);
      await createdCredentials.user.sendEmailVerification();
      return "";
    } else return "User is not created. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const resetPassword = async (email: string) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

export const resendVerificationEmail = async () => {
  if (firebase.auth().currentUser) {
    await firebase.auth().currentUser?.sendEmailVerification();
  }
};

export const resetPasswordWithOob = async (
  oobCode: string,
  password: string
) => {
  await firebase.auth().confirmPasswordReset(oobCode, password);
};

export const verifyOobCode = async (oobCode: string) => {
  const result = await firebase.auth().verifyPasswordResetCode(oobCode);
  return result;
};

export const editProfile = async (credentials: {
  mobileNo: string;
  address: {
    street: string;
    district: string;
    poscode: string;
    state: string;
  };
  bankName: keyof typeof BankType;
  bankAccountNo: string;
  bankAccountName: string;
  nameNextofKin: string;
  relationship: string;
  contactNoNextofKin: string;
}) => {
  const userId = firebase.auth().currentUser?.uid;
  if (userId) {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        mobileNo: credentials.mobileNo,
        address: {
          street: credentials.address.street,
          district: credentials.address.district,
          poscode: credentials.address.poscode,
          state: credentials.address.state,
        },
        bankName: credentials.bankName,
        bankAccountNo: credentials.bankAccountNo,
        bankAccountName: credentials.bankAccountName,
        nameNextofKin: credentials.nameNextofKin,
        relationship: credentials.relationship,
        contactNoNextofKin: credentials.contactNoNextofKin,
      });
  }
};

export const updateQuizPassed = async () => {
  const userId = firebase.auth().currentUser?.uid;
  if (userId) {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        quizResult: {
          passed: true,
          date: new Date(),
        },
      });
  }
};

export const uploadPhoto = async (items: {
  file: File;
  fileName: string;
  type: number;
}) => {
  try {
    const userId = firebase.auth().currentUser?.uid;
    const storage = firebase.storage().ref("users");
    const userStore = firebase.firestore().collection("users").doc(userId);

    if (userId && items.file) {
      const uploadTask = await storage
        .child(userId + "/avatar.jpeg")
        .put(items.file);
      const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const fileToken = urlParams.get("token");

      await userStore.update({
        imageToken: fileToken,
      });
    }

    return "";
  } catch (err) {
    return err.message;
  }
};

export const uploadDocument = async (items: {
  file: File;
  fileName: string;
  type: number;
}) => {
  try {
    const userId = firebase.auth().currentUser?.uid;
    const storage = firebase.storage().ref("users");
    const userStore = firebase.firestore().collection("users").doc(userId);
    const snapshot: any = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    let fileCheck: string = "";

    if (userId && items.file) {
      switch (items.type) {
        case 0:
          fileCheck = snapshot.data().secretAgreement?.name;
          break;
        case 1:
          fileCheck = snapshot.data().liabilityAgreement?.name;
          break;
        case 2:
          fileCheck = snapshot.data().healthDeclaration?.name;
          break;
        case 3:
          fileCheck = snapshot.data().offerLetter?.name;
          break;
        case 4:
          fileCheck = snapshot.data().medicalDegree?.name;
          break;
      }

      if (fileCheck) {
        try {
          const fileRef = storage.child(userId + "/forms/" + fileCheck);
          await fileRef.delete();
        } catch (err) {}
      }

      const uploadTask = await storage
        .child(userId + "/forms/" + items.fileName)
        .put(items.file);
      const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const fileToken = urlParams.get("token");

      switch (items.type) {
        case 0:
          await userStore.update({
            secretAgreement: {
              name: items.fileName,
              token: fileToken,
              date: new Date(),
            },
          });
          break;
        case 1:
          await userStore.update({
            liabilityAgreement: {
              name: items.fileName,
              token: fileToken,
              date: new Date(),
            },
          });
          break;
        case 2:
          await userStore.update({
            healthDeclaration: {
              name: items.fileName,
              token: fileToken,
              date: new Date(),
            },
          });
          break;
        case 3:
          await userStore.update({
            offerLetter: {
              name: items.fileName,
              token: fileToken,
              date: new Date(),
            },
          });
          break;
        case 4:
          await userStore.update({
            medicalDegree: {
              name: items.fileName,
              token: fileToken,
              date: new Date(),
            },
          });
          break;
      }
    }
    return "";
  } catch (err) {
    return err.message;
  }
};

export const verifyEmailLink = async (oobCode: string) => {
  await firebase.auth().applyActionCode(oobCode);
};

const getUserInfoListener = async (dispatch: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      try {
        const userQuery = await firebase
          .firestore()
          .collection("admin")
          .doc(firebase.auth().currentUser?.uid)
          .get();
        if (userQuery.exists) {
          signOut();
        }
      } catch (err) {}

      unsubscribeUserListener = firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser?.uid)
        .onSnapshot(async (doc) => {
          if (doc) {
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: doc.data(),
              },
            });
          }
        });
    }
  } catch (err) {}
};

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener();
    unsubscribeUserListener = null;
  }
};

const instanceofUserAttribute = (
  object: any,
  member: string
): object is UserAttributeState => {
  return member in object;
};

const instanceofUserAttributeError = (
  object: any,
  member: string
): object is UserAttributeStateError => {
  return member in object;
};

const instanceofUserAttributeEditor = (
  object: any,
  member: string
): object is UserAttributeEditorState => {
  return member in object;
};

const instanceofUserAttributeEditorError = (
  object: any,
  member: string
): object is UserAttributeEditorStateError => {
  return member in object;
};

export const handleAuthCondition = (
  userAttributeState: UserAttributeState | UserAttributeEditorState,
  userAttributeError: UserAttributeStateError | UserAttributeEditorStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line
        if (
          instanceofUserAttribute(userAttributeState, "email") &&
          instanceofUserAttributeError(userAttributeError, "emailError")
        ) {
          if (!filter.test(userAttributeState.email.replace(/\s/g, ""))) {
            userAttributeError["emailError"] = i18n.t("auth.emailError");
          } else {
            userAttributeError["emailError"] = "";
          }
        }
        break;
      case "password":
        if (
          instanceofUserAttribute(userAttributeState, "password") &&
          instanceofUserAttributeError(userAttributeError, "passwordError")
        ) {
          if (userAttributeState.password.replace(/\s/g, "").length < 6) {
            userAttributeError["passwordError"] = i18n.t("auth.passwordError");
          } else {
            userAttributeError["passwordError"] = "";
          }
        }
        break;
      case "ic":
        const regex = /^\d{6}-\d{2}-\d{4}$/;
        if (
          instanceofUserAttribute(userAttributeState, "ic") &&
          instanceofUserAttributeError(userAttributeError, "icError")
        ) {
          if (!regex.test(userAttributeState.ic.replace(/\s/g, ""))) {
            userAttributeError["icError"] = i18n.t("auth.icError");
          } else {
            userAttributeError["icError"] = "";
          }
        }
        break;
      case "name":
        if (
          instanceofUserAttribute(userAttributeState, "name") &&
          instanceofUserAttributeError(userAttributeError, "nameError")
        ) {
          if (userAttributeState.name.replace(/\s/g, "").length <= 0) {
            userAttributeError["nameError"] = i18n.t("auth.nameError");
          } else {
            userAttributeError["nameError"] = "";
          }
        }
        break;
      case "street":
        if (userAttributeState.address.street.replace(/\s/g, "").length <= 0) {
          userAttributeError["addressError"]["streetError"] =
            i18n.t("auth.addressError");
        } else {
          userAttributeError["addressError"]["streetError"] = "";
        }
        break;
      case "poscode":
        if (userAttributeState.address.poscode.replace(/\s/g, "").length <= 0) {
          userAttributeError["addressError"]["poscodeError"] =
            i18n.t("auth.poscodeError");
        } else {
          userAttributeError["addressError"]["poscodeError"] = "";
        }
        break;
      case "mobileNo":
        const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
        if (!phoneFilter.test(userAttributeState.mobileNo)) {
          userAttributeError["mobileNoError"] = i18n.t("auth.mobilenoError");
        } else {
          userAttributeError["mobileNoError"] = "";
        }
        break;
      case "professionHealthId":
        if (
          instanceofUserAttribute(userAttributeState, "professionHealthId") &&
          instanceofUserAttributeError(
            userAttributeError,
            "professionHealthIdError"
          )
        ) {
          if (
            userAttributeState.professionHealthId.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["professionHealthIdError"] = i18n.t(
              "auth.medicalidError"
            );
          } else {
            userAttributeError["professionHealthIdError"] = "";
          }
        }
        break;
      case "professionOthers":
        if (
          instanceofUserAttribute(userAttributeState, "professionOthers") &&
          instanceofUserAttributeError(
            userAttributeError,
            "professionOthersError"
          )
        ) {
          if (
            userAttributeState.professionOthers.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["professionOthersError"] = i18n.t(
              "auth.professionError"
            );
          } else {
            userAttributeError["professionOthersError"] = "";
          }
        }
        break;
      case "organisationName":
        if (
          instanceofUserAttribute(userAttributeState, "organisationName") &&
          instanceofUserAttributeError(
            userAttributeError,
            "organisationNameError"
          )
        ) {
          if (
            userAttributeState.organisationName.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["organisationNameError"] = i18n.t(
              "auth.organisationNameError"
            );
          } else {
            userAttributeError["organisationNameError"] = "";
          }
        }
        break;
      case "organisationNameOthers":
        if (
          instanceofUserAttribute(
            userAttributeState,
            "organisationNameOthers"
          ) &&
          instanceofUserAttributeError(
            userAttributeError,
            "organisationNameOthersError"
          )
        ) {
          if (
            userAttributeState.organisationNameOthers.replace(/\s/g, "")
              .length <= 0
          ) {
            userAttributeError["organisationNameOthersError"] = i18n.t(
              "auth.organisationNameError"
            );
          } else {
            userAttributeError["organisationNameError"] = "";
          }
        }
        break;
      case "organisationAddress":
        if (
          instanceofUserAttribute(userAttributeState, "organisationAddress") &&
          instanceofUserAttributeError(
            userAttributeError,
            "organisationAddressError"
          )
        ) {
          if (
            userAttributeState.organisationAddress.replace(/\s/g, "").length <=
            0
          ) {
            userAttributeError["organisationAddressError"] = i18n.t(
              "auth.organisationAddressError"
            );
          } else {
            userAttributeError["organisationAddressError"] = "";
          }
        }
        break;
      case "age":
        if (
          instanceofUserAttribute(userAttributeState, "age") &&
          instanceofUserAttributeError(userAttributeError, "ageError")
        ) {
          if (isNaN(userAttributeState.age)) {
            userAttributeError["ageError"] = i18n.t("auth.ageError");
          } else if (userAttributeState.age < 18) {
            userAttributeError["ageError"] = i18n.t("auth.ageunderError");
          } else {
            userAttributeError["ageError"] = "";
          }
        }
        break;
      case "employeePermission":
        if (
          instanceofUserAttribute(userAttributeState, "employeePermission") &&
          instanceofUserAttributeError(
            userAttributeError,
            "employeePermissionError"
          )
        ) {
          if (!userAttributeState.employeePermission) {
            userAttributeError["employeePermissionError"] =
              i18n.t("auth.emppermError");
          } else {
            userAttributeError["employeePermissionError"] = "";
          }
        }
        break;
      case "kkmEmployee":
        if (
          instanceofUserAttribute(userAttributeState, "kkmEmployee") &&
          instanceofUserAttributeError(userAttributeError, "kkmEmployeeError")
        ) {
          if (userAttributeState.kkmEmployee) {
            userAttributeError["kkmEmployeeError"] = i18n.t("auth.kkmempError");
          } else {
            userAttributeError["kkmEmployeeError"] = "";
          }
        }
        break;
      case "pregnancy":
        if (
          instanceofUserAttribute(userAttributeState, "pregnancy") &&
          instanceofUserAttributeError(userAttributeError, "pregnancyError")
        ) {
          if (userAttributeState.pregnancy) {
            userAttributeError["pregnancyError"] = i18n.t("auth.unfitError");
          } else {
            userAttributeError["pregnancyError"] = "";
          }
        }
        break;
      case "diseases":
        if (
          instanceofUserAttribute(userAttributeState, "diseases") &&
          instanceofUserAttributeError(userAttributeError, "diseasesError")
        ) {
          if (userAttributeState.diseases.length > 0) {
            userAttributeError["diseasesError"] = i18n.t("auth.unfitError");
          } else {
            userAttributeError["diseasesError"] = "";
          }
        }
        break;
      case "covidRedZoneEvent":
        if (
          instanceofUserAttribute(userAttributeState, "covidRedZoneEvent") &&
          instanceofUserAttributeError(
            userAttributeError,
            "covidRedZoneEventError"
          )
        ) {
          if (userAttributeState.covidRedZoneEvent) {
            userAttributeError["covidRedZoneEventError"] =
              i18n.t("auth.noteligError");
          } else {
            userAttributeError["covidRedZoneEventError"] = "";
          }
        }
        break;
      case "covidOutOfCountry":
        if (
          instanceofUserAttribute(userAttributeState, "covidOutOfCountry") &&
          instanceofUserAttributeError(
            userAttributeError,
            "covidOutOfCountryError"
          )
        ) {
          if (userAttributeState.covidOutOfCountry) {
            userAttributeError["covidOutOfCountryError"] =
              i18n.t("auth.noteligError");
          } else {
            userAttributeError["covidOutOfCountryError"] = "";
          }
        }
        break;
      case "covidCloseContact":
        if (
          instanceofUserAttribute(userAttributeState, "covidCloseContact") &&
          instanceofUserAttributeError(
            userAttributeError,
            "covidCloseContactError"
          )
        ) {
          if (userAttributeState.covidCloseContact) {
            userAttributeError["covidCloseContactError"] =
              i18n.t("auth.noteligError");
          } else {
            userAttributeError["covidCloseContactError"] = "";
          }
        }
        break;
      case "symptoms":
        if (
          instanceofUserAttribute(userAttributeState, "symptoms") &&
          instanceofUserAttributeError(userAttributeError, "symptomsError")
        ) {
          if (userAttributeState.symptoms.length > 0) {
            userAttributeError["symptomsError"] = i18n.t("auth.noteligError");
          } else {
            userAttributeError["symptomsError"] = "";
          }
        }
        break;
      case "bankAccountNo":
        if (
          instanceofUserAttributeEditor(userAttributeState, "bankAccountNo") &&
          instanceofUserAttributeEditorError(
            userAttributeError,
            "bankAccountNoError"
          )
        ) {
          if (userAttributeState.bankAccountNo.replace(/\s/g, "").length <= 0) {
            userAttributeError["bankAccountNoError"] = i18n.t(
              "auth.bankAccountNoError"
            );
          } else {
            userAttributeError["bankAccountNoError"] = "";
          }
        }
        break;
      case "bankAccountName":
        if (
          instanceofUserAttributeEditor(
            userAttributeState,
            "bankAccountName"
          ) &&
          instanceofUserAttributeEditorError(
            userAttributeError,
            "bankAccountNameError"
          )
        ) {
          if (
            userAttributeState.bankAccountName.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["bankAccountNameError"] = i18n.t(
              "auth.bankAccountNameError"
            );
          } else {
            userAttributeError["bankAccountNameError"] = "";
          }
        }
        break;
      case "contactNoNextofKin":
        if (
          instanceofUserAttributeEditor(
            userAttributeState,
            "contactNoNextofKin"
          ) &&
          instanceofUserAttributeEditorError(
            userAttributeError,
            "contactNoNextofKinError"
          )
        ) {
          if (
            userAttributeState.contactNoNextofKin.replace(/\s/g, "").length <= 0
          ) {
            userAttributeError["contactNoNextofKinError"] = i18n.t(
              "auth.contactNoNextofKinError"
            );
          } else {
            userAttributeError["contactNoNextofKinError"] = "";
          }
        }
        break;
      case "relationship":
        if (
          instanceofUserAttributeEditor(userAttributeState, "relationship") &&
          instanceofUserAttributeEditorError(
            userAttributeError,
            "relationshipError"
          )
        ) {
          if (userAttributeState.relationship.replace(/\s/g, "").length <= 0) {
            userAttributeError["relationshipError"] = i18n.t(
              "auth.relationshipError"
            );
          } else {
            userAttributeError["relationshipError"] = "";
          }
        }
        break;
      case "nameNextofKin":
        if (
          instanceofUserAttributeEditor(userAttributeState, "nameNextofKin") &&
          instanceofUserAttributeEditorError(
            userAttributeError,
            "nameNextofKinError"
          )
        ) {
          if (userAttributeState.nameNextofKin.replace(/\s/g, "").length <= 0) {
            userAttributeError["nameNextofKinError"] = i18n.t(
              "auth.nameNextofKinError"
            );
          } else {
            userAttributeError["nameNextofKinError"] = "";
          }
        }
        break;
      default:
        break;
    }
    return null;
  });
};

export const handleAuthConditionSignIn = (
  userAttributeState: any,
  userAttributeError: any,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line
        if (!filter.test(userAttributeState.email.replace(/\s/g, ""))) {
          userAttributeError["emailError"] = i18n.t("auth.emailError");
        } else {
          userAttributeError["emailError"] = "";
        }
        break;
      case "password":
        if (userAttributeState.password.replace(/\s/g, "").length < 6) {
          userAttributeError["passwordError"] = i18n.t("auth.passwordError");
        } else {
          userAttributeError["passwordError"] = "";
        }
        break;
      default:
        break;
    }
    return null;
  });
};

export const handleCheckBooking = async () => {
  const currentUserId = firebase.auth().currentUser?.uid ?? "";
  let condition: boolean = false;

  if (currentUserId) {
    const userSnapshot = await firebase
      .firestore()
      .collection("bookings")
      .where("userId", "==", currentUserId)
      .limit(1)
      .get();

    if (userSnapshot) {
      userSnapshot.forEach((doc) => {
        condition = true;
      });
    }
  }

  return condition;
};
