import React, { Component } from "react";
import { connect } from "react-redux";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import NotFoundHero from "../components/marketing/NotFound";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

class NotFound extends Component<Props> {
  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Header history={this.props.history} />
        <NotFoundHero history={this.props.history} />
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NotFound);
