import i18n from "i18next";
import React, { Component } from "react";
import HomeHall from "../../assets/home_hall.webp";
import HomeOffice from "../../assets/home_office.webp";
import HomeStadium from "../../assets/home_stadium.webp";

export default class CenterType extends Component {
  render() {
    const posts = [
      {
        title: i18n.t("home.typeTitle1"),
        description: [
          i18n.t("home.vacType1"),
          i18n.t("home.vacType2"),
          i18n.t("home.vacType3"),
        ],
        imageUrl: HomeOffice,
      },
      {
        title: i18n.t("home.typeTitle2"),
        description: [
          i18n.t("home.vacType4"),
          i18n.t("home.vacType5"),
          i18n.t("home.vacType6"),
        ],
        imageUrl: HomeHall,
      },
      {
        title: i18n.t("home.typeTitle3"),
        description: [i18n.t("home.vacType7"), i18n.t("home.vacType8")],
        imageUrl: HomeStadium,
      },
    ];

    return (
      <div className="relative w-full bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {i18n.t("home.vacTitle")}
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-base text-gray-500 sm:mt-4 sm:text-lg">
              {i18n.t("home.vacparagraph1")}
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {posts.map((post) => (
              <div
                key={post.title}
                className="flex flex-col rounded-lg shadow overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={post.imageUrl}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <span className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <ul className="ml-4 mt-3 text-base text-gray-500 list-disc">
                        {post.description.map((eachDescription, index) => (
                          <li key={index + post.title}>{eachDescription}</li>
                        ))}
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
