import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

class TermsConditions extends Component<Props> {
  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Header history={this.props.history} />
        <div className="relative max-w-7xl text-justify pt-6 pb-16 px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl font-bold">
            {i18n.t("termsandcondition.title1")}
          </h2>
          <p className="mt-2 text-sm">
            {i18n.t("termsandcondition.paragraph1")}
            <br />
            <br />
            {i18n.t("termsandcondition.paragraph2")}
            <br />
            <br />
            {i18n.t("termsandcondition.paragraph3")}
            <br />
            <br />
            {i18n.t("termsandcondition.paragraph4")}
            <br />
            <br />
            <br />
            <span className="font-bold">
              {i18n.t("termsandcondition.definition")}
            </span>
            <br />
            <br />
            {i18n.t("termsandcondition.paragraph5")}{" "}
            <span className="font-bold">
              {i18n.t("termsandcondition.users")}
            </span>{" "}
            {i18n.t("termsandcondition.paragraph6")}{" "}
            <span className="font-bold">
              {i18n.t("termsandcondition.admins")}
            </span>{" "}
            {i18n.t("termsandcondition.paragraph7")}{" "}
            <span className="font-bold">
              {i18n.t("termsandcondition.volunteers")}
            </span>{" "}
            {i18n.t("termsandcondition.paragraph8")}{" "}
            <span className="font-bold">
              {i18n.t("termsandcondition.events")}
            </span>{" "}
            {i18n.t("termsandcondition.paragraph9")}{" "}
          </p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title2")}
          </h2>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point1")}</li>
            <li>{i18n.t("termsandcondition.point2")}</li>
            <li>{i18n.t("termsandcondition.point3")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title3")}
          </h2>
          <p className="mt-2 text-sm">
            {i18n.t("termsandcondition.paragraph10")}
          </p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.userresp")}
          </h2>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point4")}</li>
            <li>{i18n.t("termsandcondition.point5")}</li>
            <li>{i18n.t("termsandcondition.point6")}</li>
            <li>{i18n.t("termsandcondition.point7")}</li>
            <li>{i18n.t("termsandcondition.point8")}</li>
            <li>{i18n.t("termsandcondition.point9")}</li>
            <li>{i18n.t("termsandcondition.point10")}</li>
            <li>
              {i18n.t("termsandcondition.point11")}
              <ul className="ml-4 list-decimal">
                <li>{i18n.t("termsandcondition.point12")}</li>
                <li>{i18n.t("termsandcondition.point13")}</li>
                <li>{i18n.t("termsandcondition.point14")}</li>
                <li>{i18n.t("termsandcondition.point15")}</li>
                <li>{i18n.t("termsandcondition.point16")}</li>
              </ul>
            </li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.mrcsresp")}
          </h2>
          <ul className="ml-4 mt-2 list-disc">
            <li>
              {i18n.t("termsandcondition.point17")}
              <ul className="ml-4 list-decimal">
                <li>{i18n.t("termsandcondition.point18")}</li>
                <li>{i18n.t("termsandcondition.point19")}</li>
                <li>{i18n.t("termsandcondition.point20")}</li>
                <li>{i18n.t("termsandcondition.point21")}</li>
              </ul>
            </li>
            <li>
              {i18n.t("termsandcondition.point22")}
              <ul className="ml-4 list-decimal">
                <li>{i18n.t("termsandcondition.point23")}</li>
                <li>{i18n.t("termsandcondition.point24")}</li>
                <li>{i18n.t("termsandcondition.point25")}</li>
                <li>{i18n.t("termsandcondition.point26")}</li>
                <li>{i18n.t("termsandcondition.point27")}</li>
                <li>{i18n.t("termsandcondition.point28")}</li>
              </ul>
            </li>
            <li>{i18n.t("termsandcondition.point29")}</li>
            <li>{i18n.t("termsandcondition.point30")}</li>
            <li>{i18n.t("termsandcondition.point31")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title4")}
          </h2>
          <p className="mt-2 text-sm">{i18n.t("termsandcondition.point32")}</p>
          <p className="mt-2 text-sm">{i18n.t("termsandcondition.point33")}</p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title5")}
          </h2>
          <p className="mt-2">{i18n.t("termsandcondition.point34")}</p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point35")}</li>
            <li>{i18n.t("termsandcondition.point36")}</li>
            <li>{i18n.t("termsandcondition.point37")}</li>
            <li>{i18n.t("termsandcondition.point38")}</li>
            <li>{i18n.t("termsandcondition.point39")}</li>
            <li>{i18n.t("termsandcondition.point40")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title6")}
          </h2>
          <p className="mt-2 text-sm">{i18n.t("termsandcondition.point41")}</p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title7")}
          </h2>
          <p className="mt-2 text-sm">{i18n.t("termsandcondition.point42")}</p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title8")}
          </h2>
          <p className="mt-2 text-sm">{i18n.t("termsandcondition.point43")}</p>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title9")}
          </h2>
          <p className="mt-2">{i18n.t("termsandcondition.point44")}</p>
          <p className="mt-2">{i18n.t("termsandcondition.point45")}</p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point46")}</li>
            <li>{i18n.t("termsandcondition.point47")}</li>
          </ul>
          <p className="mt-2">{i18n.t("termsandcondition.point48")}</p>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point49")}</li>
            <li>{i18n.t("termsandcondition.point50")}</li>
            <li>{i18n.t("termsandcondition.point51")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title10")}
          </h2>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point52")}</li>
            <li>
              {i18n.t("termsandcondition.point53")}
              <ul className="ml-4 list-decimal">
                <li>{i18n.t("termsandcondition.point54")}</li>
                <li>{i18n.t("termsandcondition.point55")}</li>
                <li>{i18n.t("termsandcondition.point56")}</li>
              </ul>
            </li>
            <li>{i18n.t("termsandcondition.point57")}</li>
            <li>{i18n.t("termsandcondition.point58")}</li>
          </ul>

          <h2 className="mt-12 text-xl font-bold">
            {i18n.t("termsandcondition.title11")}
          </h2>
          <ul className="ml-4 mt-2 list-disc">
            <li>{i18n.t("termsandcondition.point59")}</li>
            <li>{i18n.t("termsandcondition.point60")}</li>
            <li>{i18n.t("termsandcondition.point61")}</li>
            <li>{i18n.t("termsandcondition.point62")}</li>
            <li>{i18n.t("termsandcondition.point63")}</li>
            <li>{i18n.t("termsandcondition.point64")}</li>
            <li>{i18n.t("termsandcondition.point65")}</li>
            <li>{i18n.t("termsandcondition.point66")}</li>
            <li>{i18n.t("termsandcondition.point67")}</li>
            <li>{i18n.t("termsandcondition.point68")}</li>
          </ul>
        </div>
        <Footer history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(TermsConditions);
