import i18n from "i18next";
import React, { Component } from "react";
import { handleNavigation } from "../../utils/navigator";
import Button from "../base/Button";

interface Props {
  history: any;
}

export default class CTA extends Component<Props> {
  handleNavigate = (type: string) => {
    if (type === "FAQ") {
      handleNavigation(this.props.history, "/faqs");
    }
  };

  render() {
    return (
      <div className="bg-custom-red-600 w-full">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Volunteer Requirement</span>
          </h2>
          <ul className="mt-4 ml-4 text-lg text-left leading-6 text-white list-decimal">
            <li> {i18n.t("home.volunteerreqpoint1")}</li>
            <li> {i18n.t("home.volunteerreqpoint2")}</li>
            <li> {i18n.t("home.volunteerreqpoint3")}</li>
            <li> {i18n.t("home.volunteerreqpoint4")}</li>
          </ul>
          <Button
            className="mt-6 mx-auto"
            text={i18n.t("home.learnmorebtn")}
            type="light"
            onClick={this.handleNavigate.bind(this, "FAQ")}
          ></Button>
        </div>
      </div>
    );
  }
}
