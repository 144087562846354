import CenterModel from "../../models/Center";
import firebase from "../../utils/firebase";

let centersSocket: any = null;
export const getCenters = (state: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      if (!centersSocket && state) {
        let centersList: CenterModel[] = [];

        centersSocket = firebase
          .firestore()
          .collection("centers")
          .where("centerAddress.state", "==", state)
          .onSnapshot((querySnapshot) => {
            centersList = [];
            querySnapshot.forEach((doc) => {
              centersList.push(doc.data() as CenterModel);
            });

            dispatch({
              type: "UPDATE_CENTERS",
              payload: {
                centers: centersList,
              },
            });
          });
      }
    } catch (err) {}
  };
};

export interface CenterRes {
  err: string;
  data: CenterModel | null;
}

export const getCenterDetails = async (id: string) => {
  let centersRes: CenterRes = {
    err: "",
    data: null,
  };

  try {
    const centersQuery = await firebase
      .firestore()
      .collection("centers")
      .doc(id)
      .get();
    if (centersQuery.exists) {
      const bookingsDetail = centersQuery.data() as CenterModel;
      centersRes.data = bookingsDetail;
    } else {
      centersRes.err = "Cannot be found";
    }
  } catch (err) {
    centersRes.err = err.message;
  }

  return centersRes;
};

export const clearCentersSocket = () => {
  if (centersSocket) {
    centersSocket();
    centersSocket = null;
  }
};
