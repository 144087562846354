import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Table } from "antd";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import TextInput from "../components/TextInput";
import WindowResizer from "../components/WindowResizer";
import ResourcesModel from "../models/Resources";
import NavigatorSecurity from "../navigator/NavigatorSecurity";
import { getResources } from "../store/actions/resActions";
import { resStateInterface } from "../store/reducers/resReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  resStore: resStateInterface;
  getResources: () => void;
}

class Resources extends Component<Props> {
  state = {
    searchText: "",
  };

  columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
  ];

  componentDidMount() {
    this.props.getResources();
  }

  handleBackProfile = () => {
    handleNavigation(this.props.history, "/profile");
  };

  handleFilter = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleResourceSelected = (e: any) => {
    return {
      onClick: () => {
        this.props.resStore.resources.map((eachResource: ResourcesModel) => {
          if (eachResource.id === e.key) {
            window.open(eachResource.link, "_blank");
          }
          return null;
        });
      },
    };
  };

  renderFilteredData = () => {
    let listView: any = [];

    if (this.props.resStore.resources.length > 0) {
      this.props.resStore.resources.map((eachResources) =>
        listView.push({
          key: eachResources.id,
          title: eachResources.title,
          description: eachResources.description,
        })
      );
    }

    if (this.state.searchText) {
      const filteredData = listView.filter((o: []) =>
        Object.keys(o).some((k: any) =>
          String(o[k])
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        )
      );
      listView = filteredData;
    }

    return listView;
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <NavigatorSecurity />
        <Header history={this.props.history} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
            <RowContainer style={{ marginBottom: 10 }}>
              <ArrowLeftIcon
                className="text-custom-red-600 cursor-pointer h-6 w-6 mr-2"
                onClick={this.handleBackProfile}
              />
              <h1 className="text-2xl font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
                {i18n.t("profile.learningResource")}
              </h1>
            </RowContainer>
            <RowContainer
              style={{
                marginBottom: 20,
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <TextInput
                width={480}
                height={40}
                placeholder="Search for a resource..."
                background={COLOR.WHITE}
                borderRadius={5}
                value={this.state.searchText}
                onChange={this.handleFilter}
              />
            </RowContainer>
            <Table
              style={{
                width: "100%",
                cursor: "pointer",
              }}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              onRow={this.handleResourceSelected}
            />
          </div>
        </VerticalContainer>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    resStore: state.resStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getResources: () => dispatch(getResources()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
