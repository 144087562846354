import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import HomeAbout from "../assets/home_about.webp";
import Button from "../components/base/Button";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import WindowResizer from "../components/WindowResizer";
import {
  resetPasswordWithOob,
  verifyEmailLink,
  verifyOobCode,
} from "../store/actions/authActions";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  utilsStore: utilsStateInterface;
}

interface State {
  oobCode: string;
  oobCodeError: string;
  email: string;
  success: boolean;
  loading: boolean;
  password: string;
  passwordError: string;
  mode: string;
}

class ResetPassword extends Component<Props> {
  state: State = {
    oobCode: "",
    oobCodeError: "",
    email: "",
    success: false,
    loading: true,
    password: "",
    passwordError: "",
    mode: "",
  };

  componentDidMount() {
    const mode = this.handleGetParameterByName("mode");
    const oobCode = this.handleGetParameterByName("oobCode");
    if ((mode === "resetPassword" || mode === "verifyEmail") && oobCode) {
      this.setState(
        {
          oobCode: oobCode,
          mode: mode,
        },
        async () => {
          try {
            if (mode === "resetPassword") {
              const email = await verifyOobCode(this.state.oobCode);
              this.setState({
                email: email,
              });
            } else if (mode === "verifyEmail") {
              await verifyEmailLink(this.state.oobCode);
              this.setState({ success: true });
            }
          } catch (err) {
            this.setState({
              oobCodeError: err.message,
            });
          }
          this.setState({ loading: false });
        }
      );
    } else {
      this.setState({
        loading: false,
        oobCodeError: "notFound",
      });
    }
  }

  handleNavigateBack = () => {
    handleNavigation(this.props.history, "/");
  };

  handleResetPassword = () => {
    if (this.state.password.replace(/\s/g, "").length < 6)
      this.setState({
        passwordError: i18n.t("auth.passwordError"),
      });
    else
      this.setState(
        {
          loading: true,
        },
        async () => {
          try {
            resetPasswordWithOob(
              this.state.oobCode,
              this.state.password.replace(/\s/g, "")
            );
            this.setState({
              success: true,
            });
          } catch (err) {
            this.setState({
              passwordError: err.message,
            });
          }
          this.setState({ loading: false });
        }
      );
  };

  handleGetParameterByName(name: string) {
    //eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(this.props.location.search);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  handleChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  renderAuthActions = () => {
    let title = "";
    let description = "";
    let button = "";
    let buttonFunction: () => void = () => {};
    let input: any = null;

    if (this.state.oobCodeError) {
      title = i18n.t("auth.codeError");
      description = i18n.t("auth.actionError");
      button = i18n.t("auth.backToHome");
      buttonFunction = this.handleNavigateBack;
    } else if (this.state.success) {
      if (this.state.mode === "resetPassword") {
        title = i18n.t("auth.resetPassword");
        description = i18n.t("auth.resetPasswordSuccess");
        button = i18n.t("auth.backToHome");
        buttonFunction = this.handleNavigateBack;
      } else {
        title = i18n.t("auth.emailVerify");
        description = i18n.t("auth.emailVerifySuccess");
        button = i18n.t("auth.backToHome");
        buttonFunction = this.handleNavigateBack;
      }
    } else if (this.state.mode === "resetPassword") {
      title = i18n.t("auth.resetPassword");
      description = i18n.t("auth.email") + " : " + this.state.email;
      button = i18n.t("auth.resetPassword");
      buttonFunction = this.handleResetPassword;
      input = {
        placeholder: i18n.t("auth.password"),
        id: "password",
        password: true,
        value: this.state.password,
        error: this.state.passwordError,
      };
    }

    return (
      <div>
        <div className="mt-20">
          <div className="mt-6 sm:max-w-xl">
            <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {title}
            </h1>
            <p className="mt-6 text-xl text-gray-500">{description}</p>
          </div>

          <div className="mt-10 sm:max-w-lg sm:w-full sm:flex">
            {input && (
              <div className="min-w-0 flex-1">
                <label htmlFor="hero_email" className="sr-only">
                  Password
                </label>
                <Input
                  id={input.id}
                  placeholder={input.placeholder}
                  value={input.value}
                  onChange={this.handleChange}
                  error={input.error}
                  type="password"
                />
              </div>
            )}
            <div className="mt-4 sm:mt-0 sm:ml-3">
              <Button text={button} type="normal" onClick={buttonFunction} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    return (
      <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            {this.renderAuthActions()}
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              {this.renderSvg()}
              <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={HomeAbout}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSvg = () => {
    return (
      <div className="hidden sm:block">
        <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
        <svg
          className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
          width={404}
          height={392}
          fill="none"
          viewBox="0 0 404 392"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={392}
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </svg>
      </div>
    );
  };

  render() {
    return (
      <VerticalContainer className="w-full">
        <Overlay
          loading={this.state.loading}
          text="Please wait while we are verifying your account"
        />
        <WindowResizer />
        <Header history={this.props.history} />
        {this.renderContent()}
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(ResetPassword);
