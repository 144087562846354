import {
  ChartBarIcon,
  DocumentReportIcon,
  HeartIcon,
  InformationCircleIcon,
  OfficeBuildingIcon,
  UserIcon,
} from "@heroicons/react/outline";
import i18n from "i18next";
import React, { Component } from "react";
import HomeHealth from "../../assets/home_healthcare_steps.webp";
import HomeScope from "../../assets/home_job_scope.webp";
import HomeNonHealth from "../../assets/home_nonhealthcare_steps.webp";

export default class VolunteerSteps extends Component {
  renderSvg = (direction: string, mobile: boolean) => {
    if (mobile) {
      return (
        <svg
          className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
        >
          <defs>
            <pattern
              id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={784}
            height={404}
            fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
          />
        </svg>
      );
    } else {
      if (direction === "right") {
        return (
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>
        );
      } else if (direction === "left") {
        return (
          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        );
      }
    }
  };

  render() {
    const nonHealthSteps = [
      {
        id: 1,
        name: i18n.t("home.step1NonHealthcare"),
        description: [
          i18n.t("home.step1NonHealthcarepoint1"),
          i18n.t("home.step1NonHealthcarepoint2"),
          i18n.t("home.step1NonHealthcarepoint3"),
          i18n.t("home.step1NonHealthcarepoint4"),
          i18n.t("home.step1NonHealthcarepoint5"),
        ],
        icon: UserIcon,
      },
      {
        id: 2,
        name: i18n.t("home.step2NonHealthcare"),
        description: [
          i18n.t("home.step2NonHealthcarepoint1"),
          i18n.t("home.step2NonHealthcarepoint2"),
          i18n.t("home.step2NonHealthcarepoint3"),
          i18n.t("home.step2NonHealthcarepoint4"),
        ],
        icon: OfficeBuildingIcon,
      },
      {
        id: 3,
        name: i18n.t("home.step3NonHealthcare"),
        description: [
          i18n.t("home.step3NonHealthcarepoint1"),
          i18n.t("home.step3NonHealthcarepoint2"),
        ],
        icon: HeartIcon,
      },
    ];

    const healthSteps = [
      {
        id: 1,
        name: i18n.t("home.step1Healthcare"),
        description: [
          i18n.t("home.step1Healthcarepoint1"),
          i18n.t("home.step1Healthcarepoint2"),
          i18n.t("home.step1Healthcarepoint3"),
        ],
        icon: UserIcon,
      },
      {
        id: 2,
        name: i18n.t("home.step2Healthcare"),
        description: [
          i18n.t("home.step2Healthcarepoint1"),
          i18n.t("home.step2Healthcarepoint2"),
          i18n.t("home.step2Healthcarepoint3"),
        ],
        icon: ChartBarIcon,
      },
      {
        id: 3,
        name: i18n.t("home.step3Healthcare"),
        description: [
          i18n.t("home.step3Healthcarepoint1"),
          i18n.t("home.step3Healthcarepoint2"),
        ],
        icon: DocumentReportIcon,
      },
      {
        id: 4,
        name: i18n.t("home.step4Healthcare"),
        description: [
          i18n.t("home.step4Healthcarepoint1"),
          i18n.t("home.step4Healthcarepoint2"),
        ],
        icon: HeartIcon,
      },
    ];

    const volunteerScope = [
      {
        id: 1,
        name: i18n.t("home.genvolunteerTitle"),
        description: [
          i18n.t("home.genvolunteerpoint1"),
          i18n.t("home.genvolunteerpoint2"),
          i18n.t("home.genvolunteerpoint3"),
          i18n.t("home.genvolunteerpoint4"),
          i18n.t("home.genvolunteerpoint5"),
          i18n.t("home.genvolunteerpoint6"),
        ],
        icon: InformationCircleIcon,
      },
      {
        id: 2,
        name: i18n.t("home.medvolunteerTitle"),
        description: [
          i18n.t("home.medvolunteerpoint1"),
          i18n.t("home.medvolunteerpoint2"),
          i18n.t("home.medvolunteerpoint3"),
          i18n.t("home.medvolunteerpoint4"),
          i18n.t("home.medvolunteerpoint5"),
        ],
        icon: HeartIcon,
      },
    ];

    return (
      <div className="py-16 bg-gray-50 w-full overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          {this.renderSvg("right", false)}
          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                {i18n.t("home.stepNonHealthcareTitle")}
              </h3>
              <dl className="mt-10 space-y-10">
                {nonHealthSteps.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <ul className="mt-2 ml-20 text-base text-gray-500 list-decimal">
                      {item.description.map((eachDescription, index) => (
                        <li key={index}>{eachDescription}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <img
                className="relative mx-auto rounded"
                width={490}
                src={HomeNonHealth}
                alt=""
              />
            </div>
          </div>

          {this.renderSvg("left", false)}
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  {i18n.t("home.stepHealthcareTitle")}
                </h3>
                <dl className="mt-10 space-y-10">
                  {healthSteps.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red-500 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {item.name}
                        </p>
                      </dt>
                      <ul className="mt-2 ml-20 text-base text-gray-500 list-decimal">
                        {item.description.map((eachDescription, index) => (
                          <li key={index}>{eachDescription}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <img
                  className="relative mx-auto rounded"
                  width={490}
                  src={HomeHealth}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                {i18n.t("home.jobscopeTitle")}
              </h3>
              <dl className="mt-10 space-y-10">
                {volunteerScope.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <ul className="mt-2 ml-20 text-base text-gray-500 list-decimal">
                      {item.description.map((eachDescription, index) => (
                        <li key={index}>{eachDescription}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              {this.renderSvg("right", true)}
              <img
                className="relative mx-auto rounded"
                width={490}
                src={HomeScope}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
