import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
}

class NavigatorSecurity extends Component<Props> {
  render() {
    if (!this.props.authStore.userLoading) {
      if (!this.props.authStore.userAuth) {
        return <Redirect to="/" />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(NavigatorSecurity);
