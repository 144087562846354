import { Col, Empty, Pagination, Row, Spin } from "antd";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import TextInput from "../components/TextInput";
import WindowResizer from "../components/WindowResizer";
import NavigatorSecurity from "../navigator/NavigatorSecurity";
import { getCenters } from "../store/actions/centerActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  centerStore: centerStateInterface;
  authStore: authStateInterface;
  getCenters: (state: string) => void;
}

const paginationSize = 6;

class Centers extends Component<Props> {
  state = {
    selectedPage: 0,
    centerCard: [],
    searchText: "",
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.authStore.user) !==
        JSON.stringify(prevProps.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleNavigate = (type: string, id: string) => {
    if (type === "center") {
      handleNavigation(this.props.history, "/center/" + id);
    }
  };

  handlePaginationChange = (page: number) => {
    this.setState({
      selectedPage: page - 1,
    });
    window.scrollTo(0, 0);
  };

  handleGetPreviewData = async () => {
    this.props.getCenters(this.props.authStore.user?.address.state ?? "");
  };

  handleFilter = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleFilteredData = () => {
    let data: any = [];

    if (this.props.centerStore.centers) {
      this.props.centerStore.centers.map(function (value) {
        data.push({
          id: value.id,
          centerName: value.centerName,
          description: value.description,
          state: value.centerAddress.state,
          district: value.centerAddress.district,
          street: value.centerAddress.street,
          poscode: value.centerAddress.poscode,
          centerEmail: value.centerEmail,
          centerContactNo: value.centerContactNo,
          imageToken: value.imageToken,
          centerType: value.centerType,
        });
        return data;
      });
    }

    if (this.state.searchText) {
      const filteredData = data.filter((o: []) =>
        Object.keys(o).some((k: any) =>
          String(o[k])
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        )
      );
      data = filteredData;
    } else {
      const currentStep = this.state.selectedPage * paginationSize;
      data = data.splice(currentStep, paginationSize);
    }

    return data;
  };

  renderCenters = () => {
    let listView: any = [];
    const centerCard = this.handleFilteredData();
    let imageUrl = "";

    centerCard.map((eachPlace: any, index: number) => {
      if (eachPlace.imageToken) {
        const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
        const storageBucketId = isProd
          ? process.env.REACT_APP_PROD_STORAGE_BUCKET
          : process.env.REACT_APP_DEV_STORAGE_BUCKET;
        imageUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/centers%2F${eachPlace.id}%2Fimage.jpg?alt=media&token=${eachPlace.imageToken}`;

        let state: string = "";
        if (this.props.authStore.user) {
          state = this.props.authStore.user.address.state;
        }

        if (state !== eachPlace.state) {
          return null;
        }

        listView.push(
          <Col key={index} lg={8} sm={12} span={24}>
            <VerticalContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
                cursor: "pointer",
                marginTop: 20,
              }}
              onClick={this.handleNavigate.bind(this, "center", eachPlace.id)}
            >
              <img
                src={imageUrl}
                alt="Center"
                style={{ height: 200, objectFit: "cover", width: "100%" }}
              />
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                  width: "100%",
                  padding: "20px 0px",
                }}
              >
                <Title3 style={{ marginBottom: 0 }}>
                  {eachPlace["centerName"]}
                </Title3>
                <Body style={{ marginBottom: 5 }}>
                  Address: {eachPlace.street}, {eachPlace.district},
                  {eachPlace.poscode}, {eachPlace.state}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  Email: {eachPlace["centerEmail"]}
                </Body>
                <Body>Contact No: {eachPlace["centerContactNo"]}</Body>
              </VerticalContainer>
            </VerticalContainer>
          </Col>
        );
      }

      return null;
    });

    if (listView.length <= 0) {
      if (this.props.centerStore.loading) {
        listView.push(
          <VerticalContainer
            key="spinner"
            style={{ width: "100%", padding: "80px 0px" }}
          >
            <Spin size="large" />
          </VerticalContainer>
        );
      } else {
        listView.push(
          <VerticalContainer
            key="dataEmpty"
            style={{ width: "100%", padding: "80px 0px" }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </VerticalContainer>
        );
      }
    }

    return listView;
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <Header history={this.props.history} />
        <WindowResizer />
        <NavigatorSecurity />
        <VerticalContainer
          style={{
            width: "100%",
            paddingBottom: 30,
          }}
        >
          <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
            <h1 className="text-2xl mb-4 font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
              {i18n.t("centers.search")}
            </h1>
            <RowContainer
              style={{
                marginBottom: 20,
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <TextInput
                width={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 300
                    : 500
                }
                placeholder="Center Name/Address"
                value={this.state.searchText}
                onChange={this.handleFilter}
              />
            </RowContainer>
            {this.props.utilsStore.deviceType === DeviceType.Mobile && (
              <RowContainer
                style={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              ></RowContainer>
            )}
            <Row
              style={{
                width: "100%",
                overflow: "hidden",
                overflowY: "auto",
              }}
              gutter={8}
            >
              {this.renderCenters()}
            </Row>
            <div className="flex w-full mt-5">
              <div className="flex-grow" />
              <Pagination
                disabled={this.state.searchText ? true : false}
                current={this.state.selectedPage + 1}
                pageSize={paginationSize}
                onChange={this.handlePaginationChange}
                total={this.props.centerStore.centers.length}
              />
            </div>
          </div>
        </VerticalContainer>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    centerStore: state.centerStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCenters: (state: string) => dispatch(getCenters(state)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Centers);
