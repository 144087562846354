import {
  CheckCircleIcon,
  CheckIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Col, Modal, Radio, Row } from "antd";
import i18n from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Button from "../components/Button";
import WindowResizer from "../components/WindowResizer";
import { updateQuizPassed } from "../store/actions/authActions";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, NumberList } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

interface Question {
  title: string;
  titleI?: string;
  titleII?: string;
  titleIII?: string;
  titleIV?: string;
  a: string;
  b: string;
  c?: string;
  d?: string;
  answer: string;
}

interface State {
  visible: string;
  questions: Question[];
  answers: { [key: string]: string };
  answersError: {
    [key: string]: string;
  };
}

const listStyle = {
  marginLeft: 8,
  display: "block",
};

class Quiz extends Component<Props> {
  state: State = {
    visible: "",
    questions: [
      {
        title: i18n.t("quiz.question1"),
        a: i18n.t("quiz.question1A"),
        b: i18n.t("quiz.question1B"),
        c: i18n.t("quiz.question1C"),
        d: i18n.t("quiz.question1D"),
        answer: "a",
      },
      {
        title: i18n.t("quiz.question2"),
        a: i18n.t("quiz.question2A"),
        b: i18n.t("quiz.question2B"),
        c: i18n.t("quiz.question2C"),
        d: i18n.t("quiz.question2D"),
        answer: "d",
      },
      {
        title: i18n.t("quiz.question3"),
        titleI: i18n.t("quiz.question3I"),
        titleII: i18n.t("quiz.question3II"),
        titleIII: i18n.t("quiz.question3III"),
        titleIV: i18n.t("quiz.question3IV"),
        a: i18n.t("quiz.question3A"),
        b: i18n.t("quiz.question3B"),
        c: i18n.t("quiz.question3C"),
        d: i18n.t("quiz.question3D"),
        answer: "a",
      },
      {
        title: i18n.t("quiz.question4"),
        a: i18n.t("quiz.question4A"),
        b: i18n.t("quiz.question4B"),
        c: i18n.t("quiz.question4C"),
        d: i18n.t("quiz.question4D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question5"),
        a: i18n.t("quiz.question5A"),
        b: i18n.t("quiz.question5B"),
        c: i18n.t("quiz.question5C"),
        d: i18n.t("quiz.question5D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question6"),
        a: i18n.t("quiz.question6A"),
        b: i18n.t("quiz.question6B"),
        c: i18n.t("quiz.question6C"),
        d: i18n.t("quiz.question6D"),
        answer: "c",
      },

      {
        title: i18n.t("quiz.question7"),
        a: i18n.t("quiz.question7A"),
        b: i18n.t("quiz.question7B"),
        c: i18n.t("quiz.question7C"),
        d: i18n.t("quiz.question7D"),
        answer: "c",
      },
      {
        title: i18n.t("quiz.question8"),
        a: i18n.t("quiz.question8A"),
        b: i18n.t("quiz.question8B"),
        c: i18n.t("quiz.question8C"),
        d: i18n.t("quiz.question8D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question9"),
        a: i18n.t("quiz.question9A"),
        b: i18n.t("quiz.question9B"),
        c: i18n.t("quiz.question9C"),
        d: i18n.t("quiz.question9D"),
        answer: "a",
      },
      {
        title: i18n.t("quiz.question10"),
        a: i18n.t("quiz.question10A"),
        b: i18n.t("quiz.question10B"),
        c: i18n.t("quiz.question10C"),
        d: i18n.t("quiz.question10D"),
        answer: "d",
      },
      {
        title: i18n.t("quiz.question11"),
        a: i18n.t("quiz.question11A"),
        b: i18n.t("quiz.question11B"),
        c: i18n.t("quiz.question11C"),
        d: i18n.t("quiz.question11D"),
        answer: "d",
      },
      {
        title: i18n.t("quiz.question12"),
        titleI: i18n.t("quiz.question12I"),
        titleII: i18n.t("quiz.question12II"),
        titleIII: i18n.t("quiz.question12III"),
        titleIV: i18n.t("quiz.question12IV"),
        a: i18n.t("quiz.question12A"),
        b: i18n.t("quiz.question12B"),
        c: i18n.t("quiz.question12C"),
        d: i18n.t("quiz.question12D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question13"),
        a: i18n.t("quiz.question13A"),
        b: i18n.t("quiz.question13B"),
        c: i18n.t("quiz.question13C"),
        d: i18n.t("quiz.question13D"),
        answer: "c",
      },
      {
        title: i18n.t("quiz.question14"),
        a: i18n.t("quiz.question14A"),
        b: i18n.t("quiz.question14B"),
        c: i18n.t("quiz.question14C"),
        d: i18n.t("quiz.question14D"),
        answer: "d",
      },
      {
        title: i18n.t("quiz.question15"),
        a: i18n.t("quiz.question15A"),
        b: i18n.t("quiz.question15B"),
        c: i18n.t("quiz.question15C"),
        d: i18n.t("quiz.question15D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question16"),
        a: i18n.t("quiz.question16A"),
        b: i18n.t("quiz.question16B"),
        c: i18n.t("quiz.question16C"),
        d: i18n.t("quiz.question16D"),
        answer: "a",
      },
      {
        title: i18n.t("quiz.question17"),
        a: i18n.t("quiz.question17A"),
        b: i18n.t("quiz.question17B"),
        c: i18n.t("quiz.question17C"),
        d: i18n.t("quiz.question17D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question18"),
        a: i18n.t("quiz.question18A"),
        b: i18n.t("quiz.question18B"),
        c: i18n.t("quiz.question18C"),
        d: i18n.t("quiz.question18D"),
        answer: "c",
      },
      {
        title: i18n.t("quiz.question19"),
        a: i18n.t("quiz.question19A"),
        b: i18n.t("quiz.question19B"),
        c: i18n.t("quiz.question19C"),
        d: i18n.t("quiz.question19D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question20"),
        a: i18n.t("quiz.question20A"),
        b: i18n.t("quiz.question20B"),
        c: i18n.t("quiz.question20C"),
        d: i18n.t("quiz.question20D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question21"),
        a: i18n.t("quiz.question21A"),
        b: i18n.t("quiz.question21B"),
        c: i18n.t("quiz.question21C"),
        d: i18n.t("quiz.question21D"),
        answer: "b",
      },
      {
        title: i18n.t("quiz.question22"),
        a: i18n.t("quiz.question22A"),
        b: i18n.t("quiz.question22B"),
        c: i18n.t("quiz.question22C"),
        d: i18n.t("quiz.question22D"),
        answer: "a",
      },
      {
        title: i18n.t("quiz.question23"),
        a: i18n.t("quiz.question23A"),
        b: i18n.t("quiz.question23B"),
        c: i18n.t("quiz.question23C"),
        d: i18n.t("quiz.question23D"),
        answer: "d",
      },
      {
        title: i18n.t("quiz.question24"),
        a: i18n.t("quiz.question24A"),
        b: i18n.t("quiz.question24B"),
        c: i18n.t("quiz.question24C"),
        d: i18n.t("quiz.question24D"),
        answer: "c",
      },
      {
        title: i18n.t("quiz.question25"),
        a: i18n.t("quiz.question25A"),
        b: i18n.t("quiz.question25B"),
        c: i18n.t("quiz.question25C"),
        d: i18n.t("quiz.question25D"),
        answer: "a",
      },
    ],
    answers: {
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
      12: "",
      13: "",
      14: "",
      15: "",
      16: "",
      17: "",
      18: "",
      19: "",
      20: "",
      21: "",
      22: "",
      23: "",
      24: "",
      25: "",
    },
    answersError: {
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
      12: "",
      13: "",
      14: "",
      15: "",
      16: "",
      17: "",
      18: "",
      19: "",
      20: "",
      21: "",
      22: "",
      23: "",
      24: "",
      25: "",
    },
  };

  handleChangeRadio = (indexString: string, choice: string, e: any) => {
    let tempAnswer = JSON.parse(JSON.stringify(this.state.answers));
    tempAnswer[indexString as keyof typeof tempAnswer] = choice;
    this.setState({
      answers: tempAnswer,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "profile") {
      handleNavigation(this.props.history, "/profile");
    }
  };

  handleSubmit = () => {
    let tempAnswersError = JSON.parse(JSON.stringify(this.state.answersError));

    this.state.questions.map((eachQuestion, index) => {
      if (eachQuestion.answer === this.state.answers[index + 1]) {
        tempAnswersError[index + 1] = "correct";
      } else {
        tempAnswersError[index + 1] = "wrong";
      }
      return null;
    });

    this.setState(
      {
        answersError: tempAnswersError,
      },
      () => {
        let correctCounter = 0;
        Object.values(this.state.answersError).map((eachValue) => {
          if (eachValue === "correct") {
            correctCounter += 1;
          }
          return null;
        });

        const minNumberToPass = 18;
        if (correctCounter >= minNumberToPass) {
          this.setState(
            {
              visible: "passed",
            },
            () => {
              updateQuizPassed();
            }
          );
        } else {
          this.setState({
            visible: "failed",
          });
        }
      }
    );
  };

  renderRadioButton = (indexString: string, title: string, choice: string) => {
    let checked = false;
    if (indexString in this.state.answers) {
      const selectedAnswer = this.state.answers[indexString];
      if (choice === selectedAnswer) {
        checked = true;
      }
    }

    return (
      <Row
        style={{
          width: "100%",
          color: COLOR.GREY,
          fontFamily: "dm-sans",
          fontWeight: 500,
          fontSize: "14px",
          marginTop: 5,
        }}
      >
        <Col span={2} md={1}>
          <Radio
            style={{ height: "30px" }}
            checked={checked}
            onChange={this.handleChangeRadio.bind(this, indexString, choice)}
          ></Radio>
        </Col>
        <Col span={21} md={22}>
          {title}
        </Col>
      </Row>
    );
  };

  renderQuestions = (index: string) => {
    const detail = this.state.questions[Number(index) - 1];
    let answerFeedback: string = "";
    let indexString = index.toString();
    let feedbackContent = null;
    if (indexString in this.state.answersError) {
      answerFeedback = this.state.answersError[indexString];
      if (answerFeedback === "correct") {
        feedbackContent = (
          <>
            <CheckIcon className="text-green-600 w-5 h-5 mr-2" />
            <Body style={{ marginBottom: 0, color: COLOR.GREEN }}>
              {i18n.t("quiz.correctAnswer")}
            </Body>
          </>
        );
      } else if (answerFeedback === "wrong") {
        feedbackContent = (
          <>
            <XIcon className="text-custom-red-600 w-5 h-5 mr-2" />
            <Body style={{ marginBottom: 0, color: COLOR.RED }}>
              {i18n.t("quiz.incorrectAnswer")}
            </Body>
          </>
        );
      }
    }

    return (
      <VerticalContainer
        style={{
          width: "100%",
          alignItems: "flex-start",
          marginTop: index.toString() === "1" ? 40 : 20,
        }}
      >
        <Body
          style={{
            textAlign: "justify",
            fontWeight: "bold",
            color: "#000",
            marginBottom: 10,
          }}
        >
          {detail.title}
        </Body>
        {detail.titleI && (
          <NumberList style={{ textAlign: "justify", marginTop: 0 }}>
            <ol style={{ paddingLeft: "1.2em" }} type="i">
              <li>
                <span style={listStyle}>{detail.titleI}</span>
              </li>
              <li>
                <span style={listStyle}>{detail.titleII}</span>
              </li>
              <li>
                <span style={listStyle}>{detail.titleIII}</span>
              </li>
              <li>
                <span style={listStyle}>{detail.titleIV}</span>
              </li>
            </ol>
          </NumberList>
        )}
        {this.renderRadioButton(indexString, detail.a, "a")}
        {this.renderRadioButton(indexString, detail.b, "b")}
        {detail.c && this.renderRadioButton(indexString, detail.c, "c")}
        {detail.d && this.renderRadioButton(indexString, detail.d, "d")}
        <RowContainer style={{ marginTop: 10 }}>{feedbackContent}</RowContainer>
      </VerticalContainer>
    );
  };

  renderModal = () => {
    let modalInfo: any = [
      {
        title: i18n.t("quiz.passedTestTitle"),
        body: i18n.t("quiz.passedTestBody"),
        button2: i18n.t("quiz.goToProfile"),
      },
      {
        title: i18n.t("quiz.failedTestTitle"),
        body: i18n.t("quiz.failedTestBody"),
        button1: i18n.t("quiz.revisitAnswer"),
        button2: i18n.t("quiz.goToProfile"),
      },
    ];

    const detail = modalInfo[this.state.visible === "passed" ? 0 : 1];

    return (
      <Modal
        title={detail.title}
        visible={this.state.visible ? true : false}
        footer={null}
        closable={false}
        keyboard={false}
        maskClosable={false}
      >
        <VerticalContainer
          style={{
            alignItems: "flex-start",
          }}
        >
          <Body style={{ textAlign: "justify" }}>{detail.body}</Body>

          <RowContainer
            style={{
              alignSelf: "flex-end",
              marginTop: 40,
            }}
          >
            {detail.button1 && (
              <Button
                text={detail.button1}
                width="auto"
                small={true}
                onClick={() => {
                  this.setState({ visible: "" });
                }}
              />
            )}
            <Button
              margin={"0px 0px 0px 10px"}
              text={detail.button2}
              width="auto"
              small={true}
              onClick={this.handleNavigate.bind(this, "profile")}
            />
          </RowContainer>
        </VerticalContainer>
      </Modal>
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <Header history={this.props.history} />
        {this.renderModal()}
        <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
          <h1 className="text-2xl mb-3 font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
            {i18n.t("quiz.testName")}
          </h1>
          <Body style={{ marginBottom: 10 }}>
            {i18n.t("quiz.testDescription")}
          </Body>
          <RowContainer>
            <InformationCircleIcon className="text-custom-red-600 w-5 h-5 mr-1" />
            <Body style={{ marginBottom: 0 }}>
              {i18n.t("quiz.testInstructions")}
            </Body>
          </RowContainer>
          <RowContainer style={{ marginBottom: 10 }}>
            <CheckCircleIcon className="text-custom-red-600 w-5 h-5 mr-1" />
            <Body style={{ marginBottom: 0 }}>
              {i18n.t("quiz.testPassingScore")}
            </Body>
          </RowContainer>
          {Object.keys(this.state.answers).map((eachKey: string) =>
            this.renderQuestions(eachKey)
          )}
          <RowContainer
            style={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: 50,
              marginTop: 20,
            }}
          >
            <Button
              text={i18n.t("quiz.submitAnswer")}
              width="auto"
              small={true}
              onClick={this.handleSubmit}
            />
          </RowContainer>
        </div>
        <Footer history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Quiz);
