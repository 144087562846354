import { Select } from "antd";
import i18n from "i18next";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { Address, BankType, ProfessionType } from "../models/User";
import { editProfile, handleAuthCondition } from "../store/actions/authActions";
import { clearCentersSocket } from "../store/actions/centerActions";
import { handleNotification, sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, Footer } from "../styles/Typography";
import { getDistrictList, getStateList } from "../utils/districtList";

const { Option } = Select;

export interface UserAttributeEditorState {
  address: Address;
  mobileNo: string;
  bankName: keyof typeof BankType;
  bankAccountName: string;
  bankAccountNo: string;
  relationship: string;
  nameNextofKin: string;
  contactNoNextofKin: string;
}

export interface UserAttributeEditorStateError {
  addressError: {
    streetError: string;
    poscodeError: string;
  };
  mobileNoError: string;
  bankAccountNameError: string;
  bankAccountNoError: string;
  nameNextofKinError: string;
  relationshipError: string;
  contactNoNextofKinError: string;
}

interface State {
  userAttribute: UserAttributeEditorState;
  userAttributeError: UserAttributeEditorStateError;
}

interface Props {
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  handleLoading: (value: boolean) => void;
}

class ProfileEditor extends Component<Props> {
  state: State = {
    userAttribute: {
      address: {
        street: "",
        district: getDistrictList("")[0],
        poscode: "",
        state: getStateList()[0],
      },
      mobileNo: "",
      bankName: Object.keys(BankType)[0] as keyof typeof BankType,
      bankAccountName: "",
      bankAccountNo: "",
      nameNextofKin: "",
      relationship: "",
      contactNoNextofKin: "",
    },
    userAttributeError: {
      addressError: {
        streetError: "",
        poscodeError: "",
      },
      mobileNoError: "",
      bankAccountNoError: "",
      bankAccountNameError: "",
      nameNextofKinError: "",
      relationshipError: "",
      contactNoNextofKinError: "",
    },
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.authStore.user !== this.props.authStore.user &&
      this.props.authStore.user
    ) {
      if (
        prevProps.authStore.user &&
        this.props.authStore.user &&
        prevProps.authStore.user.address.state !==
          this.props.authStore.user.address.state
      ) {
        clearCentersSocket();
      }
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = () => {
    if (this.props.authStore.user) {
      let bankName: keyof typeof BankType = Object.keys(
        BankType
      )[0] as keyof typeof BankType;
      if (this.props.authStore.user.bankName) {
        bankName = this.props.authStore.user.bankName;
      }

      const userAttribute: UserAttributeEditorState = {
        address: this.props.authStore.user.address,
        mobileNo: this.props.authStore.user.mobileNo,
        bankName: bankName,
        bankAccountName: this.props.authStore.user.bankAccountName ?? "",
        bankAccountNo: this.props.authStore.user.bankAccountNo ?? "",
        nameNextofKin: this.props.authStore.user.nameNextofKin ?? "",
        relationship: this.props.authStore.user.relationship ?? "",
        contactNoNextofKin: this.props.authStore.user.contactNoNextofKin ?? "",
      };
      this.setState({
        userAttribute: userAttribute,
      });
    }
  };

  handleChange = (e: any) => {
    let originalUser = JSON.parse(JSON.stringify(this.state.userAttribute));
    originalUser[e.target.id] = e.target.value;
    this.setState({
      userAttribute: originalUser,
    });
  };

  handleAddressChange = (e: any) => {
    let originalUser = JSON.parse(JSON.stringify(this.state.userAttribute));

    originalUser["address"][e.target.id] = e.target.value;
    this.setState({
      userAttribute: originalUser,
    });
  };

  handleChangeAddressSelection = (type: string, value: any) => {
    const clonedUserAttribute = JSON.parse(
      JSON.stringify(this.state.userAttribute)
    );
    clonedUserAttribute["address"][type] = value;
    this.setState({
      userAttribute: clonedUserAttribute,
    });
  };

  handleChangeBankName = (value: any) => {
    const clonedUserAttribute = JSON.parse(
      JSON.stringify(this.state.userAttribute)
    );
    clonedUserAttribute["bankName"] = value;
    this.setState({
      userAttribute: clonedUserAttribute,
    });
  };

  handleCheckCondition = (typeList: string[]) => {
    const clonedUserAttributeError = JSON.parse(
      JSON.stringify(this.state.userAttributeError)
    );

    handleAuthCondition(
      this.state.userAttribute,
      clonedUserAttributeError,
      typeList
    );

    this.setState({
      userAttributeError: clonedUserAttributeError,
    });
  };

  handleSubmit = async () => {
    this.props.handleLoading(true);
    this.handleCheckCondition([
      "mobileNo",
      "street",
      "poscode",
      "state",
      "district",
      "bankAccountName",
      "bankAccountNo",
      "contactNoNextofKin",
      "relationship",
      "nameNextofKin",
    ]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.userAttributeError.mobileNoError &&
      !this.state.userAttributeError.addressError.poscodeError &&
      !this.state.userAttributeError.addressError.streetError &&
      !this.state.userAttributeError.bankAccountNameError &&
      !this.state.userAttributeError.bankAccountNoError &&
      !this.state.userAttributeError.nameNextofKinError &&
      !this.state.userAttributeError.relationshipError &&
      !this.state.userAttributeError.contactNoNextofKinError
        ? true
        : false;
    if (conditionPassed) {
      try {
        await editProfile({
          mobileNo: this.state.userAttribute.mobileNo.replace(/\s/g, ""),
          address: {
            street: this.state.userAttribute.address.street,
            district: this.state.userAttribute.address.district,
            poscode: this.state.userAttribute.address.poscode,
            state: this.state.userAttribute.address.state,
          },
          bankName: this.state.userAttribute.bankName,
          bankAccountNo: this.state.userAttribute.bankAccountNo,
          bankAccountName: this.state.userAttribute.bankAccountName,
          nameNextofKin: this.state.userAttribute.nameNextofKin,
          relationship: this.state.userAttribute.relationship,
          contactNoNextofKin: this.state.userAttribute.contactNoNextofKin,
        });
        handleNotification("success", "Profile Updated Sucessfully", "");
      } catch (err) {
        handleNotification(
          "warning",
          "Profile Could Not Be Updated",
          err.message
        );
      }
    } else {
      handleNotification("warning", "Profile Could Not Be Updated", "");
    }
    this.props.handleLoading(false);
  };

  renderProfessionContent = () => {
    if (
      this.props.authStore.user?.profession === Object.keys(ProfessionType)[1]
    ) {
      return (
        <>
          <Body style={{ marginBottom: 5, marginTop: 20 }}>
            {i18n.t("profileEditor.professionhealthid")}{" "}
          </Body>
          <TextInput
            id="professionHealthId"
            value={this.props.authStore.user?.professionHealthId}
            disabled={true}
            background={COLOR.LIGHTGREY}
            width="100%"
            placeholder={i18n.t("profileEditor.professionhealthid")}
          />
        </>
      );
    }
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%", alignItems: "flex-start" }}>
        {this.renderProfessionContent()}
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.organisationname")}
        </Body>
        <TextInput
          id="organisationName"
          value={this.props.authStore.user?.organisationName}
          disabled={true}
          width="100%"
          placeholder={i18n.t("profileEditor.organisationname")}
          background={COLOR.LIGHTGREY}
        />
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.organisationaddress")}
        </Body>
        <TextInput
          id="organisationAddress"
          value={this.props.authStore.user?.organisationAddress}
          disabled={true}
          width={"100%"}
          placeholder={i18n.t("profileEditor.organisationaddress")}
          background={COLOR.LIGHTGREY}
        />
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.mobileno")}
        </Body>
        <TextInput
          id="mobileNo"
          value={this.state.userAttribute.mobileNo}
          width="100%"
          placeholder="+60123456789"
          onChange={this.handleChange}
        />
        {this.state.userAttributeError.mobileNoError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.mobileNoError}
          </Footer>
        )}
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.address")}
        </Body>
        <TextInput
          id="street"
          value={this.state.userAttribute.address.street}
          width={"100%"}
          placeholder={i18n.t("profileEditor.fulladdress")}
          onChange={this.handleAddressChange}
        />
        {this.state.userAttributeError.addressError.streetError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.addressError.streetError}
          </Footer>
        )}
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.poscode")}
        </Body>
        <TextInput
          id="poscode"
          value={this.state.userAttribute.address.poscode}
          width="100%"
          placeholder={i18n.t("profileEditor.poscode")}
          onChange={this.handleAddressChange}
        />
        {this.state.userAttributeError.addressError.poscodeError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.addressError.poscodeError}
          </Footer>
        )}
        <RowContainer style={{ width: "100%", marginTop: 20 }}>
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>
              {i18n.t("profileEditor.state")}
            </Body>
            <Select
              id="state"
              value={this.state.userAttribute.address.state}
              onChange={this.handleChangeAddressSelection.bind(this, "state")}
              showSearch
              style={{
                flex: 1,
                width: "100%",
              }}
              optionFilterProp="value"
            >
              {getStateList().map((eachState: any, index: number) => (
                <Option key={index} value={eachState}>
                  {eachState}
                </Option>
              ))}
            </Select>
          </VerticalContainer>
          <div style={{ flex: 1 }} />
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>
              {i18n.t("profileEditor.district")}
            </Body>
            <Select
              id="district"
              value={this.state.userAttribute.address.district}
              onChange={this.handleChangeAddressSelection.bind(
                this,
                "district"
              )}
              showSearch
              style={{
                flex: 1,
                width: "100%",
              }}
              optionFilterProp="value"
            >
              {getDistrictList(this.state.userAttribute.address.state).map(
                (eachDistrict: string, index: number) => (
                  <Option key={index} value={eachDistrict}>
                    {eachDistrict}
                  </Option>
                )
              )}
            </Select>
          </VerticalContainer>
        </RowContainer>
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.bankName")}
        </Body>
        <Select
          id="state"
          value={BankType[this.state.userAttribute.bankName]}
          onChange={this.handleChangeBankName.bind(this)}
          showSearch
          style={{
            flex: 1,
            width: "100%",
          }}
          optionFilterProp="value"
        >
          {Object.keys(BankType).map((eachKey: string, index: number) => {
            const bankKey = eachKey as keyof typeof BankType;
            return (
              <Option key={eachKey} value={eachKey}>
                {BankType[bankKey]}
              </Option>
            );
          })}
        </Select>
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.bankAccountNo")}
        </Body>
        <TextInput
          id="bankAccountNo"
          value={this.state.userAttribute.bankAccountNo}
          width="100%"
          placeholder={i18n.t("profileEditor.bankAccountNo")}
          onChange={this.handleChange}
        />
        {this.state.userAttributeError.bankAccountNoError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.bankAccountNoError}
          </Footer>
        )}
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.bankAccountName")}
        </Body>
        <TextInput
          id="bankAccountName"
          value={this.state.userAttribute.bankAccountName}
          width="100%"
          placeholder={i18n.t("profileEditor.bankAccountName")}
          onChange={this.handleChange}
        />
        {this.state.userAttributeError.bankAccountNameError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.bankAccountNameError}
          </Footer>
        )}
        <RowContainer style={{ width: "100%", marginTop: 20 }}>
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>
              {i18n.t("profileEditor.nameNextofKin")}
            </Body>
            <TextInput
              id="nameNextofKin"
              value={this.state.userAttribute.nameNextofKin}
              width="100%"
              placeholder={i18n.t("profileEditor.nameNextofKin")}
              onChange={this.handleChange}
            />
            {this.state.userAttributeError.nameNextofKinError && (
              <Footer style={{ marginBottom: 0 }}>
                {this.state.userAttributeError.nameNextofKinError}
              </Footer>
            )}
          </VerticalContainer>
          <div style={{ flex: 1 }} />
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>
              {i18n.t("profileEditor.relationship")}
            </Body>
            <TextInput
              id="relationship"
              value={this.state.userAttribute.relationship}
              width="100%"
              placeholder={i18n.t("profileEditor.relationship")}
              onChange={this.handleChange}
            />
            {this.state.userAttributeError.relationshipError && (
              <Footer style={{ marginBottom: 0 }}>
                {this.state.userAttributeError.relationshipError}
              </Footer>
            )}
          </VerticalContainer>
        </RowContainer>
        <Body style={{ marginBottom: 5, marginTop: 20 }}>
          {i18n.t("profileEditor.contactNoNextofKin")}
        </Body>
        <TextInput
          id="contactNoNextofKin"
          value={this.state.userAttribute.contactNoNextofKin}
          width="100%"
          placeholder={i18n.t("profileEditor.contactNoNextofKin")}
          onChange={this.handleChange}
        />
        {this.state.userAttributeError.contactNoNextofKinError && (
          <Footer style={{ marginBottom: 0 }}>
            {this.state.userAttributeError.contactNoNextofKinError}
          </Footer>
        )}
        <RowContainer style={{ width: "100%", margin: "30px 0px" }}>
          <div style={{ flex: 1 }} />
          <Button
            text={i18n.t("profileEditor.updateBtn")}
            width={"auto"}
            small={true}
            onClick={this.handleSubmit}
          />
        </RowContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(ProfileEditor);
