import i18n from "i18next";
import React, { Component } from "react";

export default class Banner extends Component {
  render() {
    return (
      <div className="relative bg-custom-red-600 w-full z-50">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="text-xs sm:text-sm text-white">
              {i18n.t("header.banner")}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
