import React, { Component } from "react";
import { connect } from "react-redux";
import Banner from "../components/base/Banner";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import CenterType from "../components/marketing/CenterType";
import CTA from "../components/marketing/CTA";
import Hero from "../components/marketing/Hero";
import Stats from "../components/marketing/Stats";
import VolunteerSteps from "../components/marketing/VolunteerSteps";
import WindowResizer from "../components/WindowResizer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { VerticalContainer } from "../styles/Layout";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
}

class Home extends Component<Props> {
  render() {
    return (
      <VerticalContainer className="w-full">
        <WindowResizer />
        <Banner />
        <Header history={this.props.history} />
        <Hero history={this.props.history} />
        <Stats />
        <CenterType />
        <VolunteerSteps />
        <CTA history={this.props.history} />
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Home);
