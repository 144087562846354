import { Calendar, Col, List, Modal, Row, Select, Spin } from "antd";
import "antd/dist/antd.css";
import locale from "antd/es/date-picker/locale/en_GB";
import Avatar from "antd/lib/avatar/avatar";
import i18n from "i18next";
import moment from "moment";
import "moment/locale/en-gb";
import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Doses from "../assets/doses.png";
import General from "../assets/general.png";
import Healthworker from "../assets/healthworker.png";
import RoleEvening from "../assets/role_evening.png";
import RoleMorning from "../assets/role_morning.png";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import Button from "../components/Button";
import WindowResizer from "../components/WindowResizer";
import { VolunteerRoles, VolunteerShift } from "../models/Booking";
import CenterModel, { CenterType, Personnel } from "../models/Center";
import { ProfessionType } from "../models/User";
import NavigatorSecurity from "../navigator/NavigatorSecurity";
import {
  AvailableDate,
  BookRes,
  Choice,
  getBookings,
  getSelectedBookings,
  handleBookDates,
  returnAvailableDates,
} from "../store/actions/bookActions";
import { CenterRes, getCenterDetails } from "../store/actions/centerActions";
import { getMonday, handleNotification } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { bookStateInterface } from "../store/reducers/bookReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Footer, Title2, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  bookStore: bookStateInterface;
  match: any;
  getBookings: () => void;
  getSelectedBookings: (
    centerId: string,
    startDate: Date,
    endDate: Date
  ) => void;
}

type BookingChunkKey = keyof typeof BookingChunk;

enum BookingChunk {
  WHOLE = "Whole Week",
  FIRST = "Monday - Wednesday",
  SECOND = "Thursday - Friday",
  THIRD = "Saturday - Sunday",
}

interface State {
  loading: boolean;
  choices: Choice[];
  choiceError: string;
  center: CenterModel;
  selectedDate: Date;
  selectedChunk: BookingChunkKey | "";
  roles: keyof typeof VolunteerRoles;
}

interface DateCondition {
  err: string;
  choices: Choice[];
}

const { Option } = Select;
const iconStyle = {
  width: 40,
};
const { confirm } = Modal;

class EachCenter extends Component<Props> {
  state: State = {
    loading: false,
    choices: [],
    choiceError: "",
    center: {
      id: "",
      centerName: "",
      centerAddress: {
        street: "",
        district: "",
        poscode: "",
        state: "",
      },
      centerEmail: "",
      centerContactNo: "",
      imageToken: "",
      centerType: "Type 1" as keyof typeof CenterType,
      doses: 0,
      volHealth: 0,
      volGeneral: 0,
      description: "",
      personnelNeeded: [],
    },
    selectedDate: moment().toDate(),
    selectedChunk: "",
    roles: "" as keyof typeof VolunteerRoles,
  };

  componentDidMount = () => {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.authStore.user) !==
        JSON.stringify(prevProps.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  /**
   *
   * @param value
   * @returns DateCondition interface
   *
   *
   * 1. Check date is Monday
   * 2. Check user is authenticated
   * 2a. Check user is blacklisted
   * 3. Check user email has been verified
   * 4. Check user has passed through profile test
   * 5. If profession is health, check user has attended briefing & verified account
   * 6. Check date is future
   * 7. Check current user is available or not
   * 8. Check slots are available
   */
  handleSelectDateCondition = (value: any) => {
    let dateCondition: DateCondition = {
      err: "",
      choices: [],
    };

    //Condition 1
    if (value.weekday() !== 0 || !this.state.center) {
      dateCondition["err"] = i18n.t("eachCenter.condition1");
      return dateCondition;
    }

    //Condition 2
    if (!this.props.authStore.userAuth || !this.props.authStore.user) {
      dateCondition["err"] = i18n.t("eachCenter.condition2");
      return dateCondition;
    }

    //Condition 2a
    if (this.props.authStore.user.blacklisted) {
      dateCondition["err"] = i18n.t("eachCenter.condition2a");
      return dateCondition;
    }

    //Condition 3
    if (!this.props.authStore.userAuth?.emailVerified) {
      dateCondition["err"] = i18n.t("eachCenter.condition3");
      return dateCondition;
    }

    //Condition 4
    if (
      !this.props.authStore.user?.quizResult?.passed ||
      !this.props.authStore.user?.imageToken ||
      !this.props.authStore.user?.bankAccountNo ||
      !this.props.authStore.user?.nameNextofKin ||
      !this.props.authStore.user?.healthDeclaration?.name ||
      !this.props.authStore.user?.liabilityAgreement?.name ||
      !this.props.authStore.user?.secretAgreement?.name
    ) {
      dateCondition["err"] = i18n.t("eachCenter.condition4");
      return dateCondition;
    }
    if (this.props.authStore.user?.profession !== "HEALTH") {
      if (!this.props.authStore.user?.offerLetter?.name) {
        dateCondition["err"] = i18n.t("eachCenter.condition4");
        return dateCondition;
      }
    }

    //Condition 5
    if (
      this.props.authStore.user?.profession ===
      ("HEALTH" as keyof typeof ProfessionType)
    ) {
      if (
        !this.props.authStore.user?.attendanceBriefing ||
        !this.props.authStore.user?.professionHealthType ||
        !this.props.authStore.user?.verifiedHealthID
      ) {
        dateCondition["err"] = i18n.t("eachCenter.condition5");
        return dateCondition;
      }
    }

    //Condition 6
    const tempDate = new Date();
    tempDate.setHours(0, 0, 0, 0);
    // Allow booking for current week before Thursday
    let dayLimitCounter = -4;
    if (this.props.authStore.user.profession === "HEALTH") {
      dayLimitCounter = 4;
    }
    if (value.diff(moment(tempDate), "days") <= dayLimitCounter) {
      dateCondition["err"] = i18n.t("eachCenter.condition6");
      return dateCondition;
    }

    let userAvailable: boolean = true;
    if (this.props.bookStore.bookings.length > 0) {
      let mondaySlotOccupied = false;
      let thurSlotOccupied = false;
      let satSlotOccupied = false;
      this.props.bookStore.bookings.map((eachBooking) => {
        const startDate = moment(eachBooking.startDate.seconds * 1000);
        const thisMonday = startDate.startOf("week");

        if (value.isSame(moment(thisMonday), "date")) {
          if (this.props.authStore.user?.profession !== "HEALTH") {
            if (eachBooking.bookDate) {
              eachBooking.bookDate.map((eachBookDate) => {
                const currentDay = moment(eachBookDate.seconds * 1000).day();
                if (currentDay === 1 || currentDay === 2 || currentDay === 3) {
                  mondaySlotOccupied = true;
                }
                if (currentDay === 4 || currentDay === 5) {
                  thurSlotOccupied = true;
                }
                if (currentDay === 6 || currentDay === 0) {
                  satSlotOccupied = true;
                }
                return null;
              });

              if (mondaySlotOccupied && thurSlotOccupied && satSlotOccupied) {
                userAvailable = false;
              }
            }
          } else {
            userAvailable = false;
          }
        }
        return null;
      });
    }

    //Condition 7
    if (!userAvailable || this.props.bookStore.loading) {
      dateCondition["err"] = i18n.t("eachCenter.condition7");
      return dateCondition;
    }

    const result = [];
    const availableDates: AvailableDate = returnAvailableDates(
      this.state.center.personnelNeeded,
      this.props.bookStore.selectedBookings,
      this.state.roles
    );
    let bookingAvailable: number = 0;
    for (let i = 0; i < 7; i++) {
      const d = new Date(value);
      d.setDate(d.getDate() + i);
      const currentDate = moment(d).format("MM-DD-YYYY");

      if (
        Object.keys(availableDates).includes(currentDate) &&
        availableDates[currentDate].MOR + availableDates[currentDate].EVE > 0
      ) {
        let availableShift: keyof typeof VolunteerShift =
          "" as keyof typeof VolunteerShift;
        if (
          availableDates[currentDate].MOR > 0 &&
          availableDates[currentDate].EVE > 0
        ) {
          availableShift = "WHOLE";
        } else if (availableDates[currentDate].MOR <= 0) {
          availableShift = "EVE";
        } else if (availableDates[currentDate].EVE <= 0) {
          availableShift = "MOR";
        }
        if (availableShift) {
          bookingAvailable += 1;
          const defaultChoice: Choice = {
            date: d,
            answer: "MOR" as keyof typeof VolunteerShift,
            availableShift: availableShift,
          };
          result.push(defaultChoice);
        }
      }
    }

    //Condition 8
    if (bookingAvailable >= 1) {
      dateCondition["choices"] = result;
    } else {
      dateCondition["err"] = i18n.t("eachCenter.condition8");
    }
    return dateCondition;
  };

  handleSelectDate = async (value: any) => {
    const currentDate = moment(this.state.selectedDate);

    if (!currentDate.isSame(value, "month")) {
      const selectedMonthStart = moment(value).startOf("month");
      const selectedMonthEnd = moment(value).endOf("month");
      const startOfMonthOffset = selectedMonthStart.subtract(7, "d");
      const endOfMonthOffset = selectedMonthEnd.add(7, "d");

      this.props.getSelectedBookings(
        this.state.center.id,
        startOfMonthOffset.toDate(),
        endOfMonthOffset.toDate()
      );
    } else {
      const dateCondition: DateCondition =
        this.handleSelectDateCondition(value);
      if (dateCondition.err) {
        this.setState({
          choices: [],
          choiceError: dateCondition.err,
          selectedChunk: "",
        });
      } else {
        this.setState({
          choices: dateCondition.choices,
          choiceError: "",
          selectedChunk:
            this.props.authStore.user?.profession === "HEALTH" ? "WHOLE" : "",
        });
      }
    }

    this.setState({
      selectedDate: value.toDate(),
    });
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleGetPreviewData = async () => {
    if (this.handleGetParams()) {
      this.props.getBookings();
      const centerQuery: CenterRes = await getCenterDetails(
        this.handleGetParams()
      );

      if (centerQuery.err) {
        this.handleNavigate("centers");
      } else {
        const endOfMonth = moment().endOf("month");
        const endOfMonthOffset = endOfMonth.add(7, "d");

        this.props.getSelectedBookings(
          centerQuery.data?.id ?? "",
          getMonday(new Date()),
          endOfMonthOffset.toDate()
        );
        this.setState({
          center: centerQuery.data,
        });

        if (this.props.authStore.user?.profession !== "HEALTH") {
          this.setState({
            roles: "G",
          });
        }
      }
    }
  };

  handleNavigate = (path: string) => {
    if (path === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (path === "bookings") {
      handleNavigation(this.props.history, "/bookings");
    }
  };

  handleChunkSelection = (value: BookingChunkKey) => {
    this.setState({
      selectedChunk: value,
    });
  };

  handleRoleSelection = (value: keyof typeof VolunteerRoles) => {
    this.setState({
      roles: value,
    });
  };

  handleChangeIndividualDate = (
    index: number,
    value: keyof typeof VolunteerShift
  ) => {
    let tempChoices: Choice[] = JSON.parse(JSON.stringify(this.state.choices));
    tempChoices[index]["answer"] = value;

    tempChoices.map((eachChoice, index) => {
      tempChoices[index]["date"] = new Date(eachChoice.date);

      if (value === "WHOLE") {
        tempChoices[index]["answer"] = "WHOLE" as keyof typeof VolunteerShift;
      } else if (tempChoices[index]["answer"] === "WHOLE") {
        tempChoices[index]["answer"] = value;
      }

      return null;
    });
    this.setState({
      choices: tempChoices,
    });
  };

  handleBookDates = async (choices: Choice[], centerId: string) => {
    try {
      this.setState({ loading: true });
      const bookRes: BookRes = await handleBookDates(
        choices,
        centerId,
        this.state.roles
      );
      if (bookRes.err) {
        handleNotification("warning", bookRes.err, "");
        this.setState({ loading: false });
      } else if (bookRes.success) {
        this.handleNavigate("bookings");
      }
    } catch (err) {
      handleNotification("warning", err.message, "");
      this.setState({ loading: false });
    }
  };

  handleConfirmBooking = () => {
    const filteredChoices: Choice[] = [];
    let workableDates: number[] = this.renderWorkableDates();
    this.state.choices.map((eachChoice) => {
      const currentChoiceDay = moment(eachChoice.date).isoWeekday();
      if (workableDates.includes(currentChoiceDay)) {
        filteredChoices.push(eachChoice);
      }
      return null;
    });

    if (filteredChoices.length > 0) {
      confirm({
        title: "Are you sure to book these dates?",
        okText: "Confirm",
        onOk: this.handleBookDates.bind(
          this,
          filteredChoices,
          this.state.center?.id,
          this.state.roles
        ),
        onCancel: () => {},
      });
    }
  };

  returnPersonnelInfo = (position: number) => {
    let personnelInfo: any = [
      {
        icon: <img src={Doses} alt="Doses per Day" style={iconStyle} />,
        amount: this.state.center.doses,
        title: i18n.t("eachCenter.doses"),
      },
      {
        icon: <img src={Healthworker} alt="Healthworker" style={iconStyle} />,
        amount: this.state.center.volHealth,
        title: i18n.t("eachCenter.healthWorker"),
      },
      {
        icon: <img src={General} alt="General" style={iconStyle} />,
        amount: this.state.center.volGeneral,
        title: i18n.t("eachCenter.general"),
      },
    ];
    return personnelInfo[position];
  };

  renderPersonnelInfo = (detail: any) => {
    return (
      <Col span={24} md={8} style={{ marginBottom: 20 }}>
        <VerticalContainer
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {detail.icon}
          <Body
            style={{
              color: COLOR.RED,
              fontSize: 14,
              maxWidth: 30,
              marginTop: 5,
              marginBottom: 5,
              textAlign: "center",
            }}
          >
            {detail.amount}
          </Body>
          <Body
            style={{
              fontSize: 14,
              maxWidth: 100,
              marginBottom: 2,
              textAlign: "center",
            }}
          >
            {detail.title}
          </Body>
        </VerticalContainer>
      </Col>
    );
  };

  returnIconInfo = (position: number) => {
    let iconInfo: any = [
      {
        img: RoleMorning,
        title: i18n.t("eachCenter.generalMorning"),
      },
      {
        img: RoleEvening,
        title: i18n.t("eachCenter.generalEvening"),
      },
    ];
    return iconInfo[position];
  };

  renderIconInfo = (detail: any) => {
    return (
      <RowContainer
        style={{
          marginBottom: 15,
          marginLeft: 20,
          marginTop:
            this.props.utilsStore.deviceType === DeviceType.Mobile ? 0 : 15,
        }}
      >
        <img
          alt={detail.title}
          src={detail.img}
          style={{
            width: 30,
            marginRight: 10,
          }}
        />
        <Body style={{ marginBottom: 0 }}>{detail.title}</Body>
      </RowContainer>
    );
  };

  renderDateCell = (value: moment.Moment) => {
    if (this.props.bookStore.selectedLoading) {
      return null;
    }
    const currentMonday = getMonday(new Date());
    if (
      this.state.center &&
      this.state.roles &&
      value.isSameOrAfter(currentMonday)
    ) {
      let selectedPersonel: Personnel | undefined;
      this.state.center.personnelNeeded.map((eachPersonel) => {
        const startDate = moment(eachPersonel.startDate.seconds * 1000).startOf(
          "day"
        );
        const endDate = moment(eachPersonel.endDate.seconds * 1000).endOf(
          "day"
        );

        const inRange = value.isBetween(startDate, endDate);
        if (inRange) {
          selectedPersonel = eachPersonel;
        }
        return null;
      });

      if (selectedPersonel) {
        let morningNumber: number = 0;
        let eveningNumber: number = 0;

        if (this.state.roles === "G") {
          morningNumber = selectedPersonel.volGeneralNo;
          eveningNumber = selectedPersonel.volGeneralNo;
        } else if (this.state.roles === "C") {
          morningNumber = selectedPersonel.volHealthCounsNo;
          eveningNumber = selectedPersonel.volHealthCounsNo;
        } else if (this.state.roles === "O") {
          morningNumber = selectedPersonel.volHealthObsNo;
          eveningNumber = selectedPersonel.volHealthObsNo;
        } else if (this.state.roles === "V") {
          morningNumber = selectedPersonel.volHealthVacNo;
          eveningNumber = selectedPersonel.volHealthVacNo;
        }

        if (this.props.bookStore.selectedBookings.length > 0) {
          this.props.bookStore.selectedBookings.map((eachBooking) => {
            if (eachBooking.roles === this.state.roles) {
              eachBooking.bookDate.map((eachBookDate, index) => {
                const currentBookDay = moment(eachBookDate.seconds * 1000);
                if (currentBookDay.isSame(value, "day")) {
                  const selectedBookingShift = eachBooking.shift[index];
                  if (selectedBookingShift === "MOR") {
                    morningNumber -= 1;
                  } else if (selectedBookingShift === "EVE") {
                    eveningNumber -= 1;
                  } else {
                    morningNumber -= 1;
                    eveningNumber -= 1;
                  }
                }
                return null;
              });
            }
            return null;
          });
        }

        return (
          <VerticalContainer style={{ width: "100%", alignItems: "flex-end" }}>
            <RowContainer>
              <Footer style={{ marginBottom: 0, color: COLOR.BLACK }}>
                {morningNumber}
              </Footer>
              <img
                alt={"Role Morning"}
                src={RoleMorning}
                style={{
                  width: 20,
                  marginLeft: 5,
                }}
              />
            </RowContainer>
            <RowContainer style={{ marginTop: 10 }}>
              <Footer style={{ marginBottom: 0, color: COLOR.BLACK }}>
                {eveningNumber}
              </Footer>
              <img
                alt={"Role Evening"}
                src={RoleEvening}
                style={{
                  width: 20,
                  marginLeft: 5,
                }}
              />
            </RowContainer>
          </VerticalContainer>
        );
      }
      return null;
    } else {
      return null;
    }
  };

  renderWorkableDates = () => {
    let workableDates: number[] = [];
    switch (this.state.selectedChunk) {
      case "FIRST":
        workableDates = [1, 2, 3];
        break;
      case "SECOND":
        workableDates = [4, 5];
        break;
      case "THIRD":
        workableDates = [6, 7];
        break;
      case "WHOLE":
        workableDates = [1, 2, 3, 4, 5, 6, 7];
        break;
    }

    return workableDates;
  };

  renderIndividualDateSelection = () => {
    const listComponent: ReactElement[] = [];
    this.state.choices.map((eachChoice, index) => {
      let selections = [];
      if (eachChoice.availableShift === "WHOLE") {
        selections.push("WHOLE", "MOR", "EVE");
      } else if (eachChoice.availableShift === "MOR") {
        selections.push("MOR");
      } else if (eachChoice.availableShift === "EVE") {
        selections.push("EVE");
      }

      let workableDates: number[] = this.renderWorkableDates();
      const currentChoiceDay = moment(eachChoice.date).isoWeekday();
      if (!workableDates.includes(currentChoiceDay)) {
        return null;
      }

      listComponent.push(
        <li
          style={{
            marginBottom: 15,
            color: COLOR.BLACK,
          }}
          key={index}
        >
          <FlexibleRowContainer
            style={{
              alignItems:
                this.props.utilsStore.deviceType === DeviceType.Mobile
                  ? "flex-start"
                  : "center",
            }}
          >
            <Body
              style={{
                marginBottom: 0,
                width: 150,
              }}
            >
              {eachChoice.date.toDateString()}
            </Body>
            <Select
              defaultValue={"MOR" as keyof typeof VolunteerShift}
              value={eachChoice.answer}
              onChange={this.handleChangeIndividualDate.bind(this, index)}
              style={{
                width: 130,
              }}
            >
              {selections.map((shift: any, index: any) => (
                <Option
                  key={shift as keyof typeof VolunteerShift}
                  value={shift as keyof typeof VolunteerShift}
                >
                  {Object.values(
                    VolunteerShift[shift as keyof typeof VolunteerShift]
                  )}
                </Option>
              ))}
            </Select>
          </FlexibleRowContainer>
        </li>
      );
      return null;
    });

    return listComponent;
  };

  renderChunkOptions = () => {
    const bookingChunkOptions: any = [];

    if (this.props.authStore.user?.profession === "HEALTH") {
      bookingChunkOptions.push(
        <Option key={"WHOLE"} value={"WHOLE"}>
          {BookingChunk.WHOLE}
        </Option>
      );
    } else {
      let mondaySlotOccupied = false;
      let thurSlotOccupied = false;
      let satSlotOccupied = false;

      if (this.props.bookStore.bookings.length > 0) {
        this.props.bookStore.bookings.map((eachBooking) => {
          const startDate = moment(eachBooking.startDate.seconds * 1000);
          const thisMonday = startDate.startOf("week");
          const selectedChoiceMonday = moment(
            this.state.choices[0].date
          ).startOf("week");

          if (selectedChoiceMonday.isSame(moment(thisMonday), "date")) {
            if (eachBooking.bookDate) {
              eachBooking.bookDate.map((eachBookDate) => {
                const currentDay = moment(eachBookDate.seconds * 1000).day();
                if (currentDay === 1 || currentDay === 2 || currentDay === 3) {
                  mondaySlotOccupied = true;
                }
                if (currentDay === 4 || currentDay === 5) {
                  thurSlotOccupied = true;
                }
                if (currentDay === 6 || currentDay === 0) {
                  satSlotOccupied = true;
                }
                return null;
              });
            }
          }
          return null;
        });
      }

      const allWeekOccupied =
        mondaySlotOccupied || thurSlotOccupied || satSlotOccupied;
      Object.keys(BookingChunk).map((chunk: any, index: any) => {
        if (chunk === "WHOLE" && allWeekOccupied) {
          return null;
        } else if (chunk === "FIRST" && mondaySlotOccupied) {
          return null;
        } else if (chunk === "SECOND" && thurSlotOccupied) {
          return null;
        } else if (chunk === "THIRD" && satSlotOccupied) {
          return null;
        }

        bookingChunkOptions.push(
          <Option
            key={chunk as BookingChunkKey}
            value={chunk as BookingChunkKey}
          >
            {Object.values(BookingChunk[chunk as BookingChunkKey])}
          </Option>
        );
        return null;
      });
    }

    return bookingChunkOptions;
  };

  renderChunkSelection = () => {
    if (this.state.choices.length > 0) {
      return (
        <>
          {this.renderSteps(2, i18n.t("eachCenter.stepBookType"))}
          <Select
            value={this.state.selectedChunk as BookingChunkKey}
            onChange={this.handleChunkSelection.bind(this)}
            style={{
              width: 200,
            }}
          >
            {this.renderChunkOptions()}
          </Select>
        </>
      );
    } else {
      return null;
    }
  };

  renderDateSelection = () => {
    if (this.state.choices.length > 0 && this.state.selectedChunk) {
      let workableDates: number[] = this.renderWorkableDates();
      let isAValidChunkBooking = false;
      this.state.choices.map((eachChoice) => {
        const currentChoiceDay = moment(eachChoice.date).isoWeekday();
        if (workableDates.includes(currentChoiceDay)) {
          isAValidChunkBooking = true;
        }
        return null;
      });

      const firstDate = this.state.choices[0].date.toDateString();
      const selectionLength = this.state.choices.length;
      const lastDate =
        this.state.choices[selectionLength - 1].date.toDateString();

      return (
        <>
          {this.renderSteps(3, i18n.t("eachCenter.stepBookDate"), 50)}
          <Body
            style={{
              color: COLOR.RED,
              textAlign: "justify",
              marginBottom: 10,
            }}
          >
            {i18n.t("eachCenter.selectedShiftRange")} {firstDate}{" "}
            {i18n.t("eachCenter.to")} {lastDate}
          </Body>
          <List>
            <ul>{this.renderIndividualDateSelection()}</ul>
          </List>
          <RowContainer
            style={{
              margin: "30px 0px",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            {isAValidChunkBooking && (
              <Button
                text={i18n.t("eachCenter.confirmParticipation")}
                width="auto"
                small={true}
                onClick={this.handleConfirmBooking}
              />
            )}
          </RowContainer>
        </>
      );
    } else {
      return null;
    }
  };

  renderCenterPreviewImage = () => {
    let imageUrl = "";

    if (this.state.center.imageToken) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const storageBucketId = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      imageUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/centers%2F${this.state.center.id}%2Fimage.jpg?alt=media&token=${this.state.center.imageToken}`;
    }

    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="Place Preview"
          style={{
            width: "100%",
            height: 300,
            objectFit: "cover",
            borderRadius: 10,
          }}
        />
      );
    } else {
      return null;
    }
  };

  renderProfession = () => {
    if (this.props.authStore.user) {
      const profession =
        Object(ProfessionType)[this.props.authStore.user.profession];
      if (
        profession === ProfessionType.OTHERS ||
        profession === ProfessionType.NGO ||
        profession === ProfessionType.STUDENT
      ) {
        return "General Volunteer";
      } else if (profession === ProfessionType.HEALTH) {
        return "Health Volunteer";
      }
    }
    return "";
  };

  renderBookActions = () => {
    if (
      this.state.center &&
      !this.props.bookStore.loading &&
      this.props.authStore.user &&
      this.props.authStore.userAuth
    ) {
      return (
        <VerticalContainer
          style={{
            marginTop: 5,
            marginBottom: 50,
            width: "90%",
            alignItems: "flex-start",
          }}
        >
          {this.state.choiceError && (
            <Body
              style={{
                color: COLOR.RED,
                marginBottom: 5,
                textAlign: "justify",
              }}
            >
              {this.state.choiceError}
            </Body>
          )}
          {this.renderChunkSelection()}
          {this.renderDateSelection()}
        </VerticalContainer>
      );
    } else {
      return null;
    }
  };

  renderRoleSelection = () => {
    if (this.props.authStore.user?.profession === "HEALTH") {
      const roles = this.props.authStore.user?.professionHealthType;
      const allowedRoles: (keyof typeof VolunteerRoles)[] = [];
      if (roles === "DOC" || roles === "GRADDOC") {
        allowedRoles.push("V", "O", "C");
      } else if (roles === "NUR" || roles === "DEN" || roles === "GRADDEN") {
        allowedRoles.push("V");
      } else if (roles === "MA") {
        allowedRoles.push("O");
      } else if (roles === "PHA" || roles === "GRADPHA") {
        allowedRoles.push("C");
      }

      return (
        <RowContainer style={{ width: "100%", margin: 20 }}>
          <Body style={{ margin: "0px 20px 0px 20px" }}>
            {i18n.t("eachCenter.roles")} :
          </Body>
          <Select
            id="roles"
            value={this.state.roles ?? ("" as keyof typeof VolunteerRoles)}
            onChange={this.handleRoleSelection}
            style={{
              flex: 1,
              maxWidth: 250,
              marginRight: 20,
            }}
          >
            {allowedRoles.map((roles: any, index: any) => (
              <Option
                key={roles as keyof typeof VolunteerRoles}
                value={roles as keyof typeof VolunteerRoles}
              >
                {Object.values(
                  VolunteerRoles[roles as keyof typeof VolunteerRoles]
                )}
              </Option>
            ))}
          </Select>
        </RowContainer>
      );
    }
  };

  renderSteps = (index: number, description: string, marginTop?: number) => {
    return (
      <RowContainer style={{ marginTop: marginTop ?? 30, marginBottom: 10 }}>
        <Avatar
          style={{ backgroundColor: COLOR.RED, width: 40, minWidth: 40 }}
          size="large"
        >
          {index}
        </Avatar>
        <VerticalContainer style={{ marginLeft: 10, alignItems: "flex-start" }}>
          <Body
            style={{ fontWeight: "bold", color: COLOR.BLACK, marginBottom: 5 }}
          >
            {i18n.t("header.step")} {index}
          </Body>
          <Body style={{ marginBottom: 0 }}>{description}</Body>
        </VerticalContainer>
      </RowContainer>
    );
  };

  render() {
    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <VerticalContainer style={{ width: "100%" }}>
          <WindowResizer />
          <NavigatorSecurity />
          <Header history={this.props.history} />
          <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
            <Row style={{ width: "100%", marginBottom: 30 }} gutter={8}>
              <Col
                span={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 24
                    : 12
                }
              >
                {this.renderCenterPreviewImage()}
              </Col>
              <Col
                span={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 24
                    : 12
                }
                style={{
                  marginTop:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 20
                      : 0,
                }}
              >
                <VerticalContainer style={{ marginLeft: 5, width: "100%" }}>
                  <BaseProfileContainer>
                    <RowContainer
                      style={{
                        width: "90%",
                        margin:
                          this.props.utilsStore.deviceType === DeviceType.Tablet
                            ? "10px 5px"
                            : "20px 5px",
                        justifyContent:
                          this.props.utilsStore.deviceType === DeviceType.Mobile
                            ? "center"
                            : "flex-start",
                      }}
                    >
                      <Title3>{i18n.t("eachCenter.personnelNeeded")}</Title3>
                    </RowContainer>
                    <Row
                      style={{
                        width:
                          this.props.utilsStore.deviceType <= DeviceType.Tablet
                            ? "90%"
                            : "80%",
                        marginBottom: 20,
                      }}
                    >
                      {this.renderPersonnelInfo(this.returnPersonnelInfo(0))}
                      {this.renderPersonnelInfo(this.returnPersonnelInfo(1))}
                      {this.renderPersonnelInfo(this.returnPersonnelInfo(2))}
                    </Row>
                  </BaseProfileContainer>
                </VerticalContainer>
              </Col>
            </Row>
            <Row style={{ width: "100%" }} gutter={8}>
              <VerticalContainer
                style={{
                  width: "100%",
                  alignItems: "flex-start",
                  marginTop:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 20
                      : 0,
                  padding: 5,
                }}
              >
                <Title2 style={{ marginBottom: 10 }}>
                  {this.state.center.centerName}
                </Title2>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.address")}
                  {this.state.center.centerAddress.street},
                  {this.state.center.centerAddress.district},
                  {this.state.center.centerAddress.poscode},
                  {this.state.center.centerAddress.state}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.contactNumber")}
                  {this.state.center.centerContactNo}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.contactEmail")}{" "}
                  {this.state.center.centerEmail}
                </Body>
                <Body style={{ marginBottom: 5 }}>
                  {i18n.t("eachCenter.direction")}
                  {this.state.center.description}
                </Body>
              </VerticalContainer>
            </Row>
            <VerticalContainer
              style={{
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: 40,
              }}
            >
              <Title3
                style={{
                  marginBottom:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 0
                      : 5,
                }}
              >
                {i18n.t("eachCenter.selectVolunteerDate")}
              </Title3>
              <FlexibleRowContainer
                style={{
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  border: "solid 1px #ccc",
                  borderRadius: 10,
                  marginTop: 15,
                }}
              >
                <Body style={{ marginLeft: 20, marginTop: 15 }}>
                  {i18n.t("eachCenter.shift")} :
                </Body>
                {this.renderIconInfo(this.returnIconInfo(0))}
                {this.renderIconInfo(this.returnIconInfo(1))}
              </FlexibleRowContainer>

              <BaseCalenderContainer style={{ marginTop: 20 }}>
                {this.props.authStore.user?.profession === "HEALTH" &&
                  this.renderRoleSelection()}
                {this.state.center && this.state.roles && (
                  <>
                    <VerticalContainer
                      style={{
                        width: "90%",
                        overflow: "auto",
                        overflowY: "hidden",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      {this.renderSteps(
                        1,
                        i18n.t("eachCenter.selectShiftInstruction")
                      )}
                      <Calendar
                        fullscreen={true}
                        dateCellRender={this.renderDateCell}
                        onSelect={this.handleSelectDate}
                        locale={locale}
                        style={{
                          width: "100%",
                          minWidth: 500,
                          marginTop: 10,
                        }}
                        mode={"month"}
                      />
                    </VerticalContainer>
                    {this.renderBookActions()}
                  </>
                )}
              </BaseCalenderContainer>
            </VerticalContainer>
          </div>
          <FooterBar history={this.props.history} />
        </VerticalContainer>
      </Spin>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    bookStore: state.bookStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBookings: () => dispatch(getBookings()),
    getSelectedBookings: (centerId: string, startDate: Date, endDate: Date) =>
      dispatch(getSelectedBookings(centerId, startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EachCenter);

const BaseProfileContainer = styled(VerticalContainer)`
  background: ${COLOR.LIGHTGREY};
  width: 100%;
  border-radius: 10px;
`;

const BaseCalenderContainer = styled(VerticalContainer)`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
`;
