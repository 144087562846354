export enum VolunteerShift {
  MOR = "Morning",
  EVE = "Evening",
  WHOLE = "Whole Day",
}

export enum VolunteerRoles {
  G = "General Volunteer",
  V = "Vaccination Healthcare Volunteer",
  O = "Observation Bay Healthcare Volunteer",
  C = "Counselling Healthcare Volunteer",
}

export enum ValidationResult {
  "V" = "Validated",
  "IV" = "Invalidated",
}

export interface Validation {
  date: Date;
  createdAt: Date;
  result: keyof typeof ValidationResult;
}

enum BookingType {
  WEEK = "week",
  DAY = "day",
}

export type VolunteerShiftType = keyof typeof VolunteerShift;

export interface BookingModel {
  id: string;
  userId: string;
  centerId: string;
  bookDate: Date[];
  startDate: Date;
  endDate: Date;
  shift: VolunteerShiftType[];
  roles: keyof typeof VolunteerRoles;
  type: keyof typeof BookingType;
  createdAt: Date;
  medicalVerified?: false;
  validation?: Validation[];
}
