export interface utilsStateInterface {
  deviceType: DeviceType;
  deviceSize: number;
  language: string;
}

export enum DeviceType {
  Mobile = 1,
  Tablet = 2,
  Laptop = 3,
}

export enum DeviceSize {
  minMobileBreakpoints = 576,
  minTabletBreakpoints = 768,
  minLaptopBreakpoints = 992,
  minBigLaptopBreakpoints = 1200,
}

const initState: utilsStateInterface = {
  deviceType: DeviceType.Tablet,
  deviceSize: 0,
  language: "bm",
};

const utilsReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_DEVICE_TYPE":
      return {
        ...state,
        deviceType: action.payload.deviceType,
      };
    case "UPDATE_DEVICE_SIZE":
      return {
        ...state,
        deviceSize: action.payload.deviceSize,
      };
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: action.payload.language,
      };
    default:
      return state;
  }
};

export default utilsReducer;
