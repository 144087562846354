import { Table } from "antd";
import i18n from "i18next";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterBar from "../components/base/FooterBar";
import Header from "../components/base/Header";
import WindowResizer from "../components/WindowResizer";
import Centers from "../models/Center";
import SecurityNavigator from "../navigator/NavigatorSecurity";
import { getBookings } from "../store/actions/bookActions";
import { getCenters } from "../store/actions/centerActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { bookStateInterface } from "../store/reducers/bookReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  bookStore: bookStateInterface;
  centerStore: centerStateInterface;
  getBookings: () => void;
  getCenters: (state: string) => void;
}

interface State {
  tableData: [];
}

class Bookings extends Component<Props> {
  state: State = {
    tableData: [],
  };

  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: i18n.t("bookings.centerName"),
      dataIndex: "centerName",
    },
    {
      title: i18n.t("bookings.district"),
      dataIndex: "district",
    },
    {
      title: i18n.t("bookings.volDate"),
      dataIndex: "volunteerDate",
    },
    {
      title: i18n.t("bookings.volShift"),
      dataIndex: "volunteerShift",
    },
  ];

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.authStore.user) !==
        JSON.stringify(prevProps.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetData();
    }
  }

  handleGetData = () => {
    this.props.getBookings();
    this.props.getCenters(this.props.authStore.user?.address.state ?? "");
  };

  handleBookingSelected = (e: any) => {
    return {
      onClick: () => {
        handleNavigation(this.props.history, "/bookingDetails/" + e.key);
      },
    };
  };

  renderTableData = () => {
    let listView: any = [];
    if (this.props.bookStore.bookings.length > 0) {
      this.props.bookStore.bookings.map((eachBook, index) => {
        let selectedCenter = {
          centerName: "",
          district: "",
        };
        this.props.centerStore.centers.map((eachCenter: Centers) => {
          if (eachBook.centerId === eachCenter.id) {
            selectedCenter = {
              centerName: eachCenter.centerName,
              district: eachCenter.centerAddress.district,
            };
          }
          return null;
        });

        let volunteerShift = "";
        eachBook.shift.forEach((shift, index) => {
          if (index !== eachBook.shift.length - 1) {
            volunteerShift = volunteerShift.concat(shift + ", ");
          } else {
            volunteerShift = volunteerShift.concat(shift);
          }
          return null;
        });

        listView.push({
          key: eachBook.id,
          id: index + 1,
          district: selectedCenter.district,
          centerName: selectedCenter.centerName,
          volunteerDate:
            moment(eachBook.startDate.seconds * 1000).format("DD/MM/YYYY") +
            " - " +
            moment(eachBook.endDate.seconds * 1000).format("DD/MM/YYYY"),
          volunteerShift: volunteerShift,
        });
        return null;
      });
    }
    return listView;
  };

  renderTable = () => {
    return (
      <Table
        style={{
          width: "100%",
          overflowX: "auto",
          cursor: "pointer",
        }}
        columns={this.columns}
        dataSource={this.renderTableData()}
        onRow={this.handleBookingSelected}
      />
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <SecurityNavigator />
        <Header history={this.props.history} />
        <div className="flex flex-col items-start w-full max-w-7xl py-10 px-4 sm:px-6">
          <h1 className="text-2xl font-bold tracking-tight text-warm-gray-900 sm:text-3xl">
            {i18n.t("bookings.bookingTitle")}
          </h1>
          <RowContainer
            style={{
              width: "100%",
              marginTop: 15,
            }}
          >
            {this.renderTable()}
          </RowContainer>
        </div>
        <FooterBar history={this.props.history} />
      </VerticalContainer>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    bookStore: state.bookStore,
    centerStore: state.centerStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBookings: () => dispatch(getBookings()),
    getCenters: (state: string) => dispatch(getCenters(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
